import {
	Component, ComponentRef, ComponentFactory,
	ComponentFactoryResolver,
	EventEmitter,
	Input,
	OnChanges, OnDestroy, OnInit,
	Output,
	SimpleChanges, ViewChild, ViewContainerRef, ChangeDetectorRef, ElementRef
} from '@angular/core';
import {ChartContainerComponent, ChartService, TableContainerComponent} from '../../chart.service';
import {IntervalChooserData, IntervalService} from '../../interval.service';
import {UltraLiteChartComponent} from '../../../core/dashboard/website/impressions-segment/ultra-lite-chart/ultra-lite-chart.component';
import {Subscription} from 'rxjs';
import {CoreService} from '../../core.service';
import {ChartTableResource} from '../../../resources/table-chart-base.resource';
import {BlockRateBarComponent} from '../../block-rate-bar/block-rate-bar.component';
import {BlockRatePieComponent} from '../../block-rate-pie/block-rate-pie.component';
import {BlockrateTrendTableComponent} from '../../simple-table/blockrate-trend-table/blockrate-trend-table.component';
import {SimpleTableComponent} from '../../simple-table/simple-table.component';
import {BlockratePieTableComponent} from '../../simple-table/blockrate-pie-table/blockrate-pie-table.component';
import {UltraLiteTableComponent} from '../../simple-table/ultra-lite-table/ultra-lite-table.component';
import {ImpressionsChartComponent} from '../../../core/dashboard/website/impressions-segment/impressions-chart/impressions-chart.component';

@Component({
	selector: 'app-table-chart',
	templateUrl: './table-chart.component.html',
	styleUrls: ['./table-chart.component.less'],
})

export class TableChartComponent implements OnInit, OnChanges, OnDestroy {
	@Input() aabSiteIds: string[];
	@Input() intervalData: IntervalChooserData;
	@Input() chart: {name: string, title: string};
	@Input() table: {name: string, sortBy: string};
	@Input() chartDesc?: string;
	@Input() metadata?: any;
	@Output() changeDataOk = new EventEmitter<boolean>();
	@Output() changeMetadata = new EventEmitter<any>();
	@ViewChild('chartContainer', {read: ViewContainerRef, static: false}) chartContainer: ViewContainerRef;
	private chartPlaceholder: ElementRef;
	@ViewChild('chartContainer') set content(content: ElementRef) {
		if (content) { // initially setter gets called with undefined
			this.chartPlaceholder = content;
			this.loadComponent();
		}
	}
	@ViewChild('tableContainer', {read: ViewContainerRef, static: false}) tableContainer: ViewContainerRef;
	private tablePlaceholder: ElementRef;
	@ViewChild('tableContainer') set tableContent(tableContent: ElementRef) {
		if (tableContent) { // initially setter gets called with undefined
			this.tablePlaceholder = tableContent;
			this.loadComponent();
		}
	}
	@Input() resource: ChartTableResource;
	factories: {string: ComponentFactory<any>} | {} = {};
	tableData: any[] = [];
	chartData: any;
	dataOk = false;
	showDimmer = false;
	chartSubscription: Subscription;
	properties: string[];
	resMetadata: any;
	chartCreated: ComponentRef<any> = null;
	tableCreated: ComponentRef<any> = null;

	constructor(private componentFactoryResolver: ComponentFactoryResolver,
				private chartService: ChartService,
				private coreService: CoreService,
				private intervalService: IntervalService,
				private cd: ChangeDetectorRef,
				) {
	}

	ngOnInit() {
		this.createFactories();
	}

	getComponentFactory(type: string): ComponentFactory<any> {
		let ret = this.factories[type];
		if (!ret) {
			ret = this.factories['simpleTable'];
		}
		return ret;
	}

	createFactories() {
		this.factories['ultraLite'] = this.componentFactoryResolver.resolveComponentFactory(UltraLiteChartComponent);
		this.factories['blockRateBar'] = this.componentFactoryResolver.resolveComponentFactory(BlockRateBarComponent);
		this.factories['blockRatePie'] = this.componentFactoryResolver.resolveComponentFactory(BlockRatePieComponent);
		this.factories['blockRateTrendTable'] = this.componentFactoryResolver.resolveComponentFactory(BlockrateTrendTableComponent);
		this.factories['blockRatePieTable'] = this.componentFactoryResolver.resolveComponentFactory(BlockratePieTableComponent);
		this.factories['ultraLiteTable'] = this.componentFactoryResolver.resolveComponentFactory(UltraLiteTableComponent);
		this.factories['simpleTable'] = this.componentFactoryResolver.resolveComponentFactory(SimpleTableComponent);
		this.factories['impressions'] = this.componentFactoryResolver.resolveComponentFactory(ImpressionsChartComponent);
	}

	loadComponent() {
		if (this.chartCreated) {
			this.chartCreated.destroy();
		}
			const factory = this.getComponentFactory(this.chart.name);
			if (this.chartContainer && this.chartData && factory) {
				const ref = this.chartContainer.createComponent(factory);

				(<ChartContainerComponent>ref.instance).data = this.chartData;
				(<ChartContainerComponent>ref.instance).title = this.chart.title;
				this.chartCreated = ref;
			}
			if (this.tableCreated) {
				this.tableCreated.destroy();
			}
			const factoryTable = this.getComponentFactory(this.table.name);
			if (this.tableContainer && this.tableData && factoryTable) {
				const ref2 = this.tableContainer.createComponent(factoryTable);

				(<TableContainerComponent>ref2.instance).tableData = this.tableData;
				(<TableContainerComponent>ref2.instance).sortBy = this.table.sortBy;
				this.tableCreated = ref2;
			}
			this.cd.detectChanges();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['intervalData'] && changes.intervalData.previousValue &&
			this.intervalService.isDifferentIntervalData(changes.intervalData.previousValue, this.intervalData)) {
				this.intervalData = changes.intervalData.currentValue;
				this.postData();
		}
		if (changes['aabSiteIds'] && this.coreService.isChangeInFilterOfAabSiteIds(changes)) {
			this.postData();
		} else if (changes['metadata']) {
			this.postData();
		}
	}

	postData() {
		if (this.coreService.isLessThan100(this.aabSiteIds)) {
			this.showDimmer = true;
			this.chartSubscription = this.resource.post(this.aabSiteIds, this.intervalData, this.metadata).subscribe(res => {
				if (this.chart.name === 'blockRatePie') {
					this.dataOk = res.data && res.data['Blocking'] && res.data['Blocking'].length > 0;
				} else {
					this.dataOk = res.data && res.data.length > 0;
				}
				this.changeDataOk.emit(this.dataOk);
				this.resMetadata = res.metadata;
				this.changeMetadata.emit(this.resMetadata);
				if (this.dataOk) {
					if (this.chart.name === 'blockRatePie') {
						this.properties = Object.keys(res.data['Blocking'][0]);
					} else {
						this.properties = Object.keys(res.data[0]).filter(i => i !== 'date');
					}
					this.chartData = this.getChartData(res.data);
					this.tableData = this.getTableData(JSON.parse(JSON.stringify(res.data)));
					this.loadComponent();
				}
				this.showDimmer = false;
			}, err => {
				this.dataOk = false;
				this.changeDataOk.emit(this.dataOk);
				this.showDimmer = false;
			});
		}
	}

	ngOnDestroy() {
		if (this.chartContainer) {
			this.chartContainer.clear();
		}
		if (this.chartSubscription) {
			this.chartSubscription.unsubscribe();
		}
	}

	prepareTableForBrowserOs(data, state) {
		data.forEach(i => {
				const parent = data.filter(p => p['ids'] === i['parents']);
				if (parent && parent.length === 1) {
					const parentName = parent[0]['labels'];
					i['parents'] = parentName;
				}
				i['state'] = state;
				delete i['colors'];
				delete i['values'];
			});
		data.forEach(i => delete i['ids']);
		return data;
	}

	getTableData(data: any[]) {
		let final;
		if (this.chart.name === 'blockRatePie') {
			const blocking = this.prepareTableForBrowserOs(data['Blocking'], 'Blocking');
			const non_blocking = this.prepareTableForBrowserOs(data['Non Blocking'], 'Non Blocking');
			final = blocking.concat(non_blocking);
		} else {
			const d = data.slice();
			final = d.filter(i => {
				let atLeastOne = false;
				this.properties.forEach(p => {
					if (i[p] !== 0) {
						atLeastOne = true;
					}
				});
				return atLeastOne;
			});
		}
		return final;
	}

	getChartData(data: any[]) {
		if (this.chart.name === 'blockRatePie') {
			return {data: data, metadata: this.resMetadata};
		} else {
			return this.chartService.prepareDataForScatterChart(this.properties, data);
		}
	}

}
