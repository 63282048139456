import {Component, OnInit, Input} from '@angular/core';
import {Publisher, Website} from 'app/core/models/data-structure';
import {Router, ActivatedRoute} from '@angular/router';
import {WebsiteService} from '../website.service';

@Component({
	selector: 'app-publisher-website-select',
	templateUrl: './publisher-website-select.component.html',
	styleUrls: ['./publisher-website-select.component.css']
})

export class PublisherWebsiteSelectComponent implements OnInit {
	@Input() publishers: Publisher[] = [];
	@Input() selectedPublisher: Publisher;
	@Input() selectedWebsite: Website;
	websites: Website[] = [];
	isMorePublishers = false;

	constructor(private router: Router, private route: ActivatedRoute, private websiteService: WebsiteService) {
			this.getInitData();
	}

	getInitData() {
		this.websites = this.selectedPublisher ? this.selectedPublisher.websites : [];
	}

	ngOnInit() {
		this.getInitData();
		this.isMorePublishers = this.publishers.length > 1;
		if (!this.isMorePublishers) {
			this.selectedPublisher = this.publishers[0];
			this.onChangePublisher();
		}
	}

	onChangePublisher() {
		this.websites = this.selectedPublisher.websites;
	}

	onChangeWebsite() {
		this.websiteService.setWebsite(this.selectedWebsite);
		const currentPath = this.route.snapshot['_urlSegment']['segments'] !== 0 ? this.route.snapshot['_urlSegment']['segments'][1]['path']
			: 'website-detail';
		this.router.navigate(['/website', currentPath, this.selectedPublisher.publisher_data.aab_publisher_id, this.selectedWebsite.aab_site_id],
			{queryParamsHandling: 'merge', fragment: this.route.snapshot.fragment});
	}

}
