<fui-modal [isClosable]="true" [size]="normal" (dismissed)="tag.showDeleteTagModal = false" #modalDelete *ngIf="tag && tag.showDeleteTagModal">
	<div class="header">Delete tag <a class="ui teal label"><i class="tag icon" ></i>{{tag.name}}</a> ?</div>
	<div class="content">
		<div class="ui three column divided grid">
		  <div class="row">
			<div class="column">
			  <span class="sub header">Publisher: </span>{{tag.aab_publisher_id}}<br>
			</div>
			<div class="column">
			   <span class="sub header">Website: </span>{{tag.aab_site_id}}<br>
			</div>
			<div class="column">
			  <span class="sub header">Zone: </span>{{tag.zone_name}}
			</div>
		  </div>
		  <div class="row">
			<div class="column">
			  <span class="sub header">Adserver: </span>{{tag.adserver}}
			</div>
			<div class="column">
			   <span class="sub header">Category: </span>{{tag.category}}<br>
			</div>
			<div class="column">
			  <span class="sub header">Name: </span>{{tag.name}}
			</div>
		  </div>
		</div>
	  <div class="ui red button" style="float: right; margin: 5px" (click)="modalDelete.approve('save'); deleteTag(tag);">
		  <i class="trash icon"></i>Delete
	  </div>
	</div>
</fui-modal>
