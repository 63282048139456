import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {SectionsService} from '../sections.service';
import {Message, MessageService} from '../message.service';
import * as _ from 'lodash';
import {Website} from '../../core/models/data-structure';
import {Subscription} from 'rxjs';


@Component({
	selector: 'app-website-sections-selector',
	templateUrl: './website-sections-selector.component.html',
})

export class WebsiteSectionsSelectorComponent implements OnInit, OnChanges {
	@Input() checkedWebsitesNames: string[];
	@Input() checkedWebsites: Website[];
	websiteSections: string[] = [];
	selectedSections: string[] = [];
	noSectionMessage: Message = null;
	selectedSectionsSubscription: Subscription;


	constructor(private messageService: MessageService,
				private sectionsService: SectionsService) {
	}

	ngOnInit(): void {
		this.selectedSections = this.sectionsService.getSelectedSections();
		this.selectedSectionsSubscription = this.sectionsService.getSharedSelectedSectionsObservable().subscribe(selectedSelections => {
			this.selectedSections = selectedSelections;
		});
	}

	ngOnChanges() {
		// dovolujeme videt sekce jen pokud je vybrana jedinna websita
		this.websiteSections = this.sectionsService.isSectionsAvailable(this.checkedWebsites);
		if (this.websiteSections) {
			this.noSectionMessage = null;
		}
		if (this.checkedWebsites.length > 1) {
			let unusedWebsiteWithSections = null;
			this.checkedWebsites.some(web => {
				if (web.sections) {
					unusedWebsiteWithSections = web.name;
					return true;
				}
			});
			if (unusedWebsiteWithSections) {
				this.noSectionMessage = this.messageService.getNoSectionMessage(unusedWebsiteWithSections);
			}

		}
	}

	changeWebsiteSections(sections: string[]) {
		this.sectionsService.setSharedSelectedSections(sections);
	}


}
