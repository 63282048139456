import {Component} from '@angular/core';
import {ActiveFragment, ChartType} from '../../../shared/chart.service';
import {MetaTabComponent} from '../../meta/meta-tab/meta-tab.component';

@Component({
	selector: 'app-ad-scan-tab',
	templateUrl: './ad-scan-tab.component.html',
})
export class AdScanTabComponent extends MetaTabComponent {
	adScanZonesChartType = ChartType.ADSCAN_ZONES;
	adScanTemplatesChartType = ChartType.ADSCAN_PAGE_TEMPLATES;
	pageTemplatesFragment = ActiveFragment.PAGE_TEMPLATES;
	adscanZoneFragment = ActiveFragment.ADSCAN_ZONES_CHART;

	setDefaultFragment() {
		this.currentFragment = this.pageTemplatesFragment;
	}

}
