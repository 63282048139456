
<h1 class="ui teal header">FAQ</h1>
<div class="ui padded grid">
	<div class="ten wide computer sixteen wide mobile column">
		<fui-accordion [closeOthers]="false" class="ui accordion">
			<fui-accordion-panel *ngFor="let category of faqList">

				<div title>
					<h3 class="ui teal header">
						<i class="dropdown icon"></i>
						{{category.category.title}}
					</h3>
					<div>{{category.category.text}}</div>
				</div>
				<div class="padded" content>
					<fui-accordion [closeOthers]="false" class="ui accordion fluid">
						<fui-accordion-panel *ngFor="let faq of category.faq">
							<div title>
								<div class="ui strong">
									<i class="dropdown icon"></i>
									{{faq.title}}
								</div>
							</div>
							<div class="padded" content>
								<div [innerHTML]="faq.text | keepHtml"></div>
							</div>
						</fui-accordion-panel>
					</fui-accordion>
				</div>
			</fui-accordion-panel>
		</fui-accordion>
	</div>
</div>

