import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActionType, ZoneTag} from '../../../../resources/budget.resource';
import {AuthService} from '../../../../auth/auth.service';
import {Message} from '../../../../shared/message.service';


@Component({
	selector: 'app-tags-table',
	templateUrl: './tags-table.component.html',
})

export class TagsTableComponent implements OnInit {
	@Input() tags: ZoneTag[];
	@Output() onChangeTags = new EventEmitter();
	@Output() onMessage = new EventEmitter<Message>();
	canEditTags = false;
	editType = ActionType.EDIT;
	tagToEdit: ZoneTag;
	tagToDelete: ZoneTag;
	showEditTagModal = false;
	showDeleteTagModal = false;
	isDesc = false;
	column: string;
	direction: number;

	constructor(private authService: AuthService) {}

	ngOnInit() {
		this.canEditTags = this.authService.getRights().edit_budget;
		this.sortTable('name');
	}

	sortTable(by: string) {
		this.isDesc = !this.isDesc;
		this.column = by;
		this.direction = this.isDesc ? 1 : -1;
	}

	editTag(tag: ZoneTag) {
		this.tagToEdit = tag;
		this.showEditTagModal = true;
		tag.showEditTagModal = true;
	}

	deleteTag(tag: ZoneTag) {
		this.tagToDelete = tag;
		this.showDeleteTagModal = true;
		tag.showDeleteTagModal = true;
	}

	changeTags() {
		this.onChangeTags.emit();
	}

	changeMessage(message: Message) {
		this.onMessage.emit(message);
	}
}


