export enum PermissionType {
	GROUP = 'group',
	RIGHT = 'right',
}

export interface Login {
	failed: boolean;
	date: Date;
}

export interface User {
	id: string;
	email: string;
	groups?: Permission[];
	rights?: Permission[];
	brs_domains: string[];
	permissions?: Permission[];
	login?: Login[];
}

export interface Permission {
	id: string;
	type: PermissionType;
	name: string;
	target: Target;
}

export interface Target {
	admin: boolean;
	publisher: string;
	website: string;
}

export interface PublisherList {
	aab_publisher_id: string;
	publisher_name: string;
	websites: WebsitesLists[];
}

export interface WebsitesLists {
	aab_site_id: string;
	site_name: string;
}

export interface UserFilter {
	term: string;
	publisher: PublisherList;
	showAdmin: boolean;
}
