import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {PublishersService} from 'app/core/publishers.service';
import {Publisher, Website} from 'app/core/models/data-structure';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../auth/auth.service';
import {MessageService} from '../../../shared/message.service';
import {CoreService} from '../../../shared/core.service';
import {WebsiteService} from './website.service';

@Component({
	selector: 'app-core-select',
	templateUrl: './core-select.component.html',
	styleUrls: ['./core-select.component.less'],
})
export class CoreSelectComponent implements OnInit, OnDestroy {
	publishers: Publisher[] = [];
	selectedWebsite: Website;
	selectedPublisher: Publisher;
	dataSubscription: Subscription;
	paramsSubscription: Subscription;
	headerSubscription: Subscription;
	header: string;
	noPublishers = true;

	constructor(private publishersService: PublishersService,
				private route: ActivatedRoute,
				private messageService: MessageService,
				private authService: AuthService,
				private coreService: CoreService,
				private router: Router,
				private websiteService: WebsiteService) {
	}

	ngOnInit() {
		this.setHeader();
		this.headerSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.setHeader();
			}
		});
		const publishers$ = this.publishersService.getPublishers();
		this.dataSubscription = publishers$.subscribe(pubs => {
			this.publishers = pubs;
			this.noPublishers = this.publishers ? this.publishers[0] === undefined : true;
			if (this.noPublishers) {
				this.authService.logout();
				this.messageService.setNoDataMessage();
			} else {
				this.getDataOfSelectedWebsiteFromRoute();
				this.paramsSubscription = this.route.queryParams.subscribe((params: Params) => {
					const state = this.publishersService.getSelectedDataFromQueryParams(params);
					this.publishers = this.publishersService.getSelectedPublishersFromState(state);
				});
			}
		});
	}

	getDataOfSelectedWebsiteFromRoute() {
		if (this.route.snapshot.children.length !== 0) {
			const aabSiteId = this.route.snapshot['_urlSegment']['segments'][3]['path'];
			this.selectedWebsite = this.publishersService.getWebsiteByAabSiteId(aabSiteId);
			this.selectedPublisher = this.publishersService.getPublisherByAabSiteId(aabSiteId);
			this.websiteService.setWebsite(this.selectedWebsite);
		}
	}

	setHeader() {
		this.header = this.coreService.prepareHeaderFromUrlPath(this.route.snapshot['_urlSegment']['segments'][1]['path']);
	}

	ngOnDestroy() {
		if (this.dataSubscription) {
			this.dataSubscription.unsubscribe();
		}
		if (this.paramsSubscription) {
			this.paramsSubscription.unsubscribe();
		}
		if (this.headerSubscription) {
			this.headerSubscription.unsubscribe();
		}
	}

}
