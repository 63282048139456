import {Component, OnInit} from '@angular/core';
import {Website} from 'app/core/models/data-structure';
import {SelectedDataParamsComponent} from '../meta/selected-data-params/selected-data-params.component';
import {ActiveFragment} from '../../shared/chart.service';

@Component({
	selector: 'app-adscan',
	templateUrl: './ad-scan.component.html',
})
export class AdScanComponent extends SelectedDataParamsComponent implements OnInit {
	selectedWebsites: Website[] = [];
	pageTemplateFragment = ActiveFragment.PAGE_TEMPLATES;
	adScanZonesChartFragment = ActiveFragment.ADSCAN_ZONES_CHART;
	adScanZonesTableFragment = ActiveFragment.ADSCAN_ZONES_TABLE;
	possibleFragments = [this.pageTemplateFragment, this.adScanZonesChartFragment, this.adScanZonesTableFragment];

	ngOnInit() {
		super.ngOnInit();
		this.paramsChange();
	}

	paramsChange() {
		if (this.selectedData) {
			this.selectedWebsites = this.publishersService.getSelectedWebsitesFromState(this.selectedData);
		}
	}

}
