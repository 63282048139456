import {BudgetResource, ZoneTag} from '../../../../resources/budget.resource';
import {Component, EventEmitter, Input, Output, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Message, MessageState} from '../../../../shared/message.service';


@Component({
	selector: 'app-delete-tag-modal',
	templateUrl: './delete-tag-modal.component.html',
})

export class DeleteTagModalComponent implements OnDestroy {
	@Input() tag: ZoneTag;
	@Output() onMessage = new EventEmitter<Message>();
	@Output() onChangeTags = new EventEmitter();
	subscription: Subscription;

	constructor(private budgetResource: BudgetResource) {
	}

	deleteTag(tag: ZoneTag) {
		this.subscription = this.budgetResource.deleteTag(tag.id).subscribe(res => {
			this.onChangeTags.emit();
		}, err => {
			this.onMessage.emit({header: 'Tag wasn\'t deleted.', description: err['error']['description'], state: MessageState.NEGATIVE});
		});
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
