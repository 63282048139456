import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RestResource} from '../core/admin/rest/rest.component';
import {environment} from '../../environments/environment';

export class RestBaseResource implements RestResource {
	url = '/rest';
	listUrl = '/rest-list';

	constructor(protected httpClient: HttpClient) {
	}

	listItems(): Observable<any> {
		// console.log('listLoaders', this.httpClient);
		return this.httpClient.get<any>(
			environment.apiEndpoint + this.listUrl
		);
	}

	getItems(data): Observable<any> {
		return this.httpClient.get<any>(
			environment.apiEndpoint + this.url, {params: data}
		);
	}

	saveItem(data): Observable<any> {
		const copy = JSON.parse(JSON.stringify(data));
		delete copy['faq'];
		return this.httpClient.put<any>(
			environment.apiEndpoint + this.url + '/' + data['id'],
			copy
		);
	}

	createItem(data): Observable<any> {
		const copy = JSON.parse(JSON.stringify(data));
		delete copy['faq'];
		return this.httpClient.post<any>(
			environment.apiEndpoint + this.url,
			copy
		);
	}

	deleteItem(data): Observable<any> {
		return this.httpClient.delete<any>(
			environment.apiEndpoint + this.url + '/' + data['id']);
	}
}
