<div [ngClass]="{'dirty': allChange()}" class="ui verticaly divided grid">
	<div class="row" [ngClass]="small?'mini':''" (click)="open()">
		<div class="ui two wide row">
			<button class="ui icon button selection {{selectedBox}}" (click)="selectBox($event)"></button>
			<button *ngIf="fields.underline || fields.wideunderline" class="ui icon button collapse {{underlineClass}}" (click)="toggle($event)"><i class="caret square right outline icon"></i></button>
		</div>
		<div class="ui small form ten wide column" [ngClass]="small?'mini':''">
			<div class="fields wrap">
				<ng-container #container></ng-container>
			</div>
		</div>
		<div class="three wide column middle aligned">
			<ng-container *ngIf="!small">
				<button class="ui teal icon button" (click)="save($event)"><i class="save outline icon"></i></button>
				<button class="ui red icon button" (click)="delete($event)"><i class="trash alternate outline icon"></i></button>
			</ng-container>
			<div class="mini ui buttons" *ngIf="small">
				<button class="ui teal icon button" (click)="save($event)"><i class="save outline icon"></i></button>
				<button class="ui red icon button" (click)="delete($event)"><i class="trash alternate outline icon"></i></button>
			</div>
		</div>
	</div>
		<div class="ui bottom-padded row underline {{underlineClass}} grid">
			<div class="one wide column"></div>
			<div class="ui small form eleven wide column">

				<div class="fields wrap">
					<ng-container #underline></ng-container>
				</div>
			</div>
			<div class="row">
				<div class="one wide column"></div>
				<div class="ui small form fifteen wide column">
					<ng-container #wideUnderline></ng-container>
				</div>
			</div>
	</div>
</div>
