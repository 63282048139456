<table class="ui small celled table" >
	<thead>
		<tr>
			<th>User</th>
			<th>Website</th>
			<th>Hash</th>
			<th>Page Views</th>
			<th>Block Views</th>
			<th>Block Rate</th>
	  	</tr>
	</thead>
	<tbody>
		<tr *ngFor="let blockrate of blockRateOverviews">
			<fui-modal *ngIf="blockrate.showModal" [isClosable]="true" (dismissed)="blockrate.showModal = false" #modal>
				<div class="header" *ngIf="blockrate.verified">Verified!</div>
				<div class="header" *ngIf="!blockrate.verified">Unverified!</div>
				<div class="content">
					<p *ngIf="blockrate.verified">Website {{blockrate.url}} is verified.</p>
					<p *ngIf="!blockrate.verified">Website {{blockrate.url}} is  not verified.
						There is no script with id {{blockrate.hash}}.</p>
				</div>
				<div class="actions">
					<button class="ui green button" (click)="modal.approve('done')" autofocus>OK</button>
				</div>
			</fui-modal>
			<th>{{blockrate.user}}</th>
			<th>
				<div class="bsr">
					<span class="bsr-url">
						{{blockrate.url}}
					</span>
					<span [ngClass]="blockrate.verified ? 'bsr-hash  verified' : 'bsr-hash'">
						<i *ngIf="blockrate.verified; else nonVerified" title="Verified" class="check circle icon"></i>
						<ng-template #nonVerified>
							<a *ngIf="!blockrate.onLoad" class="ui mini orange button" (click)="verifySite(blockrate)">Verify</a>
							<i *ngIf="!blockrate.onLoad" title="Unverified" class="exclamation circle icon" id="unverified"></i>
							<div *ngIf="blockrate.onLoad" class="ui active mini inline loader"></div>
						</ng-template>
					</span>
				</div>
			</th>
			<th>{{blockrate.hash}}</th>
			<th>{{formatNumbers(blockrate.page_view)}}</th>
			<th>{{formatNumbers(blockrate.block_view)}}</th>
			<th>{{blockrate.block_rate}} %</th>
	  	</tr>
	</tbody>
</table>
