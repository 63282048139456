import {Component} from '@angular/core';
import {MetaPlotlyChartComponent} from '../../../../../shared/charts/meta-plotly-chart.component';
import {ChartContainerComponent} from '../../../../../shared/chart.service';

declare var Plotly: any;

@Component({
	selector: 'app-impressions-chart',
	templateUrl: './impressions-chart.component.html',
})

export class ImpressionsChartComponent extends MetaPlotlyChartComponent implements ChartContainerComponent {

	setChartTitleIdAndLayout() {
		this.data = this.data.slice();
		this.plotId = `plotly_chart_${this.title}`;
		this.layout = {
			autosize: true,
			title: this.title,
		};
		this.data.forEach(i => {
			i['type'] = 'scatter';
			if (i['name'].includes('pageviews')) {
				i['line'] = {'color': '#1169da', 'dash': 'dash'};
			} else if (i['name'].includes('ok')) {
				i['line'] = {'color': '#45ad2f'};
			} else if (i['name'].includes('rtb')) {
				i['line'] = {'color': '#ec7615'};
			} else if (i['name'].includes('ubstitute-empty')) {
				i['line'] = {'color': '#732673'};
			} else if (i['name'].includes('substitute')) {
				i['line'] = {'color': '#ffcc00'};
			} else if (i['name'].includes('empty')) {
				i['line'] = {'color': '#c83644'};
			}
			i['name'] =  this.coreService.upperCaseFirstLetter(i['name']);
		});
	}

	restyleValues() {
		if (this.isChartExist()) {
			const labels = this.data[0]['x'];
			Plotly.restyle(this.plotId, 'x', [labels]);
			for (let i = 0; this.data.length > i; i++) {
				this.restyleValueWithIndex('y', i);
			}
		}
	}
}
