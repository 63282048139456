import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IntervalChooserData, IntervalService} from '../shared/interval.service';
import {TableChartBaseResource} from './table-chart-base.resource';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class UltraLiteResource extends TableChartBaseResource {
	protected chartUrl = '/ultra-lite';

	constructor(protected httpClient: HttpClient, protected intervalService: IntervalService) {
	super(httpClient, intervalService);
	}

	postMetadata(aab_sites_ids: string[], intervalChooserData: IntervalChooserData) {
		const intervalData = JSON.parse(JSON.stringify(intervalChooserData));
		return super.postMetadata(aab_sites_ids, intervalData);
	}

	post(aab_sites_ids: string[], intervalChooserData: IntervalChooserData, metadata: any): Observable<{ data: any[], metadata: any}> {
		const intervalData = JSON.parse(JSON.stringify(intervalChooserData));
		intervalData.interval = '1d';
		return super.post(aab_sites_ids, intervalData, metadata);
	}
}
