import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActionType, BudgetResource, ZoneTag} from '../../../resources/budget.resource';
import {Subscription} from 'rxjs';
import {Message} from '../../../shared/message.service';
import * as _ from 'lodash';


@Component({
	selector: 'app-all-tags',
	templateUrl: './all-tags.component.html',
	styleUrls: ['./all-tags.component.less']
})

export class AllTagsComponent implements OnInit, OnDestroy {
	@Input() showAllTags: boolean;
	originalTags: ZoneTag[];
	filteredTags: ZoneTag[];
	allTagsSubscription: Subscription;
	message: Message;
	addType = ActionType.ADD;
	showAddTagModal = false;
	aabPublisherIds: any[];
	selectedPublishers: string[];

	constructor(private budgetResource: BudgetResource) {}

	ngOnInit() {
		this.allTagsSubscription = this.budgetResource.getAllTags().subscribe(res => {
			this.originalTags = res;
			const aabPublisherIds = _.map(this.originalTags, 'aab_publisher_id');
			const aabPublisherIdsSet = new Set(aabPublisherIds.map(item => item));
			this.aabPublisherIds = Array.from(aabPublisherIdsSet);
			this.removeOtherPublishers();
		});
	}

	changeTags() {
		setTimeout(() => {
			this.ngOnInit();
		}, 1000);
	}

	changeMessage(message: Message) {
		this.message = message;
	}

	changePublisher(publishers: string[]) {
		this.selectedPublishers = publishers;
		this.removeOtherPublishers();
	}

	removeOtherPublishers() {
		this.filteredTags = JSON.parse(JSON.stringify(this.originalTags));
		if (this.selectedPublishers && this.selectedPublishers.length > 0 && this.filteredTags && this.filteredTags.length > 0) {
			const publishersToRemove = _.differenceBy(this.aabPublisherIds, this.selectedPublishers);
			for (let i = this.filteredTags.length - 1; i > -1; i--) {
					if (publishersToRemove.indexOf(this.filteredTags[i].aab_publisher_id) >= 0) {
						this.filteredTags.splice(i, 1);
					}
			}
		}

	}

	ngOnDestroy() {
		if (this.allTagsSubscription) {
			this.allTagsSubscription.unsubscribe();
		}
	}
}


