<button class="ui teal button interval-chooser" (click)="toggle()">{{getDesc()}}</button>
<span id="absolute" *ngIf="showAbsolute">
	<fui-checkbox [(ngModel)]="absolute" (ngModelChange)=onChangeAbsolute()>Show absolute values</fui-checkbox>
</span>

<div class="overlay" *ngIf="showControls" (click)="close()">
</div>
<div id="chooser" class="ui segment" *ngIf="showControls">

	<div *ngIf="setMessage" class="ui attached warning message">
	  <i class="close icon" (click)="setMessage=false"></i>
	  <div class="header">Date Error</div>
	  <p>Date from cannot be greater than date to</p>
	</div>

	<div class="ui form attached" *ngIf="selectedPeriod">

		<div>
		  <div id="interval_type_chooser" class="label" [ngClass]="radioButtonClass">
			<div class="field">
				<fui-radio-button value="0" [(ngModel)]="intervalType" (ngModelChange)=onIntervalTypeChange()>absolute</fui-radio-button>
			</div>
			<div class="field">
				<fui-radio-button value="1" [(ngModel)]="intervalType" (ngModelChange)=onIntervalTypeChange()>quick</fui-radio-button>
			</div>
		  </div>
		  <span id='interval_type' [ngSwitch]="intervalType">

			<span *ngSwitchCase="0">
			  <button class="ui button"
					  fuiDatepicker
					  [pickerMode]="mode"
					  [(ngModel)]="dateFrom"
					  [pickerMaxDate]="pickerMaxDate"
					  (ngModelChange)=onChangeDate()>From</button>

			  <button class="ui button"
					  fuiDatepicker
					  [pickerMode]="mode"
					  [(ngModel)]="dateTo"
					  [pickerMaxDate]="pickerMaxDate"
					  (ngModelChange)=onChangeDate()>To</button>

			</span>
			<span *ngSwitchCase="0" class="date_desc">
				<span *ngIf="dateFrom">{{formatDate(dateFrom)}}</span>
				<span *ngIf="dateFrom && dateTo"> - </span>
				<span *ngIf="dateTo">{{formatDate(dateTo)}}</span>
			</span>

			<span *ngSwitchCase="1">
				<fui-select class="selection"
							id="periodSelector"
							[(ngModel)]="selectedPeriod"
							(ngModelChange)=onChangePeriod()
							[options]="periods"
							labelField="name"
							placeholder="select period"
							#selectPeriod>
					<fui-select-option *ngFor="let option of selectPeriod.filteredOptions"
									   [value]="option">
					</fui-select-option>
				</fui-select>
			</span>
		  </span>
		</div>
		<div id="interval" *ngIf="intervals && !hideIntervalSwitch">
		  <label>Granularity<app-hint-icon [hint]=intervalHint></app-hint-icon></label>
		  <fui-select   class="selection"
						[(ngModel)]="interval"
						[options]="intervals"
						(ngModelChange)=onChangeInterval()
						[isDisabled]="disableIntervalSwitch"
						#selectInterval>
				<fui-select-option *ngFor="let option of selectInterval.filteredOptions"
								   [value]="option">
				</fui-select-option>
		  </fui-select>
		</div>
		<div id="time_zone">
		  <app-timezone (changeTimezone)="changeTimeZone($event)"></app-timezone>
		</div>
		<div class="ui hidden divider"></div>
		<button id="ok-button" class="ui grey button" (click)="close($event)">OK</button>

	</div>


</div>
