import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {Website} from '../../../../models/data-structure';
import {HostageStateResource} from '../../../../../resources/hostage-state.resource';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-hostage-state',
	templateUrl: './hostage-state.component.html',
	providers: [HostageStateResource]
})

export class HostageStateComponent implements OnChanges, OnDestroy {
	@Input() selectedWebsite: Website;
	hostageState: any[];
	hostageSubscription: Subscription;
	hostageProperties: string[];

	constructor(private hostageStateResource: HostageStateResource) {}

	ngOnChanges(): void {
		this.hostageSubscription = this.hostageStateResource.get(this.selectedWebsite.aab_site_id).subscribe(res => {
			if (res.length > 0) {
				this.hostageProperties = Object.keys(res[0]);
				const index = this.hostageProperties.indexOf('header');
				if (index > -1) {
					this.hostageProperties.splice(index, 1);
				}
				this.hostageState = res;
			} else {
				this.hostageState = null;
			}
		});
	}

	formatName(name: string): string {
		return name.split('_').join(' ');
	}

	formatDate(dateStr: string): string {
		return `${dateStr.slice(0, 4)}/${dateStr.slice(4, 6)}/${dateStr.slice(6, 8)} ${dateStr.slice(8, 10)}:${dateStr.slice(10, 12)}`;
	}

	getText(state: any): string|number {
		if (typeof state === 'string') {
			if (state.length === 12) {
				return this.formatDate(state);
			}
			return state;
		} else if (typeof state === 'number') {
			return state;
		}
		return '';
	}

	getRightIcon(state: boolean): string {
		if (typeof state === 'boolean') {
			return state ? 'icon checkmark' : 'icon close';
		}
		return '';
	}

	getRightClass(state: boolean): string {
		if (typeof state === 'boolean') {
			return state ? 'positive' : 'negative';
		}
		return '';
	}

	ngOnDestroy(): void {
		if (this.hostageSubscription) {
			this.hostageSubscription.unsubscribe();
		}
	}
}
