import {AbstractControl, FormGroup} from '@angular/forms';

export function  checkTarget(publisherControl: string, websiteControl: string) {
		return (group: FormGroup): {[key: string]: any} => {
			const publisher = group.controls[publisherControl];
			const website = group.controls[websiteControl];
			if (publisher.value !== 'admin' && website.value === '' && website.pristine === true) {
				return {
					targetNotValid: true
				};
			}
		};
	}
