import {OnInit, Component, OnDestroy} from '@angular/core';
import {ChartResource} from 'app/resources/chart.resource';
import {PublishersService} from '../../publishers.service';
import {BlockRateService} from '../block-rate.service';
import {IntervalService} from '../../../shared/interval.service';
import {CoreService} from '../../../shared/core.service';



@Component({
	selector: 'app-block-rate-install',
	templateUrl: './block-rate-install.component.html',
	// styleUrls: ['./block-rate-install.component.less'],
})

export class BlockRateInstallComponent implements OnInit, OnDestroy {
	selectedSite$;
	selectedSiteSubscription;
	brs;


	constructor(private publishersService: PublishersService,
				private blockRateService: BlockRateService,
				private intervalService: IntervalService,
				private coreService: CoreService,
				private chartResource: ChartResource) {
	}

	ngOnInit() {
		this.selectedSite$ = this.blockRateService.getSelectedSite();
		this.selectedSiteSubscription = this.selectedSite$.subscribe((ret) => {
			this.brs = ret;
			});
	}

	ngOnDestroy() {
		if (this.selectedSiteSubscription) {
			this.selectedSiteSubscription.unsubscribe();
		}
	}

	getVerifyText(verify: boolean): string {
		if (verify) {
			return 'VERIFIED';
		} else {
			return 'NOT VERIFIED';
		}
	}

	verifyBrsSite() {
		return this.blockRateService.verifyBrsSite(this.brs);
	}
}
