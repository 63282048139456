import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {FormsModule, NgForm} from '@angular/forms';
import {Message, MessageService, MessageState} from 'app/shared/message.service';
import {UserResource} from '../../resources/user.resource';
import {Subscription} from 'rxjs';
import {CoreService} from '../../shared/core.service';
declare function require(moduleName: string): any;
const {version : appVersion} = require('../../../../package.json');

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.less'],
	providers: [UserResource]
})

export class LoginComponent implements OnInit, OnDestroy {
	// get link to the form template
	@ViewChild('loginForm', {static: false}) public loginForm: NgForm;

	messages: Message[] = [];
	version: string;
	loginSubscription: Subscription;
	email = '';
	password = '';

	constructor(private router: Router, private messageService: MessageService,
				private userResource: UserResource, private coreService: CoreService) {
	}

	ngOnInit() {
		this.version = appVersion;
		const message = this.messageService.getMessage();
		if (message) {
			this.messages.push(message);
			this.messageService.removeMessage();
		}
	}

	login() {
		if (!this.loginForm.valid) {
			// force validation of login form fields
			Object.keys(this.loginForm.form.controls).forEach(key => {
				this.loginForm.form.get(key).markAsDirty();
			});
			return;
		}

		this.messages = [];
		this.loginSubscription = this.userResource.login(this.email, this.password).subscribe(
			(data) => {
				const baseUrl = this.coreService.getBaseUrl();
				this.router.navigate([baseUrl]);
			},
			(err) => {
				switch (err.status) {
					case 401: {
						this.messages.push({state: MessageState.NEGATIVE, header: 'Something went wrong'});
						break;
					}
					case 423: {
						this.messages.push({state: MessageState.NEGATIVE, header: err.error.title, description: err.error.description});
						break;
					}
					default: {
						this.messages.push({state: MessageState.NEGATIVE, header: 'Something went wrong'});
						break;
					}
				}
			}
		);
	}

	ngOnDestroy() {
		if (this.loginSubscription) {
			this.loginSubscription.unsubscribe();
		}
	}
}
