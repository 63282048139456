import {AfterViewInit, Component} from '@angular/core';
import {CoreService} from '../../../shared/core.service';


@Component({
	selector: 'app-lite-setup',
	templateUrl: './lite-setup.component.html',
	styleUrls: ['./lite-setup.component.less']
})

export class LiteSetupComponent implements AfterViewInit {

	constructor(private coreService: CoreService) {}

	scrollToElement(element: Element): void {
		this.coreService.scrollToElement(element);
	}

	ngAfterViewInit() {
		this.coreService.scrollToElementByFragment();
	}

}


