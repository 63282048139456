import {Injectable} from '@angular/core';
import {RestBaseResource} from './rest-base.resource';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';


export interface FeeTimeLine {
	id?: string;
	publisher: string;
	website: string;
	valid_from?: number | Date | string;
	value: string | number;
	date?: Date | string;
	showEditFeeModal?: boolean;
	isLast?: boolean;
}

@Injectable()
export class FeeResource extends RestBaseResource {
	url = '/fees';
	listUrl = '/list-fees';

	constructor(protected httpClient: HttpClient) {
		super(httpClient);
	}

	getLastFee(aab_pub_id: string, aab_site_id: string) {
		return this.httpClient.get<any>(
			`${environment.apiEndpoint}/fee/${aab_pub_id}/${aab_site_id}`
		);
	}

}
