import { Injectable } from '@angular/core';
@Injectable()
export class ElementService {

	addClass(element: any, className: string) {
		if (element.classList) {
			element.classList.add(className);
		} else {
			element.className += ' ' + className;
		}
	}

	removeClass(element: any, className: string) {
		if (element.classList) {
			element.classList.remove(className);
		} else {
			element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		}
	}

	isTabletOrMobileSize() {
		return matchMedia('only screen and (max-width: 991px)').matches;
	}

	isMobileSize() {
		return matchMedia('only screen and (max-width: 470px)').matches;
	}
}
