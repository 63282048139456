import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {Publisher, Website} from 'app/core/models/data-structure';
import {PublishersService, SelectionState} from '../../core/publishers.service';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';
import {CoreService} from '../core.service';

@Component({
	selector: 'app-publisher-website-filter',
	templateUrl: './publisher-website-filter.component.html',
	styleUrls: ['./publisher-website-filter.component.less']
})
export class PublisherWebsiteFilterComponent implements OnInit, OnDestroy {
	@Input() publishers: any;
	@Input() hideWebsites: boolean;
	websites: Website[] = [];
	isMorePublishers = false;
	selectedPublishers: Publisher[] = [];
	selectedWebsites: Website[] = [];
	routeSubscription: Subscription;

	constructor(private router: Router,
				private publishersService: PublishersService,
				private route: ActivatedRoute,
				private coreService: CoreService) {
	}

	ngOnInit() {
		this.publishersService.setSearchToPublishers(this.publishers);
		this.routeSubscription = this.route.queryParams.subscribe((params: Params) => {
			this.setSelectedPublishersWebsitesFromQueryParams(params);
			this.isMorePublishersInit();
			this.setWebsitesToSelect();
		});
	}

	setSelectedPublishersWebsitesFromQueryParams(params: Params) {
		const websitesPublishersState = this.publishersService.getSelectedDataFromQueryParams(params);
		this.selectedPublishers = websitesPublishersState.publishers;
		this.selectedWebsites = websitesPublishersState.websites;
	}

	isMorePublishersInit() {
		this.isMorePublishers = this.publishers.length > 1;
		if (!this.isMorePublishers) {
			this.selectedPublishers = [this.publishers[0]];
		}
	}

	setWebsitesToSelect() {
		if (this.selectedPublishers && this.selectedPublishers.length > 0) {
			this.setWebsites(this.selectedPublishers);
		} else {
			this.setWebsites(this.publishers);
		}
	}

	setWebsites(publishersArray: Publisher[]) {
		this.websites = _.flattenDeep(_.map(publishersArray, 'websites'));
		this.publishersService.setSearchToWebsites(this.websites);
	}

	onChangePublisher() {
		this.setWebsitesToSelect();
		this.removeSelectedWebsitesForCurrentPublishers();
		const selectionState = this.publishersService.getSelectionState(this.selectedWebsites, this.selectedPublishers);
		this.changeQueryParamsDependsOnState(selectionState);
	}

	onChangeWebsites() {
		this.setSelectedPublishersFromCurrentWebsites();
		this.setWebsitesToSelect();
		const selectionState = this.publishersService.getSelectionState(this.selectedWebsites, this.selectedPublishers);
		this.changeQueryParamsDependsOnState(selectionState);
	}

	setQueryParams(pubs: Publisher[], webs: Website[]) {
		const publishersNames = _.map(pubs, 'publisher_data.aab_publisher_id');
		const websitesId = _.map(webs, 'aab_site_id');
		this.navigateRouter(publishersNames, websitesId);
	}

	changeQueryParamsDependsOnState(state: SelectionState) {
		switch (state) {
			case SelectionState.None: {
				this.router.navigate([], {fragment: this.coreService.getFragment(), relativeTo: this.route});
				break;
			}
			case SelectionState.Both: {
				this.setQueryParams(this.selectedPublishers, this.selectedWebsites);
				break;
			}
			case SelectionState.JustPublishers: {
				this.setQueryParams(this.selectedPublishers, []);
				break;
			}
		}
	}

	navigateRouter(publishersNames: string[] | string, websitesId: string[] | string) {
		this.router.navigate([],  { queryParams: { p: publishersNames, w: websitesId },
			fragment: this.coreService.getFragment(), relativeTo: this.route});
	}

	removeSelectedWebsitesForCurrentPublishers() {
		if (this.selectedWebsites && this.selectedWebsites.length > 0) {
			const finalArray = [];
			for (let i = this.selectedWebsites.length - 1; i > -1; i--) {
				if (this.websites.includes(this.selectedWebsites[i]) && this.selectedPublishers.length > 0) {
					finalArray.push(this.selectedWebsites[i]);
				}
			}
			this.selectedWebsites = finalArray;
		}
	}

	setSelectedPublishersFromCurrentWebsites() {
		if (this.selectedPublishers.length === 0) {
			if (this.selectedWebsites && this.selectedWebsites.length > 0) {
				this.selectedWebsites.forEach(web => {
					const pub = this.publishersService.getPublisherByAabSiteId(web.aab_site_id);
					if (!this.selectedPublishers.includes(pub)) {
						this.selectedPublishers = [...this.selectedPublishers, pub];
					}
				});
			}
		}
	}

	formatWebsites(web: Website): string {
		return `${web.publisher.name}: ${web.name}`;
	}

	formatPublishers(pub: Publisher): string {
		return `${pub.name}`;
	}

	ngOnDestroy() {
		if (this.routeSubscription) {
			this.routeSubscription.unsubscribe();
		}
	}

}
