import {ActionType, BudgetResource, Zone, ZoneTag} from '../../../../resources/budget.resource';
import {Component, EventEmitter, Input, Output, OnDestroy, OnInit, OnChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {Message, MessageState} from '../../../../shared/message.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {oneRequiredValidator} from '../../../../shared/directives/validators/one-required-validator.directive';

@Component({
	selector: 'app-action-tag-modal',
	templateUrl: './action-tag-modal.component.html',
	styleUrls: ['./action-tag-modal.component.less']
})

export class ActionTagModalComponent implements OnInit, OnChanges, OnDestroy {
	@Input() zone: Zone;
	@Input() tag: ZoneTag;
	@Input() actionType: ActionType;
	@Input() addNewTag = false;
	@Output() onMessage = new EventEmitter<Message>();
	@Output() onChangeTags = new EventEmitter();
	@Output() onClose = new EventEmitter();
	editAction = ActionType.EDIT;
	subscription: Subscription;
	addTagForm: FormGroup;
	inputValue: ZoneTag;
	allTags: ZoneTag[];
	allCategories = [];
	AllTagsSubscription: Subscription;

	constructor(private budgetResource: BudgetResource) {
	}

	ngOnInit() {
		this.AllTagsSubscription = this.budgetResource.getAllTags().subscribe(res => {
			this.allTags = res;
			for (const item of this.allTags) {
				if (!(this.allCategories.includes(item.category))) {
					this.allCategories.push(item.category);
				}
			}
		});

		this.addTagForm = new FormGroup({
			zoneInfoForm: new FormGroup({
				aab_publisher_id: new FormControl(''),
				aab_site_id: new FormControl(''),
				zone_name: new FormControl(''),
				adserver: new FormControl(''),
			}, oneRequiredValidator()),
			category: new FormControl('', Validators.required),
			name: new FormControl('', Validators.required),
		});
	}

	ngOnChanges() {
		switch (this.actionType) {
			case ActionType.ADD:
				if (this.addNewTag) {
					this.inputValue = {aab_publisher_id: '', aab_site_id: '',
						zone_name: '', adserver: '', category: '', name: ''};
					this.zone = {
						name: '', aab_site_id: '', website_name: '',
						aab_publisher_id: '', adserver: '',
						count_ok: 0, count_empty: 0, count_substitute: 0, count_rtb: 0
					};
					this.tag = {aab_site_id: '', aab_publisher_id: '', zone_name: '', name: '', adserver: '', category: ''};
				} else {
					this.inputValue = {aab_publisher_id: this.zone.aab_publisher_id, aab_site_id: '',
						zone_name: this.zone.name, adserver: '', category: '', name: ''};
				}
				break;
			case ActionType.EDIT:
				if (!this.zone) {
					this.zone = {
						name: this.tag.zone_name, aab_site_id: this.tag.aab_site_id, website_name: '',
						aab_publisher_id: this.tag.aab_publisher_id, adserver: this.tag.adserver,
						count_ok: 0, count_empty: 0, count_substitute: 0, count_rtb: 0
					};
				}
				this.inputValue = this.tag;
				break;
		}
	}

	actionTag() {
		const tag = Object.assign({}, this.addTagForm.value.zoneInfoForm,
			{'category': this.addTagForm.value.category}, {'name': this.addTagForm.value.name});

		switch (this.actionType) {
			case ActionType.ADD:
				this.subscription = this.budgetResource.postTag(tag)
					.subscribe(res => {
					this.onChangeTags.emit();
				}, err => {
					this.onMessage.emit({header: 'Tag wasn\'t added.', description: 'Please try again.', state: MessageState.NEGATIVE});
				});
				break;
			case ActionType.EDIT:
				this.subscription = this.budgetResource.putTag(this.tag.id, tag)
					.subscribe(res => {
					this.onChangeTags.emit();
				}, err => {
					this.onMessage.emit({header: 'Tag wasn\'t edited.', description: 'Please try again.', state: MessageState.NEGATIVE});
				});
				break;
		}
	}

	writeValue(formControl: string, value: string) {
		this.addTagForm.controls.zoneInfoForm['controls'][formControl].setValue(value);
	}

	closeTag() {
		if (this.zone) {
			this.zone.showAddTagModal = false;
		}
		if (this.tag) {
			this.tag.showEditTagModal = false;
		}
		this.addNewTag = false;
		this.onClose.emit();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
