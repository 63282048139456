<div *ngIf="finalItems && finalItems.length > 0" class="ui form">
  <div class="grouped fields">
    <label>{{name}}</label>
    <div *ngFor="let item of finalItems" class="field">
      <div class="ui checkbox">
        <input type="checkbox" name="{{item.name}}" [(ngModel)]="item.checked" (ngModelChange)="onChangeItem()">
        <label>{{item.name}}</label>
      </div>
    </div>
  </div>
</div>
