import {Component, OnInit} from '@angular/core';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {Message, MessageService, MessageState} from 'app/shared/message.service';
import {UserResource} from '../../../resources/user.resource';
import {AuthService} from '../../../auth/auth.service';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.less']
})

/** Change password component that use reactive forms with included partial form
	based on https://itnext.io/partial-reactive-form-with-angular-components-443ca06d8419 */
export class ChangePasswordComponent implements OnInit {
	messages: Message[] = [];
	form: FormGroup;
	navigateTo = '';
	isPassChanged: boolean;

	constructor(private userResource: UserResource, private router: Router,
				private messageService: MessageService, private fb: FormBuilder, private authService: AuthService) {
	}

	ngOnInit() {
		/* TODO: change this either to some generic message/notification system within the application or inject messages
			directly into the form validation */
		const message = this.messageService.getMessage();
		if (message) {
			this.messages.push(message);
			this.messageService.removeMessage();
		}

		this.isPassChanged = false;

		// partial form for password & confirmation will be injected elsewhere
		this.form = this.fb.group({
			current_password: ['', Validators.required],
		});
		this.navigateTo = this.authService.getUser().brs_only ? 'block-rate' : '';
	}

	/** Form submission */
	change_password() {
		this.messages = [];

		this.userResource.change_password(this.form.value.current_password,
			this.form.value.newPassword.password,
			this.form.value.newPassword.confirm_password).subscribe(
			(data) => {
				this.messages.push({state: MessageState.POSITIVE, header: 'Password has been changed.'});
				this.isPassChanged = true; // this will cause form to be invisible while message is shown
				this.form.reset(); // remove everything from the form

				// and go on the dashboard after 3 seconds
				setTimeout((router: Router) => {
					this.router.navigate([this.navigateTo]);
					}, 3000);
			},
			(err) => {
				switch (err.status) {
					case 400: {
						// AP{I generates invalid parameter response and in tat case second sentence contains the actual message}
						const err_data = err.json();

						const text = err_data.title === 'Invalid parameter' ? err_data.description.split('.')[1] : 'Something went wrong (400)';
						this.messages.push({state: MessageState.NEGATIVE, header: text});
						break;
					}
					default: {
						this.messages.push({state: MessageState.NEGATIVE, header: 'Something went wrong', description: err._body});
						break;
					}
				}
			}
		);
	}

	/** this injects partial form into the main one and allows full validation and bindings.
		it is "called" from the html template itself */
	formInitialized(name: string, form: FormGroup) {
		this.form.setControl(name, form);
	}
}
