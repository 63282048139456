import { Component, ComponentFactoryResolver} from '@angular/core';
import { MessageService } from 'app/shared/message.service';
import { AdserverRestResource } from '../../../resources/adserver.resource';
import { InputFieldContainer, RestComponent } from '../rest/rest.component';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-adserver-management',
	templateUrl: '../rest/rest.component.html',
})

export class AdserverManagementComponent extends RestComponent {
	title = 'Adservers';
	itemName = 'adserver';
	fields = this.makeFields();

	constructor(public injected_resource: AdserverRestResource,
				public messageService: MessageService,
				public componentFactoryResolver: ComponentFactoryResolver
				) {
				super(messageService, componentFactoryResolver);
	}

	makeFields() {
		const fields: InputFieldContainer = {
			container: [
				{ name: 'id', type: 'text', value: null},
				{ name: 'identifier', type: 'text', value: null},
			]
		};
		return fields;
	}
}
