import {Component, Input} from '@angular/core';
import {BlockingState, BlockratePercentData} from '../../resources/block-rate.resource';


@Component({
	selector: 'app-block-rate-progress',
	templateUrl: './block-rate-progress.component.html',
})
export class BlockRateProgressComponent {
	@Input() blockRatePercentData: BlockratePercentData;

	getClass() {
		return this.blockRatePercentData.blockingState === BlockingState.BLOCKING ? 'error' : 'green';
	}
}

