<table class="ui small celled table">
	<thead>
		<tr>
			<th (click)="sortTable('date')" >Date
				<app-sort-icon [sortBy]="'date'" [column]="column" [isDesc]="!isDesc" [isAlphabet]="false"></app-sort-icon>
			</th>
			<th>Publisher</th>
			<th>Website</th>
			<th>Fee</th>
			<th>Action</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let fee of feeTimeLines | orderBy: {property: column, direction: direction}">
			<td>{{ fee.date }}</td>
			<td>{{fee.publisher}}</td>
			<td>{{fee.website}}</td>
			<td>{{fee.value}}<span *ngIf="fee.value"> %</span></td>
			<td>
				<div *ngIf="fee.isLast && isEditable" class="ui tiny blue icon button" title="Edit" (click)="editFee(fee)">
					<i class="pencil alternate icon"></i>
				</div>
				<div *ngIf="fee.isLast && isEditable" class="ui tiny red icon button" (click)="showDeleteModal(fee)">
					<i class="trash alternate icon"></i>
				</div>
			</td>
		</tr>
	</tbody>
</table>
<app-action-fee-modal *ngIf="showEditFeeModal" [fee]="feeToEdit" [actionType]="editType"
					  (onMessage)="changeMessage($event)" (onChangeFees)="changeFees()"></app-action-fee-modal>
<app-rest-modal *ngIf="showDelete" [show]="showDelete" [name]="feeToDelete.id" (approved)="deleteFee($event)"></app-rest-modal>
