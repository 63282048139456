<div class="ui middle aligned center aligned grid bg grey">
<div class="ui left aligned column">
	<img src="/assets/logo.png">
	<div class="registration_form ui raised segment">
		<h1 class="ui teal header">Create account</h1>

		<p>and start measuring the block rate on your web</p>

		<div class="ui hidden divider"></div>
		<app-message *ngIf="message" [message]="message"></app-message>
		<form *ngIf="!hideForm" novalidate class="ui form" [formGroup]="form" (submit)="register()" >
			<div class="required field">
				<label>Email</label>
				<div *ngIf="form.get('email').invalid && (form.get('email').dirty || form.get('email').touched)" class="ui negative message" id="message_email">
					<div *ngIf="form.get('email').hasError('wrongEmail')">
						Email address is invalid.
					</div>
					<div *ngIf="form.get('email').hasError('required')">
						Email address is required.
					</div>
				</div>
				<div class="ui fluid icon input">
					<i class="user icon"></i>
					<input
						type="email"
						placeholder="email"
						name="email"
						id="email_input"
						formControlName="email">
			  	</div>
			  	<!--<p>Your email address will serve as your login name. You'll receive a verification email with a link to set your password.</p>-->
			</div>
			<div class="ui hidden divider"></div>
			<app-website-input [noControls]="true" (formReady)="formInitialized($event)"></app-website-input>
			<div class="ui hidden divider"></div>
			<div>
				<fui-checkbox id="acceptTerms" formControlName="acceptTerms" name="acceptTerms"></fui-checkbox>
				I accept and agree with <a class="teal-link" target="_blank" href="https://www.antiadblock.com/terms-of-use">Terms of Use</a>
				and I confirm that I have read <a class="teal-link" target="_blank" href="https://www.antiadblock.com/legal">Privacy Policy</a>
			</div>
			<div class="ui hidden divider"></div>
			<button class="fluid ui teal button" type="submit" id="registration_button" [disabled]="form.invalid || !websiteData">
				<ng-container i18n>Create</ng-container>
			</button>
		</form>
	</div>
	Already have an account? <a class="teal-link" [routerLink]="['/login']">Log in.</a>
</div>
</div>
