import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LiteManagementComponent } from './lite-management/lite-management.component';
import { LiteManagementFilesComponent } from './lite-management/lite-management-files.component';
import { FaqManagementComponent } from './faq-management/faq-management.component';
import { FaqCategoryManagementComponent } from './faq-management/faq-category-management.component';
import { SettingsManagementComponent } from './settings-management/settings-management.component';
import { LoggedInGuard } from '../../auth/guard/logged-in.guard';
import { SuperAdminGuard } from '../../auth/guard/superadmin.guard';
import { FeeManagementComponent } from './fee-management/fee-management.component';
import { AdserverManagementComponent } from './adserver-management/adserver-management.component';
import { PublisherManagementComponent } from './publisher-management/publisher-management.component';
import { WebsiteManagementComponent } from './website-management/website-management.component';

const adminRoutes: Routes = [
	{path: 'lite', component: LiteManagementComponent, canActivate: [LoggedInGuard, SuperAdminGuard]},
	{path: 'lite-files', component: LiteManagementFilesComponent, canActivate: [LoggedInGuard, SuperAdminGuard]},
	{path: 'faq', component: FaqManagementComponent, canActivate: [LoggedInGuard, SuperAdminGuard]},
	{path: 'faq-category', component: FaqCategoryManagementComponent, canActivate: [LoggedInGuard, SuperAdminGuard]},
	{path: 'settings', component: SettingsManagementComponent, canActivate: [LoggedInGuard, SuperAdminGuard]},
	{path: 'fee', component: FeeManagementComponent, canActivate: [LoggedInGuard, SuperAdminGuard]},
	{path: 'adserver', component: AdserverManagementComponent, canActivate: [LoggedInGuard, SuperAdminGuard]},
	{path: 'website', component: WebsiteManagementComponent, canActivate: [LoggedInGuard, SuperAdminGuard]},
	{path: 'publisher', component: PublisherManagementComponent, canActivate: [LoggedInGuard, SuperAdminGuard]},
];

@NgModule({
	imports: [RouterModule.forChild(adminRoutes)],
	exports: [RouterModule]
})
export class AdminRoutingModule {
}
