import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {IntervalChooserData, IntervalEnum, IntervalService} from '../shared/interval.service';

export interface ChartTableResource {
	post(aab_sites_ids: string[], intervalChooserData: IntervalChooserData, metadata: any): Observable<{ data: any[]; metadata: any; }>;
}

export class TableChartBaseResource implements ChartTableResource {
	protected chartUrl = 'chart';

	constructor(protected httpClient: HttpClient, protected intervalService: IntervalService) {
	}

	postMetadata(aab_sites_ids: string[], intervalChooserData: IntervalChooserData) {
		let period = intervalChooserData.period;
		if (intervalChooserData.intervalEnum === IntervalEnum.Date) {
			period = this.intervalService.getDateIntervalFromUTC({dateFrom: period['dateFrom'], dateTo: period['dateTo']});
		}
		const body = {aab_sites_ids: aab_sites_ids, interval_enum: intervalChooserData.intervalEnum, period: period,
				time_zone: intervalChooserData.timeZone};
		return this.httpClient.post<any>(`${environment.apiEndpoint}${this.chartUrl}/metadata`, body);
	}

	post(aab_sites_ids: string[], intervalChooserData: IntervalChooserData, metadata: any): Observable<{ data: any[], metadata: any}> {
		let period = intervalChooserData.period;
		if (intervalChooserData.intervalEnum === IntervalEnum.Date) {
			period = this.intervalService.getDateIntervalFromUTC({dateFrom: period['dateFrom'], dateTo: period['dateTo']});
		}
		const body = {aab_sites_ids: aab_sites_ids, interval_enum: intervalChooserData.intervalEnum, period: period,
				time_zone: intervalChooserData.timeZone, interval: intervalChooserData.interval, metadata: metadata};
		return this.httpClient.post<{ data: any[], metadata: any }>(`${environment.apiEndpoint}${this.chartUrl}`, body);
	}
}
