import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserManagementResource } from 'app/resources/user-management.resource';
import { User, PublisherList, PermissionType } from './data-structure';
import {AuthService} from '../../../auth/auth.service';

export enum UserAction {
	ADD = 'Add',
	SAVE = 'Save',
}

@Injectable()
export class UserManagementService {
	publisherLists$: Observable<PublisherList[]>;
	publisherLists: PublisherList[];

	userList$: Observable<User[]>;
	userList: User[];

	private static splitPerms(user: User) {
		const ret = user;

		const split = _.groupBy(ret.permissions, 'type');
		ret.groups = split[PermissionType.GROUP] ? split[PermissionType.GROUP] : [];
		ret.rights = split[PermissionType.RIGHT] ? split[PermissionType.RIGHT] : [];

		return ret;
	}



	constructor(
		private http: HttpClient,
		private userManagementResource: UserManagementResource,
		private authService: AuthService) { }

	getUsersFromDB(): Observable<User[]> {
		return this.userManagementResource.query();
	}

	getUsers(): Observable<User[]> {
		if (!this.userList$) {
			this.userList$ = this.getUsersFromDB();
			return this.userList$.pipe(
				map(u => {
					this.userList = u;
					// prepare separated lists
					_.map(this.userList, UserManagementService.splitPerms);
					return this.userList;
				})
			);
		}
		return of(this.userList);
	}

	getUser(id: string): Observable<User> {
		return this.userManagementResource.get(id).pipe(
			map(user => {
				return UserManagementService.splitPerms(user);
			})
		);
	}

	editUser(action: UserAction, user: User) {
		return this.userManagementResource.post({
					action: action.toLowerCase(),
					user: user}).pipe(
			tap( data => {
					if (data.ok === true) {
						if (user.permissions.length === 0 && !this.authService.isSuperAdmin()) {
							this.userList = this.userList.filter(u => u.id !== user.id);
						} else if (action === UserAction.SAVE) {
							const index = _.findIndex(this.userList, function(i: User) { return i.id === user.id; });
							if (index > -1) {
								this.userList[ index ] = UserManagementService.splitPerms(user);
							}
						} else {
							user.id = data.id;
							this.userList.unshift(UserManagementService.splitPerms(user));
						}
					}
				})
		);
	}

	deleteUser(id: string) {
		return this.userManagementResource.delete(id).pipe(
			tap( data => {
				if (data.ok === true) {
					this.userList = this.userList.filter(u => u.id !== id);
				}
			})
		);
	}

	inviteUser(id: string): Observable<{invite: boolean}> {
		return this.userManagementResource.inviteUser(id);
	}

	getUserRights() {
		return this.userManagementResource.userRights();
	}

	getUserRightGroups() {
		return this.userManagementResource.userRightGroups();
	}

	getUserPubwebListDB(): Observable<PublisherList[]> {
		return this.userManagementResource.userPubwebList();
	}

	getUserPubwebList(): Observable<PublisherList[]> {
		if (!this.publisherLists$) {
			this.publisherLists$ = this.getUserPubwebListDB();
			return this.publisherLists$.pipe(
				map(
				p => {
					this.publisherLists = p;
					return this.publisherLists;
				})
			);
		}
		return of(this.publisherLists);
	}
}

