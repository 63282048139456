<fui-modal [isClosable]="true" [size]="normal" (dismissed)="closeTag()" #modal *ngIf="addNewTag || (inputValue && ((zone && zone.showAddTagModal) || (actionType == editAction && tag && tag.showEditTagModal)))">
	<div class="header">{{actionType}} tag</div>
	<div class="content">
		<form novalidate (submit)="modal.approve('save'); actionTag()" [formGroup]="addTagForm">
			<div *ngIf="addTagForm.get('zoneInfoForm').invalid && (addTagForm.get('zoneInfoForm').touched || addTagForm.get('zoneInfoForm').dirty)" class="ui negative message">
				At least one of publisher, website, zone or ad server is required.
			</div>
			<div *ngIf="addTagForm.get('category').invalid && (addTagForm.get('category').dirty || addTagForm.get('category').touched)" class="ui negative message">
				<div *ngIf="addTagForm.get('category').hasError('required')">
					Tag category is required.
				</div>
			</div>
			<div *ngIf="addTagForm.get('name').invalid && (addTagForm.get('name').dirty || addTagForm.get('name').touched)" class="ui negative message">
				<div *ngIf="addTagForm.get('name').hasError('required')">
					Tag name is required.
				</div>
			</div>
			<span formGroupName="zoneInfoForm">
				<div class="ui left icon input">
					<a class="ui teal right corner mini label" (click)="writeValue('aab_publisher_id', zone.aab_publisher_id)">
						<i class="pencil icon"></i>
					</a>
					<i class="user icon"></i>
					<input type="text" name="aab_publisher_id" placeholder="Publisher" formControlName="aab_publisher_id" class="form-control" [ngModel]="inputValue.aab_publisher_id">
				</div>
				<div class="ui left icon input">
					<a class="ui teal right corner mini label" (click)="writeValue('aab_site_id', zone.aab_site_id)">
						<i class="pencil icon"></i>
					</a>
					<i class="world icon"></i>
					<input type="text" name="aab_site_id" placeholder="Website" formControlName="aab_site_id" class="form-control" [ngModel]="inputValue.aab_site_id">
				</div>
				<div class="ui left icon input">
					<a class="ui teal right corner mini label" (click)="writeValue('zone_name', zone.name)">
						<i class="pencil icon"></i>
					</a>
					<i class="image icon"></i>
					<input type="text" name="zone_name" placeholder="Zone Name" formControlName="zone_name" class="form-control" [ngModel]="inputValue.zone_name">
				</div>
				<div class="ui left icon input">
					<a class="ui teal right corner mini label" (click)="writeValue('adserver', zone.adserver)">
						<i class="pencil icon"></i>
					</a>
					<i class="cogs icon"></i>
					<input type="text" name="adserver" placeholder="Ad Server" formControlName="adserver" class="form-control" [ngModel]="inputValue.adserver">
				</div>
			</span>
			<div class="ui left icon input">
				<i class="user icon"></i>
				<input type="text" name="category" placeholder="Category" formControlName="category" list="browsers" class="form-control" [ngModel]="inputValue.category">
				<datalist id="browsers">
				  <option *ngFor="let cat of allCategories" value="{{cat}}">
				</datalist>

			</div>
			<div class="ui right labeled left icon input">
				<i class="tags icon"></i>
				<input name="tag" type="text" placeholder="Tag Name" formControlName="name" class="form-control" [ngModel]="inputValue.name">

					<button id="submit_tag_button" class="ui tag label" type="submit" [disabled]="addTagForm.invalid">
						{{actionType}} Tag
					</button>

			</div>
		</form>
	</div>
</fui-modal>
