import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {Website} from '../../models/data-structure';
import * as _ from 'lodash';
import {WebsiteService} from '../../dashboard/website/website.service';

@Component({
	selector: 'app-selected-website-params',
	template: ''
})

export class SelectedWebsiteParamsComponent implements OnInit, OnDestroy {
	selectedWebsite: Website;
	selectedWebsiteSubscription: Subscription;
	isWebLive: boolean;

	constructor(protected route: ActivatedRoute, protected websiteService: WebsiteService) {
	}

	ngOnInit() {
		this.selectedWebsite = this.websiteService.getWebsite();
		this.isWebLiveSet(this.selectedWebsite);
		this.selectedWebsiteSubscription = this.websiteService.getWebsiteObservable().subscribe(
			web => {
				this.selectedWebsite = web;
				this.isWebLiveSet(this.selectedWebsite);
			});
	}

	isWebLiveSet(website: Website): void {
		this.isWebLive = _.get(website, 'state') === 'Live';
	}

	ngOnDestroy() {
		if (this.selectedWebsiteSubscription) {
			this.selectedWebsiteSubscription.unsubscribe();
		}
	}

	paramsChange() {
	}

}
