import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';

export function equalsTrueValidator(): ValidatorFn {
	return (control: AbstractControl): {[key: string]: any} => {
		const checkedValue = typeof control.value === 'boolean' && control.value === true;
		return checkedValue ? null : {'wrongValue': {value: control.value}};
	};
}

@Directive({
	selector: '[appValueEquals][ngModel]',
	providers: [{provide: NG_VALIDATORS, useExisting: TrueValueValidatorDirective, multi: true}]
})
export class TrueValueValidatorDirective implements Validator {

	validate(control: AbstractControl): {[key: string]: any} {
		return equalsTrueValidator()(control);
	}
}


