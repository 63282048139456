import {Component, OnDestroy, OnInit} from '@angular/core';
import {LiteResource} from '../../../resources/lite.resource';
import {Subscription} from 'rxjs';


@Component({
	selector: 'app-lite-loader',
	templateUrl: './lite-loader.component.html',
	styleUrls: ['./lite-loader.component.less']
})

export class LiteLoaderComponent implements OnInit, OnDestroy {
	subscription: Subscription;
	loaderList: Object[];
	showSpinner = false;

	constructor(private liteResource: LiteResource) {}

	ngOnInit(): void {
		this.showSpinner = true;
		this.liteResource.listItems().subscribe(res => {
			this.loaderList = res.loaders;
			this.showSpinner = false;
		});
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}

