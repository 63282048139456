import { Injectable } from '@angular/core';
export enum ActiveFragment {
	BLOCK_RATE_TREND = 'block_rate',
	BLOCK_RATE_OS_BROWSER = 'block_rate',
	IMPRESSIONS = 'impressions',
	HOSTAGE_STATE = 'hostage_state',
	LITE_STATS = 'lite_stats',
	NO_PATTERN = 'no_pattern',
	CLIENT_FAILED = 'client_failed',
	FAILED_COUNT = 'failed_count',
	PAGE_TEMPLATES = 'page_templates',
	ADSCAN_ZONES_TABLE = 'adscan_zones_table',
	ADSCAN_ZONES_CHART = 'adscan_zones_chart',
}

export enum ChartType {
	BLOCK_RATE_PIE,
	BLOCK_RATE_BAR,
	BLOCK_RATE_BLOCKING_OS_BROWSER,
	BLOCK_RATE_NON_BLOCKING_OS_BROWSER,
	IMPRESSION,
	ADSCAN_FAILED_COUNT_TOP_5,
	ADSCAN_CLIENT_FAILED_TOP_5,
	ADSCAN_NO_PATTERN_TOP_5,
	ADSCAN_PAGE_TEMPLATES,
	ADSCAN_ZONES,
	LITE_STATS,
	SIMPLE_BAR,
	SIMPLE_PIE
}

export enum ChartGroup {
	PIE,
	LINE,
	BAR,
}

export interface ChartTypeInterface {
	type: ChartType;
	name: string;
	group: ChartGroup;
	title: string;
	fragment?: ActiveFragment;
}

export const ChartTypeStringEnum: ChartTypeInterface[] = [
		{type: ChartType.BLOCK_RATE_PIE, name: 'blockrate_pie', group: ChartGroup.PIE, title: 'Block Rate'},
		{type: ChartType.BLOCK_RATE_BAR, name: 'blockrate_bar', group: ChartGroup.BAR, title: 'Block Rate',
			fragment: ActiveFragment.BLOCK_RATE_TREND},
		{type: ChartType.BLOCK_RATE_BLOCKING_OS_BROWSER, name: 'blockrate_os_blocking_pie', group: ChartGroup.PIE,
			title: 'Blocking', fragment: ActiveFragment.BLOCK_RATE_OS_BROWSER},
		{type: ChartType.BLOCK_RATE_NON_BLOCKING_OS_BROWSER, name: 'blockrate_os_non_blocking_pie', group: ChartGroup.PIE,
			title: 'Non Blocking', fragment: ActiveFragment.BLOCK_RATE_OS_BROWSER},
		{type: ChartType.IMPRESSION, name: 'impressions_line', group: ChartGroup.LINE, title: 'Impressions',
			fragment: ActiveFragment.IMPRESSIONS},
		{type: ChartType.ADSCAN_FAILED_COUNT_TOP_5, name: 'adscan_failed_count_top_five_bar', group: ChartGroup.BAR,
			title: 'Failed Count Top 5', fragment: ActiveFragment.FAILED_COUNT},
		{type: ChartType.ADSCAN_CLIENT_FAILED_TOP_5, name: 'adscan_client_failed_top_five_bar', group: ChartGroup.BAR,
			title: 'Client Failed Top 5', fragment: ActiveFragment.CLIENT_FAILED},
		{type: ChartType.ADSCAN_NO_PATTERN_TOP_5, name: 'adscan_no_pattern_top_five_bar', group: ChartGroup.BAR,
			title: 'No Pattern Top 5', fragment: ActiveFragment.NO_PATTERN},
		{type: ChartType.ADSCAN_PAGE_TEMPLATES, name: 'adscan_page_templates', group: ChartGroup.LINE,
			title: 'Ad Scan Page Templates', fragment: ActiveFragment.PAGE_TEMPLATES},
		{type: ChartType.ADSCAN_ZONES, name: 'adscan_zones', group: ChartGroup.LINE,
			title: 'Ad Scan Zones', fragment: ActiveFragment.ADSCAN_ZONES_CHART},
		{type: ChartType.LITE_STATS, name: 'lite_stats', group: ChartGroup.LINE,
			title: 'Lite Clicks & Earnings', fragment: ActiveFragment.LITE_STATS},
		{type: ChartType.SIMPLE_PIE, name: 'simple_pie', group: ChartGroup.PIE, title: 'Block Rate'},
		{type: ChartType.SIMPLE_BAR, name: 'simple_bar', group: ChartGroup.BAR, title: 'Pageviews'},
];

export interface ScatterChartData {
	name: string;
	x: string[];
	y: number[];
}

export interface UltraLiteBaseData {
	website: string;
	date: string;
	revenue: number;
	clicks: number;
	impressions: number;
	pageview: number;
}

export interface ChartContainerComponent {
	data: any;
	title: string;
}

export interface TableContainerComponent {
	tableData: any;
	sortBy: string;
}

@Injectable()
export class ChartService {

	chartTypeEnum: ChartTypeInterface[] = [];

	constructor() {
		this.chartTypeEnum = ChartTypeStringEnum;
	}

	getChartTypeInterfaceByEnum(chartType: ChartType): ChartTypeInterface {
		let ret = this.chartTypeEnum[0];
		this.chartTypeEnum.forEach(per => {
			if (per.type === chartType) {
				ret = per;
			}
		});
		return ret;
	}

	prepareDataForScatterChart(xAttributes: string[], data: UltraLiteBaseData[]): ScatterChartData[] {
		const final = [], dates = [];
		xAttributes.forEach(i => {
			final.push({name: i, y: [], x: []});
		});
		data.forEach(i => {
			final.forEach(f => {
				const name = f['name'];
				f['y'].push(i[name]);
			});
			dates.push(i.date);
		});
		const allDates = Array.from(new Set(dates)).sort();
		final.forEach(f => {
				f['x'] = allDates;
			});
		return final;
	}
}
