import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ActiveFragment} from '../../../shared/chart.service';
import {Website} from '../../models/data-structure';
import {CoreService} from '../../../shared/core.service';
import {IntervalChooserData, IntervalService} from '../../../shared/interval.service';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-meta-tab',
	template: '',
})
export class MetaTabComponent implements OnInit, OnDestroy {
	@Input() selectedWebsites: Website[] = [];
	@Input() possibleFragments: ActiveFragment[];
	currentFragment: ActiveFragment;
	intervalData: IntervalChooserData;
	intervalDataSubscription: Subscription;

	constructor(protected coreService: CoreService, protected route: ActivatedRoute, protected intervalService: IntervalService) {
	}

	ngOnInit(): void {
		this.currentFragmentInit();
		this.subscribeToIntervalData();
	}

	currentFragmentInit() {
		this.setDefaultFragment();
		const currentFragment = this.route.snapshot.fragment;
		this.navigateToCurrentFragment(currentFragment);
		this.route.fragment.subscribe(fragment => {
			this.navigateToCurrentFragment(fragment);
		});
	}

	subscribeToIntervalData() {
		this.intervalDataSubscription = this.intervalService.getSharedIntervalDataObservable().subscribe((intervalData => {
			if (!this.intervalData || this.intervalService.isDifferentIntervalData(intervalData, this.intervalData)) {
				this.intervalData = intervalData;
			}
		}));
	}

	navigateToCurrentFragment(currentFragment: string) {
		this.possibleFragments.forEach((fragment) => {
			if (currentFragment === fragment.toString()) {
			this.currentFragment = fragment;
			}
		});
		this.onTabClick(this.currentFragment);
	}

	setDefaultFragment() {}

	isActive(fragmentEnum: ActiveFragment) {
		return fragmentEnum === this.currentFragment;
	}

	onTabClick(fragmentEnum: ActiveFragment) {
		this.currentFragment = fragmentEnum;
		this.coreService.navigateTofragment(fragmentEnum);
	}

	ngOnDestroy() {
		if (this.intervalDataSubscription) {
			this.intervalDataSubscription.unsubscribe();
		}
	}

}
