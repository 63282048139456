import {Component} from '@angular/core';
import {SimpleTableComponent} from '../simple-table.component';

@Component({
	selector: 'app-block-rate-trend-table',
	templateUrl: '../simple-table.component.html',
	styles: ['td { text-align: right!important; }']
})

export class BlockrateTrendTableComponent extends SimpleTableComponent {

	getTotal(name: string) {
		if (name.includes('Blocking %')) {
			const totalBlock = this.addItemsFromArray(this.tableData, 'Blocking');
			const totalNonBlock = this.addItemsFromArray(this.tableData, 'Non Blocking');
			if (name === 'Blocking %') {
				return (totalBlock / (totalBlock + totalNonBlock)) * 100;
			} else if (name === 'Non Blocking %') {
				return (totalNonBlock / (totalBlock + totalNonBlock)) * 100;
			}
		}
		return super.getTotal(name);
	}

	formatCount(count: any, name: string) {
		count = super.formatCount(count, name);
		if (count && name.includes('%')) {
			return count + ' %';
		}
		return count;
	}
}
