import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Publisher} from 'app/core/models/data-structure';
import {Subscription, Observable} from 'rxjs';
import {PublisherResource} from 'app/resources/publisher.resource';
import {ConfigResource} from 'app/resources/config.resource';

@Component({
	selector: 'app-configuration-document',
	templateUrl: './configuration-document.component.html',
	styleUrls: ['./configuration-document.component.less'],
	providers: [ConfigResource]
})
export class ConfigurationDocumentComponent implements OnInit, OnDestroy {
	selectedPublisher: Publisher;
	selectedPublisher$: Observable<Publisher>;
	outputConfig: string;
	publisherSubscription: Subscription;
	configSubscription: Subscription;
	showSpinner = false;

	constructor(private route: ActivatedRoute,
				private publishersResource: PublisherResource,
				private configResource: ConfigResource) {
	}

	ngOnInit() {
		this.showSpinner = true;
		const aab_publisher_id = this.route.snapshot.params['aab_publisher_id'];
		this.selectedPublisher$ = this.publishersResource.get(aab_publisher_id);
		this.publisherSubscription = this.selectedPublisher$.subscribe(
			pub => {
				this.selectedPublisher = pub;
				this.getConfig();
			},
			err => {
				this.showSpinner = false;
			});
	}

	getConfig() {
		const outputConfig$ = this.configResource.get(this.selectedPublisher.publisher_data.aab_publisher_id);
		this.configSubscription = outputConfig$.subscribe(
			out => {
				let finalOutput = '';
				for (let i = 0; i < Object.keys(out).length - 4; i++) {
					finalOutput += out[i];
				}
				this.outputConfig = finalOutput;
				this.setDownload();
				this.showSpinner = false;
			});
	}

	setDownload() {
		const downloadButton = document.getElementById('download_button');
		downloadButton.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.outputConfig));
		downloadButton.setAttribute('download', `configuration_for_${this.selectedPublisher.name}.conf`);
	}

	ngOnDestroy() {
		if (this.publisherSubscription) {
			this.publisherSubscription.unsubscribe();
		}
		if (this.configSubscription) {
			this.configSubscription.unsubscribe();
		}
	}


}
