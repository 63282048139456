import {Component, Input, OnInit} from '@angular/core';
import {ChartType, ActiveFragment} from '../../../../shared/chart.service';
import {AuthService} from '../../../../auth/auth.service';
import {Website} from 'app/core/models/data-structure';
import {LoggedInUserRights} from '../../../models/data-structure';
import {ChangeDetectorRef} from '@angular/core';
import {CoreService} from '../../../../shared/core.service';
import {ActivatedRoute} from '@angular/router';
import {MetaTabComponent} from '../../../meta/meta-tab/meta-tab.component';
import {IntervalService} from '../../../../shared/interval.service';

@Component({
	selector: 'app-dashboard-charts-tab',
	templateUrl: './dashboard-charts-tab.component.html',
	styleUrls: ['./dashboard-charts-tab.component.less']
})

export class DashboardChartsTabComponent extends MetaTabComponent implements OnInit {
	@Input() isWebLive: boolean;
	@Input() selectedWebsites: Website[];
	@Input() withHostageState = false;
	@Input() possibleFragments: ActiveFragment[];
	rights: LoggedInUserRights;
	impressionFragment = ActiveFragment.IMPRESSIONS;
	blockRateFragment = ActiveFragment.BLOCK_RATE_TREND;
	liteFragment = ActiveFragment.LITE_STATS;
	hostageFragment = ActiveFragment.HOSTAGE_STATE;

	constructor(protected coreService: CoreService, protected route: ActivatedRoute, protected intervalService: IntervalService,
		private authService: AuthService, private cdRef: ChangeDetectorRef) {
		super(coreService, route, intervalService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.rights = this.authService.getUser().frontend_rights;
		this.cdRef.detectChanges();
	}

	setDefaultFragment() {
		if (this.isWebLive) {
			this.currentFragment = this.impressionFragment;
		} else {
			this.currentFragment = this.blockRateFragment;
		}
	}

	isFewWebsites(websites: any[]) {
		return this.coreService.isLessThan100(websites);
	}

}
