import {Component, Input, OnInit} from '@angular/core';
import {MetaPlotlyChartComponent} from '../charts/meta-plotly-chart.component';
import {ChartType, ChartTypeInterface} from '../chart.service';
import {CoreService} from '../core.service';
import {IntervalService} from '../interval.service';

declare var Plotly: any;

@Component({
	selector: 'app-block-rate-bar',
	templateUrl: './block-rate-bar.component.html'
})

export class BlockRateBarComponent extends MetaPlotlyChartComponent implements OnInit {
	@Input() chartType: ChartType;
	chart: ChartTypeInterface;
	absolute: boolean;

	constructor(protected coreService: CoreService, private intervalService: IntervalService) {
		super(coreService);
	}

	ngOnInit() {
		this.intervalService.getSharedIntervalDataObservable().subscribe(intervalData => {
			if (intervalData.absolute !== this.absolute) {
				this.absolute = intervalData.absolute;
				this.setChartTitleIdAndLayout();
			}
		});
		super.ngOnInit();
	}

	setChartTitleIdAndLayout() {
		this.data = this.data.slice();
		if (this.absolute) {
			this.data = this.data.filter(function(i) { return !i['name'].includes('%'); });
		} else {
			this.data = this.data.filter(function(i) { return i['name'] !== 'Blocking' && i['name'] !== 'Non Blocking'; });
		}
		this.plotId = `plotly_chart_${this.title}`;
		const legend = {x: 1, xanchor: 'right', y: -0.1, orientation: 'h'};
		this.layout = {
			autosize: true,
			title: this.title,
			barmode: 'stack',
			legend: legend
		};
		const hovertemplate = this.absolute ? '%{y} <extra></extra>' : '%{y} %<extra></extra>';
		this.data.forEach(i => {
			i['type'] = 'bar';
			i['textposition'] = 'auto';
			i['hovertemplate'] = hovertemplate;
			if (this.absolute) {
				i['y'] = i['y'].map(j => this.coreService.formatNumberSeparateByThousands(j));
				i['text'] = i['y'];
			} else {
				i['text'] = i['y'].map(j => j + '%');
			}
			if (i['name'].includes('Non Blocking')) {
				i['marker'] = {'color': 'rgb(165,229,158)'};
			} else if (i['name'].includes('Blocking')) {
				i['marker'] = {'color': 'rgb(243,105,105)'};
			}
		});
	}

	restyleValues() {
		if (this.isChartExist()) {
			for (let i = 0; this.data.length > i; i++) {
				this.restyleValueWithIndex('y', i);
				this.restyleValueWithIndex('x', i);
				this.restyleValueWithIndex('text', i);
				this.restyleValueWithIndex('hoverinfo', i);
			}
		}
	}

	changeLegendOrientation(x) {
	}
}
