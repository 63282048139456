	<ng-container *ngIf="brs; else noSite">
		<div class="ui raised segment stackable grid">
			<div class="ui four wide column">
			<app-interval-chooser *ngIf="intervalData" [intervalData]="intervalData" [hideIntervalSwitch]="hideIntervalSwitch"
						[showAbsolute]="true"></app-interval-chooser>
			</div>
			<div class="ui four wide column">
				<label class="teal">{{brs.name}}</label><br>
				<a class="teal-link" target="_blank" href="{{brs.url}}">{{brs.url}}</a>
			</div>
			<div class="ui four wide column">
				<label>eCPM: </label>${{brs.ecpm}}<br>
			</div>
			<div class="ui four wide column">
				<div *ngIf="brs.verifiedResolved; else loader" id='verified_tag' [ngClass]="brs.verified ? 'ui teal small basic label' : 'ui red small basic label'">
				{{getVerifyText(brs.verified)}}
				</div>
				<ng-template #loader>
					<div id="verify_loader" class="ui active small inline loader"></div>
				</ng-template>
				<button class="ui red mini button" id="delete_button" (click)="showDeleteDialog = true"><i class="trash icon"></i>Remove</button>
			</div>
		</div>

		<ng-container *ngIf="!brs.verified">
			<div class="ui raised segment stackable grid">
				<div class="ui four wide column">
					<button id="verify_button" class="ui button" (click)="verifyBrsSite(brs)">Verify</button>
				</div>
				<div class="ui ten wide column">
					If you already installed the measurement code on your page, please click the verify button.<br>
					You'll find the code in the <a class="teal-link" [routerLink]="['/block-rate/install']">install</a> section.
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="brs.verified">

			<div class="ui stackable grid unpad">
				<div class="center aligned three column row">
					<div class="ui mobile hidden column">
						<div class="ui raised segment">
							<h4>Total Pageviews</h4>
							<h1>{{ total['all'] | number }}</h1>
						</div>
					</div>

					<div class="ui column">
						<div class="ui raised segment">
							<h4>Allowing Ads</h4>
							<h1 class="ui green-link">{{ (total['non_blocking'] / total['all'] * 100) | number : '1.2-2' }} %</h1>
						</div>
					</div>

					<div class="ui column">
						<div class="ui raised segment">
							<h4>Blocking Ads</h4>
							<h1 class="ui red-link">{{ (total['blocking'] / total['all'] * 100) | number : '1.2-2' }} %</h1>
						</div>
					</div>
				</div>

				<div class="center aligned two column row">
					<div class="ui column">
						<div class="ui raised segment">
							<h4>Revenue Lost</h4>
							<h1 class="ui red-link">-${{ total['blocking'] * brs.ecpm / 1000 | number : '1.2-2' }}</h1>
							<div class="narrow info-block">
								<p>
									<label>Per day:{{dayInterval}}</label> <span>-${{(total['blocking'] * brs.ecpm / 1000) / dayInterval | number : '1.2-2'}}</span>
								</p>
								<p>
									<label>Per month:</label> <span>-${{(total['blocking'] * brs.ecpm / 1000) / dayInterval * 30 | number : '1.2-2'}}</span>
								</p>
							</div>
						</div>
					</div>
					<div class="ui column">
						<div class="ui raised segment">
							<h4>Revenue Recovered {{user.brs_upgrade?'true':'false'}}</h4>
							<h1 class="ui green-link">${{ 0 }}</h1>

							<ng-container *ngIf="!user.brs_upgrade">
								<div *ngIf="upgradePending !== false" class="ui success message info-block">
									<!--<div *ngIf="upgradePending === true" class="ui active dimmer">
    									<div class="ui text loader">Loading</div>
  									</div>-->
									<p>Losing money? Click the button bellow and get your revenue back.</p>
									<button class="ui teal button" (click)="requestUpgrade()">
										<i *ngIf="upgradePending === true" class="spinner icon rotating"></i>
										Get your revenue back
									</button>
								</div>
								<ng-container *ngIf="upgradePending === false">

									<div *ngIf="upgradeSuccess" class="ui success message info-block">
										Our staff will get back to you by email within 24 hours with an offer
										of our ad-recovery services.<br>
										Together - we'll make it.
									</div>
									<div *ngIf="upgradeSuccess === false" class="ui error message info-block">
										Something went wrong, try again later!
										<button class="ui teal button" (click)="requestUpgrade()">Get your revenue back</button>
									</div>

								</ng-container>
							</ng-container>
							<div *ngIf="user.brs_upgrade" class="ui success message info-block">
								You've already requested your account upgrade.
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="ui six wide column">
						<div class="ui raised segment">
							<app-plotly-chart *ngIf="plotlyDataPie" [data]="plotlyDataPie" [chartType]="chartTypePie"></app-plotly-chart>
						</div>
					</div>
					<div class="ui ten wide column">
						<div class="ui raised segment">
							<app-plotly-chart *ngIf="plotlyDataBar" [data]="plotlyDataBar" [chartType]="chartTypeBar"></app-plotly-chart>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="this.formatedCsv" class="ui raised segment">
				<table class="ui celled table">
					<thead>
						<tr>
							<th [colSpan]="this.formatedCsv[0].time?'2':'1'">
								<app-block-rate-csv [csvData]="csv" [filename]="brs.aab_site_id + '_%from-%to'"></app-block-rate-csv>
							</th>
							<th colspan="2">Allowed</th>
							<th colspan="2">Blocked</th>
						</tr>
						<tr>
							<th>Date</th>
							<th *ngIf="this.formatedCsv[0].time">Time</th>
							<th>Pageviews</th>
							<th>Percent</th>
							<th>Pageviews</th>
							<th>Percent</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let row of this.formatedCsv">
							<td>{{row.date}}</td>
							<td *ngIf="row.time">{{row.time}}</td>
							<td>{{row.allowed_pvs | number}}</td>
							<td>{{row.allowed_perc | number : '1.2-2'}} %</td>
							<td>{{row.blocked_pvs | number}}</td>
							<td>{{row.blocked_perc | number : '1.2-2'}} %</td>
						</tr>
					</tbody>
					<tfoot>
						<!--<tr>
							<th [colSpan]="this.formatedCsv[0].time?'2':'1'"></th>
							<th colspan="2">Allowed</th>
							<th colspan="2">Blocked</th>
						</tr>
						<tr>
							<th></th>
							<th *ngIf="this.formatedCsv[0].time"></th>
							<th>Pageviews</th>
							<th>Percent</th>
							<th>Pageviews</th>
							<th>Percent</th>
						</tr>-->
						<tr>
							<th>Total</th>
							<th *ngIf="this.formatedCsv[0].time"></th>
							<th>{{sumCsv.allowed_pvs | number}}</th>
							<th>{{sumCsv.allowed_perc | number : '1.2-2'}} %</th>
							<th>{{sumCsv.blocked_pvs | number}}</th>
							<th>{{sumCsv.blocked_perc | number : '1.2-2'}} %</th>
						</tr>
				  	</tfoot>
				</table>
			</div>
		</ng-container>
	</ng-container>
	<ng-template #noSite>
		<div class="ui raised segment">
			<h1 class="ui teal header">No site selected</h1>
			Start by creating a website by clicking "add website" in the top menu.<br>
			Once the website is created you will find instructions on how to insert the measurement code in your page here.
		</div>
	</ng-template>

<fui-modal *ngIf="showDeleteDialog" [isClosable]="false" (dismissed)="showDeleteDialog = false" #modal>
    <div class="header">Remove Website</div>
	<div class="content">
        Do you really want to remove the website {{brs.name}} from your dashboard?
        <button class="ui red button" id="delete_button" (click)="deleteBrsSite(brs); modal.approve('done');">Remove</button>
    </div>
    <div class="actions">
        <button class="ui button" (click)="modal.deny();">Cancel</button>
    </div>
</fui-modal>
