import {Component, OnDestroy, OnInit} from '@angular/core';
import {SasZone, SasZonesMainTypes, SasZonesResource} from '../../resources/sas-zones.resource';
import {Subscription} from 'rxjs';
import {CoreService} from '../../shared/core.service';

@Component({
	selector: 'app-sas-zones',
	templateUrl: './sas-zones.component.html',
	styleUrls: ['./sas-zones.component.less'],
	providers: [SasZonesResource]

})
export class SasZonesComponent implements OnInit, OnDestroy {
	aab_publisher_id = 'mafra';
	sasZonesSubscription: Subscription;
	showSpinner = false;
	sasZones: SasZone[];
	sasZonesListing: SasZone[];
	isList = false;
	zoneTypes = [{type: SasZonesMainTypes.BRANDING, value: false}, {type: SasZonesMainTypes.PREROLL, value: false},
		{type: SasZonesMainTypes.POSTROLL, value: false}];
	zonesSaved = false;

	constructor(private sasZonesResource: SasZonesResource, private coreService: CoreService) {
	}

	ngOnInit() {
		this.getAllZones();
	}

	checkStarEntry(sasZone: SasZone) {
		return sasZone.prefix !== '*';
	}

	setStarEntriesToTrue(sasZones: SasZone[]): SasZone[] {
		const starPrefix = sasZones.filter(s => s.prefix === '*');
		if (starPrefix && starPrefix.length > 0) {
			const starEntries = starPrefix[0].postfixes.map(p => p.name);
			sasZones.forEach(sasZone => {
				sasZone.postfixes.forEach(postfix => {
					if (starEntries.includes(postfix.name)) {
						postfix.value = true;
					}
				});
			});
		}
		return sasZones;
	}

	getAllZones() {
		this.showSpinner = true;
		this.sasZonesSubscription = this.sasZonesResource.get(this.aab_publisher_id).subscribe(
			res => {
				this.sasZones = this.setStarEntriesToTrue(res['sas-zones-all']).filter(this.checkStarEntry);
				this.showSpinner = false;
				this.sasZonesListing = res['sas-zones-true'];
			});
	}

	upperCaseFirstLetter(word: string) {
		return this.coreService.upperCaseFirstLetter(word);
	}

	checkSavedZones() {
		if (this.zonesSaved) {
			this.getAllZones();
			this.zonesSaved = false;
		}
	}

	zonesSave() {
		this.zonesSaved = true;
	}

	ngOnDestroy(): void {
		if (this.sasZonesSubscription) {
			this.sasZonesSubscription.unsubscribe();
		}
	}
}
