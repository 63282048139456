import {Component, OnDestroy} from '@angular/core';
import {CoreFilterMetaComponent} from './core-filter-meta.component';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {PublishersService} from './publishers.service';
import {MessageService} from '../shared/message.service';
import {AuthService} from '../auth/auth.service';
import {CoreService} from '../shared/core.service';
import {Subscription} from 'rxjs';


@Component({
	selector: 'app-core-filter',
	templateUrl: './core-filter.component.html',
	styleUrls: ['./core-filter.component.less']
})
export class CoreFilterComponent extends CoreFilterMetaComponent implements OnDestroy {
	headerSubscription: Subscription;
	header: string;
	hideWebsites = false;

	constructor(protected publishersService: PublishersService,
				protected route: ActivatedRoute,
				protected messageService: MessageService,
				protected authService: AuthService,
				protected coreService: CoreService,
				protected router: Router) {
		super(publishersService, route, messageService, authService);
	}

	onInitFirst() {
		super.onInitFirst();
		this.setHeader();
		this.headerSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.setHeader();
			}
		});
	}

	setHeader() {
		const path = this.route.snapshot.children[0].url[0].path;
		this.hideWebsites = this.coreService.hideWebsitesByPath(path);
		this.header = this.coreService.prepareHeaderFromUrlPath(path);
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		if (this.headerSubscription) {
			this.headerSubscription.unsubscribe();
		}
	}
}
