import {OnInit, Component, ViewChild, ElementRef, AfterViewInit, OnDestroy, Input} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {LoggedInUser, LoggedInUserRights, MenuType} from '../../models/data-structure';
import {AuthService} from '../../../auth/auth.service';
import {ElementService} from '../../../shared/element.service';
import {CoreService} from '../../../shared/core.service';
import {BrsSite} from '../../models/data-structure';
import {environment} from '../../../../environments/environment';


@Component({
	selector: 'app-main-menu-links',
	templateUrl: './main-menu-links.component.html',
	styleUrls: ['./main-menu-links.component.less'],
})

export class MainMenuLinksComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() menuType: MenuType;
	baseUrl = '/dashboard';
	rights: LoggedInUserRights;
	public user: LoggedInUser;
	routerEventsSubscription: Subscription;
	leftMenu = MenuType.LEFT;
	@ViewChild('menu', {static: false}) menu: ElementRef;
	commitSha: String = '';
	appVersion: String = '';

	constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute,
				protected elementService: ElementService, private coreService: CoreService) {
		this.appVersion = environment.appVersion;
		this.commitSha = environment.appCommitSha;
	}

	ngOnInit() {
		this.user = this.authService.getUser();
		this.rights = this.user.frontend_rights;
		this.baseUrl = this.coreService.getBaseUrl();
		this.checkPathChange();
	}

	checkPathChange() {
		this.routerEventsSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.activateMenuItem();
			}
		});
	}

	ngAfterViewInit() {
		this.activateMenuItem();
	}

	activateMenuItem() {
		const currentPath = location.pathname.split('/')[1];
		const menuItems = Array.from(this.menu.nativeElement.querySelectorAll('.item'));

		menuItems.forEach(menuItem => {
			if (!menuItem['href']) {
			} else if (menuItem['href'].startsWith(currentPath)) {
				this.elementService.addClass(menuItem, 'active');
			} else {
				this.elementService.removeClass(menuItem, 'active');
			}
		});
	}

	getStyleForEmail(): any {
		const style = {};
		if (this.user.email.length > 25) {
			style['font-size'] = '0.7em';
		}
		return style;
	}

	navigateToBase() {
		this.router.navigate([this.baseUrl], { queryParams: this.route.snapshot.queryParams });
	}

	logout() {
		this.authService.logout();
		this.router.navigate(['login']);
	}

	ngOnDestroy() {
		if (this.routerEventsSubscription) {
			this.routerEventsSubscription.unsubscribe();
		}
	}

}

@Component({
	selector: 'app-top-menu-links',
	templateUrl: './top-menu-links.component.html',
	styleUrls: ['./top-menu-links.component.less'],
})
export class TopMenuLinksComponent extends MainMenuLinksComponent {
	showWebsiteForm = false;
	useSelectedSite = false;
	selectedSite: BrsSite = null;

	constructor(authService: AuthService, router: Router, route: ActivatedRoute,
				elementService: ElementService, coreService: CoreService) {
		super(authService, router, route, elementService, coreService);
	}

	openEdit() {
		if (this.selectedSite) {
			this.showWebsiteForm = true;
			this.useSelectedSite = true;
		}
	}

	activateMenuItem() {
		const currentPath = location.pathname.split('?')[0];
		const menuItems = Array.from(this.menu.nativeElement.querySelectorAll('.item'));

		menuItems.forEach(menuItem => {

			if (!menuItem['href']) {
				return;
			}
			const href = menuItem['href'].split('?')[0].replace(location.protocol + '//' + location.host, '');
			if (href === currentPath) {
				this.elementService.addClass(menuItem, 'active');
			} else {
				this.elementService.removeClass(menuItem, 'active');
			}
		});
	}

	updateSelection(site: BrsSite) {
		this.selectedSite = site;
	}
}
