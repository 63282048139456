import {Component, HostBinding} from '@angular/core';
import {BaseInputComponent} from './base-input.component';

@Component({
	selector: 'app-longtext-input',
	template: '<label>{{name}}</label><textarea rows="2" (change)="onChange($event)">{{value}}</textarea>',
	styles: [''],
})

export class LongTextInputComponent extends BaseInputComponent {
	@HostBinding('attr.class') role = 'sixteen wide field';

	constructor( ) {
		super();
	}
}
