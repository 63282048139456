import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CoreService} from '../../shared/core.service';

@Component({
	selector: 'app-page-with-message',
	templateUrl: './page-with-message.component.html',

})
export class PageWithMessageComponent implements OnInit {
	message: string;
	baseUrl = '/dashboard';

	constructor(private route: ActivatedRoute, private coreService: CoreService) {
	}

	ngOnInit() {
		this.baseUrl = this.coreService.getBaseUrl();
		this.message = this.route.snapshot.data[0]['message'];
	}

}
