import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {UserManagementComponent} from './user-management.component';
import {UserManagementFormComponent} from './user-management-form/user-management-form.component';
import {LoggedInGuard} from '../../../auth/guard/logged-in.guard';
import {AllowedGuard} from '../../../auth/guard/allowed.guard';


const userManagementRoutes: Routes = [
	{ path: '', component: UserManagementComponent, canActivate: [LoggedInGuard, AllowedGuard] },
	{ path: 'edit-user/:user_id', component: UserManagementFormComponent, canActivate: [LoggedInGuard, AllowedGuard] },
];

@NgModule({
	imports: [RouterModule.forChild(userManagementRoutes)],
	exports: [RouterModule]
})
export class UserManagementRoutingModule {
}
