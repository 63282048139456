import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

export enum DebugType {
	REAL_WEBSITE,
	AAB_DEMO
}

export enum DebugResponseMessage {
	NEED_TO_CHECK,  // 0
	OK,             // 1
	BAD_STATUS,     // 2
	BAD_PROXY       // 3
}

export interface CommandResponse {
	status: string;
	output: string;
	message: DebugResponseMessage;
}

export interface CommandsResponse {
	aab_id: string;
	name: string;
	collapse: boolean;
	link: string;
}

@Injectable()
export class DebugResource {
	url = '/test_curl';

	constructor(private httpClient: HttpClient) {
	}

	get(debug_type: DebugType, aab_publisher_id: string, aab_site_id: string, request: string): Observable<CommandResponse> {
		return this.httpClient.get<CommandResponse>(
			`${environment.apiEndpoint}${this.url}/${aab_publisher_id}/${aab_site_id}/${debug_type}/${request}`
		);
	}

	getCommands(debug_type: DebugType, aab_publisher_id: string, aab_site_id: string): Observable<CommandsResponse> {
		return this.httpClient.get<CommandsResponse>(
			`${environment.apiEndpoint}${this.url}/${aab_publisher_id}/${aab_site_id}/${debug_type}`
		);
	}

}
