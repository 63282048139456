<div *ngIf="showSpinner" class="ui active small inline loader"></div>
<label>Timezone </label>
<fui-select *ngIf="showSelect && timeZones && timeZone"
			class="selection"
			[(ngModel)]="timeZone"
			[options]="timeZones"
			[isSearchable]="true"
			(ngModelChange)=onChangeTimeZone()
			(click)="loadSelect()"
			#selectTimeZone>
		<span ></span>
		<div class="ui icon search input">
			<i class="search icon"></i>
			<input fuiSelectSearch type="text" placeholder="Search...">
		</div>
		<div class="divider"></div>
		<div class="header">
			<i class="list icon"></i>
			Time Zones
		</div>
	<fui-select-option *ngFor="let option of selectTimeZone.filteredOptions"
					   [value]="option">
	</fui-select-option>
</fui-select>
<div *ngIf="!showSelect" class="ui input">
	<input (click)="showSelect = true;" type="text" value="{{timeZone}}">
</div>
