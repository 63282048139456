import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoggedInGuard} from './auth/guard/logged-in.guard';
import {AllowedGuard} from 'app/auth/guard/allowed.guard';
import {SuperAdminGuard} from 'app/auth/guard/superadmin.guard';
import {SasZonesGuard} from './auth/guard/sas-zones.guard';
import {LoginComponent} from './auth/login/login.component';
import {ResetPasswordComponent} from './auth/reset-password/reset-password.component';
import {MenuComponent} from './core/menu/menu.component';
import {DashboardComponent} from './core/dashboard/dashboard.component';
import {InstallationComponent} from './core/installation/installation.component';
import {InstallationDocumentComponent} from './core/installation/installation-document/installation-document.component';
import {ConfigurationDocumentComponent} from './core/installation/configuration-document/configuration-document.component';
import {LiveDemoComponent} from './core/live-demo/live-demo.component';
import {PageWithMessageComponent} from 'app/core/page-with-message/page-with-message.component';
import {AdScanComponent} from './core/adscan/ad-scan.component';
import {ChangePasswordComponent} from './core/loggedinuser/changepassword/change-password.component';
import {AdScanDetailComponent} from './core/adscan/ad-scan-detail/ad-scan-detail.component';
import {CoreFilterComponent} from './core/core-filter.component';
import {FaqComponent} from './core/faq/faq.component';


const routes: Routes = [
	{
		path: '', component: MenuComponent, canActivate: [LoggedInGuard], children: [
			{path: '', component: CoreFilterComponent, canActivate: [LoggedInGuard, AllowedGuard], children: [
				{path: '', redirectTo: 'dashboard', pathMatch: 'full'},
				{path: 'dashboard', component: DashboardComponent, canActivate: [LoggedInGuard, AllowedGuard]},
				{path: 'installation', component: InstallationComponent, canActivate: [LoggedInGuard, AllowedGuard]},
				{path: 'live-demo', component: LiveDemoComponent, canActivate: [LoggedInGuard, AllowedGuard]},
				{path: 'ad-scan', component: AdScanComponent, canActivate: [LoggedInGuard, AllowedGuard]},
				// {path: 'ad-scan', loadChildren: 'app/core/adscan/ad-scan.module#AdScanModule'},
			]},
			{path: 'website', loadChildren: () => import('app/core/dashboard/website/website.module').then(m => m.WebsiteModule)},
			{path: 'sas-zones', loadChildren: () => import('app/core/sas-zones/sas-zones.module').then(m => m.SasZonesModule)},
			{path: 'installation/:aab_publisher_id', component: InstallationDocumentComponent, canActivate: [LoggedInGuard, AllowedGuard]},
			{path: 'installation/configuration/:aab_publisher_id', component: ConfigurationDocumentComponent,
				canActivate: [LoggedInGuard, AllowedGuard]},
			{path: 'ad-scan/ad-scan-detail/:aab_publisher_id/:aab_site_id', component: AdScanDetailComponent,
				canActivate: [LoggedInGuard, AllowedGuard]},
			{path: 'budget', loadChildren: () => import('app/core/budget/budget.module').then(m => m.BudgetModule)},

			{path: 'change-password', component: ChangePasswordComponent, canActivate: [LoggedInGuard]},
			{path: 'user-management', loadChildren: () => import('app/core/admin/user-management/user-management-routing.module')
					.then(m => m.UserManagementRoutingModule)},
			{path: 'management', loadChildren: () => import('app/core/admin/admin-routing.module').then(m => m.AdminRoutingModule)},
			{path: 'not-found', component: PageWithMessageComponent, data: [{message: 'Page was not found!'}]},
			{path: 'not-allowed', component: PageWithMessageComponent, data: [{message: 'You are not allowed to see this site.'}]},
			{path: 'aab-lite', loadChildren: () => import('app/core/lite/lite.module').then(m => m.LiteModule)},
			{path: 'faq', component: FaqComponent, canActivate: [LoggedInGuard, AllowedGuard]},
		]
	},

	{path: 'block-rate', loadChildren: () => import('app/core/block-rate/block-rate.module').then(m => m.BlockRateModule)},

	{path: 'login', component: LoginComponent},
	{path: 'reset-password/:reset_key', component: ResetPasswordComponent},
	{path: 'change-password/:reset_key', component: ResetPasswordComponent},

	{path: 'brs/registration', redirectTo: 'block-rate/registration'},
	{path: '**', redirectTo: 'not-found'}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: [
		LoggedInGuard, AllowedGuard, SuperAdminGuard, SasZonesGuard
	],
})
export class AppRoutingModule {
}
