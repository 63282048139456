import {Component, Input} from '@angular/core';
import {ChartType} from '../chart.service';
import {CoreService} from '../core.service';
import {MetaPlotlyChartComponent} from '../charts/meta-plotly-chart.component';
import {BlockingState, BlockratePercentData} from '../../resources/block-rate.resource';

declare var Plotly: any;

const BLOCKING_CHART = { os_color: [123, 0, 9], browser_color: [126, 20, 0], add_os_color: [5, 3, 3], add_browser_color: [4, 2, 2]};
const NON_BLOCKING_CHART = { os_color: [0, 93, 31], browser_color: [14, 105, 0], add_os_color: [5, 3, 5], add_browser_color: [2, 1, 2]};

@Component({
	selector: 'app-block-rate-pie',
	templateUrl: './block-rate-pie.component.html'
})

export class BlockRatePieComponent extends MetaPlotlyChartComponent {
	@Input() chartType: ChartType;
	isBlocking = true;
	blockingState = BlockingState.BLOCKING;
	state = 'Blocking';
	blockRatePercentData: BlockratePercentData;
	xData: any;
	percent: number;

	constructor(protected coreService: CoreService) {
		super(coreService);
	}

	init() {
		this.setProgressChart();
		this.setChartTitleIdAndLayout();
		this.checkMaxWidth();
		setTimeout(() => {
			Plotly.newPlot(this.plotId, this.xData, this.layout, this.options);
			this.plotlyInit = true;
		}, 0);
	}

	setProgressChart() {
		const metadataPercent = this.data['metadata']['percent'];
		this.percent = this.blockingState === BlockingState.BLOCKING ? metadataPercent['blocking'] : metadataPercent['non_blocking'];
		this.blockRatePercentData = {blockingState: this.blockingState, percent: this.percent};
	}

	unpack(rows, key) {
		return rows.map(function(row) {return row[key]; });
	}

	getRgbString(rgb: number[]) {
		const [r, g, b] = rgb;
		return `rgb(${r}, ${g}, ${b})`;
	}


	getLighterColor(previousRGB: number[], add= [2, 2, 2]) {
		let [r, g, b] = previousRGB;
		const [add_r, add_g, add_b] = add;
		r += add_r;
		g += add_g;
		b += add_b;
		const current = [r, g, b];
		return {color: this.getRgbString(current), previous: current};
	}

	countColor(colors: string[], addColor: number[], previousColor: number[]) {
		const colorPrevious = this.getLighterColor(previousColor, addColor);
		previousColor = colorPrevious.previous;
		colors.push(colorPrevious.color);
		return previousColor;
	}

	getColors(state) {
		const colors = [];
		const chart = state === 'Blocking' ? BLOCKING_CHART : NON_BLOCKING_CHART;
		let previousOsColor = chart.os_color;
		let previousBrColor = chart.browser_color;
		this.data['data'][state].forEach(i => {
			if (i['parents'] === '') {
				previousOsColor = this.countColor(colors, chart.add_os_color, previousOsColor);
			} else {
				previousBrColor = this.countColor(colors, chart.add_browser_color, previousBrColor);
			}
		});
		return colors;
	}

	getChartDataForState(state: string) {
		return [{
				type: 'sunburst',
				maxdepth: 2,
				ids: this.unpack(this.data['data'][state], 'ids'),
				labels: this.unpack(this.data['data'][state], 'labels'),
				parents: this.unpack(this.data['data'][state], 'parents'),
				values: this.unpack(this.data['data'][state], 'values'),
				marker: {colors: this.getColors(state)},
				textposition: 'inside',
				insidetextorientation: 'radial',
				branchvalues: 'total',
				textinfo: 'label+percent entry',
				hoverinfo: 'label+percent entry',
				opacity: 0.95,
				// direction: 'clockwise',
				// rotation: 0
			}];
	}

	setChartTitleIdAndLayout() {
		this.plotId = `plotly_chart_${this.chartType}${this.state}`;
		this.layout = {autosize: true, showlegend: false, margin: {t: 0, b: 0}, padding: {t: 0}};
		let x = null;
		if (this.blockingState === BlockingState.BLOCKING) {
			x = this.getChartDataForState('Blocking');
		} else if (this.blockingState === BlockingState.NON_BLOCKING) {
			x = this.getChartDataForState('Non Blocking');
		}
		this.xData = x;
	}


	setDifferentLayoutToPie(x) {
		if (x.matches) {
			// this.layout['height'] = null;
		} else {
			// this.layout['height'] = 600;
		}
	}

	restyleValues() {
		if (this.isChartExist()) {
			this.setChartTitleIdAndLayout();
			Plotly.relayout(this.plotId, this.layout);
			for (let i = 0; this.data.length > i; i++) {
				this.restyleValueWithIndex('labels', i);
				this.restyleValueWithIndex('marker', i);
				this.restyleValueWithIndex('marker.colors', i);
				this.restyleValueWithIndex('values', i);
			}
		}
	}

	changeBlockingState() {
		this.isBlocking = !this.isBlocking;
		if (this.isBlocking) {
			this.blockingState = BlockingState.BLOCKING;
			this.state = 'Blocking';
		} else {
			this.blockingState = BlockingState.NON_BLOCKING;
			this.state = 'Non Blocking';
		}
		this.init();
	}
}
