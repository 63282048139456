import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
	selector: 'app-ad-scan-detail',
	templateUrl: './ad-scan-detail.component.html',
})

export class AdScanDetailComponent implements OnInit, OnDestroy {
	paramsSubcription: Subscription;
	aabPubId: string;
	aabSiteId: string;

	constructor(private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.paramsSubcription = this.route.params.subscribe(
			(params: Params) => {
				this.aabPubId = params['aab_publisher_id'];
				this.aabSiteId = params['aab_site_id'];
			});
	}

	ngOnDestroy(): void {
		if (this.paramsSubcription) {
			this.paramsSubcription.unsubscribe();
		}
	}
}
