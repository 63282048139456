<span *ngIf="hideable">
	<a (click)="showScript=!showScript;">
		<span *ngIf="!showScript" class="teal-link">Show blockrate script <i class="code branch icon"></i></span>
		<span *ngIf="showScript" class="teal-link">Hide blockrate script <i class="code branch icon"></i></span>
	</a>
</span>
<div *ngIf="!hideable || showScript" class="ui form">
	<div class="field">
		<textarea>{{kununuScript}}</textarea>
	</div>
	<button class="ui teal button" [appCopyClipboard]="kununuScript"><i class="ui clipboard icon"></i>Copy code to clipboard</button>
</div>
