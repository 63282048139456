<table class="ui very compact small fixed very basic single line sortable table" *ngIf="columnNames">
  <thead>
	<tr>
		<th *ngFor="let name of columnNames" class="pointer" (click)="sortTable(name)" >
			{{name}}
			<app-sort-icon [sortBy]=name [column]="column" [isDesc]="isDesc" [isAlphabet]="false"></app-sort-icon>
		</th>
  </tr></thead>
  <tbody>
  	<tr>
		<td *ngFor="let name of columnNames">{{formatCount(getTotal(name), name)}}</td>
	</tr>
	<tr *ngFor="let item of tableData | orderBy: {property: column, direction: direction}">
		<td *ngFor="let name of columnNames">{{formatCount(item[name], name)}}</td>
	</tr>
  </tbody>
</table>
