import {Component, Input, OnChanges, OnInit} from '@angular/core';


@Component({
	selector: 'app-csv-download',
	templateUrl: './csv-download.component.html',
})

export class CsvDownloadComponent implements OnInit, OnChanges {
	@Input() csvData: any;
	@Input() filename: string;
	properties: string[];

	renderFilename = '';
	showSpinner = true;
	csv: string;
	date = {from: '', to: ''};

	ngOnInit() {
		if (!this.csv) {
			this.showSpinner = true;
		}
	}

	ngOnChanges() {
		this.prepareCSV(this.csvData);
		this.setFilename(this.filename);
	}

	setFilename(filename) {
		this.renderFilename = filename.replace('%from', this.date.from).replace('%to', this.date.to) + '.csv';
	}

	prepareCSV(csv: any) {
		if (csv) {
			this.properties = Object.keys(csv[0]);
			this.csvData = csv;
			this.showSpinner = false;
			this.csv = '\ufeff';
			const names = this.properties.join(', ');
			this.csv += names + '\n';
			this.csvData.forEach(item => {
				let line = '';
				this.properties.forEach(name => {
					if (this.properties[this.properties.length - 1] === name) {
						line += item[name];
					} else {
						line += item[name] + ', ';
					}
				});
				this.csv += line + '\n';
			});
			if (this.properties.includes('date')) {
				this.date.from = this.csvData[0]['date'].replace(/\//g, '.');
				this.date.to = this.csvData[this.csvData.length - 1]['date'].replace(/\//g, '.');
			}
		}
	}

	getCSV(event) {
		event.preventDefault();
		const date = new Date();
		const link = document.createElement('a');
		link.setAttribute('href', 'data:text/csv;charset=UTF-8,' + this.csv);
		const formatDate = date.toLocaleDateString('en-GB');
		link.setAttribute('download', `${this.renderFilename}`);
		link.click();
	}

}
