import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {SasZonesComponent} from './sas-zones.component';
import {LoggedInGuard} from '../../auth/guard/logged-in.guard';
import {SasZonesGuard} from '../../auth/guard/sas-zones.guard';


const sasZonesRoutes: Routes = [
	{ path: '', component: SasZonesComponent, canActivate: [LoggedInGuard, SasZonesGuard] }
];

@NgModule({
	imports: [RouterModule.forChild(sasZonesRoutes)],
	exports: [RouterModule]
})
export class SasZonesRoutingModule {
}
