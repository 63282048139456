<table class="ui sortable celled table">
  <thead>
	<tr>
		<th class="pointer" (click)="sortTable('name')" >
			Tag Name
			<app-sort-icon [sortBy]="'name'" [column]="column" [isDesc]="isDesc" [isAlphabet]="true"></app-sort-icon>
		</th>
		<th class="pointer" (click)="sortTable('category')" >
			Tag Category
			<app-sort-icon [sortBy]="'category'" [column]="column" [isDesc]="isDesc" [isAlphabet]="true"></app-sort-icon>
		</th>
		<th class="pointer" (click)="sortTable('zone_name')" >
			Zone
			<app-sort-icon [sortBy]="'zone_name'" [column]="column" [isDesc]="isDesc" [isAlphabet]="true"></app-sort-icon>
		</th>
		<th class="pointer" (click)="sortTable('aab_site_id')" >
			Website
			<app-sort-icon [sortBy]="'aab_site_id'" [column]="column" [isDesc]="isDesc" [isAlphabet]="true"></app-sort-icon>
		</th>
		<th class="pointer" (click)="sortTable('aab_publisher_id')" >
			<app-sort-icon [sortBy]="'aab_publisher_id'" [column]="column" [isDesc]="isDesc" [isAlphabet]="true"></app-sort-icon>
			Publisher
		</th>
		<th class="pointer" (click)="sortTable('adserver')">
			<app-sort-icon [sortBy]="'adserver'" [column]="column" [isDesc]="isDesc" [isAlphabet]="true"></app-sort-icon>
			Adserver
		</th>
		<th *ngIf="canEditTags===true">Actions</th>
  </tr></thead>
  <tbody>
	<tr *ngFor="let tag of tags | orderBy: {property: column, direction: direction}">
		<td data-label="Tag Name">{{tag.name}}</td>
		<td data-label="Tag Category">{{tag.category}}</td>
		<td data-label="Zones">{{tag.zone_name}}</td>
		<td data-label="Website">{{tag.aab_site_id}}</td>
		<td data-label="Publisher">{{tag.aab_publisher_id}}</td>
		<td data-label="Adserver">{{tag.adserver}}</td>
		<td data-label="Actions" *ngIf="canEditTags===true">
			<div  class="ui tiny blue icon button" title="Edit" (click)="editTag(tag)">
					<i class="pencil alternate icon"></i>
				</div>
				<div  class="ui tiny red icon button" title="Delete" (click)="deleteTag(tag)">
					<i class="trash icon"></i>
				</div>
		</td>
	</tr>
  </tbody>
</table>
<app-action-tag-modal *ngIf="showEditTagModal" [zone]=null [tag]="tagToEdit" [actionType]="editType" (onMessage)="changeMessage($event)" (onChangeTags)="changeTags()"></app-action-tag-modal>
<app-delete-tag-modal *ngIf="showDeleteTagModal" [tag]="tagToDelete" (onMessage)="changeMessage($event)" (onChangeTags)="changeTags()"></app-delete-tag-modal>
