<h1 *ngIf="!subForm" class="ui header">{{title}}
<span [ngClass]="showSpinner ? 'ui small active inline loader' : 'inactive loader'"></span></h1>

<h3 *ngIf="subForm" class="ui header">{{title}}
<span [ngClass]="showSpinner ? 'ui small active inline loader' : 'inactive loader'"></span></h3>

<div class="ui grid" [ngClass]="subForm ? 'padded' : ''">
	<div class="ui segments" [ngClass]="subForm ? 'sixteen wide column' : 'twelve wide column'">
		<div class="ui segment">
			<ng-container #controls></ng-container>
		</div>
		<div class="ui segment">
			<div class="ui icon input">
				<input #searchBox
					(keyup)="filterChange(searchBox.value)"
					placeholder="Filter..."
					/>
				<i class="filter icon"></i>
			</div>
		</div>
		<app-rest-item *ngFor='let loader of itemList; index as i' class="ui container segment"
					[index]="i"
					[data]="loader"
					[fields]="fields"
					[messageIn]="messageOut"
					[messageInHandler]="itemMessageInHandler"
					[collapseIn]="collapseOut"
					(message)="listen($event)"
					>

		</app-rest-item>
		<div *ngIf="pages" class="ui segment">
	        <fui-pagination [(collectionSize)]="pages.length*pageSize"
	                        [pageSize]="pageSize"
	                        [maxSize]="10"
	                        [canRotate]="true"
	                        [hasNavigationLinks]="true"
	                        [(page)]="selectedPage"
	                        (pageChange)="pageChange()">
	        </fui-pagination>
	    </div>
	</div>
	<div class="three wide column">
		<div *ngIf="messages?.length > 0">
			<app-message *ngFor="let message of messages" [message]="message"></app-message>
		</div>
	</div>

</div>

