import { Component, ComponentRef, Input, Output, OnInit, EventEmitter,
		ViewChild, Compiler, OnDestroy } from '@angular/core';
import { ComponentFactoryResolver, ComponentFactory, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DateInputComponent } from '../inputs/date-input.component';
import { DateTimeInputComponent } from '../inputs/datetime-input.component';
import { TextInputComponent } from '../inputs/text-input.component';
import { LongTextInputComponent } from '../inputs/longText-input.component';
import { SelectInputComponent } from '../inputs/select-input.component';
import { BooleanInputComponent } from '../inputs/boolean-input.component';
import { NumberInputComponent } from '../inputs/number-input.component';
import { RestComponent } from '../rest.component';
import { MessageService } from '../../../../shared/message.service';
import { FileInputComponent } from '../inputs/file-input.component';
import { Subscription } from 'rxjs';
import { SelectInputUneditComponent } from '../inputs/select-input-unedit.component';
import { MultiSelectInputComponent } from '../inputs/multi-select-input.component';

@Component({
	selector: 'app-rest-item',
	templateUrl: './rest-item.component.html',
	styleUrls: ['./rest-item.component.less'],
})

export class RestItemComponent implements OnInit, OnDestroy {
	@Input() index: number;
	@Input() data: any;
	@Input() fields: any;
	@Input() messageIn: any;
	@Input() collapseIn: any;
	@Input() small: any; // head
	@Input() messageInHandler: any;
	@Output() message = new EventEmitter();
	@ViewChild('container', {read: ViewContainerRef, static: true}) viewContainer: ViewContainerRef;
	@ViewChild('underline', {read: ViewContainerRef, static: true}) viewUnderline: ViewContainerRef;
	@ViewChild('wideUnderline', {read: ViewContainerRef, static: true}) viewWideUnderline: ViewContainerRef;
	subComponentContainer: ComponentRef<any>;

	active = false;
	factories = {};
	changed = false;
	underlineClass = 'closed';
	changeEmitter = new EventEmitter<string>();
	fixedChangeSubscriptions: {string: Subscription}|{} = {};
	selectedBox = 'unselected';
	collapseBox = false;
	defaultData;
	previousData;
	responses = {};
	cmps = {string: ComponentRef};
	private subscriptionMessage: Subscription;
	private subscriptionCollapse: Subscription;

	constructor(
		private compiler: Compiler,
		/*public viewContainerRef: ViewContainerRef,*/
		private sanitizer: DomSanitizer,
		private componentFactoryResolver: ComponentFactoryResolver
	) {
	}

	getComponentFactory(type): ComponentFactory<any> {
		let ret = this.factories[type];
		if (!ret) {
			ret = this.factories['text'];
		}
		return ret;
	}

	ngOnInit(): void {
		this.createFactories();
		this.buildFields(this.viewContainer, this.fields['container']);
		this.buildFields(this.viewUnderline, this.fields['underline']);
		this.buildFields(this.viewWideUnderline, this.fields['wideUnderline']); // head
		this.allChange();
		this.storeDefaultData();

		this.subscriptionCollapse = this.collapseIn.subscribe(coll => {
			if (coll) {
				// this.collapseBox = true;
				if (this.underlineClass === 'open') {
					this.underlineClass = 'closed';
				}
			}
		});
	}

	createFactories() {
		this.factories['text'] = this.componentFactoryResolver.resolveComponentFactory(TextInputComponent);
		this.factories['date'] = this.componentFactoryResolver.resolveComponentFactory(DateInputComponent);
		this.factories['datetime'] = this.componentFactoryResolver.resolveComponentFactory(DateTimeInputComponent);
		this.factories['longText'] = this.componentFactoryResolver.resolveComponentFactory(LongTextInputComponent);
		this.factories['select'] = this.componentFactoryResolver.resolveComponentFactory(SelectInputComponent);
		this.factories['selectUnedit'] = this.componentFactoryResolver.resolveComponentFactory(SelectInputUneditComponent);
		this.factories['multiSelect'] = this.componentFactoryResolver.resolveComponentFactory(MultiSelectInputComponent);
		this.factories['boolean'] = this.componentFactoryResolver.resolveComponentFactory(BooleanInputComponent);
		this.factories['number'] = this.componentFactoryResolver.resolveComponentFactory(NumberInputComponent);
		this.factories['rest'] = this.componentFactoryResolver.resolveComponentFactory(RestComponent);
		this.factories['file'] = this.componentFactoryResolver.resolveComponentFactory(FileInputComponent);
	}

	buildFields(container: ViewContainerRef, fields) {
		if (!fields) {
			return;
		}
		for ( const f of fields ) {
			if (f.type !== 'Rest') {
				const cmp = container.createComponent(this.getComponentFactory(f.type));
				this.cmps[f.name] = cmp;
				cmp.instance.name = f.name;
				cmp.instance.value = this.data[f.name];
				cmp.instance.type = f.type;
				cmp.instance.isNew = !this.data['id'];
				cmp.instance.options = f.options;
				/*if (f.type === 'select') {
					cmp.instance.options = f.options;
				}*/
				if (f.fixed) {
					cmp.instance.fixed = f.fixed;
				}
				cmp.instance.change.subscribe((event: any) => {
					this.onChange(event);
				});
				continue;
			}
			if (f.type === 'Rest') {
				// console.log('building rest:', f, f.options);
				const factoryName = 'rest_' + f.name;
				this.factories[factoryName] = this.componentFactoryResolver.resolveComponentFactory(f.options.component);
				this.subComponentContainer = container.createComponent(this.getComponentFactory(factoryName));
				// cmp.instance['title'] = f[0];
				this.subComponentContainer.instance.component = f.options.component;
				this.subComponentContainer.instance.subForm = true;
				this.subComponentContainer.instance.fixed = this.getFixedData(f);
				this.subComponentContainer.instance.disable = f.options.disable;
				this.fixedChangeSubscriptions[f.name] = this.changeEmitter.subscribe(ret => {
					this.subComponentContainer.instance.fixed = this.getFixedData(f);
				});
				// cmp.instance['injected_resource'] = f[2]['injected_resource'];
				// cmp.instance[''] =
				// cmp.instance['change'].subscribe((event: any) => {this.onChange(event)});
			}
		}
	}

	enableSubcomponent(data: any) {
		if (this.subComponentContainer) {
			this.subComponentContainer.instance.disable = false;
			this.subComponentContainer.instance.descOfSubForm = false;
			const id = data['id'];
			this.subComponentContainer.instance.changeControlsDisable({'category': id});
			this.subComponentContainer.instance.setFixedCategory({name: data['title'], value: id}, true);
		}
	}

	getFixedData(field) {
		const fixedData = {};
		for (const name in field.options['fixed']) {
			if (field.options.fixed.hasOwnProperty(name)) {
				fixedData[name] = this.data[field.options.fixed[name]];
			}
		}
		// console.log('passing fixedData', fixedData);
		return fixedData;
	}

	allChange(): boolean {
		this.getPreviousData();
		if (this.changed) {
			return true;
		}
	}
	storeDefaultData() {
		const defaultData = JSON.parse(JSON.stringify(this.data));
		this.defaultData = defaultData;
	}
	deepEqual(a, b) {
		if (a === b) {return true; }
		if (a == null || typeof a !== 'object' || b == null || typeof b !== 'object') {
			return false; }
		const keysA = Object.keys(a), keysB = Object.keys(b);
		if (keysA.length !== keysB.length) {
			return false; }
		for (const key of keysA) {
			if (!keysB.includes(key) || !this.deepEqual(a[key], b[key])) {
				return false; }
		}
		return true;
	}
	compareData() {
		let isEqual;
		if (this.previousData === undefined || this.isEmpty(this.responses[this.index])) {
			isEqual = this.deepEqual(this.defaultData, this.data);
		} else {
			isEqual = this.deepEqual(this.responses[this.index], this.data);
		}

		if (!isEqual) {
			this.changed = true; // make it dirty
			this.selectedBox = 'selected';
			this.sendMessage('edit', this.index);
		} else {
			this.changed = false; // make it white again
			this.selectedBox = 'unselected';
			this.sendMessage('unedit', this.index);

		}
	}

	getPreviousData() {
		if (this.subscriptionMessage) {
			return;
		}
		this.subscriptionMessage = this.messageIn.subscribe(res => {
			if (this.subComponentContainer && this.subComponentContainer.instance.fixed['category'] === undefined) {
				// after parent was saved enable controls for child (subcomponet) and send its category id of parent
				this.enableSubcomponent(res['data']);
			}
			// TODO: this works only by chance -
			// should someone delete an item during the update of another,
			// the indices would go out of sync and fail horribly
			// BUT - we can't use the 'id' of the item, as new items don't have one before saving
			// and we can have multiple new items ...
			// MAYBE try to use some "frontend uuid" which would be kept through the whole update
			// to identify the updated item
			if (res.index !== this.index) { return; }
			const data = res.data;
			for (const key in data) {
				if (data.hasOwnProperty(key)) {
					const previousData = JSON.parse(JSON.stringify(data));
					this.previousData = previousData[key];
					this.responses[key] = previousData[key];
					// TODO: tie updating of data to updating of component in a better way
					this.data[key] = data[key];
					if (this.cmps[key]) {
						this.cmps[key].instance.value = data[key];
						this.cmps[key].instance.isNew = false;
					}
				}
			}
			if (this.messageInHandler) {
				this.messageInHandler(this, data);
			}
			this.changed = false;
			this.selectedBox = 'unselected';
			this.collapseBox = true;
			this.underlineClass = 'closed';
		});
	}
	isEmpty(obj) {
		return !obj || (Object.keys(obj).length === 0 && obj.constructor === Object);
	}
	onChange(event) {
		this.collapseBox = false;
		this.data[event[0]] = event[1];
		// console.log('zmenena hodnota', this.data[event[0]]);
		this.compareData();
	}
	save($event) {
		$event.stopPropagation();
		this.sendMessage('save', this.index);
		this.changed = false;
		this.changeEmitter.emit('save');
		this.selectedBox = 'unselected';
	}

	delete($event) {
		$event.stopPropagation();
		this.sendMessage('delete', this.index);
	}

	sendMessage(action: string, index: number) {
		this.message.emit({action: action, index: index});
	}

	open() {
		this.underlineClass = 'open';
	}

	selectBox($event) {
		$event.stopPropagation();
		if (this.selectedBox === 'selected') {
			this.selectedBox = 'unselected';
			this.sendMessage('unselect', this.index);
		} else {
			this.selectedBox = 'selected';
			this.sendMessage('select', this.index);
		}
	}

	toggle($event) {
		$event.stopPropagation();
		this.collapseBox = false;

		if (this.underlineClass === 'open') {
			this.underlineClass = 'closed';
		} else {
			this.underlineClass = 'open';
		}
	}

	ngOnDestroy() {
		for (const subscription in this.fixedChangeSubscriptions) {
			if (this.fixedChangeSubscriptions.hasOwnProperty(subscription)) {
				this.fixedChangeSubscriptions[subscription].unsubscribe();
			}
		}
	}
}
