<div>
	<app-interval-chooser *ngIf="canSeeChart" [intervalData]="intervalData" [hideIntervalSwitch]="hideIntervalSwitch"></app-interval-chooser>
	<div *ngIf="websites" id="chart_segment">
		<div *ngIf="canSeeChart; else TooManySelected">
			<app-pop-up-descriptions class="adscan-legend-description" [label]="'Legend Description'" [descriptions]="legendDescription"></app-pop-up-descriptions>
			<p>Showing data for: <app-more-websites [websites]="websites" [subscript]="'name'"></app-more-websites></p>
			<div [ngClass]="showDimmer ? 'ui active dimmer' : 'inactive dimmer'">
				<div class="ui medium text loader">Loading</div>
			</div>
			<div *ngIf="plotlyData">
				<span *ngFor="let data of plotlyData" >
				<div class="ui mini circular button" tabindex="0" (click)="navigateToTemplate(data.template)">
				  {{data.template}}
				</div>
				<app-plotly-chart [data]="data.data" [title]="data.template" [chartType]="chartType"></app-plotly-chart>
				</span>
			</div>
		</div>
		<ng-template #TooManySelected>
			<app-info-segment [text]="'Only data for 100 or less selected websites can be shown for performance reasons. Try filtering the websites in the select boxes above.'"></app-info-segment>
		</ng-template>
		<div *ngIf="setNoDataMessage">
			<app-info-segment [text]="'No data to show'"></app-info-segment>
		</div>
	</div>
</div>
