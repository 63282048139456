import {Injectable} from '@angular/core';
import {Observable, Subject, BehaviorSubject, Subscription} from 'rxjs';
import {BrsSite} from 'app/core/models/data-structure';
import {BlockRateResource, UpgradeResponse} from '../../resources/block-rate.resource';
import {PublishersService} from '../publishers.service';
import {AuthService} from '../../auth/auth.service';
import {Message, MessageState} from '../../shared/message.service';


@Injectable()
export class BlockRateService {
	brsSites$: Observable<BrsSite[]>;
	brsSites: BrsSite[];
	selectedSite: BrsSite;
	subjectBrsSites = new BehaviorSubject<BrsSite[]>([]);
	subjectSelectedSite = new BehaviorSubject<BrsSite>(null);
	verifyDomainSubscription: Subscription;

	constructor(private blockRateResource: BlockRateResource,
		private publishersService: PublishersService, private authService: AuthService) {
	}

	getBrsSites(): Observable<BrsSite[]> {
		if (!this.brsSites$) {
			this.updateBrsSites();
		}
		return this.subjectBrsSites;
	}

	updateBrsSites(hash?: string): Observable<BrsSite[]> {
		this.brsSites$ = this.blockRateResource.getSites();
		this.brsSites$.subscribe(
		(res) => {
			if (!res) {
				res = [];
			}
			this.brsSites = res;
			if (this.brsSites) {
				this.brsSites.forEach(brs => {
					this.verifyBrsSite(brs);

					const aabSiteId = this.publishersService.parseSiteUrl(brs.url).host;
					brs.aab_site_id = aabSiteId.replace('www.', '');

					brs.name = brs.name || brs.url;
					if (hash && brs.hash === hash) {
						brs.selected = true;
					} else {
						brs.selected = false;
					}
				});
			}
			// console.log("brs service on update sending", this.brsSites);
			this.subjectBrsSites.next(this.brsSites);
		});
		return this.subjectBrsSites;
	}

	getSelectedSite(): BehaviorSubject<BrsSite> {
		return this.subjectSelectedSite;
	}

	setSelectedSiteByKey(key: string, value: any): Boolean {
		for (const site of this.brsSites) {
			if (site.hasOwnProperty(key) && site[key] === value) {
				this.setSelectedSite(site);
				return true;
			}
		}
		return false;
	}

	setSelectedSite(site: BrsSite) {
		this.selectedSite = site;
		this.subjectSelectedSite.next(this.selectedSite);
	}

	verifyBrsSite(brs: BrsSite): void {
		brs.verifiedResolved = true;
		if (!brs.verified) {
			brs.verifiedResolved = false;
			this.verifyDomainSubscription = this.blockRateResource.getVerified(brs.hash).subscribe(res => {
				const brsIndex = this.brsSites.indexOf(brs);
				this.brsSites[brsIndex].verified = res.verified;
				if (res.verified) {
					brs.verified = true;
				}
				brs.verifiedResolved = true;
			},
				err => {
				brs.verifiedResolved = true;
				});
		}
	}

	requestUpgrade(brs: BrsSite): Observable<UpgradeResponse> {
		const user = new Subject<UpgradeResponse>();
		this.blockRateResource.requestUpgrade(brs.hash).subscribe(res => {
			const brsIndex = this.brsSites.indexOf(brs);
			if (res.success) {
				this.authService.upgradeBrsUser(res.brs_upgrade);
			}
			user.next(res);
		});
		return user;
	}

	deleteBrsSite(brs: BrsSite): Observable<Message> {
		const subject = new Subject<Message>();
		this.blockRateResource.deleteBrsSite(brs.hash).subscribe(res => {
			if (res.deleted) {
				this.updateBrsSites().subscribe((brsSites) => {
					const message = {header: 'Success',
					description: `Website ${brs.url} has been removed from block rate domains.`, state: MessageState.INFO};
					subject.next(message);
					subject.complete();
				});
			} else {
				const message = {header: 'Failed',
					description: `Website ${brs.url} could not be deleted.`, state: MessageState.WARNING};
				subject.next(message);
				subject.complete();
			}

		});
		return subject;
	}
}
