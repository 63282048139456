<fui-select class="selection"
	id="siteSelector"
	[(ngModel)]="selectedSite"
	(ngModelChange)=onChangeSelectedSite()
	[options]="brsSites$ | async"
	labelField="name"
	placeholder="select site"
	#selectSite>
	<fui-select-option *ngFor="let option of selectSite.filteredOptions"
					   [value]="option">
	</fui-select-option>
</fui-select>
