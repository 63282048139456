import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PublisherList, WebsitesLists, Permission, PermissionType } from '../../data-structure';

@Component({
	selector: 'app-user-management-permission',
	templateUrl: './user-management-permission.component.html',
	styleUrls: ['./user-management-permission.component.less']
})

export class UserManagementPermissionComponent implements OnInit {
	@Input() permissionForm: FormGroup;
	@Input() availablePermissions: string[];
	@Input() permissionType: PermissionType;
	@Input() itemIndex: number;
	@Input() publisherLists: PublisherList[];

	@Output() deleteNotify: EventEmitter<Permission> = new EventEmitter<Permission>();

	activeWeblist: WebsitesLists[];
	publisherNotSelected = true;

	constructor() { }

	ngOnInit() {
		this.onSelect(this.permissionForm.get('publisher').value);
	}

	onSelect(publisherid: string) {
			const selectedPub = this.publisherLists.filter(item => item.aab_publisher_id === publisherid)[0];
			if (selectedPub) {
				this.publisherNotSelected = false;
				this.activeWeblist = selectedPub.websites;
				if (selectedPub.aab_publisher_id === 'admin') {
					this.permissionForm.controls['website'].setValue('all');
					this.publisherNotSelected = true;
				}
			} else {
				this.publisherNotSelected = true;
			}
	}

	deletePermission() {
		this.deleteNotify.emit( {
			type: this.permissionType,
			id: this.itemIndex.toString(),
			name: '',
			target: null
		});
	}

	filterPublishers(options: PublisherList[], query: string) {
		return options.filter(item => item.publisher_name.toLowerCase().indexOf(query.toLowerCase()) > -1);
	}
}
