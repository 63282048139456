import {Subscription, Observable} from 'rxjs';
import {CommandResponse} from '../../resources/debug.resource';

export interface Zone {
	id: number;
	name: string;
	defaultZone: string;
	newZone: string;
	group: string;
}

export interface DateFromTo {
	dateFrom: string;
	dateTo: string;
}

export interface TestResponse {
	aab_id: string;
	name: string;
	expected_status: number;
	link: string;
	hint: string;
	response$?: Observable<CommandResponse>;
	response: CommandResponse;
	subscription?: Subscription;
	collapse: boolean;
	commandDescription?: string;
	onLoad?: boolean;
}

export interface PublisherData {
	id: string;
	name: string;
	aab_publisher_id: string;
	auth_password?: string;
	is_forbidden: boolean;
	aab_domain: string;
}

export interface Website {
	id: string;
	publisher: PublisherData;
	name: string;
	aab_site_id: string;
	response: boolean;
	state: string;
	site_url: string;
	url_mask: string;
	not_url_mask: string[];
	adservers: string[];
	zones: Zone[];
	search?: string;
	sections?: string[];
}

export interface Publisher {
	name: string;
	websites: Website[];
	publisher_data: PublisherData;
	search?: string;
}

export interface BrsSite {
	url: string;
	verified: boolean;
	hash: string;
	name: string;
	ecpm: number;
	impPerPage: number;
	aab_site_id?: string;
	verifiedResolved?: boolean;
	selected?: boolean;
}

export interface LoggedInUserRights {
	demo: boolean;
	config: boolean;
	zones: boolean;
	stats: boolean;
	blockrate: boolean;
	adscan: boolean;
	super_admin: boolean;
	edit_users: boolean;
	budget: boolean;
	edit_budget: boolean;
	lite: boolean;
	edit_sas_zones: boolean;
}

export interface LoggedInUser {
	id: number;
	client_id: number;
	email: string;
	fullname: string;
	password: string;
	api_key: string;
	frontend_rights: LoggedInUserRights;
	brs_sites: BrsSite[];
	brs_only: boolean;
	brs_upgrade: string;
	version?: string;
}

export interface ChartsStructure {
	data: any;
	csv?: any;
	total?: any;
}

export interface BlockRateOverview {
	user: string;
	url: string;
	hash: string;
	verified: boolean;
	page_view: number;
	block_view: number;
	block_rate: number;
	onLoad?: boolean;
	showModal?: boolean;
}

export enum MenuType {
	SMALL = 'small',
	LEFT = 'left',
}

