import {Component, Input, OnInit} from '@angular/core';
import {CoreService} from '../core.service';

@Component({
	selector: 'app-simple-table',
	templateUrl: './simple-table.component.html',
	styles: ['td { text-align: right!important; }']
})

export class SimpleTableComponent implements OnInit {
	@Input() tableData: any[];
	@Input() sortBy: string;
	columnNames: string[];
	isDesc = false;
	column: string;
	direction: number;

	constructor(protected coreService: CoreService) {
	}

	ngOnInit() {
		this.sortTable(this.sortBy);
		if (this.tableData.length > 0) {
				this.columnNames = Object.keys(this.tableData[0]);
			}
	}

	addItemsFromArray(array, itemName): number {
		const items = array.map(i => i[itemName]);
		return items.reduce((a, b) => {
				return a + b;
			});
	}

	getTotal(name: string) {
		if (typeof this.tableData[0][name] === 'number') {
			return this.addItemsFromArray(this.tableData, name);
		} else if (name === 'date') {
			return 'total';
		}

	}

	formatCount(count: any, name: string) {
		if (count) {
			if (this.coreService.isNumberFloat(count)) {
				count = count.toFixed(2);
			}
			if (typeof count === 'number') {
				return this.coreService.formatNumberSeparateByThousands(count);
			}
		}
		return count;
	}

	sortTable(by: string) {
		this.isDesc = !this.isDesc;
		this.column = by;
		this.direction = this.isDesc ? 1 : -1;
	}
}
