import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
	selector: 'app-loader-link',
	templateUrl: './loader-link.component.html',
	styleUrls: ['./loader-link.component.less'],
})

export class LoaderLinkComponent implements OnInit {
	@Input() loader: Object;
	class = '';
	active = false;

	constructor( private sanitizer: DomSanitizer ) {

	}

	ngOnInit() {
		this.updateClass();
	}

	sanitizedUrl(): SafeUrl {
		return this.sanitizer.bypassSecurityTrustUrl('data:text/plain;base64,' + btoa(this.loader['loader']));
	}

	activate(): void {
		this.active = !this.active;
		this.updateClass();
	}

	getLoaderCode() {
		return this.loader['loader'];
	}

	updateClass() {
		const cls = [];
		if (this.active) {
			cls.push('active');
		}
		if (this.loader && this.loader['status']) {
			cls.push(this.loader['status']);
		}
		this.class = cls.join(' ');
	}
}
