<app-message *ngIf="message" [message]="message"></app-message>
<app-action-tag-modal *ngIf="showAddTagModal" [zone]="zoneToAddTag" [tag]="tagToEdit" [actionType]="actionType" (onMessage)="changeMessage($event)" (onChangeTags)="changeTags()"></app-action-tag-modal>
<app-delete-tag-modal *ngIf="showDeleteTagModal" [tag]="tagToDelete" (onMessage)="changeMessage($event)" (onChangeTags)="changeTags()"></app-delete-tag-modal>
<div *ngIf="zones && zones.length > 0">
	<div>
		<table class="ui sortable very compact small fixed table" id="budget-end-table">
			<thead style="user-select: none;">
				<tr>
					<th class="pointer one wide" (click)="sortTable('day')" id="table_sort_day" *ngIf="groupByDay">
						Day
						<i [ngClass]="(column?.match('day') && isDesc) ? 'sort numeric ascending icon' : (column?.match('day') && !isDesc) ? 'sort numeric descending icon' : 'sort icon'"> </i>
					</th>
					<th class="pointer one wide" (click)="sortTable('website_name')" id="table_sort_website">
						Website
						<i [ngClass]="(column?.match('website_name') && isDesc) ? 'sort alphabet ascending icon' : (column?.match('website_name') && !isDesc) ? 'sort alphabet descending icon' : 'sort icon'"> </i>
					</th>
					<th class="pointer three wide" (click)="sortTable('name')" id="table_sort_zone_id">
						Zone Name
						<i [ngClass]="(column?.match('name') && isDesc) ? 'sort alphabet ascending icon' : (column?.match('name') && !isDesc) ? 'sort alphabet descending icon' : 'sort icon'"> </i>
					</th>
					<th *ngIf="groupByRawData" class="pointer two wide" (click)="sortTable('tags')" id="table_sort_tag">
						Tags
						<i [ngClass]="(column?.match('tags') && isDesc) ? 'sort alphabet ascending icon' : (column?.match('tags') && !isDesc) ? 'sort alphabet descending icon' : 'sort icon'"> </i>
					</th>
					<th class="pointer one wide" (click)="sortTable('adserver')" id="table_sort_ad_server">
						Ad Server
						<i [ngClass]="(column?.match('adserver') && isDesc) ? 'sort alphabet ascending icon' : (column?.match('adserver') && !isDesc) ? 'sort alphabet descending icon' : 'sort icon'"> </i>
					</th>
					<th class="pointer one wide" (click)="sortTable('count_ok')" id="table_sort_zone_count">
						OK
						<i [ngClass]="(column?.match('count_ok') && isDesc) ? 'sort numeric ascending icon' : (column?.match('count_ok') && !isDesc) ? 'sort numeric descending icon' : 'sort icon'"> </i>
					</th>
					<th class="pointer one wide" (click)="sortTable('count_rtb')" id="table_sort_zone_count_rtb">
						RTB
						<i [ngClass]="(column?.match('count_rtb') && isDesc) ? 'sort numeric ascending icon' : (column?.match('count_rtb') && !isDesc) ? 'sort numeric descending icon' : 'sort icon'"> </i>
					</th>
					<th class="pointer one wide" (click)="sortTable('count_substitute')" id="table_sort_zone_count_substitute">
						Substitute
						<i [ngClass]="(column?.match('count_substitute') && isDesc) ? 'sort numeric ascending icon' : (column?.match('count_substitute') && !isDesc) ? 'sort numeric descending icon' : 'sort icon'"> </i>
					</th>
					<th class="pointer one wide" (click)="sortTable('count_empty')" id="table_sort_zone_count_empty">
						Empty
						<i [ngClass]="(column?.match('count_empty') && isDesc) ? 'sort numeric ascending icon' : (column?.match('count_empty') && !isDesc) ? 'sort numeric descending icon' : 'sort icon'"> </i>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let zone of zones | orderByMore: orderBy">
					<td *ngIf="groupByDay" class="day-cell">
						{{zone.day}}
					</td>
					<td class="web-name-cell">
						{{zone.website_name}}
					</td>
					<td class="zone-name-cell">
						<div class="tooltip">
							<span class="tooltiptext">{{zone.name}}</span>
							{{zone.name}}
						</div>
					</td>
					<td  *ngIf="groupByRawData" style="user-select: none">
						<span *ngIf="frontendRights.edit_budget" >
							<a *ngFor="let tag of zone.tags" class="ui mini teal tag label">
								<span (click)="editTagFromZone(tag, zone)" style="color: white">
									{{tag.name}}
								</span>
								<i class="delete icon" (click)="deleteTag(tag)"></i>
							</a>
							<a class="ui mini teal label" style="color: white" (click)="addTagToZone(zone)">
								<i class="tag icon" style="color: white" id="add-tag-icon"></i>
							</a>
						</span>
						<span *ngIf="!frontendRights.edit_budget">
							<a *ngFor="let tag of zone.tags" class="ui mini teal tag label">
								<span style="color: white">
									{{tag.name}}
								</span>
							</a>
						</span>
					</td>
					<td>
						{{zone.adserver}}
					</td>
					<td>
						{{formatCount(zone.count_ok)}}
					</td>
					<td>
						{{formatCount(zone.count_rtb)}}
					</td>
					<td>
						{{formatCount(zone.count_substitute)}}
					</td>
					<td>
						{{formatCount(zone.count_empty)}}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

