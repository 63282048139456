<div id="budget-grid">
	<div *ngIf="showLoader">
		<div class="ui active centered inline loader" *ngIf="showLoader"></div>
	</div>
	<app-message *ngIf="message" [message]="message"></app-message>
	<div *ngIf="budgetOutput">
		<table class="ui very compact small fixed table" style="border: 0">
			<thead>
				<th class="one wide" style="text-align: left">Website</th>
				<th class="four wide"></th>
				<th class="two wide" *ngIf="budgetInput.group_by_raw_data"></th>
				<th class="one wide" *ngIf="budgetInput.group_by_day"></th>
				<th class="one wide">OK</th>
				<th class="one wide">RTB</th>
				<th class="one wide">Substitute</th>
				<th class="one wide">Empty</th>
			</thead>
		</table>
		<div class="panel" *ngFor="let item of budgetOutput">
			<div class="panel-title" (click)="unwrapPanel(item.name)">
				<app-table-header [groupByDay]="budgetInput.group_by_day" [groupByRawData]="budgetInput.group_by_raw_data" [type]="'website'" [item]="item" [collapse]="unwrappedPanel(item.name)"></app-table-header>
			</div>
			<div class="panel-content" *ngIf="unwrappedPanel(item.name)">
				<div>
					<div *ngIf="budgetInput.group_by_adserver == true">
						<div class="panel" *ngFor="let adserver of item.adservers">
							<div class="panel-title" (click)="unwrapPanel(item.name + '/' + adserver.name)">
								<app-table-header [groupByDay]="budgetInput.group_by_day" [groupByRawData]="budgetInput.group_by_raw_data" [type]="'adserver'" [item]="adserver" [collapse]="unwrappedPanel(item.name + '/' + adserver.name)"></app-table-header>
							</div>
							<div class="content" *ngIf="unwrappedPanel(item.name + '/' + adserver.name)">
								<app-end-table [groupByDay]="budgetInput.group_by_day" [groupByAdServer]="budgetInput.group_by_adserver" [groupByRawData]="budgetInput.group_by_raw_data" [zones]="adserver.zones" (onMessage)="changeMessage($event)" (onChangeTags)="changeTags()"></app-end-table>
							</div>
						</div>
					</div>
					<div *ngIf="budgetInput.group_by_adserver == false">
						<app-end-table [groupByDay]="budgetInput.group_by_day" [groupByAdServer]="budgetInput.group_by_adserver" [groupByRawData]="budgetInput.group_by_raw_data" [zones]="item.zones" (onMessage)="changeMessage($event)" (onChangeTags)="changeTags()"></app-end-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
