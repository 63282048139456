import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-info-segment',
	templateUrl: './info-segment.component.html',
	styleUrls: ['./info-segment.component.less']
})

export class InfoSegmentComponent {
	@Input() text: string;
	@Input() class: string;
}
