<span #menu>
	<a *ngIf="menuType === leftMenu" (click)="navigateToBase()" class="item" queryParamsHandling="merge" id="homepage-link">
        		<img src="assets/logo.png" id="logo" height="50">
      		</a>
	<a *ngIf="!user.brs_only && (rights.stats || rights.config)" class="item" [routerLink]="['/dashboard']" id="menu_dashboard" queryParamsHandling="merge" i18n="main menu|Dashboard">
		DASHBOARD
	</a>
	<a *ngIf="rights.edit_sas_zones" class="item" [routerLink]="['/sas-zones']" id="sas_zones" queryParamsHandling="merge" i18n="main menu|SAS Zones">
		SAS ZONES
	</a>
	<a *ngIf="rights.lite" class="item" [routerLink]="['/aab-lite']" id="menu_lite" queryParamsHandling="merge" i18n="main menu|Lite">
		SETUP LITE AAB
	</a>
	<a *ngIf="rights.config" class="item" [routerLink]="['/installation']" id="menu_installation" queryParamsHandling="merge" i18n="main menu|Installation guide">
		SETUP FULL AAB
	</a>
	<!--<a *ngIf="rights.demo" class="item" [routerLink]="['/live-demo']" id="menu_live_demo" queryParamsHandling="merge" i18n="main menu|Live demo of adblock running on a website">
		LIVE DEMO
	</a>-->
	<a class="item" [routerLink]="['/faq']" id="menu_faq" queryParamsHandling="merge" i18n="main menu|Frequently asked questions">
		FAQ
	</a>
	<ng-container *ngIf="!rights.super_admin">
		<a *ngIf="rights.edit_users" class="item" [routerLink]="['/user-management']" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|User Management" id="menu_user_management">
			USERS
		</a>
	</ng-container>
	<ng-container *ngIf="rights.super_admin">
		<span class="ui horizontal inverted divider">
    		SUPER ADMIN
  		</span>

		<a class="item" [routerLink]="['/user-management']" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|User Management" id="menu_user_management">
			USERS
		</a>
		<a class="item" [routerLink]="['/ad-scan']"  id="menu_ad_scan" queryParamsHandling="merge" i18n="main menu|AdScan">
			AD SCAN
		</a>
		<a class="item" [routerLink]="['/management', 'settings']" id="menu_global_settings" queryParamsHandling="merge" i18n="main menu|Global Settings">
			GLOBAL SETTINGS
		</a>
		<a class="item" [routerLink]="['/block-rate']" id="menu_block_rate" queryParamsHandling="merge" i18n="main menu|Block Rate">
			BLOCK RATE
		</a>
		<fui-accordion class="item">
    		<fui-accordion-panel>
				<div title>
					<span class="item">
					<i class="dropdown icon"></i>
						<a [routerLink]="['/budget', 'report']" id="menu_budge2" queryParamsHandling="merge" i18n="main menu|Budget">
							BUDGET
						</a>
					</span>

				</div>
				<div content>
					<a class="item" [routerLink]="['/budget', 'report']" id="menu_budget" queryParamsHandling="merge" i18n="main menu|Budget">
						BUDGET REPORT
					</a>
					<a class="item" [routerLink]="['/budget', 'all-tags']" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|Budget Tags" id="menu_budget_tags">
						BUDGET TAGS
					</a>
				</div>
			</fui-accordion-panel>
		</fui-accordion>
		<a class="item" [routerLink]="['/block-rate/overview']" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|Block Rate Overview" id="menu_block_rate_overview">
			BLOCK RATE OVERVIEW
		</a>
		<a class="item" [routerLink]="['/management', 'lite']" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|Lite Management" id="menu_lite_management">
			LITE LOADERS
		</a>
		<a class="item" [routerLink]="['/management', 'lite-files']" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|Lite Management Files" id="menu_lite_management_files">
			LITE LOADER Files
		</a>
		<a class="item" [routerLink]="['/management', 'faq']" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|Faq Management" id="menu_faq_management">
			FAQs
		</a>
		<a class="item" [routerLink]="['/management', 'faq-category']" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|Faq Category Management" id="menu_faq_category_management">
			FAQ Categories
		</a>
		<a class="item" [routerLink]="['/management', 'fee']" id="menu_fee_management" queryParamsHandling="merge" i18n="main menu|Fee Management">
			FEES
		</a>
		<fui-accordion class="item">
			<fui-accordion-panel>
				<div title>
					<span class="item">
					<i class="dropdown icon"></i>
						<a id="menu_knack" queryParamsHandling="merge" i18n="main menu|Knack">
							KNACK
						</a>
					</span>

				</div>
				<div content>
					<a class="item" [routerLink]="['/management', 'adserver']" id="menu_adserver_management" queryParamsHandling="merge" i18n="main menu|Adserver Management">
						ADSERVERS
					</a>
					<a class="item" [routerLink]="['/management', 'publisher']" id="menu_publisher_management" queryParamsHandling="merge" i18n="main menu|Publisher Management">
						PUBLISHERS
					</a>
					<a class="item" [routerLink]="['/management', 'website']" id="menu_website_management" queryParamsHandling="merge" i18n="main menu|Website Management">
						WEBSITES
					</a>
				</div>
			</fui-accordion-panel>
		</fui-accordion>
	</ng-container>
	<!--
	<span class="ui horizontal inverted divider" >
    ACCOUNT
  	</span>
	<span class="item" [ngStyle]="getStyleForEmail()">{{user.email}}</span>
	<a class="item" [routerLink]="['/change-password']" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|Change Password" id="menu_change_password">
		<i class="keyboard outline icon"></i> CHANGE PASSWORD
	</a>
	<a class="item" (click)="logout()" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|User Management" id="menu_logout">
		<i class="power off icon"></i> LOGOUT
	</a>
	-->
	<fui-accordion class="item">
		<fui-accordion-panel>
			<div title>
				<span class="item">
				<i class="dropdown icon"></i>ACCOUNT
				</span>
			</div>
			<div content>
				<span class="item" [ngStyle]="getStyleForEmail()">{{user.email}}</span>
				<a class="item" [routerLink]="['/change-password']" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|Change Password" id="menu_change_password">
					<i class="keyboard outline icon"></i> CHANGE PASSWORD
				</a>
				<a class="item" (click)="logout()" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|User Management" id="menu_logout">
					<i class="power off icon"></i> LOGOUT
				</a>
			</div>
		</fui-accordion-panel>
	</fui-accordion>
	<div id="build" class="item">
		build: {{appVersion}}-{{commitSha}}
	</div>
</span>
