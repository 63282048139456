import {Injectable} from '@angular/core';
import {EMPTY, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Params} from '@angular/router';
import {Publisher, Website} from 'app/core/models/data-structure';
import {PublisherResource} from 'app/resources/publisher.resource';
import * as _ from 'lodash';
import {MessageService} from '../shared/message.service';
import {AuthService} from '../auth/auth.service';

export enum SelectionState {
	None,
	JustPublishers,
	Both
}

export interface SelectedData {
		publishers: Publisher[];
		websites: Website[];
		state: SelectionState;
	}

@Injectable()
export class PublishersService {
	publishers$: Observable<Publisher[]>;
	publishers: Publisher[];
	allWebsites: Website[] = [];
	testPublisher: Publisher;
	zoneIdCount = 3;

	constructor(private publisherResource: PublisherResource,
				private messageService: MessageService,
				private authService: AuthService) {
	}

	getDBPublishers(): Observable<Publisher[]> {
		return this.publisherResource.query();
	}

	getPublishers(): Observable<Publisher[]> {
		if (!this.publishers$) {
			this.publishers$ = this.getDBPublishers();
			return this.publishers$.pipe(map(
				p => {
					this.publishers = p;
					this.testPublisher = _.find(this.publishers, {'name': 'TEST'});
					_.pull(this.publishers, this.testPublisher);
					this.allWebsites = _.flattenDeep(this.publishers.map((pub) => (pub.websites)));
					return this.publishers;
				},
				err => {
					this.authService.logout();
					this.messageService.setNoDataMessage();
				}
			));
		}
		return of(this.publishers);
	}

	getWebsitesAdServers(websites: Website[]): string[] {
		const adservers = _.flattenDeep(_.map(websites, 'adservers'));
		return this.getUniqueAdServers(adservers);
	}

	getUniqueAdServers(adservers: string[]): string[] {
		return _.uniq(adservers);
	}

	getWebsiteByAabSiteId(aab_site_id: string): any {
		return this.allWebsites.find((w) => w.aab_site_id === aab_site_id);
	}

	getPublisherByAabSiteId(aab_site_id: string): Publisher {
		const website = this.getWebsiteByAabSiteId(aab_site_id);
		return this.publishers.find((p) => p.publisher_data.aab_publisher_id === website.publisher.aab_publisher_id);
	}

	getPublisherByAabPublisherId(aab_publisher_id: string) {
		return this.publishers.find((p) => p.publisher_data.aab_publisher_id === aab_publisher_id);
	}

	generateZoneId(): number {
		return this.zoneIdCount++;
	}

	getSelectionState(websites: Website[], publishers: Publisher[]): SelectionState {
		if ((websites && websites.length === 0) && (publishers && publishers.length === 0)) {
			return SelectionState.None;
		} else if ((websites && websites.length === 0) && (publishers && publishers.length > 0)) {
			return SelectionState.JustPublishers;
		} else if ((websites && websites.length > 0) && (publishers && publishers.length > 0)) {
			return SelectionState.Both;
		}
	}

	// getSelectedObjectsToArray(getObject: Function, byWhat: string | string[]) {
	// 	return byWhat instanceof Array ? byWhat.map(by => getObject(by))
	// 		: (byWhat ? [getObject(byWhat)] : []);
	// }
	//
	// getSelectedDataFromQueryParams(params: Params): SelectedData {
	// 	if (!params) {
	// 		return {publishers: [], websites: [], state: SelectionState.None};
	// 	} else {
	// 		const selectedPublishers = this.getSelectedObjectsToArray(this.getPublisherByName, params['p']);
	// 		const selectedWebsites = this.getSelectedObjectsToArray(this.getWebsiteById, params['w']);
	// 		const state = this.getSelectionState(selectedWebsites, selectedPublishers);
	// 		return {publishers: selectedPublishers, websites: selectedWebsites, state: state};
	// 	}
	// }

	getSelectedDataFromQueryParams(params: Params): SelectedData {
		if (!params) {
			return {publishers: [], websites: [], state: SelectionState.None};
		} else {
			const publisherParams = params['p'];
			const websiteParams = params['w'];
			let selectedPublishers = [];
			let selectedWebsites = [];
			if (publisherParams instanceof Array) {
				selectedPublishers = publisherParams.map((name) => {
					return this.getPublisherByAabPublisherId(name);
				});
			} else if (publisherParams) {
				selectedPublishers[0] = this.getPublisherByAabPublisherId(publisherParams);
			}
			if (websiteParams instanceof Array) {
				selectedWebsites = websiteParams.map((id) => {
					return this.getWebsiteByAabSiteId(id);
				});
			} else if (websiteParams) {
				selectedWebsites[0] = this.getWebsiteByAabSiteId(websiteParams);
			}
			const state = this.getSelectionState(selectedWebsites, selectedPublishers);
			return {publishers: selectedPublishers, websites: selectedWebsites, state: state};
		}
	}

	getSelectedWebsitesFromState(selectedData: SelectedData): Website[] {
		let websites = [];
		switch (selectedData.state) {
			case SelectionState.None: {
				websites = this.allWebsites;
				break;
			}
			case SelectionState.JustPublishers: {
				websites = _.flattenDeep(_.map(selectedData.publishers, 'websites'));
				break;
			}
			case SelectionState.Both: {
				websites = selectedData.websites;
				break;
			}
		}
		return websites;
	}

	getSelectedPublishersFromState(selectedData: SelectedData): Publisher[] {
		let pubs = [];
		switch (selectedData.state) {
			case SelectionState.None: {
				pubs = this.publishers;
				break;
			}
			default: {
				pubs = selectedData.publishers;
				break;
			}
		}
		return pubs;
	}

	parseSiteUrl(siteUrl: string): URL {
		return new URL(siteUrl);
	}

	getStringWithoutDiacritics(str: string): string {
		return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	}

	setSearchToWebsites(websites: Website[]) {
		websites.forEach(web => {
			const withoutDiacritics = this.getStringWithoutDiacritics(web.name);
			web.search = `${web.name} ${withoutDiacritics}`;
		});
	}

	setSearchToPublishers(publishers: Publisher[]) {
		publishers.forEach(pub => {
			const withoutDiacritics = this.getStringWithoutDiacritics(pub.name);
			pub.search = `${pub.name} ${withoutDiacritics}`;
		});
	}
}
