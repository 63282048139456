import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-sort-icon',
	templateUrl: './sort-icon.component.html',
})
export class SortIconComponent implements OnInit {
	@Input() sortBy: string;
	@Input() column: string;
	@Input() isDesc: false;
	@Input() isAlphabet: boolean;
	iconAscClass: string;
	iconDescClass: string;

	ngOnInit() {
		this.iconAscClass = this.isAlphabet ? 'sort alphabet ascending icon' : 'sort numeric ascending icon';
		this.iconDescClass = this.isAlphabet ? 'sort alphabet descending icon' : 'sort numeric descending icon';
	}
}

