<fui-tabset *ngIf="dataOk">
	<div class="ui icon buttons" id="ultra-lite-icons">
		<button class="ui teal button" fuiTabHeader="1"><i class="chart area icon"></i></button>
		<button class="ui teal button" fuiTabHeader="2"><i class="table icon"></i></button>
		<button class="ui teal button" fuiTabHeader="3"><i class="download icon"></i></button>
	</div>
	<div class="ui" fuiTabContent="1">
		<div [ngClass]="showDimmer ? 'ui active dimmer' : 'inactive dimmer'">
			<div class="ui medium text loader">Loading</div>
		</div>
		<ng-container #chartContainer></ng-container>
		<p>Showing data for: <app-more-websites [websites]="aabSiteIds"></app-more-websites></p>
		<p>{{chartDesc}}</p>
	</div>
	<div class="ui" fuiTabContent="2">
		<ng-container #tableContainer></ng-container>
	</div>
	<div class="ui" fuiTabContent="3">
		<div class="ui hidden divider"></div>
		<app-csv-download *ngIf="tableData && tableData.length > 0" [csvData]="tableData" [filename]="chart.name + '_%from-%to'"></app-csv-download>
	</div>
</fui-tabset>


