import {Component, HostBinding} from '@angular/core';
import {BaseInputComponent} from './base-input.component';

@Component({
	selector: 'app-date-input',
	template: '<label>{{name}}({{type}})</label><input (change)="onChange($event)" type="date" value="{{value}}">',
	styles: [''],
})

export class DateInputComponent extends BaseInputComponent {
	@HostBinding('attr.class') role = 'four wide field';

	constructor( ) {
		super();
	}
}
