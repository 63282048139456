import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {LoginComponent} from './login/login.component';
import {ForgottenPasswordComponent} from './forgotten-password/forgotten-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';

@NgModule({
	declarations: [
		LoginComponent,
		ForgottenPasswordComponent,
		ResetPasswordComponent,
	],
	imports: [
		SharedModule,
		BrowserAnimationsModule,
		],
	providers: [

	]
})

export class AuthModule {

}
