import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManagementModule } from './user-management/user-management.module';
import { RestModule } from './rest/rest.module';
import { FaqResource, FaqCategoryResource } from '../../resources/faq.resource';
import { SettingsResource } from '../../resources/settings.resource';
import { LiteManagementComponent } from './lite-management/lite-management.component';
import { FaqManagementComponent } from './faq-management/faq-management.component';
import { FaqCategoryManagementComponent } from './faq-management/faq-category-management.component';
import { AdserverManagementComponent } from './adserver-management/adserver-management.component';
import { PublisherManagementComponent } from './publisher-management/publisher-management.component';
import { SettingsManagementComponent } from './settings-management/settings-management.component';
import { WebsiteManagementComponent } from './website-management/website-management.component';
import { LiteManagementFilesComponent } from './lite-management/lite-management-files.component';
import { AdminRoutingModule } from './admin-routing.module';
import { RestComponent } from './rest/rest.component';
import { FeeManagementComponent } from './fee-management/fee-management.component';
import { FeeTableComponent } from './fee-management/fee-table/fee-table.component';
import { FeeFilterPublisherWebsiteComponent } from './fee-management/fee-filter-publisher-website/fee-filter-publisher-website.component';
import { FeeResource } from '../../resources/fee.resource';
import { ActionFeeModalComponent } from './fee-management/fee-table/action-fee-modal/action-fee-modal.component';
import { AdserverRestResource } from '../../resources/adserver.resource';
import { PublisherRestResource } from '../../resources/publisher.resource';
import { WebsiteRestResource } from '../../resources/website.resource';

@NgModule({
	declarations: [
		LiteManagementComponent,
		FaqManagementComponent,
		FaqCategoryManagementComponent,
		SettingsManagementComponent,
		LiteManagementFilesComponent,
		FeeManagementComponent,
		FeeTableComponent,
		FeeFilterPublisherWebsiteComponent,
		ActionFeeModalComponent,
		AdserverManagementComponent,
		PublisherManagementComponent,
		WebsiteManagementComponent,
	],
	imports: [
		RestModule,
		CommonModule,
		UserManagementModule,
		AdminRoutingModule,
	],
	exports: [
		LiteManagementComponent,
		FaqManagementComponent,
		FaqCategoryManagementComponent,
		SettingsManagementComponent,
		LiteManagementFilesComponent,
	],
	entryComponents: [
		FaqManagementComponent,
		RestComponent,
	],
	providers: [
		FaqResource,
		FaqCategoryResource,
		SettingsResource,
		FeeResource,
		AdserverRestResource,
		PublisherRestResource,
		WebsiteRestResource,
	],
})
export class AdminModule { }
