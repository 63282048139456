import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { forkJoin, of, Observable, BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { AuthService } from 'app/auth/auth.service';
import { Message, MessageService, MessageState } from 'app/shared/message.service';
import { UserManagementService } from './user-management.service';
import { User, PublisherList, UserFilter } from './data-structure';

@Component({
	selector: 'app-user-management',
	templateUrl: './user-management.component.html',
	styleUrls: ['./user-management.component.less']
})

export class UserManagementComponent implements OnInit, OnDestroy {
	users: User[];
	users$: Observable<User[]>;

	publisherLists: PublisherList[] = [];

	canSetSuperAdmin = false;
	filter: UserFilter;
	userToInvite: User;

	routeSubscription: Subscription;

	showSpinner = false;
	showInviteModal = false;

	// pagination variables
	pageSize = 20;
	selectedPage = 1;
	usersCount = 0;

	messages: Message[] = [];


	private searchTerms = new BehaviorSubject<string>('');

	constructor(private userManagement: UserManagementService,
				private route: ActivatedRoute,
				private messageService: MessageService,
				private auth: AuthService) {
	}

	ngOnInit() {
		this.showSpinner = true;

		const message = this.messageService.getMessage();
		if (message) {
			this.messages.push(message);
			this.messageService.removeMessage();
		}

		this.getFormData().subscribe(res => {
				this.users = res[0];
				this.usersCount = this.users.length;

				this.publisherLists = res[1].filter(item => item.aab_publisher_id !== 'admin');

				this.users$ = this.searchTerms.pipe(
					// wait 300ms after each keystroke before considering the term
					debounceTime(300),

					// switch to new search observable each time the term changes
					switchMap((term: string) => this.filterUsers(term)),
				);

				this.canSetSuperAdmin = this.auth.getRights()['super_admin'];

				this.routeSubscription = this.route.queryParams.subscribe((params: Params) => {
					this.filter = this.prepareFilterFromParams(params);
					this.search();
				});

				this.showSpinner = false;
			},
			err => this.catchError(err));
	}

	ngOnDestroy() {
		if (this.routeSubscription) {
			this.routeSubscription.unsubscribe();
		}
	}


	search(): void {
		this.searchTerms.next(this.filter.term);
	}

	/* deleteUser(user: User) {
		if (confirm(`Do you want to delete user ${user.email}?`)) {
			this.userManagement.deleteUser(
				user.id
			).subscribe(data => {
					if (data['ok'] === true) {
						this.users = this.users.filter(u => u.id !== user.id);
						this.searchTerms.next(this.searchTerms.value); // reload search
					}
			}, err => {
				this.catchError(err);
			});

		}
	} */

	inviteUser(user: User) {
		this.userToInvite = user;
		this.showInviteModal = true;
	}

	sendInvitation(user: User) {
		this.userManagement.inviteUser(user.id).subscribe(res => {
			if (res.invite === true) {
				this.messages.push({state: MessageState.POSITIVE, header: 'Invitation sent',
					description: `Invitation email sent to user ${user.email}. Please check this email (inbox or spam).`});
			}
		}, err => {
			this.catchError(err);
		});
	}

	pageChange(page: number) {
		this.searchTerms.next(this.filter.term);
	}

	private prepareFilterFromParams(param: Params) {
		const ret: UserFilter = {
					showAdmin: this.canSetSuperAdmin ? param.f_admin === 'true' : false,
					publisher: param.f_publisher ? this.publisherLists.filter(item => item.aab_publisher_id === param.f_publisher)[0] : null,
					term: param.f_term ? param.f_term : ''
				};
		return ret;
	}


	private filterUsers(term: string) {
		let ret: User[] = [];
		const superAdmin = (this.filter.showAdmin === true ? 1 : 0);

		// filter super admins
		ret = this.users.filter(item => Math.sign(
											item.permissions.filter(perm => perm.type === 'group' && perm.name === 'super_admin').length
										) === superAdmin);

		// if not super admins only then use remaining filter criteria
		if (this.filter.showAdmin === false) {
			if (term.length > 2) {
				ret = ret.filter(item =>	item.email.indexOf(term) > -1);
			}
			if (this.filter.publisher) {
				ret = ret.filter(item =>
					item.permissions.filter(perm => perm.target.publisher === this.filter.publisher.aab_publisher_id).length > 0
				);
			}
		}

		this.usersCount = ret.length;
		if ( Math.round(this.usersCount / this.pageSize) < this.selectedPage) {
			this.selectedPage = 1;
		}

		return of(_.slice(ret, this.pageSize * (this.selectedPage - 1), this.pageSize * (this.selectedPage - 1) + this.pageSize));
	}

	private catchError(err) {
		this.messages.push({state: MessageState.NEGATIVE, header: err['error']['description']});
	}

	private getFormData() {
		return forkJoin(
			[this.userManagement.getUsers(),
			this.userManagement.getUserPubwebList()]
		);
	}
}
