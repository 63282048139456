<span>
	<h1 id="config_header" class="ui header">Configuration for {{ selectedPublisher?.publisher_data.name }}
		<span [ngClass]="showSpinner ? 'ui small active inline loader' : 'inactive loader'"></span>
	</h1>
</span>
<a id="download_button" class="ui teal button">
	<i class="download icon"></i>Download</a>
<div class="ui hidden divider"></div>
<code id="'config-code">
	<pre id="config-text">{{outputConfig}}</pre>
</code>

