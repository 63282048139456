<h1 class="ui header">User
<span [ngClass]="showSpinner ? 'ui small active inline loader' : 'inactive loader'"></span></h1>
<div *ngIf="messages?.length > 0">
		<app-message *ngFor="let message of messages" [message]="message"></app-message>
</div>
<section class="ui segment" *ngIf="showSpinner==false">
	<form class="ui form" [formGroup]="userForm" (submit)="saveUser()">
		<div class="field">
			<label>E-mail</label>
			<input type="email" name="" placeholder="E-mail" style="min-width: 15em;" formControlName="email"
			id="email"
			name="email"
			class="form-control">
			<div *ngIf="userForm.get('email').invalid && (userForm.get('email').dirty || userForm.get('email').touched)" class="ui negative message">
				<div *ngIf="userForm.get('email').hasError('required')">
					Email is required.
	  			</div>
				<div *ngIf="userForm.get('email').hasError('wrongEmail')">
					Not valid email address.
				</div>
			</div>
		</div>

		<div class="two fields">
			<div class="field" [formArrayName]="permissionType.GROUP">
				<label>Groups</label>
				<div *ngFor="let group of userForm.controls.group.controls; let i=index">
					<div [formGroupName]="i">
						<app-user-management-permission
							[permissionForm]="group"
							[availablePermissions]="availableRightGroups"
							[publisherLists]="publisherLists"
							[permissionType]="permissionType.GROUP"
							[itemIndex]=i
							(deleteNotify)="removePermission($event)">
						</app-user-management-permission>
					</div>
				</div>
				<div (click)="addPermission('group')" class="ui small primary labeled icon button">
					<i class="plus icon"></i> Add group
				</div>
			</div>

			<div class="field" [formArrayName]="permissionType.RIGHT">
				<label>Rights</label>
				<div *ngFor="let right of userForm.controls.right.controls; let i=index">
					<div [formGroupName]="i">
						<app-user-management-permission
							[permissionForm]="right"
							[availablePermissions]="availableRights"
							[publisherLists]="publisherLists"
							[permissionType]="permissionType.RIGHT"
							[itemIndex]=i
							(deleteNotify)="removePermission($event)">
						</app-user-management-permission>
					</div>
				</div>
				<div (click)="addPermission('right')"  class="ui small primary labeled icon button">
					<i class="plus icon"></i> Add right
				</div>
			</div>
		</div>
		<button class="small primary ui button" type="submit">{{ action }}</button>
	</form>
	<br>
	<app-user-login-table *ngIf="user.login && user.login.length > 0" [userLogin]="user.login"></app-user-login-table>
</section>

