<app-budget-filter *ngIf="budgetInput.aab_sites_ids.length > 0" [aab_site_ids]="budgetInput.aab_sites_ids" [dateFrom]="date" [dateTo]="dateTo" (onChangeAdServer)="changeAdServers($event)"></app-budget-filter>
<p></p>
<div [style.display]="messages.size > 0 ? 'inline': 'hidden'">
	<app-message *ngFor="let message of messages" [message]="message"></app-message>
</div>
<app-month-picker (changeDate)="changeDate($event)" (changeMessage)="changeMessage($event)"></app-month-picker>
<app-timezone id="time_zone" (changeTimezone)="changeTimeZone($event)"></app-timezone>
<span class="ui transparent input" id="err-threshold">
	Set Error Threshold: <input id="err-threshold-input" type="number" min="0" [(ngModel)]="budgetInput.error_threshold" (change)="hideTable=true;" >
</span>
<div class="ui hidden divider"></div>
<div id="budget_sliders">
	<app-slider-checkbox [checkBoxType]="groupByAdserversType" [checkBoxValue]="budgetInput.group_by_adserver" (valueChanges)="sliderChanged($event)"></app-slider-checkbox>
	<app-slider-checkbox [checkBoxType]="splitByDayType" [checkBoxValue]="budgetInput.group_by_day" (valueChanges)="sliderChanged($event)"></app-slider-checkbox>
	<app-slider-checkbox [checkBoxType]="aggregateByWebsiteType" [checkBoxValue]="budgetInput.aggregate_by_website" (valueChanges)="sliderChanged($event)"></app-slider-checkbox>
	<app-slider-checkbox [checkBoxType]="aggregateByTagsType" [checkBoxValue]="!budgetInput.group_by_raw_data" (valueChanges)="sliderChanged($event)"></app-slider-checkbox>
</div>
<button id="generate-budget-button" class="ui teal button" (click)="postData(false)" [disabled]="!isValidInput()">
	<i class="cogs icon"></i>GENERATE
</button>
<button id="scratch-generate-budget-button" class="ui teal button" (click)="postData(true)" [disabled]="!isValidInput()">
	<i class="cogs icon"></i>GENERATE FROM SCRATCH
</button>
<p></p>
<div *ngIf="!hideTable">
	<span *ngIf="generatedDate" id="budget-data-generated">Data generated {{generatedDate}} | </span>
	<a class="teal-link" (click)="getCSV()">
		Download CSV
		<span *ngIf="showCSVSpinner" class="ui active mini inline loader"></span>
		<i *ngIf="!showCSVSpinner" class="download icon"></i>
	</a>
</div>
<p></p>
<app-budget-tree-table *ngIf="!hideTable" [budgetOutput]="budgetOutput" [generatedDate]="generatedDate" [budgetInput]="budgetInput" (tagsChanged)="changeTags()"></app-budget-tree-table>
<app-info-segment id="budget-info-segment" *ngIf="hideTable" [text]="'After you set all required data, click on generate button.'" [class]="'ui raised segment'"></app-info-segment>
