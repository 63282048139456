import {ActionType, Zone, ZoneTag} from '../../../../resources/budget.resource';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {Message} from '../../../../shared/message.service';
import {AuthService} from '../../../../auth/auth.service';
import {LoggedInUserRights} from '../../../models/data-structure';
import {CoreService} from '../../../../shared/core.service';

@Component({
	selector: 'app-end-table',
	templateUrl: './end-table.component.html',
	styleUrls: ['./end-table.component.less']
})

export class EndTableComponent implements OnInit {
	@Input() groupByAdServer: boolean;
	@Input() zones: Zone[];
	@Input() groupByDay: boolean;
	@Input() groupByRawData: boolean;
	@Output() onMessage = new EventEmitter<Message>();
	@Output() onChangeTags = new EventEmitter();
	frontendRights: LoggedInUserRights;
	isDesc = {'day': false, 'website_name': false, 'name': false, 'tags': false, 'adserver': false, 'count_ok': true,
		'count_rtb': false, 'count_substitute': false, 'count_empty': false};
	column: string;
	direction: number;
	subscription: Subscription;
	message: Message;
	zoneToAddTag: Zone;
	showAddTagModal = false;
	tagToDelete: ZoneTag;
	showDeleteTagModal = false;
	tagToEdit: ZoneTag;
	actionType: ActionType;
	orderBy: {property: string, direction: number}[];

	constructor(private authService: AuthService, private coreService: CoreService) {
		this.frontendRights = this.authService.getRights();
	}

	ngOnInit() {
		if (this.groupByDay) {
			this.sortTable('day');
		} else {
			this.sortTable('count_ok');
		}
	}

	sortTable(by: string) {
		this.isDesc[by] = !this.isDesc[by];
		this.column = by;
		this.direction = this.isDesc[by] ? 1 : -1;
		if (this.groupByDay) {
			this.orderBy = [{property: 'day', direction: 1}, {property: this.column, direction: this.direction},
				{property: 'count_ok', direction: -1}];
		} else {
			this.orderBy = [{property: this.column, direction: this.direction}, {property: 'count_ok', direction: -1}];
		}
	}

	formatCount(count: number) {
		return this.coreService.formatNumberSeparateByThousands(count);
	}

	changeMessage(message: Message) {
		this.onMessage.emit(message);
	}

	changeTags() {
		this.onChangeTags.emit();
	}

	addTagToZone(zone: Zone) {
		this.actionType = ActionType.ADD;
		this.zoneToAddTag = zone;
		zone.showAddTagModal = true;
		this.showAddTagModal = true;
	}

	editTagFromZone(tag: ZoneTag, zone: Zone) {
		this.actionType = ActionType.EDIT;
		this.zoneToAddTag = zone;
		this.tagToEdit = tag;
		tag.showEditTagModal = true;
		this.showAddTagModal = true;
	}

	deleteTag(tag: ZoneTag) {
		tag.showDeleteTagModal = true;
		this.tagToDelete = tag;
		this.showDeleteTagModal = true;
	}

}
