import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BlockRateResource} from '../../resources/block-rate.resource';
import {Subscription} from 'rxjs';
import {MessageService, MessageState} from '../message.service';
import {Website} from 'app/core/models/data-structure';
import {SectionsService} from '../sections.service';
import {CoreService} from '../core.service';
import {IntervalChooserData} from '../interval.service';

@Component({
	selector: 'app-block-rate-segment',
	templateUrl: './block-rate-segment.component.html',
	styleUrls: ['./block-rate-segment.component.less'],
})

export class BlockRateSegmentComponent implements OnInit, OnChanges, OnDestroy {
	@Input() websites: Website[];
	@Input() hash: string;
	@Input() verified: boolean;
	@Input() intervalData: IntervalChooserData;
	aabSiteIds: string[];
	checkedWebsitesNames: string[];
	checkedWebsites: Website[] = [];
	selectedSections: string[] = [];
	showSections = false;
	isBlockRateRunning: boolean;
	blockRateSubscription: Subscription;
	selectedSectionsSubscription: Subscription;
	showSpinner = false;
	barChart = {name: 'blockRateBar', title: 'Block Rate'};
	barTable = {name: 'blockRateTrendTable', sortBy: 'date'};
	pieChart = {name: 'blockRatePie', title: 'BR'};
	pieTable = {name: 'blockRatePieTable', sortBy: 'values'};
	barChartDataOk = false;
	pieChartDataOk = false;
	metadataPie = {chart_type: 'pie', percent: null};
	metadataBar = {chart_type: 'bar', csvData: null, percent: null};

	notDataMessage = {	state: MessageState.INFO, header: 'No data',
		description: 'It seems that your script is running, but we didn\'t receive any data yet. Please wait for a while.'};

	constructor(private blockRateResource: BlockRateResource, private messageService: MessageService,
				private sectionsService: SectionsService, private coreService: CoreService) {
	}

	ngOnInit() {
		this.selectedSectionsSubscription = this.sectionsService.getSharedSelectedSectionsObservable().subscribe(selectedSelections => {
			this.selectedSections = selectedSelections;
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.verified && changes.websites && this.coreService.isChangeInFilterOfWebsites(changes)) {
			this.postData();
		}
	}

	postData() {
		this.showSections = false;
		this.checkedWebsites = [];
		this.isBlockRateRunning = false;
		if (this.coreService.isLessThan100(this.websites)) {
			this.showSpinner = true;
			this.aabSiteIds = this.websites.map(website => {return website.aab_site_id; });
			this.blockRateSubscription = this.blockRateResource.postCheckBlockRate(this.aabSiteIds).subscribe((result) => {
					this.checkedWebsitesNames = result.websitesNames;
					result.websitesNames.map(aab_site_id => {
						this.websites.map(website => {
							if (website.aab_site_id === aab_site_id) {
								this.checkedWebsites.push(website);
							}
						});
						this.showSections = true;
					});
					this.isBlockRateRunning = result.isRunning;
					this.showSpinner = false;
				},
				(err) => {
					this.showSpinner = false;
				});
		}
	}

	changeBarChartDataOk(barChartDataOk: boolean) {
		this.barChartDataOk = barChartDataOk;
	}

	changePieChartDataOk(pieChartDataOk: boolean) {
		this.pieChartDataOk = pieChartDataOk;
	}

	setBarMetaData(metadata: any) {
		this.metadataBar.percent = metadata['percent'];
		this.metadataBar.csvData = metadata['csv'];
	}

	setPieMetaData(metadata: any) {
		this.metadataPie.percent = metadata['percent'];
	}

	ngOnDestroy() {
		if (this.blockRateSubscription) {
			this.blockRateSubscription.unsubscribe();
		}
	}
}
