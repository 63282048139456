<div [formGroup]="newPasswordForm">
	<div class="field fluid">
    	<label>New Password</label>
    	<input  type="password"
    			placeholder="New Password"
    			formControlName="password"
    			id="password"
				name="password"
				class="form-control"/>
  	</div>
	<div *ngIf="newPasswordForm.get('password').invalid && (newPasswordForm.get('password').dirty || newPasswordForm.get('password').touched)" class="ui negative message">
		<div *ngIf="newPasswordForm.get('password').hasError('required')">
			New Password is required.
	  	</div>
		<div *ngIf="newPasswordForm.get('password').hasError('minlength')">
			New Password must be at least 8 characters long.
		</div>
		<div *ngIf="newPasswordForm.get('password').hasError('needNumber')">
			New Password should have at least one number.
	  	</div>
		<div *ngIf="newPasswordForm.get('password').hasError('needChar')">
			New Password should have at least one letter.
		</div>
		<div *ngIf="newPasswordForm.get('password').hasError('noSpace')">
			New Password cannot contain whitespace characters.
		</div>
	</div>
	<div class="ui hidden divider"></div>
	<div class="field fluid">
		<label>Confirm Password</label>
		<input  type="password"
	    		placeholder="Confirm Password"
				formControlName="confirm_password"
				id="confirmation"
				name="confirmation"
				class="form-control"/>
		</div>
		<div *ngIf="newPasswordForm.get('confirm_password').invalid && (newPasswordForm.get('confirm_password').dirty || newPasswordForm.get('confirm_password').touched)" class="ui negative message">
		<div *ngIf="newPasswordForm.get('confirm_password').hasError('required')">
			Confirmation is required.
	  	</div>
		<div *ngIf="newPasswordForm.get('confirm_password').hasError('mismatchedPassword')">
			Confirmation does not match.
		</div>
	</div>
</div>
