import {OnInit, Component, HostListener} from '@angular/core';
import {MenuType} from '../models/data-structure';
import {ElementService} from '../../shared/element.service';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.less']
})

export class MenuComponent implements OnInit {
	leftMenu = MenuType.LEFT;
	smallMenu = MenuType.SMALL;
	tabletOrMobileSize = false;

	@HostListener('window:resize') onResize() {
		this.checkResize();
	}


	constructor(private elementService: ElementService, private route: ActivatedRoute, protected router: Router) {
	}

	checkResize() {
		this.tabletOrMobileSize = this.elementService.isTabletOrMobileSize();
	}

	ngOnInit() {
		this.router.events.subscribe( (event) => {
			if (event instanceof NavigationEnd) {
				this.checkResize();
			}
		});
		this.checkResize();
	}
}
