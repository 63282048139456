import {OnInit, Component, OnDestroy, EventEmitter, Output, Input} from '@angular/core';
import {Subscription} from 'rxjs';
import {BlockRateResource} from '../../resources/block-rate.resource';

@Component({
	selector: 'app-block-rate-code',
	templateUrl: './block-rate-code.component.html',
	// styleUrls: ['./block-rate-code.component.less'],
	providers: []
})

export class BlockRateCodeComponent implements OnInit, OnDestroy {
	@Input() hash ? = false;
	@Input() hideable ? = false;
	showScript = false;
	kununuScript = '';
	kununuSubscription: Subscription;

	constructor(private blockRateResource: BlockRateResource) {}

	ngOnInit() {
		this.kununuSubscription = this.blockRateResource.getKununu().subscribe(
			(res) => {
				this.kununuScript = res.script;
				if (this.hash) {
					this.kununuScript = '<script id="' + this.hash + '">' + this.kununuScript + '</script>';
				} else {
					this.kununuScript = '<script>' + this.kununuScript + '</script>';
				}
			},
			(err) => {
				this.kununuScript = 'Error getting script contents. Please try reloading this page again in a few minutes.';
			});

	}

	getKununuScript() {
		// console.log("get kununu scrip");
		return this.kununuScript;
	}

	ngOnDestroy() {
		if (this.kununuSubscription) {
			this.kununuSubscription.unsubscribe();
		}
	}
}
