<app-website-links></app-website-links>
<div class="ui segment">
	<fui-tabset>
	<div class="ui teal compact secondary menu">
		<a class="item" id="debug_tab_site" fuiTabHeader="1">
			<span *ngIf="!tabletOrMobileSize">Debug over {{selectedWebsite?.name}}</span>
			<span *ngIf="tabletOrMobileSize">{{selectedWebsite?.name}}</span>
			<app-hint-icon class="hint_icon" [hint]=siteHint></app-hint-icon>
		</a>
		<a class="item" id="debug_tab_aab" fuiTabHeader="2">
			<span *ngIf="!tabletOrMobileSize">Debug over Antiadblock server</span>
			<span *ngIf="tabletOrMobileSize">AAB server</span>
			<app-hint-icon class="hint_icon" [hint]=aabHint></app-hint-icon>
		</a>
	</div>
	<div class="ui bottom attached" fuiTabContent="1">
		<app-debug-table *ngIf="selectedWebsite" [selectedWebsite]="selectedWebsite" [debugType]=realWebsiteDebugType></app-debug-table>
	</div>
	<div class="ui bottom attached" fuiTabContent="2">
		<app-debug-table *ngIf="selectedWebsite" [selectedWebsite]="selectedWebsite" [debugType]=aabDemoDebugType></app-debug-table>
	</div>
	</fui-tabset>
</div>
