import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../auth.service';

@Injectable()
export class SasZonesGuard implements CanActivate {
	constructor(private authService: AuthService, private router: Router) {
	}

	canActivate(next: ActivatedRouteSnapshot,
				state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const isAllowed = this.authService.hasSASRight();
		if (!isAllowed) {
			this.router.navigate(['not-allowed']);
		}
		return isAllowed;
	}
}
