<!--<div class="ui inverted stackable menu">-->
	<!--<div class="stackable container right menu">-->
		<!--<div class="item">-->
			<!--<a [routerLink]="['/brs/registration']" id="registration_link" routerLinkActive="active">-->
				<!--<i class="key icon" id="key_icon"></i> Registration-->
			<!--</a>-->
		<!--</div>-->
	<!--</div>-->
<!--</div>-->
<div class="first_page">
	<div class="login_form">
		<div class="ui hidden divider"></div>
		<div class="ui fluid image">
			<img src="assets/logo.png"/>
		</div>
		<div *ngIf="messages?.length > 0">
			<app-message *ngFor="let message of messages" [message]="message"></app-message>
		</div>
		<form (submit)="login()" #loginForm="ngForm">
			<div class="ui fluid icon input">
				<i class="user icon"></i>
				<input type="email" placeholder="email" name="email" id="email" i18n-placeholder
				[(ngModel)]="email"
				#loginEmail="ngModel"
				required=""
				appEmail>
			</div>
			<div *ngIf="loginEmail.invalid && (loginEmail.dirty || loginEmail.touched)" class="ui negative message" id="input_email_message">
				<div *ngIf="loginEmail.errors.required">
    				Email is required.
  				</div>
				<div *ngIf="loginEmail.errors.wrongEmail && ! loginEmail.errors.required">
						The email address is invalid.
			  	</div>
			</div>

			<div class="ui hidden divider"></div>
			<div class="ui fluid icon input">
				<i class="lock icon"></i>
				<input type="password" placeholder="password" name="password" id="password" i18n-placeholder [(ngModel)]="password">
			</div>
			<div class="ui hidden divider"></div>
			<div class="ui hidden divider"></div>
			<button class="fluid ui button" type="submit" id="login_button">
				<ng-container i18n>Login</ng-container>
			</button>
		</form>
		<br>
		<app-forgotten-password [defaultEmail]="loginEmail.value"></app-forgotten-password>
	</div>
	<div class="ui hidden divider"></div>
	<div class="footer">
		<p class="copyright" id="copyright">© ANTIADBLOCK 2018 v{{version}}</p>
	</div>
</div>
