import {Component, HostListener, Input, OnChanges, OnInit} from '@angular/core';
import {CoreService} from '../core.service';

declare var Plotly: any;

@Component({
	selector: 'app-meta-plotly-chart',
	template: ''
})

export class MetaPlotlyChartComponent implements OnInit, OnChanges {
	@Input() title: string;
	@Input() data: any[];
	layout: any;
	options = {
		modeBarButtonsToRemove: ['toImage', 'sendDataToCloud', 'select2d', 'lasso2d',
			'pan2d', 'autoScale2d', 'zoomIn2d', 'zoomOut2d', 'toggleSpikelines'], displaylogo: false
	};
	plotlyInit = false;
	plotId = '';

	@HostListener('window:resize') onResize() {
		this.checkMaxWidth();
		this.resizeChart();
	}

	constructor(protected coreService: CoreService) {
	}

	ngOnInit() {
		this.init();
	}

	init() {
		this.setChartTitleIdAndLayout();
		this.checkMaxWidth();
		setTimeout(() => {
			Plotly.newPlot(this.plotId, this.data, this.layout, this.options);
			this.plotlyInit = true;
		}, 0);
	}

	ngOnChanges() {
		/*if (this.plotlyInit) {
			this.restyleValues();
		}*/
		this.init();
	}

	setChartTitleIdAndLayout() {
		this.plotId = `plotly_chart_${this.title}`;
		this.layout = {autosize: true, title: this.title};
	}

	setDifferentLayoutToPie(x) {
	}

	checkMaxWidth() {
		const x = window.matchMedia('(max-width: 700px)');
		this.changeLegendOrientation(x);
		this.setDifferentLayoutToPie(x);
		if (x.matches) {
			this.layout['margin'] = [];
			this.layout['margin']['l'] = 15;
			this.layout['margin']['r'] = 15;
			this.layout['margin']['t'] = 35;
			this.layout['margin']['b'] = 15;
			this.options['displayModeBar'] = false;
		}
	}

	isChartExist(): boolean {
		if (this.plotId !== '') {
			return this.coreService.isElementWithContentExist(this.plotId);
		} else {
			return false;
		}
	}

	resizeChart() {
		if (this.isChartExist()) {
			Plotly.relayout(this.plotId, this.layout);
		}
	}

	changeLegendOrientation(x) {
		if (x.matches) {
			this.layout['legend'] = {'orientation': 'h'};
		} else {
			this.layout['legend'] = {'orientation': 'v'};
		}
	}

	restyleValueWithIndex(key, i) {
		Plotly.restyle(this.plotId, key, [this.data[i][key]], i);
	}

	restyleValues() {
	}
}
