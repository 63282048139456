import {OnInit, Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {BrsSite} from '../../models/data-structure';
import {Subscription, Observable} from 'rxjs';
import {BlockRateService} from '../block-rate.service';
import {Message, MessageState} from '../../../shared/message.service';


@Component({
	selector: 'app-select-website',
	templateUrl: './select-website.component.html',
	providers: []
})

export class SelectWebsiteComponent implements OnInit, OnDestroy {
	brsSites$: Observable<BrsSite[]>;
	selectedSite: BrsSite;
	@Output() onChange = new EventEmitter<BrsSite>();
	brsSitesSubscription: Subscription;
	serviceSubscription: Subscription;

	constructor(private blockRateService: BlockRateService) {
	}

	ngOnInit() {
		this.brsSites$ = this.blockRateService.getBrsSites();
		this.brsSitesSubscription = this.brsSites$.subscribe((ret) => {
			let siteAlreadySelected = false;
			// console.log("brsSites updates", this.selectedSite, ret);
			for (const site of ret) {
				if (site.selected) {
					// console.log("service passed selected site", site);
					siteAlreadySelected = true;
					this.selectedSite = site;
					this.onChangeSelectedSite();
					return;
				}
			}

			for (const site of ret) {
				if (this.selectedSite && site.url === this.selectedSite.url) {
					// console.log("selecting current site", site);
					siteAlreadySelected = true;
					this.selectedSite = site;
					this.onChangeSelectedSite();
					break;
				}
			}

			if (!siteAlreadySelected) {
				if (ret.length) {
					// console.log("selecting first site", ret[0]);
					this.selectedSite = ret[0];
					this.onChangeSelectedSite();
				} else {
					// console.log("no site selected");
					this.selectedSite = null;
					this.onChangeSelectedSite();
				}
			}
		});
	}

	onChangeSelectedSite() {
		// console.log("onChangeSelectedSite", this.selectedSite);
		this.blockRateService.setSelectedSite(this.selectedSite);
		this.onChange.emit(this.selectedSite);
	}

	ngOnDestroy() {
		if (this.brsSitesSubscription) {
			this.brsSitesSubscription.unsubscribe();
		}
	}
}
