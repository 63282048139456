import { Injectable } from '@angular/core';

@Injectable()
export class AdminInterceptorService {

	constructor() {}

	isUrlWithApiKey(url: string) {
		if (url === 'https://cdn.dopc.cz/js/stat-loader.min.js' || url.includes('login')
			|| url.includes('password_recovery') || url.includes('brs/register')) {
			// console.log('interceptor without api key ', url);
			return false;
		} else {
			// console.log('interceptor WITH api key ', url);
			return true;
		}
	}

	isLogin(url: string) {
		return url.includes('login');
	}

	isStatLoader(url: string) {
		return url === 'https://cdn.dopc.cz/js/stat-loader.min.js';
	}
}
