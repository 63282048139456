<div class="ui top inverted fixed menu">
	<div class="ui container" #menu>

		<span class="ui mobile hidden item" #menu>Website:</span>
		<span class="item">
			<app-select-website (onChange)="updateSelection($event)"></app-select-website>
		</span>

		<div class="ui mobile hidden menupart">
			<a class="item" id="add_website" (click)="showWebsiteForm = true;" i18n="main menu|Add Website">
				<i class="ui plus icon"></i>
				ADD WEBSITE
			</a>
			<a class="item" [ngClass]="selectedSite?'':'disabled'" id="edit_website" (click)="openEdit()" i18n="main menu|Edit Website">
				<i class="ui cogs icon"></i>
				EDIT WEBSITE
			</a>
			<a class="item" [routerLink]="['./']" id="menu_block_rate" queryParamsHandling="merge" i18n="main menu|Block Rate">
				MEASURE
			</a>
			<a class="item" [routerLink]="['install']" id="menu_block_rate_install" queryParamsHandling="merge" i18n="main menu|Install">
				INSTALL
			</a>
			<a class="item" [routerLink]="['faq']" id="menu_faq" queryParamsHandling="merge" i18n="main menu|Frequently asked questions">
				FAQ
			</a>
			<div class="ui right dropdown item" fuiDropdown>
				ACCOUNT
				<i class="dropdown icon"></i>
				<div class="menu" fuiDropdownMenu>
					<span class="item">{{user.email}}</span>
					<a class="item" [routerLink]="['change-password']" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|Change Password" id="menu_change_password">
						<i class="keyboard outline icon"></i> CHANGE PASSWORD
					</a>
					<a class="item" (click)="logout()" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|User Management" id="menu_logout">
						<i class="power off icon"></i> LOGOUT
					</a>
				</div>
			</div>
		</div>

		<div class="ui mobile only dropdown link item" fuiDropdown [autoClose]="true">
			<i class="bars icon"></i><i class="dropdown icon"></i>
			<div id="mobile-dropdown" class="ui inverted menu" fuiDropdownMenu>
				<a class="item" id="add_website" (click)="showWebsiteForm = true;" i18n="main menu|Add Website">
					<i class="ui plus icon"></i>
					ADD WEBSITE
				</a>
				<a class="item" [ngClass]="selectedSite?'':'disabled'" id="edit_website" (click)="openEdit()" i18n="main menu|Edit Website">
					<i class="ui cogs icon"></i>
					EDIT WEBSITE
				</a>
				<a class="item" [routerLink]="['./']" id="menu_block_rate" queryParamsHandling="merge" i18n="main menu|Block Rate">
					MEASURE
				</a>
				<a class="item" [routerLink]="['install']" id="menu_block_rate_install" queryParamsHandling="merge" i18n="main menu|Install">
					INSTALL
				</a>
				<a class="item" [routerLink]="['faq']" id="menu_faq" queryParamsHandling="merge" i18n="main menu|Frequently asked questions">
					FAQ
				</a>
				<div class="ui divider"></div>
				<span class="item">{{user.email}}</span>
				<a class="item" [routerLink]="['change-password']" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|Change Password" id="menu_change_password">
					<i class="keyboard outline icon"></i> CHANGE PASSWORD
				</a>
				<a class="item" (click)="logout()" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|User Management" id="menu_logout">
					<i class="power off icon"></i> LOGOUT
				</a>
			</div>
		</div>

	</div>
</div>

<fui-modal *ngIf="showWebsiteForm" [isClosable]="true" (dismissed)="showWebsiteForm = false; useSelectedSite = false;" #modal>
    <div *ngIf="!useSelectedSite" class="header">Add Website</div>
	<div *ngIf="useSelectedSite" class="header">Edit Website</div>
    <div class="content">
        <app-website-input (formClosed)="modal.approve('done');" [useSelectedSite]="useSelectedSite"></app-website-input>
    </div>
</fui-modal>
