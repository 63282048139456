<app-website-links></app-website-links>
<div *ngIf="selectedWebsite" class="ui stackable one cards">
	<div class="card">
		<div class="content">
		  <div class="header">
			{{selectedWebsite.name}}
		  </div>
		  <div class="meta">
			{{selectedWebsite.publisher.name}}
		  </div>
		  <div class="description">
			<span class="sub header">State:</span> {{selectedWebsite.state}}<br>
			<span class="sub header">Site url:</span> {{selectedWebsite.site_url}}<br>
			<span class="sub header">Ad Server:</span> {{selectedWebsite.adservers}}<br>
			<span class="sub header">Url mask:</span> {{selectedWebsite.url_mask}}
			  <span *ngIf="selectedWebsite.not_url_mask?.length"><span class="sub header">AND NOT</span>
				<span>{{selectedWebsite.not_url_mask.join(", ")}}</span>
			</span>
		  </div>
		</div>
  </div>
</div>
<div class="ui hidden divider"></div>
<app-dashboard-charts-tab *ngIf="selectedWebsite"
						  [isWebLive]="isWebLive"
						  [selectedWebsites]="[selectedWebsite]"
						  [possibleFragments]="possibleFragments"
						  [withHostageState]="true">
</app-dashboard-charts-tab>

