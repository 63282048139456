import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../../../auth/auth.service';

@Component({
	selector: 'app-websites-table',
	templateUrl: './websites-table.component.html',
})

export class WebsitesTableComponent implements OnInit {
	@Input() selectedWebsites: any;
	isDesc = false;
	column: string;
	direction: number;
	configRight: boolean;

	constructor(private authService: AuthService) {
	}

	ngOnInit(): void {
		this.configRight = this.authService.getRights().config;
		this.sortTable('name');
	}

	sortTable(by: string) {
		this.isDesc = !this.isDesc;
		this.column = by;
		this.direction = this.isDesc ? 1 : -1;
	}

	getCellClass(response) {
		switch (response) {
			case null: return 'warning';
			case true: return 'positive';
			case false: return 'negative';
		}
	}

	getCellIcon(response) {
		switch (response) {
			case null: return 'icon refresh';
			case true: return 'icon checkmark';
			case false: return 'icon close';
		}
	}
}
