<span id="core_filter_container">
	<h1 class="ui header" style="margin: 0;">
		{{header}}
		<span [ngClass]="showSpinner ? 'ui small active inline loader' : 'inactive loader'"></span>
	</h1>
	<app-publisher-website-filter *ngIf="publishersResolved"  id="core-filter" [publishers]="publishers" [hideWebsites]="hideWebsites"></app-publisher-website-filter>
</span>
<span *ngIf="publishersResolved">
	<router-outlet></router-outlet>
</span>
