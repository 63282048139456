<div *ngIf="tabletOrMobileSize" class="ui inverted menu" id="top-menu">
	<div class="stackable container left menu">
		<div class="ui pointing dropdown link item" id="small_top_left_menu" fuiDropdown [autoClose]="false">
			<i class="bars icon"></i><i class="dropdown icon"></i>
			<div class="ui inverted menu" fuiDropdownMenu>
				<app-main-menu-links [menuType]="smallMenu"></app-main-menu-links>
			</div>
		</div>
	</div>
</div>

<div class="stackable padded ui grid" id="left-menu-grid">
	<div class="ui mobile hidden tablet hidden grid" id="left_menu">
		<div class="ui vertical menu inverted">
			<app-main-menu-links [menuType]="leftMenu"></app-main-menu-links>
		</div>
	</div>
	<div class="ui grid" id="page-content">
		<div class="sixteen wide column">
			<router-outlet></router-outlet>
			<router-outlet name="state"></router-outlet>
		</div>
	</div>
</div>
