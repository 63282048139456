import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import * as Sentry from '@sentry/angular';

import {FomanticUIModule} from 'ngx-fomantic-ui';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {CoreModule} from './core/core.module';
import {AuthModule} from './auth/auth.module';

import {AuthService} from './auth/auth.service';
import {PublishersService} from './core/publishers.service';
import {PublisherResource} from 'app/resources/publisher.resource';
import {AdminInterceptorService} from './resources/interceptors/admin-interceptor.service';
import {HttpInterceptorProvider} from './resources/interceptors/http-interceptor-provider';
import {Router} from '@angular/router';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		CommonModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FomanticUIModule,
		CoreModule,
		AuthModule,
	],
	providers: [
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
				logErrors: false
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		AuthService,
		PublishersService,
		PublisherResource,
		AdminInterceptorService,
		HttpInterceptorProvider,
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
