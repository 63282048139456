import {Component, OnInit} from '@angular/core';
import {Website} from 'app/core/models/data-structure';
import {SelectedDataParamsComponent} from '../meta/selected-data-params/selected-data-params.component';

@Component({
	selector: 'app-live-demo',
	templateUrl: './live-demo.component.html',
	styleUrls: ['./live-demo.component.less']
})
export class LiveDemoComponent extends SelectedDataParamsComponent implements OnInit {
	selectedWebsites: Website[] = [];

	ngOnInit() {
		super.ngOnInit();
		this.paramsChange();
	}

	paramsChange() {
		if (this.selectedData) {
			this.selectedWebsites = this.publishersService.getSelectedWebsitesFromState(this.selectedData);
		}
	}

}
