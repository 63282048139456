import {Component} from '@angular/core';
import {MetaPlotlyChartComponent} from '../../../../../shared/charts/meta-plotly-chart.component';
import {ChartContainerComponent} from '../../../../../shared/chart.service';

declare var Plotly: any;

@Component({
	selector: 'app-ultra-lite-chart',
	templateUrl: './ultra-lite-chart.component.html',
})

export class UltraLiteChartComponent extends MetaPlotlyChartComponent implements ChartContainerComponent {

	setChartTitleIdAndLayout() {
		this.data = this.data.slice();
		this.data = this.data.filter(function(i) { return i['name'] !== 'clicks' && i['name'] !== 'CPT'; });
		this.plotId = `plotly_chart_${this.title}`;
		this.layout = {
			autosize: true,
			title: this.title,
			yaxis: {
				title: 'Revenue',
				titlefont: {color: '#375170'},
				tickfont: {color: '#375170'},
				overlaying: 'y2',
				anchor: 'free',
				side: 'left',
				rangemode: 'tozero',
			},
			yaxis2: {
				title: 'PV & Imp',
				titlefont: {color: '#3A704F'},
				tickfont: {color: '#3A704F'},
				side: 'right',
				rangemode: 'tozero',
			}
		};
		this.data.forEach(i => {
			if (i['name'].includes('pageview')) {
				i['yaxis'] = 'y2';
				i['marker'] = {'color': '#3A704F'};
				i['type'] = 'scatter';
			} else if (i['name'].includes('impression')) {
				i['yaxis'] = 'y2';
				i['marker'] = {'color': '#5ec9cb'};
				i['type'] = 'bar';
			} else if (i['name'].includes('revenue')) {
				i['marker'] = {'color': '#375170'};
				i['type'] = 'scatter';
			} else if (i['name'].includes('fee ')) {
				i['marker'] = {'color': '#e78426'};
				i['type'] = 'scatter';
				i['visible'] = 'legendonly';
			}
			i['name'] =  this.coreService.upperCaseFirstLetter(i['name']);
		});
	}

	restyleValues() {
		if (this.isChartExist()) {
			const labels = this.data[0]['x'];
			Plotly.restyle(this.plotId, 'x', [labels]);
			for (let i = 0; this.data.length > i; i++) {
				this.restyleValueWithIndex('y', i);
			}
		}
	}
}
