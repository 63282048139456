<div class="ui animated fluid fade button" id="forgotten_pass" (click)="showForm()"  i18n>
	<div class="visible content">Forgotten password</div>
	<div class="hidden content">
	Send me reset email
	</div>
</div>
<div [@formEmail] *ngIf="showForgottenPasswordForm"
		(@formEmail.done)="animationDone($event, forgottenPassword.value)">
	<div class="ui hidden divider"></div>
	<div>Please enter your email address:</div>
	<br>
	<div class="ui fluid input">
		<input type="email" name="forgottenPassword" id="email_to_send" [ngModel]="defaultEmail" appEmail #forgottenPassword="ngModel">
	</div>
	<div *ngIf="forgottenPassword.invalid && (forgottenPassword.dirty || forgottenPassword.touched)" class="ui negative message" id="input_email_message">
			<div *ngIf="forgottenPassword.errors.wrongEmail">
					The email address is invalid.
			  	</div>
	</div>
	<br>
	<button class="ui fluid fade button" (click)="sendEmail(forgottenPassword.value)" id="send_button" [disabled]="forgottenPassword.invalid">
		Send
	</button>
</div>

<div [@formEmail] *ngIf="message" [ngClass]="message?.state ? 'ui positive message' : 'ui negative message'" id="submit_email_message">
	<i class="close icon" (click)="closeMessage()"></i>
	<div class="header">
		{{message.header}}
	</div>
	<p>{{message.description}}</p>
</div>
