import {Component, Input, Output, EventEmitter} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-rest-modal',
	templateUrl: './delete-modal.component.html',
	// styleUrls: ['./rest-item.component.less'],
})

export class DeleteModalComponent {
	@Input() show: boolean;
	@Input() name: string;
	@Output() approved = new EventEmitter<boolean>();

	onDismissed() {
		this.show = false;
	}

	onApproved() {
		this.approved.emit(true);
	}

	onCancel() {
		this.approved.emit(false);
	}
}
