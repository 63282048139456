import { Component, ComponentFactoryResolver} from '@angular/core';
import { MessageService } from 'app/shared/message.service';
import { SettingsResource } from '../../../resources/settings.resource';
import { InputFieldContainer, RestComponent } from '../rest/rest.component';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-settings-management',
	templateUrl: '../rest/rest.component.html',
})

export class SettingsManagementComponent extends RestComponent {
	title = 'Global Settings';
	itemName = 'setting';
	fields: InputFieldContainer = {
			container: [
				{ name: 'key', type: 'text', value: null, options: {onlyNew: true}},
				// { name: 'deleted', type: 'boolean', value: null, options: {displayOnly: true}},
				{ name: 'created_at', type: 'datetime', value: null, options: {title: 'created', displayOnly: true}},
				{ name: 'updated_at', type: 'datetime', value: null, options: {title: 'updated', displayOnly: true}},
				{ name: 'value', type: 'text', value: null},
			],
			// underline: [],
	};

	constructor(public injected_resource: SettingsResource,
				public messageService: MessageService,
				public componentFactoryResolver: ComponentFactoryResolver
				) {
				super(messageService, componentFactoryResolver);
	}

/*
	createForm(): void {
		this.getFormData().subscribe(res => {
			this.itemList = res[0]['settings'];
			// copy only the contents, must not replace the original array

			this.showSpinner = false;
		});
	}*/

	/*
	getFormData() {
		return Observable.forkJoin(
			this.resource.listItems(),
		);
	}
	*/

	getItemName(index): string {
		if ( index && this.itemList[index] ) {
			return 'Setting: ' + this.itemList[index]['key'];
		}
		return '';
	}
}
