import {Subject, Observable} from 'rxjs';
import {Website} from '../../models/data-structure';
import { Injectable } from '@angular/core';


@Injectable()
export class WebsiteService {
	private selectedWebsite$ = new Subject<Website>();
	private selectedWebsite: Website;

	setWebsite(website: Website): void {
		this.selectedWebsite = website;
		this.selectedWebsite$.next(website);
	}

	getWebsite(): Website {
		return this.selectedWebsite;
	}

	getWebsiteObservable(): Observable<Website> {
		return this.selectedWebsite$;
	}

}
