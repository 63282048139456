<div [formGroup]="permissionForm" class="four fields">
	<div class="field">
		<!--<select formControlName="permission" class="ui fluid search dropdown">
  				<option *ngFor="let availablePermission of availablePermissions"
  						[ngValue]="availablePermission">
  					{{ availablePermission }}
  				</option>
		</select>-->
		<fui-select class="ui fluid selection"
                    formControlName="permission"
                    [options]="availablePermissions"
                    [isSearchable]="false"
                    #selectPermission>
            <fui-select-option *ngFor="let availablePermission of selectPermission.filteredOptions"
                               [value]="availablePermission">
            </fui-select-option>
        </fui-select>
		<div *ngIf="permissionForm.get('permission').invalid && (permissionForm.get('permission').dirty || permissionForm.get('permission').touched)" class="ui negative message">
			<div *ngIf="permissionForm.get('permission').hasError('required')">
				Permission required.
			</div>
		</div>
	</div>
	<div class="field">
		<fui-select
			class="ui fluid selection"
			name=""
			formControlName="publisher"
			(selectedOptionChange)="onSelect($event)"
			[isSearchable]="true"
			[options]="publisherLists"
			labelField="publisher_name"
			valueField="aab_publisher_id"
			[optionsFilter]="filterPublishers"
			#publishersSelect
			>
			<fui-select-option *ngFor="let availablePublisher of publishersSelect.filteredOptions"
				[value]="availablePublisher">
			</fui-select-option>
		</fui-select>
		<div *ngIf="permissionForm.get('publisher').invalid && (permissionForm.get('publisher').dirty || permissionForm.get('publisher').touched)" class="ui negative message">
			<div *ngIf="permissionForm.get('publisher').hasError('required')">
				Publisher required.
			</div>
		</div>
	</div>
	<div class="field">
		<fui-select
			class="ui fluid selection"
			name=""
			formControlName="website"
			[isSearchable]="true"
			[options]="activeWeblist"
			labelField="site_name"
			valueField="aab_site_id"
			[isDisabled]="publisherNotSelected"
			#websitessSelect
			>
			<fui-select-option *ngFor="let availableWebsite of websitessSelect.filteredOptions"
				[value]="availableWebsite">
			</fui-select-option>
		</fui-select>
		<div *ngIf="permissionForm.invalid && (permissionForm.get('website').dirty || permissionForm.get('website').touched) && permissionForm.hasError('targetNotValid')" class="ui negative message">
			<div>
				Website required.
			</div>
		</div>
	</div>

	<div class="field">
		<div (click)="deletePermission()" title="Delete" class="ui small red icon button">
			<i class="trash icon"></i>
		</div>
	</div>
</div>
