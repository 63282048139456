import { Component, ComponentFactoryResolver } from '@angular/core';
import { MessageService } from 'app/shared/message.service';
import { FaqCategoryResource } from '../../../resources/faq.resource';
import { FaqManagementComponent } from './faq-management.component';
import { InputFieldContainer, RestComponent } from '../rest/rest.component';
import { forkJoin } from 'rxjs';
import { InputField } from '../rest/rest.component';

@Component({
	selector: 'app-faq-category-management',
	templateUrl: '../rest/rest.component.html',
})

export class FaqCategoryManagementComponent extends RestComponent {
	title = 'Faq Category Management';
	itemName = 'faq_category';
	fields = this.makeFields();

	constructor(public injected_resource: FaqCategoryResource,
				public messageService: MessageService,
				// private faqComponent: FaqManagementComponent,
				public componentFactoryResolver: ComponentFactoryResolver,
				) {
				super(messageService, componentFactoryResolver);
	}
	makeFields() {
		const fields: InputFieldContainer = {
			container: [
				{ name: 'title', type: 'text', value: null},
				{ name: 'disabled', type: 'boolean', value: null},
				{ name: 'order', type: 'number', value: null},
			],
			underline: [
				{ name: 'title', type: 'longText', value: null},
				{ name: 'text',	type: 'longText', value: null},
			],
			wideUnderline: [
				{ name: 'Faqs', type: 'Rest', value: null, options: {title: 'Faqs', component: FaqManagementComponent, fixed: {category: 'id'}}}
			],
		};
		return fields;
	}

	createForm(): void {
		this.getFormData().subscribe(res => {
			this.itemList = res[0]['faq_categories'];
			this.showSpinner = false;
		});
	}

	getFormData() {
		return forkJoin(
			[this.resource.getItems(this.fixed)],
		);
	}
}
