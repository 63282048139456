<div class="ui segment">
	<fui-tabset>
		<div class="ui teal compact secondary menu">
			<a class="item" id="tab_page_templates" fuiTabHeader="1" [isActive]="isActive(pageTemplatesFragment)" (click)="onTabClick(pageTemplatesFragment)">Page Templates</a>
		</div>
		<div class="ui teal compact secondary menu">
			<a class="item" id="tab_adscan_zones_chart" fuiTabHeader="2" [isActive]="isActive(adscanZoneFragment)" (click)="onTabClick(adscanZoneFragment)">Ad Scan Zones</a>
		</div>
		<div class="ui bottom attached" fuiTabContent="1">
			<app-ad-scan-templates-chart-segment [websites]="selectedWebsites" [intervalData]="intervalData" [chartType]="adScanTemplatesChartType" [hideIntervalSwitch]="false"></app-ad-scan-templates-chart-segment>
		</div>
		<div class="ui bottom attached" fuiTabContent="2">
			<app-ad-scan-zones-chart-segment [websites]="selectedWebsites" [intervalData]="intervalData" [chartType]="adScanZonesChartType" [hideIntervalSwitch]="false"></app-ad-scan-zones-chart-segment>
		</div>
	</fui-tabset>
</div>
