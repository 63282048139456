import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {Website} from '../../models/data-structure';
import {ChartService, ChartType, ChartTypeInterface} from '../../../shared/chart.service';
import {IntervalChooserData, IntervalService} from '../../../shared/interval.service';
import {ChartResource} from '../../../resources/chart.resource';
import {SectionsService} from '../../../shared/sections.service';
import {CoreService} from '../../../shared/core.service';

@Component({
	selector: 'app-meta-chart-segment',
	template: '',
})

export class MetaChartSegmentComponent implements OnChanges, OnDestroy, OnInit {
	@Input() noCaption ? = false;
	@Input() websites: Website[];
	@Input() chartType: ChartType;
	@Input() hideIntervalSwitch: boolean;
	@Input() websiteSections?: string[];
	@Input() intervalData: IntervalChooserData;
	canSeeChart = false;
	aabSiteIds: string[];
	chartSubscription: Subscription;
	fragmentSubscription: Subscription;
	plotlyData: any;
	showDimmer = false;
	setNoDataMessage = false;
	chart: ChartTypeInterface;
	showPlotly = false;
	template = null;
	csv: any;
	simpleChanges: SimpleChanges;

	constructor(protected chartResource: ChartResource,
				protected chartService: ChartService,
				protected intervalService: IntervalService,
				protected route: ActivatedRoute,
				protected coreService: CoreService,
				protected sectionsService: SectionsService) {
	}

	ngOnInit(): void {
		this.chart = this.chartService.getChartTypeInterfaceByEnum(this.chartType);
		this.fragmentSubscription = this.route.fragment.subscribe(fragment => {
			this.showPlotly = this.chart.fragment === fragment;
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.canSeeChart = this.coreService.isLessThan100(this.websites) || this.noCaption;
		this.simpleChanges = changes;
		this.beforePost();
	}

	beforePost() {
		if (this.coreService.isChangeInFilterOfWebsites(this.simpleChanges) || this.isSectionsAvailableAndChosen()) {
			this.postData();
		}
	}

	postData() {
		if (this.coreService.isLessThan100(this.websites) || this.noCaption) {
			this.aabSiteIds = this.websites.map(w => w.aab_site_id);
			this.showDimmer = true;
			const chartType = this.chartService.getChartTypeInterfaceByEnum(this.chartType).name;
			this.chartSubscription = this.chartResource.post(chartType, this.websites, this.aabSiteIds, this.intervalData,
				this.template, this.websiteSections).subscribe(res => {
				this.plotlyData = res.data;
				this.csv = res.csv;
				this.showDimmer = false;
				this.setNoDataMessage = !this.plotlyData;
				this.dataReceived();
			});
			this.afterPostData();
		}
	}

	afterPostData() {}

	dataReceived(): void {}

	ngOnDestroy() {
		if (this.chartSubscription) {
			this.chartSubscription.unsubscribe();
		}
		if (this.fragmentSubscription) {
			this.fragmentSubscription.unsubscribe();
		}
		this.destroyOtherSubscriptions();
	}

	isSectionsAvailableAndChosen() {
		return this.sectionsService.isSectionsAvailable(this.websites) && this.websiteSections && this.websiteSections.length > 0;
	}

	destroyOtherSubscriptions() {}
}

