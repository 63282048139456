import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {SasZonesResource} from '../../resources/sas-zones.resource';
import {SasZonesComponent} from './sas-zones.component';
import {SasZonesLegendComponent} from './sas-zones-legend/sas-zones-legend.component';
import {SasZonesAccordionComponent} from './sas-zones-accordion/sas-zones-accordion.component';
import {SasZonesRoutingModule} from './sas-zones-routing.module';

@NgModule({
	declarations: [
		SasZonesComponent,
		SasZonesLegendComponent,
		SasZonesAccordionComponent,
	],
	imports: [
		SharedModule,
		SasZonesRoutingModule
	],
	providers: [
		SasZonesResource
	]
})
export class SasZonesModule { }
