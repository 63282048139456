import {AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Output} from '@angular/core';
import {IntervalService} from '../../interval.service';


@Component({
	selector: 'app-timezone',
	styleUrls: ['./timezone.component.less'],
	templateUrl: './timezone.component.html',
})

export class TimezoneComponent implements AfterViewChecked {
	@Output() changeTimezone  = new EventEmitter<string>();
	timeZones: string[];
	timeZone: string;
	showSelect = false;
	showSpinner = false;
	selectLoaded = false;

	constructor(private intervalService: IntervalService, private cd: ChangeDetectorRef) {
		this.timeZones = this.intervalService.getTimeZones();
		this.timeZone = this.intervalService.getDefaultTimeZone();
	}

	onChangeTimeZone() {
		this.changeTimezone.emit(this.timeZone);
	}

	ngAfterViewChecked() {
		this.cd.detectChanges();
	}


	loadSelect() {
		if (!this.selectLoaded) {
			this.showSpinner = true;
			setTimeout(() => {
				this.showSpinner = false;
				this.selectLoaded = true;
			}, 1000);
		}
	}
}
