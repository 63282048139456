import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PublisherList, UserFilter } from '../data-structure';

@Component({
	selector: 'app-user-management-filter',
	templateUrl: './user-management-filter.component.html',
	styleUrls: ['./user-management-filter.component.less']
})

export class UserManagementFilterComponent implements OnInit {
	@Input() publisherLists: PublisherList[];
	@Input() canSetSuperAdmin: boolean;

	filter: UserFilter;

	constructor(private router: Router,
				private route: ActivatedRoute) { }

	ngOnInit() {
		this.filter = this.loadFilterFromParams(this.route.snapshot.queryParams);
	}

	filterPublishers(options: PublisherList[], query: string) {
		return options.filter(item => item.publisher_name.toLowerCase().indexOf(query.toLowerCase()) > -1);
	}

	search(term: string) {
		this.filter.term = term;
		this.navigateRouter(this.filter);
	}

	protected loadFilterFromParams(param: Params): UserFilter {
		return {
			showAdmin: this.canSetSuperAdmin ? param.f_admin === 'true' : false,
			publisher: this.publisherLists.filter(item => item.aab_publisher_id === param.f_publisher)[0],
			term: param.f_term
		};
	}

	protected navigateRouter(filter: UserFilter) {
		const params = {
			f_term: filter.term ? filter.term : '',
			f_admin: this.canSetSuperAdmin ? filter.showAdmin : false,
			f_publisher: filter.publisher ? filter.publisher.aab_publisher_id : ''};

		this.router.navigate([],  { queryParams: params });
	}

}
