import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {AdScanComponent} from './ad-scan.component';
import {AdScanTableComponent} from './ad-scan-table/ad-scan-table.component';
import {AdScanDetailComponent} from './ad-scan-detail/ad-scan-detail.component';
import {AdScanTemplatesChartSegmentComponent} from './ad-scan-templates-chart-segment/ad-scan-templates-chart-segment.component';
import {AdScanZonesTableComponent} from './ad-scan-zones-table/ad-scan-zones-table.component';
import {AdScanResource} from '../../resources/ad-scan.resource';
import {AdScanTabComponent} from './ad-scan-tab/ad-scan-tab.component';
import {AdScanZonesChartSegmentComponent} from './ad-scan-zones-chart-segment/ad-scan-zones-chart-segment.component';
// import {AdScanRoutingModule} from './ad-scan-routing.module';
import {AppRoutingModule} from '../../app-routing.module';

@NgModule({
	declarations: [
		AdScanComponent,
		AdScanTabComponent,
		AdScanTableComponent,
		AdScanDetailComponent,
		AdScanTemplatesChartSegmentComponent,
		AdScanZonesTableComponent,
		AdScanZonesChartSegmentComponent,
	],
	imports: [
		SharedModule,
		AppRoutingModule,
		// AdScanRoutingModule
	],
	providers: [
		AdScanResource
	]
})
export class AdScanModule { }
