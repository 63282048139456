import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestBaseResource} from './rest-base.resource';


@Injectable()
export class WebsiteRestResource extends RestBaseResource {
	url = '/rest/website';
	listUrl = '/rest/list-website';

	constructor(protected httpClient: HttpClient) {
		super(httpClient);
	}
}
