<div *ngIf="isFewWebsites(websites)" >
	<app-interval-chooser [intervalData]="intervalData" [hideIntervalSwitch]="true"></app-interval-chooser>
	<div *ngIf="showLoader">
		<div class="ui active inline loader" *ngIf="showLoader"></div>
	</div>
	<div *ngIf="liteStats?.mgid_data.length > 0">
		<table *ngIf="liteStats?.mgid_data.length > 0" class="ui celled collapsing table">
		  <thead>
			<tr>
				<th class="pointer" (click)="sortTable('widgetId')">Widget Id
					<app-sort-icon [sortBy]="'widgetId'" [column]="column" [isDesc]="isDesc" [isAlphabet]="false"></app-sort-icon>
				</th>
				<th>Clicks</th>
				<th>CPC</th>
				<th>Shows</th>
				<th>Earnings</th>
			</tr>
		  </thead>
		  <tbody>
			<tr *ngFor="let data of liteStats.mgid_data | orderBy: {property: column, direction: direction}">
				<td>{{data.widgetId}}</td>
				<td class="right aligned">{{formatCount(data.clicks)}}</td>
				<td class="right aligned">{{data.cpc}}¢</td>
				<td class="right aligned">{{formatCount(data.shows)}}</td>
				<td class="right aligned">{{data.wages}}$</td>
			</tr>
		  </tbody>
			<tfoot>
			<tr>
				<th></th>
				<th class="right aligned">{{formatCount(liteStats.total.clicks)}}</th>
				<th class="right aligned">{{liteStats.total.cpc}}¢</th>
				<th class="right aligned">{{formatCount(liteStats.total.shows)}}</th>
				<th class="right aligned">{{liteStats.total.wages}}$</th>
			</tr>
			</tfoot>
		</table>
		<app-plotly-chart *ngIf="showPlotly && plotlyData" [data]="plotlyData" [chartType]="chartType"></app-plotly-chart>
	</div>
	<div *ngIf="liteStats?.mgid_data && liteStats?.mgid_data.length === 0">No lite data for {{aabSiteIds}}.</div>
</div>
