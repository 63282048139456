<button id="save-all-sas-zones" class="ui teal button" (click)="saveZones()" [disabled]="disableSaveAll">
	<i class="save icon"></i>Save all
</button>

<button id="cancel-sas-zones" class="ui basic red button" (click)="getAllZones()" [disabled]="disableSaveAll">
	<i class="undo icon"></i>Cancel
</button>

<div class="ui hidden divider"></div>

<app-message *ngIf="message" [message]="message"></app-message>

<span *ngIf="!isList">
	<span *ngFor="let zoneType of zoneTypes" >
		<fui-checkbox class="toggle" style="margin-right: 15px;" [(ngModel)]="zoneType.value" (ngModelChange)="changeAllSameZones(zoneType); disableSaveAll=false; message=null;">{{upperCaseFirstLetter(zoneType.type)}}</fui-checkbox>
	</span>
	<div class="ui hidden divider"></div>
	<app-sas-zones-legend></app-sas-zones-legend>
	<div class="ui hidden divider"></div>
</span>
<fui-accordion *ngIf="sasZones && sasZones.length > 0" class="styled fluid" [closeOthers]="!isList">
	<fui-accordion-panel *ngFor="let sasZone of sasZones" [isOpen]="isList">
		<div title [ngClass]="getClassFor(sasZone)">
			<i class="dropdown icon"></i>
			{{sasZone.prefix}}
		</div>
		<div content>
			<div *ngIf="!isList">
			<span class="ui checkbox">
			  <input type="checkbox" id="{{sasZone.prefix}}-select-all" [(ngModel)]="sasZone.value" (ngModelChange)="changeZonesToSameValue([sasZone], sasZone.value); checkAllZonesAndSetToggleValues(); disableSaveAll=false; message=null;">
			  <label for="{{sasZone.prefix}}-select-all">Select all</label>
			</span>
			</div>
			<div *ngFor="let postfix of sasZone.postfixes">
			<span class="ui checkbox">
			  <input type="checkbox" id="{{sasZone.prefix}}-{{postfix.name}}" [(ngModel)]="postfix.value" (ngModelChange)="checkAllZonesAndSetToggleValues(); disableSaveAll=false; message=null;">
			  <label for="{{sasZone.prefix}}-{{postfix.name}}">{{postfix.name}}</label>
			</span>
			</div>
		</div>
	</fui-accordion-panel>
</fui-accordion>
<div *ngIf="!sasZones || sasZones.length < 1">
	<p *ngIf="isList">No checked zones yet.</p>
</div>
