import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Website} from '../../../models/data-structure';
import {Subscription} from 'rxjs';
import {IntervalChooserData, IntervalService} from '../../../../shared/interval.service';
import {LiteResource, LiteStats} from '../../../../resources/lite.resource';
import {ChartType} from '../../../../shared/chart.service';
import {CoreService} from '../../../../shared/core.service';


@Component({
	selector: 'app-lite-stats',
	templateUrl: './lite-stats.component.html',
	providers: [LiteResource]
})

export class LiteStatsComponent implements OnInit, OnChanges, OnDestroy {
	@Input() websites: Website[] = [];
	aabSiteIds: string[];
	intervalData: IntervalChooserData;
	intervalDataSubscription: Subscription;
	liteStatsSubscription: Subscription;
	liteStatsChartSubscription: Subscription;
	liteStats: LiteStats;
	showLoader = false;
	isDesc = false;
	column: string;
	direction: number;
	chartType = ChartType.LITE_STATS;
	plotlyData: any;
	showPlotly = false;

	constructor(private intervalService: IntervalService, private liteResource: LiteResource, private coreService: CoreService) {
	}

	ngOnInit() {
		this.intervalDataSubscription = this.intervalService.getSharedIntervalDataObservable().subscribe((intervalData => {
			if (this.intervalService.isDifferentIntervalData(intervalData, this.intervalData)) {
				this.postLiteStats(intervalData);
			}
			this.intervalData = intervalData;
		}));
	}

	postLiteStats(intervalData: IntervalChooserData) {
		if (this.coreService.isLessThan100(this.websites)) {
			this.showLoader = true;
			this.liteStats = null;
			this.postChartData(intervalData);
			this.liteStatsSubscription = this.liteResource.postLiteStats(this.aabSiteIds, intervalData).subscribe(res => {
				this.showLoader = false;
				this.liteStats = res;
				this.sortTable('widgetId');
			});
		}
	}

	postChartData(intervalData: IntervalChooserData) {
		this.liteStatsChartSubscription = this.liteResource.postLiteStatsChart(this.aabSiteIds, intervalData).subscribe(res => {
			this.plotlyData = res;
			this.showPlotly = true;
		});
	}

	formatCount(count: number) {
		return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.aabSiteIds = this.websites.map(w => w.aab_site_id);
		this.intervalData = this.intervalService.getSharedIntervalData();
		if (this.coreService.isChangeInFilterOfWebsites(changes)) {
			this.postLiteStats(this.intervalData);
		}
	}

	sortTable(by: string) {
		this.isDesc = !this.isDesc;
		this.column = by;
		this.direction = this.isDesc ? 1 : -1;
	}

	isFewWebsites(websites: any[]) {
		return this.coreService.isLessThan100(websites);
	}

	ngOnDestroy(): void {
		if (this.intervalDataSubscription) {
			this.intervalDataSubscription.unsubscribe();
		}
		if (this.liteStatsSubscription) {
			this.liteStatsSubscription.unsubscribe();
		}
		if (this.liteStatsChartSubscription) {
			this.liteStatsChartSubscription.unsubscribe();
		}
	}
}
