<app-system-message [location]="'blockrate'"></app-system-message>
<div *ngIf="verified && checkedWebsites && isBlockRateRunning">
	<app-website-sections-selector *ngIf="showSections" [checkedWebsites]="checkedWebsites" [checkedWebsitesNames]="checkedWebsitesNames"></app-website-sections-selector>
	<app-interval-chooser [intervalData]="intervalData" [hideIntervalSwitch]="false" [showAbsolute]="true"></app-interval-chooser>
	<span id="blockrate-charts-container">
		<span id="block-rate-trend-chart">
			<app-table-chart id="br-bar-table-chart" *ngIf="barChart && aabSiteIds" [aabSiteIds]="aabSiteIds" [intervalData]="intervalData"
							 [chart]="barChart" [resource]="blockRateResource" [metadata]="{chart_type: 'bar'}" [chartDesc]="''" [table]="barTable"
							 (changeDataOk)="changeBarChartDataOk($event)" (changeMetadata)="setBarMetaData($event)"></app-table-chart>
		</span>
		<span id="block-rate-os-br-chart">
			<app-table-chart id="br-pie-table-chart" *ngIf="pieChart && aabSiteIds" [aabSiteIds]="aabSiteIds" [intervalData]="intervalData"
							 [chart]="pieChart" [resource]="blockRateResource" [metadata]="{chart_type: 'pie'}" [chartDesc]="''" [table]="pieTable"
							 (changeDataOk)="changePieChartDataOk($event)" (changeMetadata)="setPieMetaData($event)"></app-table-chart>
		</span>
		<p class="block-rate-chart-desc" >These charts shows the block-rate and the probable source of blocking. The data are sampled from roughly 1 page view
			in 20 to keep a low profile - the lower the absolute number of page views on the site, the greater may be the error.<br>
			You may consider these measurements accurate for websites with more than 100 page views / hour.
		</p>
	</span>
</div>
<div *ngIf="websites.length > 10">
	<app-info-segment [text]="'Only data for 100 or less selected websites can be shown for performance reasons. Try filtering the websites in the select boxes above.'"></app-info-segment>
</div>
<div id="block_rate_info" *ngIf="isBlockRateRunning">
	<span [ngClass]="showSpinner ? 'ui small active centered inline loader' : 'inactive loader'"></span>
	<div class="ui hidden divider"></div>
<!--	<app-block-rate-csv [csvData]="metadataBar.csvData" [filename]="checkedWebsitesNames + '_%from-%to'"></app-block-rate-csv>-->
<!--	<span> | </span>-->
	<span>
		<app-block-rate-code [hideable]="true" [hash]="hash"></app-block-rate-code>
	</span>
</div>
