<table class="ui very compact small fixed table" style="border: 0" id="budget-table-header" (click)="collapse = !collapse">
	<tbody>
		<td class="one wide" style="text-align: left">
			<i [ngClass]="collapse ? 'chevron up icon' : 'chevron down icon'" ></i>
			{{item.name}}
		</td>
		<td class="one wide" *ngIf="groupByDay">
		</td>
		<td class="two wide" *ngIf="groupByRawData">
		</td>
		<td class="four wide">
		</td>
		<td class="one wide">
			{{formatCount(item.total_count.count_ok)}}
		</td>
		<td class="one wide">
			{{formatCount(item.total_count.count_rtb)}}
		</td>
		<td class="one wide">
			{{formatCount(item.total_count.count_substitute)}}
		</td>
		<td class="one wide">
			{{formatCount(item.total_count.count_empty)}}
		</td>
	</tbody>
</table>
