import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {FeeResource, FeeTimeLine} from '../../../../../resources/fee.resource';
import {ActionType} from '../../../../../resources/budget.resource';
import {Subscription} from 'rxjs';
import {Message, MessageState} from '../../../../../shared/message.service';

@Component({
	selector: 'app-action-fee-modal',
	templateUrl: './action-fee-modal.component.html',
	styleUrls: ['./action-fee-modal.component.less']
})

export class ActionFeeModalComponent implements OnChanges, OnDestroy {
	@Input() fee: FeeTimeLine;
	@Input() actionType: ActionType;
	@Input() addNewFee = false;
	@Input() lastDate?: Date;
	@Output() onMessage = new EventEmitter<{actionType: ActionType, fee: FeeTimeLine}>();
	@Output() onChangeFees = new EventEmitter();
	@Output() onClose = new EventEmitter();
	editAction = ActionType.EDIT;
	addAction = ActionType.ADD;
	subscription: Subscription;

	constructor(private feeResource: FeeResource) {
	}

	setAll(publisherOrWeb: any) {
		return !publisherOrWeb ? '*' : publisherOrWeb;
	}

	ngOnChanges() {
		this.fee = JSON.parse(JSON.stringify(this.fee));
		if (this.addNewFee) {
			this.fee.publisher = this.setAll(this.fee.publisher);
			this.fee.website = this.setAll(this.fee.website);
		}
	}

	actionFee() {
		this.onMessage.emit({actionType: this.actionType, fee: this.fee});
	}

	closeTag() {
		if (this.fee && this.actionType === ActionType.EDIT) {
			this.fee.showEditFeeModal = false;
		}
		this.addNewFee = false;
		this.onClose.emit();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
