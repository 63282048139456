import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export enum CheckBoxType {
	GROUP_BY_ADSERVER = 'group_by_adserver',
	AGGREGATE_BY_TAGS = 'aggregate_by_tags',
	SPLIT_BY_DAY = 'split_by_day',
	AGGREGATE_BY_WEBSITE = 'aggregate_by_website',
}

export interface ValueTypeCheckbox {
	value: boolean;
	type: CheckBoxType;
}

@Component({
	selector: 'app-slider-checkbox',
	templateUrl: './slider-checkbox.component.html',
})

export class SliderCheckboxComponent implements OnInit {
	@Input() checkBoxType: CheckBoxType;
	@Input() checkBoxValue: boolean;
	@Output() valueChanges = new EventEmitter<ValueTypeCheckbox>();
	name: string;

	ngOnInit() {
		this.name = this.checkBoxType.toUpperCase().split('_').join(' ');
	}

	changeValue() {
		this.valueChanges.emit({'value': this.checkBoxValue, 'type': this.checkBoxType});
	}
}
