import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { UserManagementFormComponent } from './user-management-form/user-management-form.component';
import { UserManagementComponent } from './user-management.component';
import { UserManagementService } from './user-management.service';
import { UserManagementPermissionComponent } from './user-management-form/user-management-permission/user-management-permission.component';
import { UserManagementFilterComponent } from './user-management-filter/user-management-filter.component';
import { UserManagementResource } from 'app/resources/user-management.resource';
import {UserLoginTableComponent} from './user-management-form/user-login-table/user-login-table.component';
import {UserManagementRoutingModule} from './user-management-routing.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		UserManagementRoutingModule,
	],
	declarations: [
		UserManagementFormComponent,
		UserManagementComponent,
		UserManagementPermissionComponent,
		UserManagementFilterComponent,
		UserLoginTableComponent,
	],
	providers: [
		UserManagementResource, UserManagementService
	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
	]
})
export class UserManagementModule { }
