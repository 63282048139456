<div class="setup-text">
	<div>
		<p>
			Antiadblock LITE is a simple version of Antiadblock. It is simple to deploy and maintain. You only need to
			put an iframe and a few divs in the HTML of your page. There's no need to configure your proxy or the
			back-end server of your website. If you are able to make changes to the HTML templates of your website, then
			you can use it. No knowledge of programming is required.
		</p>
	</div>
	<div class="ui icon message faded">
		<i class="question icon"></i>
		<p>
			We also offer Antiadblock FULL, a heavier solution with more options that suits enterprise size publishers.
			If you are interested in this solution as well, please contact our customer service at
			<a href="mailto:support@antiadblock.com">support@antiadblock.com</a>
		</p>
	</div>
	<div>
		<h2 class="ui header" #TLDR id="tldr">
			<a [routerLink]="['.']"  href="#tldr" fragment="tldr" queryParamsHandling="merge" (click)="scrollToElement(TLDR)">
				TL;DR; - Setup in short
				<i class="linkify icon small"></i>
			</a>
		</h2>
		<p>Below you will find snippets containing all the necessary codes.</p>
		<p>There's an <code>iframe</code> ( we call it <code>loader</code> ) that has to be put anywhere in your HTML page.
			Then there are the ad placements/placeholders - <code>div</code>s with <code>class="aab-lite"</code> which
			go in your HTML right where the ads should appear.</p>
		<p>It's best to copy and paste these elements directly from here.
			If this works for you, you can skip the rest of the guide and proceed straight to the codes and use them.</p>
		<p>That's it.</p>
	</div>
	<br>
	<h2 class="ui header" #availableLoaders id="available-loaders">
			<a [routerLink]="['.']"  href="#available-loaders" fragment="available-loaders" queryParamsHandling="merge" (click)="scrollToElement(availableLoaders)">
				Available loaders
				<i class="linkify icon small"></i>
			</a>
		</h2>
	<app-lite-loader></app-lite-loader>
	<br>
	<br>
	<div>
		<h2 class="ui header" #detailedDescription id="detailed-description">
			<a [routerLink]="['.']"  href="#detailed-description" fragment="detailed-description" queryParamsHandling="merge" (click)="scrollToElement(detailedDescription)">
				Detailed description
				<i class="linkify icon small"></i>
			</a>
		</h2>
		<h3 class="ui header">Loader</h3>
		<div>
			<p>
				First there is an <code>iframe</code> with a <code>srcdoc</code> attribute containing a <code>&lt;script&gt;</code>. This script is
				responsible for loading the main javascript through an obfuscated channel. The main javascript is
				responsible for the proper working of the Antiadblock LITE solution. You don't have to care about
				the main javascript - just copy and paste the whole <code>iframe</code> in your HTML page.
			</p>
			<p>
				Don't try to alter the contents of the iframe, as it may
				break things. It's not possible to insert the iframe using google tag manager or similar ways. The
				iframe with all its contents must be put in line in the HTML of your page.
			</p>
		</div>
	</div>
	<br>
	<div>
		<h4 class="ui header">Page Layout</h4>
		<div>
			<p>The iframe has <code>display: none</code> property, so it won't damage the layout of your page - and it
			doesn't matter if it gets blocked by some adblocker's cosmetic filters.</p>
		</div>
	</div>
	<br>
	<div>
		<h4 class="ui header">Updates</h4>
		<div>
			<p>
				There may come a situation, when a new version of the loader will be necessary. Please note the
				process that you need to take to insert the loader in your page, so that you can replace it with a
				newer version if need be.
			</p>
			<!-- <p>
				You will receive a notification about a new version in your email, along with a description of what to
				do, and the code of the new version as well.
			</p> -->
		</div>
	</div>
	<br>
	<div>
		<h3 class="ui header">Ad placements</h3>
		<div>
			<p>
				Ad placement is done using <code>div</code>s put in the <code>body</code> of your page, right where the ads should
				appear. The <code>div</code>s have these attributes that correspond to the usual mgid / adskeeper
				parameters:
			</p>
			<ul>
				<li>data-widget_id</li>
				<li>data-src_id</li>
				<li>data-token</li>
			</ul>
			<p>The div's must have the <code>class="aab-lite"</code> to work properly.</p>
		</div>
		<br>
		<div>
			<code>
				&lt;div class="aab-lite" data-system="__data_system__" data-widget_id="__data_widget_id__" data-src_id="9999" data-token="__data_token__"&gt;&lt;/div&gt;
			</code>
		</div>
	</div>
	<br>
	<div>
		<h4 class="ui header">Advanced - Ad placement styling</h4>
		<div>
			<p>If you are not sure how to do this, please use our preset styles, or ask our staff for customization.</p>
		</div>
		<div>
			<p>To create custom css styles for the ads, you need to disable the pseudo-random naming of the
			elements by inserting skip_replace=1; at the beginning of the script tag in the srcdoc attribute
			of the iframe - i.e.:</p>
			<p><code>&lt;iframe style="display: none" srcdoc="&lt;script&gt;skip_replace=1;!function(e,i,o) ...</code></p>
			<p>
				Then you can style the ads to your liking. You must have your adblock on to see the ads. Once you
				remove the <code>skip_replace=1;</code> from the loader script, the classes and ids of the ads will be
				randomized as well as the classes and ids in the style. The only limitation is, that the style must
				either be loaded from the same domain or put inline in the html.
			</p>
			<p>WARNING! Do not forget to remove the <code>skip_replace=1;</code> part from the loader script. If it
			   remains in place your ads can be blocked easily.
			</p>
		</div>
	</div>
</div>
<div id="white-space"></div>
