<h1 class="ui header">User management
<span [ngClass]="showSpinner ? 'ui small active inline loader' : 'inactive loader'"></span></h1>
<div *ngIf="messages?.length > 0">
		<app-message *ngFor="let message of messages" [message]="message"></app-message>
</div>
<fui-modal *ngIf="showInviteModal" [isClosable]="true" (dismissed)="showInviteModal = false" #modal>
    <div class="header">Invite User</div>
    <div class="content">
        <p>Do you want to send invitation email to the user {{userToInvite?.email}}?</p>
    </div>
    <div class="actions">
        <button class="ui red button" (click)="modal.deny()">No</button>
        <button class="ui green button" (click)="modal.approve('done'); sendInvitation(userToInvite)" autofocus>Yes</button>
    </div>
</fui-modal>
<div *ngIf="showSpinner == false" class="ui segments">
	<div class="ui segment">
		<a href="" title="Add User" class="ui primary labeled icon button" [routerLink]="['/user-management', 'edit-user', '']" routerLinkActive="active" queryParamsHandling="merge">
			<i class="plus icon"></i> Add User
		</a>
	</div>
	<div class="ui segment">
		<app-user-management-filter
			[publisherLists]="publisherLists"
			[canSetSuperAdmin]="canSetSuperAdmin"
		></app-user-management-filter>
	</div>
	<div class="ui segment">
		<table class="ui small celled table">
			<thead>
				<tr>
					<th>
						<div class="ui label tiny">api v.</div>
						User
					</th>
					<th>
						Groups
					</th>
					<th>
						Rights
					</th>
					<th>
						Action
					</th>
				</tr>
			</thead>
			<tbody>
			<tr *ngFor="let user of users$ | async">
				<td>
					<div class="ui label tiny">v{{ user.api_version || '0' }}</div><a [routerLink]="['/user-management', 'edit-user', user.id]" routerLinkActive="active" queryParamsHandling="merge">{{ user.email }}</a>
				</td>
				<td class="top aligned">
						<div *ngFor="let group of user.groups">
							{{ group.name }}
							<span *ngIf="group.target.admin">All</span>
							<span *ngIf="group.target.admin==false">{{ group.target.publisher }}<span *ngIf="group.target?.website && group.target.website != 'all'">&gt;{{ group.target.website }}</span></span>
						</div>
				</td>
				<td class="top aligned">
							<div *ngFor="let right of user.rights">
								{{ right.name }}
								<span *ngIf="right.target.admin">All</span>

							<span *ngIf="right.target.admin==false">{{ right.target.publisher }}<span *ngIf="right.target?.website && right.target.website != 'all'">&gt;{{ right.target.website }}</span></span>
						</div>
				</td>
				<td>
						<div  class="ui tiny blue icon button" title="Invite" (click)="inviteUser(user)" *ngIf="canSetSuperAdmin===true">
							<i class="envelope icon"></i>
						</div>
						<!-- <div  class="ui small red icon button" (click)="deleteUser(user)" *ngIf="canSetSuperAdmin===true">
							<i class="trash icon"></i>
						</div> -->
				</td>

			</tr>
			</tbody>
		</table>
	</div>
	<div class="ui segment">
        <fui-pagination [(collectionSize)]="usersCount"
                        [pageSize]="pageSize"
                        [hasNavigationLinks]="true"
                        [(page)]="selectedPage"
                        (pageChange)="pageChange()">
        </fui-pagination>
    </div>
</div>


