<h1 class="ui header">Budget - Tags</h1>
<app-budget-publisher-filter id="pub-filter" [aabPublisherIds]="aabPublisherIds" (onChangePublisher)="changePublisher($event)"></app-budget-publisher-filter>
<span id="all-tags-buttons">
	<button class="ui mini teal button" id="new_tag" (click)="showAddTagModal=true;">Add New Tag</button>
	<a [routerLink]="['/budget', 'report']" queryParamsHandling="merge" class="ui mini teal basic button" id="back_budget">Back to Budget</a>
</span>
<div class="ui hidden divider"></div>
<app-message *ngIf="message" [message]="message"></app-message>
<app-tags-table [tags]="filteredTags" (onChangeTags)="changeTags()" (onMessage)="changeMessage($event)"></app-tags-table>
<app-action-tag-modal *ngIf="showAddTagModal" [addNewTag]="true" [zone]=null [tag]=null [actionType]="addType" (onMessage)="changeMessage($event)" (onChangeTags)="changeTags()" (onClose)="showAddTagModal=false;"></app-action-tag-modal>
