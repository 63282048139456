import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import {noSpaceValidator, numberValidator, characterValidator
} from '../../directives/validators/password-validator.directive';

/** Duplicate passwrod check validation. At the moment function works only in one direction
	so if you change password after the confirmation has been filled ok it won't invalidate it.	*/
function duplicatePassword(input: FormControl) {
	// this check must be present here as it seems that first few validations occur prior the form is actually accessible
	if (!input || !input.parent || !input.parent['controls']) {
		return { mismatchedPassword: true };
	}
	const exactMatch = input.parent['controls']['password'].value === input.value;
	return exactMatch ? null : { mismatchedPassword: true };
}

@Component({
	selector: 'app-new-password-form',
	templateUrl: './new-password-form.component.html'
})

/** Partial form for password and its confirmation input
	implementation based on https://itnext.io/partial-reactive-form-with-angular-components-443ca06d8419 */
export class NewPasswordFormComponent implements OnInit {
	// value that exchange/passes the FormGroup object to the parent form
	@Output() formReady = new EventEmitter<FormGroup>();
	newPasswordForm: FormGroup;

	constructor(private fb: FormBuilder) {}

	ngOnInit() {
		// create react form binding for the "partial" form
		this.newPasswordForm = this.fb.group({
			password: ['', [Validators.required,
							Validators.minLength(8),
							noSpaceValidator(),
							numberValidator(),
							characterValidator()]],
			confirm_password: ['', [Validators.required, duplicatePassword]],
		});

		// emit an event for parent control/form when the partial form is actually ready
		this.formReady.emit(this.newPasswordForm);
	}
}
