import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {BlockRateOverview, BrsSite, Website} from '../core/models/data-structure';
import {IntervalChooserData, IntervalService} from '../shared/interval.service';
import {SectionsService} from '../shared/sections.service';
import {TableChartBaseResource} from './table-chart-base.resource';

export enum BlockingState {
	BLOCKING = 'blocking',
	NON_BLOCKING = 'non_blocking',
}

export interface BlockratePercentData {
	blockingState: BlockingState;
	percent: number;
}

export interface BlockRateResponse {
	isRunning: boolean;
	websitesNames: string[];
}

export interface KununuResponse {
	script: any;
}

export interface NewBlockRateResponse {
	created: boolean;
	error?: string;
}

export interface UpdateBlockRateResponse {
	updated: boolean;
	error?: string;
}

export interface UpgradeResponse {
	success: boolean;
	error?: string;
	brs_upgrade?: string;
}

interface VerifiedResponse {
	verified: boolean;
}

interface DeletedResponse {
	deleted: boolean;
}

@Injectable()
export class BlockRateResource extends TableChartBaseResource {
	url = '/block_rate';

	constructor(protected httpClient: HttpClient, protected intervalService: IntervalService, private sectionsService: SectionsService) {
		super(httpClient, intervalService);
	}

	post(aab_sites_ids: string[], intervalChooserData: IntervalChooserData, metadata: any): Observable<{ data: any[], metadata: any}> {
		this.chartUrl = this.url + '/chart/' + metadata['chart_type'];
		return super.post(aab_sites_ids, intervalChooserData, metadata);
	}

	postCheckBlockRate(aab_site_ids: string[]): Observable<BlockRateResponse> {
		return this.httpClient.post<BlockRateResponse>(
			environment.apiEndpoint + this.url,
			{aab_site_ids: aab_site_ids}
		);
	}

	postBlockRatePercent(blocking_state: BlockingState, websites: Website[], aab_site_ids: string[], intervalChooserData: IntervalChooserData,
							websiteSections?: string[]): Observable<{percent: number}> {
		const body = {aab_site_ids: aab_site_ids, interval_enum: intervalChooserData.intervalEnum, period: intervalChooserData.period,
			time_zone: intervalChooserData.timeZone};
		if (this.sectionsService.isSectionsAvailable(websites)) {
			body['website_sections'] = websiteSections;
		}
		return this.httpClient.post<{percent: number}>(
			`${environment.apiEndpoint}${this.url}/percent/${blocking_state}`, body
		);
	}

	getKununu(): Observable<KununuResponse> {
		return this.httpClient.get<KununuResponse>(
			environment.apiEndpoint + '/kununu'
		);
	}

	postBlockRateOverview(timezone: string): Observable<BlockRateOverview[]> {
		return this.httpClient.post<BlockRateOverview[]>(
			environment.apiEndpoint + this.url + '/brs_overview', {time_zone: timezone}
		);
	}

	postNewWebsite(brsSite: BrsSite): Observable<NewBlockRateResponse> {
		return this.httpClient.post<NewBlockRateResponse>(
			environment.apiEndpoint + this.url + '/add',
			{website_domain: brsSite.url,
					name: brsSite.name,
					ecpm: brsSite.ecpm
				}
		);
	}

	postUpdateWebsite(hash: string, brsSite: BrsSite): Observable<UpdateBlockRateResponse> {
		return this.httpClient.post<UpdateBlockRateResponse>(
			environment.apiEndpoint + this.url + '/update/' + hash,
			{  name: brsSite.name,
					ecpm: brsSite.ecpm
				}
		);
	}

	getVerified(hash: string): Observable<VerifiedResponse> {
		return this.httpClient.get<VerifiedResponse>(
			`${environment.apiEndpoint}${this.url}/verify/${hash}`
		);
	}

	requestUpgrade(hash: string): Observable<UpgradeResponse> {
		return this.httpClient.post<UpgradeResponse>(
			`${environment.apiEndpoint}${this.url}/upgrade/${hash}`, {}
			);
	}

	deleteBrsSite(hash: string): Observable<DeletedResponse> {
		return this.httpClient.get<DeletedResponse>(
			`${environment.apiEndpoint}${this.url}/delete/${hash}`
		);
	}

	getSites(): Observable<BrsSite[]> {
		return this.httpClient.get<BrsSite[]>(
			`${environment.apiEndpoint}${this.url}/sites`
		);
	}

	postRegister(email: string, websiteData): Observable<NewBlockRateResponse> {
		return this.httpClient.post<NewBlockRateResponse>(
			environment.apiEndpoint + '/brs/register',
			{email: email, website_domain: websiteData.url, name: websiteData.name, ecpm: websiteData.ecpm}
		);
	}


}
