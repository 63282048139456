import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RestBaseResource} from './rest-base.resource';
import {environment} from '../../environments/environment';

@Injectable()
export class FaqResource extends RestBaseResource {
	url = '/faq';
	listUrl = '/list-faq';

	constructor(protected httpClient: HttpClient) {
		super(httpClient);
	}

	listFaqCategories(): Observable<any> {
		// console.log('listLoaders', this.httpClient);
		return this.httpClient.get<any>(
			environment.apiEndpoint + '/list-faq-category'
		);
	}
}

@Injectable()
export class FaqCategoryResource extends RestBaseResource {
	url = '/faq-category';
	listUrl = '/list-faq-category';

	constructor(protected httpClient: HttpClient) {
		super(httpClient);
	}
}
