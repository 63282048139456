<table *ngIf="hostageState; else noData" class="ui very basic single line collapsing table">
  <thead>
    <tr>
	  <th>date time</th>
      <th *ngFor="let name of hostageProperties">{{formatName(name)}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let state of hostageState">
      <td>{{state?.header}}</td>
      <td *ngFor="let name of hostageProperties" [ngClass]=getRightClass(state[name])><i [ngClass]=getRightIcon(state[name])></i>{{getText(state[name])}}</td>
    </tr>
  </tbody>
</table>
<ng-template #noData><div>No hostage data for {{selectedWebsite.name}}.</div></ng-template>
