<div class="one column stackable ui grid" *ngIf="selectedWebsites && selectedWebsites.length > 0">
	<div class="sixteen wide column">
		<app-ad-scan-tab *ngIf="selectedWebsites && selectedWebsites.length > 0" [selectedWebsites]="selectedWebsites"
                         [possibleFragments]="possibleFragments"></app-ad-scan-tab>
	</div>
	<div class="sixteen wide column">
		<app-ad-scan-table [selectedWebsites]="selectedWebsites"></app-ad-scan-table>
	</div>
</div>

