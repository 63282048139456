import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {RestResource} from '../core/admin/rest/rest.component';
import {IntervalChooserData} from '../shared/interval.service';
import {RestBaseResource} from './rest-base.resource';

export interface LoaderList {
	loaders: Object[];
	message?: string;
}

export interface LiteLoader {
	loader: string;
}

export interface LiteStats {
	mgid_data: MgidData[];
	total: MgidData;
}

export interface MgidData {
	clicks: number;
	cpc: number;
	cpm: number;
	ctr: number;
	realShows: number;
	shows: number;
	vCpm: number;
	wages: number;
	widgetId: number;
}

@Injectable()
export class LiteResource implements RestResource {
	listUrl = '/lite';
	loaderUrl = '/lite/';

	constructor(private httpClient: HttpClient) {
	}

	listItems(): Observable<LoaderList> {
		// console.log('listLoaders', this.httpClient);
		return this.httpClient.get<LoaderList>(
			`${environment.apiEndpoint}${this.listUrl}`
		);
	}

	getItems(): Observable<LoaderList> {
		// console.log('listLoaders', this.httpClient);
		return this.httpClient.get<LoaderList>(
			`${environment.apiEndpoint}${this.listUrl}`
		);
	}

	postLiteStats(aab_site_ids: string[], intervalChooserData: IntervalChooserData): Observable<LiteStats> {
		return this.httpClient.post<LiteStats>(
			`${environment.apiEndpoint}/mgid_data`, {aab_site_ids: aab_site_ids,
				interval_enum: intervalChooserData.intervalEnum, period: intervalChooserData.period,
				time_zone: intervalChooserData.timeZone}
		);
	}

	saveItem(data): Observable<any> {
		// console.log('about to save', data);
		const copy = JSON.parse(JSON.stringify(data));
		delete copy['loader'];
		return this.httpClient.put<any>(
			environment.apiEndpoint + this.listUrl + '/' + data['id'],
			copy
		);
	}

	createItem(data): Observable<any> {
		// console.log('about to create', data);
		const copy = JSON.parse(JSON.stringify(data));
		delete copy['loader'];
		return this.httpClient.post<any>(
			environment.apiEndpoint + this.listUrl,
			copy
		);
	}

	deleteItem(data): Observable<any> {
		// console.log('about to delete', data);
		return this.httpClient.delete<any>(
			environment.apiEndpoint + this.listUrl + '/' + data['id']);
	}

	postLiteStatsChart(aab_site_ids: string[], intervalChooserData: IntervalChooserData): Observable<LiteStats> {
		return this.httpClient.post<LiteStats>(
			`${environment.apiEndpoint}/mgid_graph_data`, {aab_site_ids: aab_site_ids,
				interval_enum: intervalChooserData.intervalEnum, period: intervalChooserData.period,
				time_zone: intervalChooserData.timeZone}
		);
	}

	listLoaderOptions(): Observable<string[]> {
		// console.log('listLoaderOptions', this.httpClient);
		return this.httpClient.get<string[]>(
			`${environment.apiEndpoint}/lite-options`
		);
	}

	listLoaderFiles(): Observable<string[]> {
		return this.httpClient.get<string[]>(
			`${environment.apiEndpoint}/lite-files`
		);
	}
}

@Injectable()
export class LiteFilesResource extends RestBaseResource {
	url = '/lite-files';
	listUrl = '/lite-files';

	constructor(protected httpClient: HttpClient) {
		super(httpClient);
	}
}

