<div *ngIf="selectedWebsites">
	<table class="ui very basic table" id="adscan-table">
	  <thead>
		<tr>
			<th class="pointer" (click)="sortTable('name')" id="table_sort_name">
				<div class="content">
					Publisher
					<div class="sub header">Website Name
						<i [ngClass]="(column?.match('name') && isDesc) ? 'sort alphabet ascending icon' : (column?.match('name') && !isDesc) ? 'sort alphabet descending icon' : 'sort icon'"> </i>
					</div>
				</div>
			</th>
			<th></th>
	  	</tr>
	  </thead>
	  <tbody>
			<tr *ngFor="let web of selectedWebsites | orderBy: {property: column, direction: direction}">
			  <td>
				<div class="content">
					{{web?.publisher?.name}}
					<div class="sub header">
						{{web?.name}}
					</div>
				</div>
			  </td>
			  <td class="top aligned" style="text-align:center;">
					<a class="ui tiny teal button" id="table_detail_{{web?.aab_site_id | cssFriendly}}" [routerLink]="['/ad-scan/ad-scan-detail', web?.publisher.aab_publisher_id, web?.aab_site_id]" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|Ad Scan detail">
						<i id="detail_button" class="info circle icon"></i>DETAIL</a>
			  </td>
			</tr>
	  </tbody>
	</table>
</div>
