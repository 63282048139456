import {Component, Input, OnInit} from '@angular/core';
import {ButtonType, ButtonTypeProperties} from 'app/core/installation/button-type';

const INSTALLATION_PROPERTIES: ButtonTypeProperties = {
	name: 'installation_button',
	desc: 'Overview',
	iconType: 'icon file text outline',
	link: '/installation'
};

const CONFIGURATION_PROPERTIES: ButtonTypeProperties = {
	name: 'configuration_button',
	desc: 'nginx.conf',
	iconType: 'icon file code outline',
	link: '/installation/configuration'
};

@Component({
	selector: 'app-installation-link',
	templateUrl: './installation-link.component.html',
	styleUrls: ['./installation-link.component.less'],
})
export class InstallationLinkComponent implements OnInit {
	@Input() selectedPublisherAabId: string;
	@Input() selectedPublisherName: string;
	@Input() buttonType: ButtonType;
	@Input() index: number;
	buttonProperties: ButtonTypeProperties;

	constructor() {
	}

	ngOnInit() {
		this.buttonProperties = this.buttonType === ButtonType.INSTALLATION ? INSTALLATION_PROPERTIES :
			(this.buttonType === ButtonType.CONFIGURATION ? CONFIGURATION_PROPERTIES : null);
	}

}
