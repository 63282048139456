	<div class="ui raised segment">
		<div class="one column stackable ui grid middle aligned" >
			<div class="three wide column">
				<div class="ui icon input" id="email-filter">
					<input #searchBox
						id="search-box"
						(keyup)="search(searchBox.value)"
						placeholder="Email Filter..."
						[(ngModel)]="filter.term"
						[disabled]="filter.showAdmin"/>
				  	<i class="filter icon"></i>
				</div>
			</div>
			<div class="one wide column"></div>
			<div class="three wide column">
				<fui-select
					[isDisabled]="filter.showAdmin"
					class="ui fluid selection"
					id="publisher-filter"
					name=""
					(selectedOptionChange)="search(searchBox.value)"
					[isSearchable]="true"
					[options]="publisherLists"
					labelField="publisher_name"
					placeholder="Publisher"
					[optionsFilter]="filterPublishers"
					[(ngModel)]="filter.publisher"
					#publishersSelect
					>
					<fui-select-option [value]="{publisher_name: 'Clear filter', aab_publisher_id: null}"></fui-select-option>
					<fui-select-option *ngFor="let availablePublisher of publishersSelect.filteredOptions"
						[value]="availablePublisher">
					</fui-select-option>
				</fui-select>
			</div>
			<div class="nine wide column" *ngIf="canSetSuperAdmin===true">
				<div class="field" id="super-admin-toogle">
			        <fui-checkbox
			        	class="toggle"
			        	[(ngModel)]="filter.showAdmin"
			        	(checkChange)="search(searchBox.value)"
			    		>
			    	Show Super Admins</fui-checkbox>
			    </div>
		    </div>
    	</div>
    </div>

