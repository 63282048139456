import { Component, EventEmitter, OnInit, OnDestroy, Output, Input } from '@angular/core';
import { ComponentFactoryResolver, ComponentFactory, ViewContainerRef, ViewChild } from '@angular/core';
import { Message, MessageService, MessageState } from 'app/shared/message.service';
import { Observable, Subscription, of } from 'rxjs';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { ControlsComponent } from './controls/controls.component';
import { forkJoin } from 'rxjs';

export enum ItemActions {
	SAVE = 'save',
	DELETE = 'delete',
	EDIT = 'edit',
	UNEDIT = 'unedit',
	SELECT = 'select',
	UNSELECT = 'unselect',
}
export interface InputField {
	name: string;
	type: string;
	value: any;
	fixed?: boolean;
	options?: RestOptions | SelectOptions;
}
export interface SelectOptions extends Array<object> {
	[index: number]: {name: string, value: any};
}
export interface RestOptions {
	title?: string;
	component?: any;
	fixed?: any;
	displayOnly?: boolean;
	onlyNew?: boolean;
}
export interface InputFieldContainer {
	[key: string]: InputField[];
}

export interface ItemMessage {
	action: ItemActions;
	index: number;
}

export interface RestResource {
	getItems(data?): Observable<any>;
	saveItem(data): Observable<any>;
	createItem(data): Observable<any>;
	deleteItem(data): Observable<any>;
	listItems(): Observable<any>;
}

export interface ResourceMapping {
	resourceListVar: string;
	resourceItemsName: string;
	resourceName: string;
	resourceMethodName?: string;
	passData?: boolean;
	mapping?: Object;
	zeroItem?: Object;
	optionsVars?: Object;
	pagination?: boolean;
}

@Component({
	selector: 'app-rest',
	templateUrl: './rest.component.html',
})


export class RestComponent implements OnInit, OnDestroy {
	@Output() messageOut = new EventEmitter();
	@Output() collapseOut = new EventEmitter();
	@Input() title = '';
	@Input() subForm = false;
	itemName = 'item';
	// allows customization of item component on messageIn,
	// for example: file component in lite-management-files gets its value from response
	// field 'name' instead of response field 'file'
	itemMessageInHandler = null;

	itemList: Object[] = [];
	pages: string[];
	pageSize = 10;
	selectedPage = 1;
	filterTerm = null;
	fields: InputFieldContainer;
	fixed = {};
	desc = '';
	descOfSubForm: string;

	showSpinner = false;
	messages: Message[] = [];

	fromDeleteAll = false;
	showDeleteModal = false;
	indexToDelete = null;
	injected_resource: RestResource;
	resource: RestResource;
	resourceMapping: Array<ResourceMapping>;
	selectedIndexes = [];

	categories: SelectOptions = [];
	fixCategory = false;
	disable = false;

	@ViewChild('controls', {read: ViewContainerRef, static: true}) viewContainer: ViewContainerRef;
	controlsRef: any;
	modalFactory: ComponentFactory<DeleteModalComponent>;
	deleteModalComponent: DeleteModalComponent;
	modalSubscription: Subscription;
	controlsFactory: ComponentFactory<ControlsComponent>;
	controls: ControlsComponent;
	controlsSubscriptions: {add: Subscription, delete: Subscription, save: Subscription, collapse: Subscription} = {
		add: null, delete: null, save: null, collapse: null
	};

	constructor(
		public messageService: MessageService,
		protected componentFactoryResolver: ComponentFactoryResolver,
	) {
	}

	hasFixed(): boolean {
		const keys = Object.keys(this.fixed);
		if (! keys.length) {
			return false;
		}
		for (const k of keys) {
			if (!this.fixed[k]) {
				return false;
			}
		}
		return true;
	}

	ngOnInit(): void {
		if (this.injected_resource) {
			this.resource = this.injected_resource;
		}
		this.showSpinner = true;
		const message = this.messageService.getMessage();
		if (message) {
			this.messages.push(message);
			this.messageService.removeMessage();
		}
		this.disableFixedField();
		this.createForm();
		this.createControls();
		this.modalFactory = this.componentFactoryResolver.resolveComponentFactory(DeleteModalComponent);
		this.deleteModalComponent = this.viewContainer.createComponent(this.modalFactory).instance;
		this.modalSubscription = this.deleteModalComponent.approved.subscribe((ret) => {
			if (ret) {
				this.doDelete();
			}
		});

		// Disable controls in subform when parent is not created
		if (this.subForm && !this.hasFixed()) {
			console.log('Subform without category handling.');
			this.descOfSubForm = this.desc;
			this.disable = true;
			this.changeControlsDisable(null);
		}
	}

	disableFixedField(): void {
		for (const container in this.fields) {
			if (!this.fields.hasOwnProperty(container)) {
				continue;
			}
			for (const field of this.fields[container]) {
				const arr = this.fields[container];
				this.setFixed(arr);
			}
		}
	}
	setFixed(arr): void {
		for (let i = 0; i < arr.length; i++) {
			const name = arr[i]['name'];
			if (this.fixed[name]) {
					arr[i]['fixed'] = true;
			}
		}
	}

	mapDict(mapping: Object, dict: Object): Object {
		const ret = {};
		for (const target in mapping) {
			if (!mapping.hasOwnProperty(target)) {
				continue;
			}
			ret[target] = dict[mapping[target]];
		}
		return ret;
	}

	createForm(): void {
		const p = this.selectedPage - 1;
		this.getFormData(p, this.filterTerm).subscribe(res => {
			// console.log('res', res);
			if (this.resource && !this.resourceMapping) {
				this.itemList = res[this.itemName + 's'];
				if (p === 0 && res['pages']) {
					this.pages = res['pages'];
				}
				this.showSpinner = false;
				return;
			}
			if (this.resourceMapping) {
				let i = 0;
				for (const mapping of this.resourceMapping) {
					// clear current items

					if (mapping.resourceListVar === 'itemList') {
						this.resource = this[mapping.resourceName];
					}
					if (mapping.pagination && p === 0) {
						this.pages = res[i]['pages'];
					}
					this[mapping.resourceListVar].length = 0;

					if (mapping.optionsVars && res[i]['options']) {
						for (const optVar in mapping.optionsVars) {
							if (!mapping.optionsVars.hasOwnProperty(optVar)) {
								continue;
							}
							this[mapping.optionsVars[optVar]].length = 0;
							for (const item of res[i]['options'][optVar]) {
								this[mapping.optionsVars[optVar]].push(item);
							}
						}
					}

					if (mapping.zeroItem) {
						// TODO: make a copy of the item
						this[mapping.resourceListVar].push(mapping.zeroItem);
					}
					for (const item of res[i][mapping.resourceItemsName]) {
						if (mapping.mapping) {
							this[mapping.resourceListVar].push(this.mapDict(mapping.mapping, item));
						} else {
							// console.log("full copy", mapping.resourceListVar, this.itemList);
							this[mapping.resourceListVar].push(item);
						}
					}
					i++;
					// console.log("i", i);
				}
				this.showSpinner = false;
				return;
			}
		});
	}

	pageChange(): void {
		this.createForm();
	}

	filterChange(term): void {
		if (term.length < 3) {
			this.filterTerm = null;
			if (term.length === 0) {
				this.selectedPage = 1;
				this.createForm();
			}
			return;
		}
		this.filterTerm = term;
		this.selectedPage = 1;
		this.createForm();
	}

	setFixedCategory(category: SelectOptions, fixed: boolean) {
		this.categories.unshift(category);
		this.fixCategory = fixed;
	}

	changeControlsDisable(fixed: object) {
		if (this.controlsRef) {
			(<ViewContainerRef>this.controlsRef.instance)['disable'] = this.disable;
		}
	}

	createControls(): void {
		this.controlsFactory = this.componentFactoryResolver.resolveComponentFactory(ControlsComponent);
		this.controlsRef = this.viewContainer.createComponent(this.controlsFactory);
		(<ViewContainerRef>this.controlsRef.instance)['disable'] = this.disable;
		this.controls = this.controlsRef.instance;
		for (const name in this.controlsSubscriptions) {
			if (this.controlsSubscriptions.hasOwnProperty(name)) {
				this.controlsSubscriptions[name] = this.controls[name].subscribe((ret) => {
					this['onControls' + this.titleCaseWord(name)](ret);
				});
			}
		}
	}

	getFormData(page, filter): Observable<any> {
		const data = {};
		for (const f in this.fixed) {
			if (this.fixed.hasOwnProperty(f)) {
				data[f] = this.fixed[f];
			}
		}
		if (page && this.pages[page]) {
			data['_search_after'] = this.pages[page];
		}
		if (filter) {
			data['query'] = '*' + filter + '*';
		}
		if (this.resourceMapping) {
			return forkJoin(this.createResourceRequests(data));
		}
		if (this.resource) {
			return this.resource.getItems(data);
		}
		return of([]);
	}

	createResourceRequests(data): Array<Observable<any>> {
		const requests = [];
		// console.log('requests', requests);
		for (const mapping of this.resourceMapping) {
			const resource = this[mapping.resourceName];
			const methodName = mapping.resourceMethodName || 'listItems';
			if (mapping.passData) {
				requests.push(resource[methodName](data));
			} else {
				requests.push(resource[methodName]());
			}
		}
		return requests;
	}

	makeFields(): InputFieldContainer {
		return {};
	}

	onControlsAdd(): void {
		if (!this.itemList) {
			this.itemList = [];
		}
		const newItem = {_isNewItem: true };
		if (this.fixed) {
			for (const name in this.fixed) {
				if (this.fixed.hasOwnProperty(name)) {
					newItem[name] = this.fixed[name];
				}
			}
		}
		this.itemList.unshift(newItem);
		console.log('add', this.itemList);
		this.fields = this.makeFields();
	}

	onControlsSave(): void {
		const list = this.selectedIndexes;
		// toto dole sa nikdy nestane
		if (!list.length) {
			this.messages.push({state: MessageState.WARNING, header: null, description: 'Nothing to delete.'});
			return;
		} else {
			let index = 0;
			for (const i of list) {
				if (i) {
					this.saveItem(index);
					this.selectedIndexes[index] = false;
				}
				index += 1;
			}
		}
	}

	onControlsDelete(): void {
		const list = this.selectedIndexes;
		// toto dole sa nikdy nestane
		if (!list.length) {
			this.messages.push({state: MessageState.WARNING, header: null, description: 'Nothing to delete.'});
			return;
		} else {
			this.deleteModal(null);
		}
	}

	onControlsCollapse(): void {
		this.sendMessageToRestItem('collapse-all');
	}

	deleteModal(index): void {
		this.indexToDelete = index;
		this.deleteModalComponent.show = true;
		this.deleteModalComponent.name = this.getItemName(this.indexToDelete);
	}

	listen(msg: ItemMessage): void {
		if (msg.action === ItemActions.SAVE) {
			return this.saveItem(msg.index);
		}
		if (msg.action === ItemActions.DELETE) {
			return this.deleteModal(msg.index);
		}
		if (msg.action === ItemActions.EDIT) {
			this.selectedIndexes[msg.index] = true;
			return;
		}
		if (msg.action === ItemActions.UNEDIT) {
			this.selectedIndexes[msg.index] = false;
			return;
		}
		if (msg.action === ItemActions.SELECT) {
			this.selectedIndexes[msg.index] = true;
			return;
		}
		if (msg.action === ItemActions.UNSELECT) {
			this.selectedIndexes[msg.index] = false;
			return;
		}
	}

	showMessage(response, verb, name): void {
		let state, text;
		if (response.error) {
			state = MessageState.NEGATIVE;
			text = `Error ${verb}ing ${name}.`;
		} else {
			state = MessageState.POSITIVE;
			text = `${name} ${verb}ed.`;
		}
		this.messages.push({state: state, header: null,
			description: text});
	}

	sendMessageToRestItem(data: any): void {
		if (data === 'collapse-all') {
			this.collapseOut.emit(data);

		} else {
			this.messageOut.emit(data);
		}
	}

	prepareItemData(item: any): object {
		const data = {id: item.id};
		if (item._isNewItem) {
			data['_isNewItem'] = item._isNewItem;
		}
		// console.log("item", item);
		for (const container in this.fields) {
			if (this.fields.hasOwnProperty(container)) {
				for (const field of this.fields[container]) {
					// console.log("field", field);
					if (!field.options || !field.options['displayOnly']) {
						data[field.name] = item[field.name];
					}
				}
			}
		}
		return data;
	}

	saveItem(index): void {
		const item = this.prepareItemData(this.itemList[index]);
		if (item['id'] && !item['_isNewItem']) {
			console.log('save with id');
			this.resource.saveItem(item).subscribe((e) => {
				this.showMessage(e, 'sav', this.itemName);
				this.sendMessageToRestItem({index: index, data: e[this.itemName]});
			},
			(error) => {
				this.showMessage(error, 'sav', this.itemName);
			});
		} else {
			console.log('create');
			delete item['_isNewItem'];
			this.resource.createItem(item).subscribe((e) => {
				this.showMessage(e, 'creat', this.itemName);
				this.sendMessageToRestItem({index: index, data: e[this.itemName]});
			},
			(error) => {
				this.showMessage(error, 'creat', this.itemName);
			});
		}
	}

	deleteSelected(): void {
		for (const value in this.selectedIndexes) {
			if (this.selectedIndexes.hasOwnProperty(value)) {
				if (this.selectedIndexes[value]) {
					this.deleteItem(value);
					this.selectedIndexes[value] = false;
				}
			}
		}
	}

	doDelete(): void {
		if (this.indexToDelete !== null) {
			this.deleteItem(this.indexToDelete);
			this.indexToDelete = null;
		} else {
			this.deleteSelected();
		}
	}

	deleteItem(index): void {
		const item = this.itemList[index];
		if ( !item['id'] ) {
			this.itemList.splice(index, 1);
		} else {
			this.resource.deleteItem(item).subscribe((e) => {
				for (let i = 0; i < this.itemList.length; i++) {
					if (item['id'] === this.itemList[i]['id']) {
						this.itemList.splice(i, 1);
					}
				}
			});
		}
	}

	getItemName(index): string {
		if (index !== null && this.itemList[index]) {
			let title = '';
			if (this.itemList[index]['title']) {
				title = ' ' + this.itemList[index]['title'];
			}
			return this.itemName + title;
		} else {
			const title = [];
			for (const value in this.selectedIndexes) {
				if (this.selectedIndexes.hasOwnProperty(value)) {
					if (this.selectedIndexes[value]) {
						if (this.itemList[value]['title']) {
							const titulok = ' ' + this.itemList[value]['title'];
							title.push(titulok);
						}
					}
				}
			}
			const length = title.length;
			return length + ' item' + ((length > 1) ? 's' : '');
			// return this.itemName + title; // ma sa vratit konkretny zoznam faq to delete ci len pocet?
		}
	}

	titleCaseWord(word: string) {
		if (!word) { return word; }
		return word[0].toUpperCase() + word.substr(1).toLowerCase();
	}

	ngOnDestroy(): void {
		for (const name in this.controlsSubscriptions) {
			if (this.controlsSubscriptions.hasOwnProperty(name)) {
				if (this.controlsSubscriptions[name]) {
					this.controlsSubscriptions[name].unsubscribe();
				}
			}
		}
		if (this.modalSubscription) {
			this.modalSubscription.unsubscribe();
		}
	}
}
