import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Message, MessageService, MessageState } from 'app/shared/message.service';
import {emailValidator} from 'app/shared/directives/validators/email-validator.directive';
import { UserManagementService, UserAction } from '../user-management.service';
import { User, Permission, PublisherList, PermissionType } from '../data-structure';
import { forkJoin } from 'rxjs';
import { checkTarget } from './user-form-target-validator';


@Component({
	selector: 'app-user-management-form',
	templateUrl: './user-management-form.component.html',
	styleUrls: ['./user-management-form.component.less']
})

export class UserManagementFormComponent implements OnInit {
	user: User;
	publisherLists: PublisherList[] = [];

	userForm: FormGroup;
	showSpinner = true;

	availableRights: string[];
	availableRightGroups: string[];

	action = UserAction.SAVE;
	existing_item = true;
	origin_params: any;

	messages: Message[] = [];

	PermissionType: PermissionType;
	permissionType = PermissionType;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private userManagement: UserManagementService,
		private messageService: MessageService,
		private fb: FormBuilder) { }

	ngOnInit() {
		const id = this.route.snapshot.paramMap.get('user_id');
		this.origin_params = this.route.snapshot.queryParams;


		this.getFormData().subscribe(res => {
				this.availableRightGroups = res[0];
				this.availableRights = res[1];
				this.publisherLists = res[2];

				if (id) {
					this.userManagement.getUser(id).subscribe( data => {
							this.user = data;
							this.buildForm();
						},
						error => {
							this.messageService.setMessage({
								state: MessageState.NEGATIVE,
								header: 'Problem with User loading.',
								description: error['error']['description']
							});
							this.router.navigate(['/user-management'], { queryParams: this.origin_params });
						});
				} else {
					this.user = {
						id: '',
						email: '',
						groups: [],
						rights: [],
						brs_domains: [],
						permissions: []
					};
					this.action = UserAction.ADD;
					this.existing_item = false;
					this.buildForm();
				}
			},
			error => {
				this.messageService.setMessage({state: MessageState.NEGATIVE, header: 'Problem with User Form loading.'});
				this.router.navigate(['/user-management'], { queryParams: this.origin_params });
			}
		);
	}

	saveUser() {
		if (!this.userForm.valid) {
			this.validateAllFormFields(this.userForm);
			return;
		}

		this.userManagement.editUser(
			this.action,
			this.getFromForm()
		).subscribe(data => {
			if (data['ok'] === true) {
				this.messageService.setMessage({state: MessageState.POSITIVE, header: data['message']});
				this.router.navigate(['/user-management'], { queryParams: this.origin_params });
			}
		}, error => {
			this.catchError(error);
		});
	}

	addPermission(perm_type: PermissionType) {
		const control = <FormArray>this.userForm.controls[perm_type];
		control.push(this.initPermission());
	}

	removePermission(item: Permission) {
		const control = <FormArray>this.userForm.controls[item.type];
		control.removeAt(+(item.id));
	}

	private catchError(err) {
		this.messages.push({state: MessageState.NEGATIVE, header: err['error']['description']});
	}

	private getFormData() {
		return forkJoin(
			[this.userManagement.getUserRightGroups(),
			this.userManagement.getUserRights(),
			this.userManagement.getUserPubwebList()]
		);
	}

	private buildForm() {
		this.userForm = this.fb.group({
			email: [{value: this.user.email, disabled: this.existing_item}, [Validators.required, emailValidator()]],
			group: this.fb.array(
				this.loadPermissions(this.user.groups),
			),
			right: this.fb.array(
				this.loadPermissions(this.user.rights),
			)
		});
		this.showSpinner = false;
	}

	private getFromForm() {
		return {
			id: this.user.id,
			email: this.userForm.controls['email'].value,
			permissions: this.getPermissionsFromFormArray(<FormArray>this.userForm.controls[PermissionType.GROUP], PermissionType.GROUP).concat(
						this.getPermissionsFromFormArray(<FormArray>this.userForm.controls[PermissionType.RIGHT], PermissionType.RIGHT)),
			brs_domains: []};
	}

	private getPermissionsFromFormArray(source: FormArray, type: PermissionType) {
		const ret = [];
		source.value.forEach(function(item) {
			ret.unshift({
				type: type,
				name: item['permission'],
				id: item['id'],
				target: {
					admin: item['publisher'] === 'admin',
					publisher: item['publisher'],
					website: item['website'],
				}
			});
		});

		return ret;
	}


	private loadPermissions(items: Permission[]) {
		const ret = [];
		const self = this;
		items.forEach( function(item) {
			/*
				publisher is injected with "admin [All]" in cases where the target is admin
			*/
			const perm = self.fb.group({
						permission: [item.name, [Validators.required]],
						publisher: [item.target.admin === true ? 'admin' : item.target.publisher == null ? '' : item.target.publisher, [Validators.required]],
						website: [item.target.website == null ? '' : item.target.website],
						id: [item.id]
					}, {validator: checkTarget('publisher', 'website')});
			perm.controls['website'].markAsDirty(); // mark existing item as dirty for the later validation
			ret.push(perm);
		});
		return ret;
	}

	private initPermission() {
		// we are happy that new perm is pristine so it will be caught and checked by the validator
		return this.fb.group({
					permission: ['', [Validators.required]],
					publisher: ['', [Validators.required]],
					website: [''],
					id: ['']
				}, {validator: checkTarget('publisher', 'website')});
	}

	private validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			} else if (control instanceof FormArray) {
				control.controls.forEach(item => {
					this.validateAllFormFields(<FormGroup>item);
				});
			}
		});
	}
}

