import {Component, Input} from '@angular/core';
import {ChartGroup, ChartService, ChartType, ChartTypeInterface} from '../../chart.service';
import {CoreService} from '../../core.service';
import {MetaPlotlyChartComponent} from '../meta-plotly-chart.component';

declare var Plotly: any;

@Component({
	selector: 'app-plotly-chart',
	templateUrl: './plotly-chart.component.html'
})

export class PlotlyChartComponent extends MetaPlotlyChartComponent {
	@Input() chartType: ChartType;
	chart: ChartTypeInterface;

	constructor(protected coreService: CoreService, private chartService: ChartService) {
		super(coreService);
	}

	setChartTitleIdAndLayout() {
		this.chart = this.chartService.getChartTypeInterfaceByEnum(this.chartType);
		this.title = this.title ? this.title : this.chart.title;
		this.plotId = `plotly_chart_${this.chartType}${this.title}`;
		this.layout = {autosize: true, title: this.title};

		if (this.chartType === ChartType.SIMPLE_BAR) {
			this.layout['barmode'] = 'stack';
		} else if (
			this.chartType === ChartType.BLOCK_RATE_BLOCKING_OS_BROWSER ||
			this.chartType === ChartType.BLOCK_RATE_NON_BLOCKING_OS_BROWSER) {
			this.layout['showlegend'] = false;
			this.layout['height'] = 600;
		} else if (this.chartType === ChartType.LITE_STATS) {
			this.layout['yaxis2'] = {
				ticksuffix: '$',
				overlaying: 'y',
				position: 0.99,
				side: 'right'
			};
		}
		if (this.chartType === ChartType.SIMPLE_BAR ||
			this.chartType === ChartType.SIMPLE_PIE) {
			this.layout['margin'] = [];
			this.layout['margin']['l'] = 15;
			this.layout['margin']['r'] = 15;
			this.layout['margin']['t'] = 35;
			this.layout['margin']['b'] = 10;
			this.options['displayModeBar'] = false;
		}
		if (this.chartType === ChartType.SIMPLE_BAR) {
			this.layout['margin']['l'] = 25;
			this.layout['margin']['b'] = 30;
		}

		if (this.chartType === ChartType.SIMPLE_BAR ||
			this.chartType === ChartType.SIMPLE_PIE) {
			this.layout['showlegend'] = false;
			this.layout['height'] = 300;
		}
	}

	setDifferentLayoutToPie(x) {
		if (this.chart.group === ChartGroup.PIE &&
			this.chartType !== ChartType.SIMPLE_PIE
			) {
			if (x.matches) {
				this.layout['height'] = null;
			} else {
				this.layout['height'] = 600;
			}
		}
	}

	restyleValues() {
		if (this.isChartExist()) {
			switch (this.chart.group) {
				case ChartGroup.LINE: {
					const labels = this.data[0]['x'];
					Plotly.restyle(this.plotId, 'x', [labels]);
					for (let i = 0; this.data.length > i; i++) {
						this.restyleValueWithIndex('y', i);
					}
					break;
				}
				case ChartGroup.PIE: {
					const values = this.data[0]['values'];
					Plotly.restyle(this.plotId, 'values', [values]);
					break;
				}
				case ChartGroup.BAR: {
					const websites = this.data[0]['x'];
					Plotly.restyle(this.plotId, 'x', [websites]);
					const values = this.data[0]['y'];
					Plotly.restyle(this.plotId, 'y', [values]);
				}
			}
		}
	}
}
