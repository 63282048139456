import {Injectable} from '@angular/core';
import {LoggedInUserRights, LoggedInUser} from '../core/models/data-structure';
declare function require(moduleName: string): any;

@Injectable()
export class AuthService {
	version: string;

	constructor() {
	}

	logout(): void {
		localStorage.removeItem('loggedinuser');
		location.reload();
	}

	isLoggedIn(): boolean {
		return localStorage.getItem('loggedinuser') !== null;
	}

	getUser(): LoggedInUser {
		return <LoggedInUser>JSON.parse(localStorage.getItem('loggedinuser'));
	}

	setUser(user: LoggedInUser) {
		localStorage.setItem('loggedinuser', JSON.stringify(user));
	}

	upgradeBrsUser(date) {
		const user = this.getUser();
		user.brs_upgrade = date;
		this.setUser(user);
	}

	getRights(): LoggedInUserRights {
		return this.getUser().frontend_rights;
	}

	isAllowed(url: string): boolean {
		const user = this.getUser();
		const userRights = user.frontend_rights;
		if (url.includes('aab-lite')) {
			return userRights['lite'];
		}
		if (url.includes('installation') || url.includes('debug-page')) {
			return userRights['config'];
		}
		if (url.includes('zone')) {
			return userRights['zones'];
		}
		if (url.includes('live-demo')) {
			return userRights['demo'];
		}
		if (url.includes('ad-scan')) {
			return userRights['adscan'];
		}
		if (url.includes('user-management')) {
			return userRights['edit_users'];
		}
		if (url.includes('block-rate')) {
			return user['brs_only'] || userRights['super_admin'];
		}
		return true;
	}

	isSuperAdmin(): boolean {
		const user = this.getUser();
		const userRights = user.frontend_rights;
		return userRights['super_admin'];
	}

	hasSASRight(): boolean {
		const user = this.getUser();
		const userRights = user.frontend_rights;
		return userRights['edit_sas_zones'];
	}

	// setVersionToUser(version: string): void {
	// 	const user = JSON.parse(localStorage.getItem('loggedinuser'));
	// 	user.version = version;
	// 	localStorage.setItem('loggedinuser', JSON.stringify(user));
	// }

}
