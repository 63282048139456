import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AllowedGuard} from '../../../auth/guard/allowed.guard';
import {LoggedInGuard} from '../../../auth/guard/logged-in.guard';
import {DebugPageComponent} from './debug-page/debug-page.component';
import {WebsiteDetailComponent} from './website-detail/website-detail.component';
import {CoreSelectComponent} from './core-select.component';


const websiteRoutes: Routes = [
	{path: '', component: CoreSelectComponent, children: [
					{path: 'debug-page/:aab_publisher_id/:aab_site_id', component: DebugPageComponent, canActivate: [LoggedInGuard, AllowedGuard]},
	{path: 'website-detail/:aab_publisher_id/:aab_site_id', component: WebsiteDetailComponent, canActivate: [LoggedInGuard, AllowedGuard]},
				]},
];

@NgModule({
	imports: [RouterModule.forChild(websiteRoutes)],
	exports: [RouterModule]
})
export class WebsiteRoutingModule {
}
