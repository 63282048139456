import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';

const F = /^([0-9]{1,2}(.[0-9]{1,2})?)?$/i;


export function feeValidator(): ValidatorFn {
	return (control: AbstractControl): {[key: string]: any} => {
		const fee = F.test(control.value);
		return fee ? null : {'wrongFeeValue': {value: control.value}};
	};
}

@Directive({
	selector: '[appFeeValue][ngModel]',
	providers: [{provide: NG_VALIDATORS, useExisting: FeeValidatorDirective, multi: true}]
})
export class FeeValidatorDirective implements Validator {

	validate(control: AbstractControl): {[key: string]: any} {
		return feeValidator()(control);
	}
}


