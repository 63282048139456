import { Component, Input, HostBinding, OnInit } from '@angular/core';
import { BaseInputComponent } from './base-input.component';

@Component({
	selector: 'app-multi-select-input',
	template: '<label>{{name}}</label>' +
	'<fui-multi-select id="publishers_selector"' +
	'                    class="selection"' +
	'                    [(ngModel)]="value"' +
	'                    (ngModelChange)="onChange($event)"' +
	'                    [options]="options"' +
	'                    labelField="name"' +
	'                    valueField="value"' +
	'                    placeholder="{{name}}"' +
	'                    [isSearchable]="true"' +
	'                    #multiSelect' +
	'                    >' +
	'        <fui-select-option *ngFor="let o of multiSelect.filteredOptions" [value]="o">' +
	'        </fui-select-option>' +
	'</fui-multi-select>',
	styles: [''],
})

export class MultiSelectInputComponent extends BaseInputComponent {
	@Input() options: Array<any>;
	@HostBinding('attr.class') role = 'eight wide field';

	constructor( ) {
		super();
	}

	onChange(event): void {
		this.change.emit([this.name, this.value]);
	}
}
