import {Component, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IntervalChooserData, IntervalEnum, IntervalService, PeriodsInterval} from '../interval.service';
import {ElementService} from '../element.service';
import {Subscription} from 'rxjs';


@Component({
	selector: 'app-interval-chooser',
	templateUrl: './interval-chooser.component.html',
	styleUrls: ['./interval-chooser.component.less']
})

export class IntervalChooserComponent implements OnInit, OnChanges {
	@Input() hideIntervalSwitch: boolean;
	@Input() showAbsolute ? = false;
	@Input() intervalData: IntervalChooserData;
	mode = 'date';
	intervalType: string;
	selectedPeriod: PeriodsInterval;
	periods: PeriodsInterval[] = [];
	pickerMaxDate: Date;
	dateFrom: Date;
	dateTo: Date;
	lastDateFrom: Date;
	lastDateTo: Date;
	setMessage: boolean;
	timeZone: string;
	intervals = ['1d', '1h'];
	interval: string;
	disableIntervalSwitch = false;
	radioButtonClass = 'grouped fields';
	subscription: Subscription;
	absolute = false;
	showControls = false;
	intervalHint = 'Set the granularity of shown data. Per hour is available for intervals of 14 days and shorter. ';
	@HostListener('window:resize') onResize() {
		this.checkResize();
	}

	constructor(private intervalService: IntervalService, private elementService: ElementService) {
		this.periods = this.intervalService.periods;
	}

	ngOnInit() {
		this.doChange(false);
		this.pickerMaxDate = new Date();
		this.checkResize();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.intervalData.previousValue || changes.intervalData &&
			this.intervalService.isDifferentIntervalData(changes.intervalData.currentValue, changes.intervalData.previousValue)) {
			this.setVariablesFromSharedIntervalData(true);
		}
	}

	setVariablesFromSharedIntervalData(changeIntervalOnly: boolean) {
		this.timeZone = this.intervalData.timeZone;
		this.absolute = this.intervalData.absolute;
		this.selectedPeriod = this.intervalService.getPeriodInterfaceByPeriodTypeId(this.intervalData.period);
		if (this.intervalData.intervalEnum === IntervalEnum.Date
			&& this.intervalData.period['dateFrom'] && this.intervalData.period['dateTo']) {
			this.intervalType = '0';
			this.dateFrom = this.lastDateFrom = this.intervalData.period['dateFrom'];
			this.dateTo = this.lastDateTo = this.intervalData.period['dateTo'];
			this.disableIntervalSwitch = this.intervalService.isDatesMoreThanXDays({dateFrom: this.dateFrom, dateTo: this.dateTo}, 14);
			if (changeIntervalOnly) {
				this.interval = this.intervalData.interval;
			} else {
				const isDayInterval =  this.intervalService.isDatesMoreThanXDays({dateFrom: this.dateFrom, dateTo: this.dateTo}, 14);
				this.interval = isDayInterval ? '1d' : '1h';
			}
		} else if (this.intervalData.intervalEnum === IntervalEnum.Period) {
			this.intervalType = '1';
			this.disableIntervalSwitch = this.intervalService.isPeriodMoreThan14Days(this.selectedPeriod.type);
			this.interval = changeIntervalOnly ? this.intervalData.interval : this.selectedPeriod.default_interval;
		}
	}

	onIntervalTypeChange() {
		this.doChange(false);
	}

	onChangePeriod() {
		this.doChange(false);
	}

	changeTimeZone(timeZone: string) {
		this.timeZone = timeZone;
		this.doChange(false);
	}

	onChangeDate() {
		if (this.isDatesDifferent()) {
			this.doChange(false);
		}
	}

	onChangeInterval() {
		this.doChange(true);
	}

	onChangeAbsolute() {
		this.doChange(false);
	}

	isDatesDifferent(): boolean {
		if ((!this.lastDateFrom || !this.lastDateTo || !this.dateFrom || !this.dateTo)
			|| (this.dateFrom.getTime() !== this.lastDateFrom.getTime() || this.dateTo.getTime() !== this.lastDateTo.getTime())) {
			if (this.dateFrom && this.dateTo) {
				this.lastDateFrom = new Date(this.dateFrom.getTime());
				this.lastDateTo = new Date(this.dateTo.getTime());
			}
			return true;
		} else {
			return false;
		}
	}

	isDatesValid(): boolean {
		if (this.dateFrom > this.dateTo) {
			this.setMessage = true;
			return false;
		} else {
			this.setMessage = false;
			return !!(this.dateFrom && this.dateTo);
		}
	}

	doChange(changeIntervalOnly: boolean) {
		if (this.intervalType === '0') {
			if (this.isDatesValid()) {
				this.disableIntervalSwitch = this.intervalService.isDatesMoreThanXDays({dateFrom: this.dateFrom, dateTo: this.dateTo}, 14);
				this.setDefaultInterval(changeIntervalOnly);
				const date = {'dateFrom': this.dateFrom, 'dateTo': this.dateTo};
				this.intervalService.setSharedIntervalData({intervalEnum: IntervalEnum.Date, period: date,
					timeZone: this.timeZone, interval: this.interval, absolute: this.absolute});
			}
		} else if (this.intervalType === '1') {
			this.disableIntervalSwitch = this.intervalService.isPeriodMoreThan14Days(this.selectedPeriod.type);
			this.setDefaultInterval(changeIntervalOnly);
			this.intervalService.setSharedIntervalData({intervalEnum: IntervalEnum.Period, period: this.selectedPeriod.type_id,
				timeZone: this.timeZone, interval: this.interval, absolute: this.absolute});
		}
	}

	private setDefaultInterval(changeIntervalOnly: boolean) {
		if (!changeIntervalOnly) {
			if (this.disableIntervalSwitch || this.hideIntervalSwitch) {
				this.interval = '1d';
			} else {
				this.interval = '1h';
			}
		}
	}

	// format from:  Wed Jun 27 2018 00:00:00 GMT+0200 (CEST)
	formatDate(date: Date): string {
		return date.toLocaleDateString('en-GB');
	}

	checkResize() {
		this.radioButtonClass = this.elementService.isMobileSize() ? 'inline fields' : 'grouped fields';
	}

	toggle() {
		this.showControls = !this.showControls;
	}

	close() {
		this.showControls = false;
	}

	getDesc() {
		if (this.intervalType === '0' && this.dateFrom && this.dateTo) {
			return this.formatDate(this.dateFrom) + ' - ' + this.formatDate(this.dateTo);
		}
		if (this.selectedPeriod) {
			return this.selectedPeriod.name;
		}
		return '';
	}
}
