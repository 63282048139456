import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';


export interface DemoHashResponse {
	hash: string;
}

@Injectable()
export class DemoHashResource {
	url = '/demo_hash';

	constructor(private httpClient: HttpClient) {
	}

	get(aab_publisher_id: string, aab_site_id: string): Observable<DemoHashResponse> {
		return this.httpClient.get<DemoHashResponse>(
			`${environment.apiEndpoint}${this.url}/${aab_publisher_id}/${aab_site_id}`
		);
	}
}
