export interface ButtonTypeProperties {
	name: string;
	desc: string;
	iconType: string;
	link: string;
}

export enum ButtonType {
	INSTALLATION,
	CONFIGURATION
}
