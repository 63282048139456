		<label id="publisher_select_label"><strong>Publisher:</strong></label>
		<fui-select *ngIf="isMorePublishers; else onePublisher"
					class="selection"
					id="publisher_selector"
					[(ngModel)]="selectedPublisher"
					(ngModelChange)=onChangePublisher()
					[options]="publishers"
					labelField="name"
					isSearchable="true"
					placeholder=" "
					#selectPub>
			<fui-select-option *ngFor="let option of selectPub.filteredOptions" id="select_pub_{{option.publisher_data.aab_publisher_id | cssFriendly}}"
							   [value]="option">
			</fui-select-option>
		</fui-select>
		<ng-template #onePublisher id="one_publisher_label">{{selectedPublisher.name}}</ng-template>


		<label id="website_select_label"><strong>Website:</strong></label>
		<fui-select
					class="selection"
					id="website_selector"
					[(ngModel)]="selectedWebsite"
					(ngModelChange)=onChangeWebsite()
					[options]="websites"
					labelField="name"
					isSearchable="true"
					placeholder=" "
					#selectWeb>
			<fui-select-option *ngFor="let option of selectWeb.filteredOptions" id="select_web_{{option.aab_site_id | cssFriendly}}"
							   [value]="option">
			</fui-select-option>
		</fui-select>
