<h1 class="ui header">{{title}}
<span [ngClass]="showSpinner ? 'ui small active inline loader' : 'inactive loader'"></span></h1>

<div class="ui grid">
	<div class="ui segments sixteen wide mobile twelve wide computer column">
		<div class="ui segment">
			<ng-container #controls></ng-container>
		</div>
		<app-rest-item *ngFor='let loader of itemList; index as i' class="ui container segment"
					[index]="i"
					[data]="loader"
					[fields]="fields"
					[messageIn]="messageOut"
					[messageInHandler]="itemMessageInHandler"
					[collapseIn]="collapseOut"
					(message)="listen($event)"
					>

		</app-rest-item>
	</div>
	<div class="three wide column">
		<div *ngIf="messages?.length > 0">
			<app-message *ngFor="let message of messages" [message]="message"></app-message>
		</div>
	</div>

</div>
