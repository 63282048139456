<div [formGroup]="websiteForm" id="website_form" class="ui form">

	<div *ngIf="showError" class="ui negative message">
		<span *ngIf="showError==='URL_EXISTS'">This website already exists in Antiadblock. Contact the administrators if you are the owner of this website.</span>
		<span *ngIf="showError==='URL_DOESNT_EXIST'">Url could'nt be resolved - check your typing and try again.</span>
		<span *ngIf="showError==='INVALID_URL'">Invalid url - check your typing and try again.</span>
		<span *ngIf="showError===true">Something went wrong. Try again later or contact our support.</span>
	</div>

	<div *ngIf="showSuccess && useSelectedSite" class="ui positive message">
		Updated successsfully.
	</div>
	<div *ngIf="showSuccess && !useSelectedSite" class="ui positive message">
		Created successfully.
	</div>

	<ng-container *ngIf="!noControls">

	<p *ngIf="!useSelectedSite">Create a website entry you'd like to measure block-rate on. You can later edit the website's properties or add more websites.</p>
	<div class="field">
		<label>Website Name</label>
		<div class="ui fluid input">
			<input
				type="text"
				placeholder="name"
				formControlName="name"
				name="name"
				id="name"
				class="form-control"
				 />
		</div>
		<p>Name to identify the website in this administration, you can change it later.</p>
	</div>
	</ng-container>

	<div class="required field">
		<label>Domain</label>
		<div *ngIf="websiteForm.get('url').invalid && (websiteForm.get('url').dirty || websiteForm.get('url').touched)" class="ui negative message">
			<div *ngIf="websiteForm.get('url').hasError('required')">
				The domain is required to create a website entry.
		  	</div>
			<div *ngIf="websiteForm.get('url').hasError('wrongUrlPath')">
				This domain is not valid. It should look like this: www.mysite.com
			</div>
		</div>
		<div class="ui fluid icon input">
			<i class="world icon"></i>
			<input
				type="text"
				placeholder="domain"
				formControlName="url"
				name="url"
				id="website"
				class="form-control"
				[attr.disabled]="useSelectedSite?true:null"
				 />
		</div>
		<p *ngIf="!noControls">Domain of the website i.e: www.mysite.com, it's the unique identifier of the website in this administration.
			You will add a measurement code to the website on this domain later. The domain needs to be live and accessible for public.
		</p>
		<!--<p *ngIf="noControls">Domain of the website you'd like to measure. You can later edit the website's properties or add more websites.
		</p>-->
	</div>

	<ng-container *ngIf="!noControls">
	<div class="field">
		<label>eCPM</label>
		<div class="ui fluid icon input">
			<i class="dollar icon"></i>
			<input
				type="text"
				placeholder="eCPM"
				formControlName="ecpm"
				name="ecpm"
				id="ecpm"
				class="form-control"
				 />
		</div>
		<p>Calculated as total revenue divided by total page views. It serves to calculate revenue lost to adblockers.</p>
	</div>

	<div *ngIf="!useSelectedSite">
		<button (click)="submit()" id="add_website_button" class="ui teal button" [disabled]="!websiteForm.valid || subscription || showSuccess">Create</button>
	</div>
	<div *ngIf="useSelectedSite">
		<button (click)="submit()" id="edit_website_button" class="ui teal button" [disabled]="!websiteForm.valid || subscription || showSuccess">Save</button>
	</div>
	</ng-container>
</div>
