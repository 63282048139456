import {Component, Input, OnInit, OnChanges} from '@angular/core';


@Component({
	selector: 'app-more-websites',
	templateUrl: './more-websites.component.html',
})

export class MoreWebsitesComponent implements OnInit, OnChanges {
	@Input() websites: any[];
	@Input() subscript?: string;
	fullText: string;
	moreLength = 0;
	moreText = '';
	maxShown = 5;
	minMore = 2;

	extract(subscript: string): string[] {
		return this.websites.map(m => { return m[subscript]; });
	}

	reload() {
		if (this.websites) {
			let texts = this.websites;
			if (this.subscript) {
				texts = this.extract(this.subscript);
			}
			if (this.websites.length > this.maxShown) {
				let fullLength = this.websites.length - this.minMore;
				if (fullLength > this.maxShown - this.minMore) {
					fullLength = this.maxShown - this.minMore;
				}
				this.fullText = texts.slice(0, fullLength).join(', ');
				const more = texts.slice(fullLength);
				this.moreLength = more.length;
				this.moreText = more.join(', ');
			} else {
				this.fullText = texts.join(', ');
				this.moreLength = 0;
				this.moreText = '';
			}
		}
	}

	ngOnInit() {
		this.reload();
	}

	ngOnChanges() {
		this.reload();
	}
}
