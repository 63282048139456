import {Component, HostBinding, AfterViewInit, ElementRef, ViewChild, Output, EventEmitter} from '@angular/core';
import {BaseInputComponent} from './base-input.component';


@Component({
	selector: 'app-boolean-input',
	template: '<div class="custom-checkbox"><input #input (change)="onChange($event)" type="checkbox"><label for="checkbox">' +
		'{{getTitle()}}</label></div>',
	styleUrls: ['./../rest-item/rest-item.component.less'],
})

export class BooleanInputComponent extends BaseInputComponent implements AfterViewInit {
	@HostBinding('attr.class') role = 'two wide field';
	@ViewChild('input', {static: false}) inputElement: ElementRef;
	@Output() message = new EventEmitter();

	constructor(
	) {
		super();
	}

	getInputValue(event) {
		return this.inputElement.nativeElement.checked;
	}

	ngAfterViewInit() {
		if (this.value) {
			this.inputElement.nativeElement.checked = true;
		}
	}

	onChange(event): void {
		this.value = this.getInputValue(event);
		this.change.emit([this.name, this.value]);
	}
}
