import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import * as _ from 'lodash';
import {LoggedInUser} from '../../core/models/data-structure';
import {Injectable} from '@angular/core';
import {AdminInterceptorService} from './admin-interceptor.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(private adminInterceptorService: AdminInterceptorService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		if (this.adminInterceptorService.isUrlWithApiKey(req.url)) {
			const user = <LoggedInUser>JSON.parse(localStorage.getItem('loggedinuser'));
			const authToken = _.get(user, 'api_key');
			const authReq = req.clone({
			headers: req.headers.set('Authorization', authToken)
			});
			return next.handle(authReq);
		} else {
			return next.handle(req);
		}
	}
}
