import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CoreService} from '../../core.service';


@Component({
	selector: 'app-multi-selector',
	templateUrl: './multi-selector.component.html',
})

export class MultiSelectorComponent {
	@Input() name: string;
	@Input() items: string[];
	@Input() selectedItems: string[];
	@Output() changeSelectedItems = new EventEmitter<string[]>();

	constructor(private coreService: CoreService) {
	}

	upperCaseFirstLetter(word: string) {
		return this.coreService.upperCaseFirstLetter(word);
	}

	onChangeItems() {
		this.changeSelectedItems.emit(this.selectedItems);
	}
}
