import { Component, Input } from '@angular/core';
import {Login} from '../../data-structure';

@Component({
	selector: 'app-user-login-table',
	templateUrl: './user-login-table.component.html',
})

export class UserLoginTableComponent {
	@Input() userLogin: Login[];

	private formatFailedLogin(failed: boolean): string {
		return failed ? 'Failed' : 'Success';
	}
}
