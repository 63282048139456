import { Component, Input, Output, EventEmitter} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-rest-controls',
	templateUrl: './controls.component.html',
	// styleUrls: ['./rest-item.component.less'],
})

export class ControlsComponent {
	@Input() selectedIndexes: [any];
	@Input() disable: boolean;
	@Output() add = new EventEmitter<void>();
	@Output() save = new EventEmitter<[any]>();
	@Output() delete = new EventEmitter<[any]>();
	@Output() collapse = new EventEmitter<void>();

	onAdd() {
		this.add.emit();
	}

	onSave() {
		this.save.emit(this.selectedIndexes);
	}

	onDelete() {
		this.delete.emit(this.selectedIndexes);
	}

	onCollapse() {
		this.collapse.emit();
	}
}
