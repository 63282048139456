import {Subject, Observable} from 'rxjs';
import {Website} from '../core/models/data-structure';
import { Injectable } from '@angular/core';

@Injectable()
export class SectionsService {

	private selectedSections$ = new Subject<string[]>();
	private selectedSections: string[] = [];

	setSharedSelectedSections(selectedSections: string[]): void {
		if (!this.selectedSections || selectedSections.length !== this.selectedSections.length) {
			this.selectedSections$.next(selectedSections);
			this.selectedSections = selectedSections;
		}
	}

	getSharedSelectedSectionsObservable(): Observable<string[]> {
		return this.selectedSections$;
	}

	getSelectedSections(): string[] {
		return this.selectedSections;

	}

	isSectionsAvailable(websites: Website[]) {
		if (websites && websites.length === 1) {
			const websiteSections = websites.map(w => w.sections)[0];
			return websiteSections;
		}
		return null;
	}

}
