import {Component, HostBinding} from '@angular/core';
import {BaseInputComponent} from './base-input.component';

@Component({
	selector: 'app-text-input',
	template: '<label>{{getTitle()}}</label><input [disabled]="!isEditable()" (change)="onChange($event)" type="text" value="{{value}}">',
	styles: [''],
})

export class TextInputComponent extends BaseInputComponent {
	@HostBinding('attr.class') role = 'four wide field';

	constructor( ) {
		super();
	}
}
