import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';
import {Website} from 'app/core/models/data-structure';
import {PublishersService, SelectedData} from './publishers.service';
import {MessageService} from '../shared/message.service';
import {AuthService} from 'app/auth/auth.service';


@Component({
	selector: 'app-core-filter-meta',
	template: '',
})
export class CoreFilterMetaComponent implements OnInit, OnDestroy {
	publishers$: any;
	publishers: any;
	selectedWebsites: Website[] = [];
	selectedData: SelectedData;
	dataSubscription: Subscription;
	routeSubscription: Subscription;
	noPublishers = true;
	publishersResolved = false;
	aabSitesIds: string[];
	isWebLive: boolean;
	showSpinner = false;

	constructor(protected publishersService: PublishersService,
				protected route: ActivatedRoute,
				protected messageService: MessageService,
				protected authService: AuthService,
		) {
	}

	onInitFirst() {}

	ngOnInit() {
		this.onInitFirst();
		this.showSpinner = true;
		this.publishers$ = this.publishersService.getPublishers();
		this.dataSubscription = this.publishers$.subscribe(
			pubs => {
				this.publishers = pubs;
				this.noPublishers = this.publishers ? this.publishers[0] === undefined : true;
				if (this.noPublishers) {
					this.authService.logout();
					this.messageService.setBadPublisherStatus();
				} else {
					this.publishersResolved = true;
					this.routeSubscription = this.route.queryParams.subscribe((params: Params) => {
						this.selectedData = this.publishersService.getSelectedDataFromQueryParams(params);
						this.selectedWebsites = this.publishersService.getSelectedWebsitesFromState(this.selectedData);
						this.isWebLive = this.hasSomeWebsiteLiveState(this.selectedWebsites);
						this.aabSitesIds = _.map(this.selectedWebsites, 'aab_site_id');
						this.showSpinner = false;
					});
				}
			},
			err => {
				this.authService.logout();
				this.messageService.setNoDataMessage();
			});
	}


	hasSomeWebsiteLiveState(websites: Website[]): boolean {
		for (let i = 0; i < websites.length; i++) {
			if (_.get(websites[i], 'state') === 'Live') {
				return true;
			}
		}
		return false;
	}

	ngOnDestroy() {
		if (this.dataSubscription) {
			this.dataSubscription.unsubscribe();
		}
		if (this.routeSubscription) {
			this.routeSubscription.unsubscribe();
		}
	}

}
