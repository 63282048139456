
<div class="ui grid">
	<div class="two wide column">
		<button class="ui icon collapseAll button" (click)="onCollapse()"><i class="caret up icon"></i></button>
	</div>
	<div class="fourteen wide column">
		<button [ngClass]="disable ? 'ui icon labeled teal disabled button' : 'ui icon labeled teal button'" (click)="onAdd()"><i class="plus icon" id="add-control"></i>Add</button>
		<button [ngClass]="disable ? 'ui icon labeled teal disabled saveAll button' : 'ui icon labeled teal saveAll button'" (click)="onSave()"><i class="save outline icon"></i>Save All</button>
		<button [ngClass]="disable ? 'ui icon labeled red disabled deleteAll button' : 'ui icon labeled red deleteAll button'" (click)="onDelete()"><i class="trash alternate outline icon"></i>Delete All</button>
	</div>
</div>
