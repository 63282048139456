import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';


@Component({
	selector: 'app-multi-checkbox',
	templateUrl: './multi-checkbox.component.html',
})

export class MultiCheckboxComponent implements OnChanges {
	@Input() name: string;
	@Input() items: string[];
	@Input() selectedItems?: string[];
	@Output() changeSelectedItems = new EventEmitter<string[]>();
	finalItems: { name: string; 'checked': boolean }[];

	ngOnChanges(changes: SimpleChanges) {
		const final = [];
		if (this.items) {
			this.items.forEach(i => {
				if (!this.selectedItems || this.selectedItems.includes(i)) {        // if there is no selectedItems set all to true
					final.push({'name': i, 'checked': true});
				} else {
					final.push({'name': i, 'checked': false});
				}
			});
		}
		this.finalItems = final;
	}


	onChangeItem() {
		const selected = this.finalItems.filter(i => i.checked === true).map(i => i.name);
		this.changeSelectedItems.emit(selected);
	}
}
