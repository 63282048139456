import {OnInit, Component} from '@angular/core';
import {MenuComponent} from './menu.component';
import {ElementService} from '../../shared/element.service';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';

@Component({
	selector: 'app-top-menu',
	templateUrl: './top-menu.component.html',
	styleUrls: ['./top-menu.component.less']
})
export class TopMenuComponent extends MenuComponent implements OnInit {
	containerBg = false;

	constructor(elementService: ElementService, route: ActivatedRoute, router: Router) {
		super(elementService, route, router);
	}

	setLayout() {
		this.containerBg = false;
		if (location.pathname.startsWith('/block-rate')) {
			if (location.pathname.includes('faq') || location.pathname.includes('change-password')) {
				this.containerBg = true;
			}
		}
	}

	ngOnInit() {
		super.ngOnInit();

		this.router.events.subscribe( (event) => {
			if (event instanceof NavigationEnd) {
				this.setLayout();
			}
		});
		this.setLayout();
	}
}
