<div class="one column stackable ui grid">
	<div class="seven wide column installation-text">
		<p>The following documents will explain in short the needed setup for antiadblock - full deployment, and will
			lead you through the installation process. The full solution requires some setup on your reverse-proxy
			and/or backend servers as well as the insertion of a <code>detection</code> script in the HTML of your page.
			It is best suited for enterprise sized publishers with their own marketing and development teams.
		</p>
		<div class="ui icon message faded">
  			<i class="question icon"></i>
			<p>
				We also offer Antiadblock LITE, a simpler and limited solution that suits smaller blog-like publishers.
				If you are interested in this solution as well, please contact our customer service at
				<a href="mailto:support@antiadblock.com">support@antiadblock.com</a>
			</p>
		</div>
		<p>The configuration parameters are the same for all websites of one publisher, but the definitive configuration
		depends on the overall setup of your application and may be different for each of your websites. All information in
		these documents serve as basic guidelines for the necessary setup of the service.
		</p>
		<div class="ui icon warning message">
			<i class="warning sign icon"></i>
			We highly recommend testing the proxy setup in a development or staging environment.<br>
			We strongly discourage you from experiments with a proxy on a live website, as this can easily break
			the functionality for all incoming traffic.
		</div>
		<p>The <em>overview</em> contains the necessary setup parameters for your proxy and describes in short how your proxy should handle
		incoming requests to cooperate with antiadblock. It also describes in short the behavior of the resulting system
		and explains what antiadblock does to the html code of your pages.
		</p>
		<p>The <em>nginx.conf</em> is an example of nginx configuration that you may use as a starting point for
		configuring your proxy. For some simple infrastructure setups it contains all that needs to be done, but for some more complicated
		setups you might need to customize it.
		</p>
		<p>
		The setup should be done in these main steps. First in your development environment and then replicated to your
		production environment.
		<p>
			<ol>
			<li>Setup the proxy</li>
			<li>Verify the proxy setup</li>
			<li>Verify the web functionality with Antiadblock</li>
			<li>Insert the detection script in the html</li>
			</ol>
		<p>If you encounter any problems during the setup or have any questions, please contact us at
			<a href="mailto:support@antiadblock.com">support@antiadblock.com</a>
		</p>
	</div>
	<div *ngIf="selectedPublishers && selectedPublishers.length > 0" class="seven wide column stackable padded">
		<div class="ui stackable vertically padded grid container"
			 id="install_config_buttons"
			 *ngFor='let p of selectedPublishers; let i = index'>
			<div class="row">
				<div class="three wide column">
					<b>{{p?.publisher_data?.name}}:</b>
				</div>
				<div class="seven wide column">
					<app-installation-link [selectedPublisherAabId]='p.publisher_data.aab_publisher_id' [selectedPublisherName]='p.publisher_data.name' [buttonType]='installationType' [index]='i'></app-installation-link>
				</div>
				<div class="six wide column">
					<app-installation-link [selectedPublisherAabId]='p.publisher_data.aab_publisher_id' [selectedPublisherName]='p.publisher_data.name' [buttonType]='configurationType' [index]='i'></app-installation-link>
				</div>
			</div>
		</div>
	</div>

	<div class="ten wide column installation-text">
		<h2 class="ui header" #target_1 style="padding-top: 3rem"  id="setup" >
		<a [routerLink]="['.']" href="#setup" fragment="setup" queryParamsHandling="merge" (click)="scrollToElement(target_1)"  >
				1. Setup the proxy
				<i class="linkify icon small"></i>
			</a>
		</h2>
		<p>The proxy needs to handle incoming traffic based on cookies and must be able to fall back to normal content
			delivery based on the return status from antiadblock.
		</p>

		<h3 class="ui header">Incoming traffic</h3>
		<p>All requests containing the cookie adb=1 should be proxied to antiadblock upstream following this scheme:
		<code>www.yourdomain.com/path/page.html -> _publisher_.antiadblock.[cz|com|...]/path/page.html</code>
		</p>
		<p>
			The exact url of the antiadblock endpoint is contained in the overview document of the particular publisher.
		</p>
		<h3 class="ui header">Adding http headers</h3>
		<p>All requests sent from your proxy to antiadblock should contain the following http headers in addition to those
			required without antiadblock.
		</p>
		<ul>
			<li>Host: domain of origin</li>
			<li>X-Auth: passphrase</li>
			<li>X-Client: publisher name</li>
			<li>If the user visited your site over https, then also:<br>
			X-Forwarded-Ssl: on</li>
		</ul>
		<p>The exact values can be found in th overview document of the particular publisher.</p>
		<h3 class="ui header">Antiadblock response</h3>
		<p>Your proxy should fall back to normal content delivery upstream when antiadblock returns status 4xx or 5xx.
		</p>
		<p>
		These statuses should not be considered as errors for purposes of determining antiadblock health. Some of them
		have special meanings, others are just proxied statuses of a third party system.
		Your proxy should determine antiadblock as unhealthy and stop sending traffic only on connect fail.
		</p>
		<p>Antiadblock uses the following status codes with the following meanings</p>
		<ul>
			<li>403 - processing of this request is not necessary, fall back to normal delivery
			</li>
			<li>299 - no content, 3rd party timeout when processing an obfuscated url. No need to fall back to normal delivery
			because your proxy can't decode the obfuscated url. Something will probably be broken.
			</li>
		</ul>
		<h2 class="ui header" #target_2 style="padding-top: 3rem" id="verify-proxy">
			<a [routerLink]="['.']" href="#verify-proxy" fragment="verify-proxy" queryParamsHandling="merge" (click)="scrollToElement(target_2)">
				2. Verify the proxy setup
				<i class="linkify icon small"></i>
			</a>
		</h2>
		<p>
		Once the proxy of your website is set up, you may use the debug page of this admin to check if everything is ready.
		Go to Dashboard and click on the Debug Page field next to the website you want to check.
		</p>
		<p>
		The Debug Page contains curl commands that should be executed against your proxy and return desired http status
		codes. You may use the play button on the Debug Page to execute the scripts from our servers, or copy the
		commands and execute them locally in your command line.
		</p>
		<h2 class="ui header" #target_3 style="padding-top: 3rem" id="verify-functionality">
			<a [routerLink]="['.']"  href="#verify-functionality" fragment="verify-functionality" queryParamsHandling="merge" (click)="scrollToElement(target_3)">
				3. Verify the web functionality with Antiadblock
				<i class="linkify icon small"></i>
			</a>
		</h2>
		<p>If all the curl commands return the correct http status, you should check the functinality of your site with antiadblock.
		Go to your web, set the cookie adb manually in the console of your browser (<code>document.cookie="adb=1; path=/"</code>)
		and check everything on your web works as expected. It is common to check these features (if your page emplys them):
		</p>
		<p>While reviewing your site make sure the proxying through antiadblock is in use. Open the developer console
		of your browser and in the network tab look at the headers of the main html request. You should see an
		<code>X-Nginx-Proxy: ...</code> header. It's content may vary depending on your country and target machine our
		loadbalancer sends this particular request to.
		</p>

		<ul>
			<li>Layout of the page, subpages, galleries, etc.</li>
			<li>Functionality of your on-page search</li>
			<li>User login and logout</li>
			<li>Discussions and comments</li>
		</ul>
		<h2 class="ui header" #target_4 style="padding-top: 3rem" id="insert-detection">
			<a [routerLink]="['.']"  href="#insert-detection" fragment="insert-detection" queryParamsHandling="merge" (click)="scrollToElement(target_4)">
				4. Insert the detection script in the html
				<i class="linkify icon small"></i>
			</a>
		</h2>
		Now is the time to insert the detection script in the html of your page. The detection script should be regularly
		updated from the location mentioned in the <em>overview</em> and stored locally on your machines. It should be
		embedded inline in the html of your pages in a script tag i.e.
		<code>&lt;script&gt; ... content of the detection script ... &lt;/script&gt;</code>
		<div class="ui icon warning message">
			<i class="warning sign icon"></i>
			The detection script must not be included like &lt;script src="....." &gt;&lt;/script&gt; or via google tag
			manager or similar ways because then it would be easy to block the detection script and disable antiadblock
			right away.
		</div>
	</div>
</div>
