<div *ngIf="selectedWebsites" class="ui segment">
	<table class="ui very basic table" id="websites-table">
	  <thead>
		<tr>
			<th class="pointer" (click)="sortTable('name')" id="table_sort_name">
				<div class="content">
					Publisher
					<div class="sub header">Website Name
						<i [ngClass]="(column?.match('name') && isDesc) ? 'sort alphabet ascending icon' : (column?.match('name') && !isDesc) ? 'sort alphabet descending icon' : 'sort icon'"> </i>
					</div>
				</div>
			</th>
			<th class="pointer" (click)="sortTable('state')" id="table_sort_state">
				State
					<i [ngClass]="(column?.match('state') && isDesc) ? 'sort alphabet ascending icon' : (column?.match('state') && !isDesc) ? 'sort alphabet descending icon' : 'sort icon'"> </i>
			</th>
			<th class="pointer" (click)="sortTable('adservers')" id="table_sort_adservers">
				Ad Servers
					<i [ngClass]="(column?.match('adservers') && isDesc) ? 'sort alphabet ascending icon' : (column?.match('adservers') && !isDesc) ? 'sort alphabet descending icon' : 'sort icon'"> </i>
			</th>
			<th></th>
			<th *ngIf="configRight"></th>
		</tr>
	  </thead>
	  <tbody>
			<tr *ngFor="let web of selectedWebsites | orderBy: {property: column, direction: direction}">
			  <td>
				<div class="content">
					{{web?.publisher?.name}}
					<div class="sub header">
						{{web?.name}}
					</div>
				</div>
			  </td>
			 <td id="table_state_{{web?.aab_site_id | cssFriendly}}">
				 {{web?.state}}
			 </td>
			 <td id="table_adserver_{{web?.aab_site_id | cssFriendly}}">
				 {{web?.adservers}}
			 </td>
				<td class="top aligned" style="text-align:center;">
					<a class="ui tiny teal button" id="table_detail_{{web?.aab_site_id | cssFriendly}}" [routerLink]="['/website/website-detail', web?.publisher.aab_publisher_id, web?.aab_site_id]" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|Debug page">
						<i id="detail_button" class="info circle icon"></i>DETAIL</a>
			  </td>
			  <td *ngIf="configRight" class="top aligned" style="text-align:center;">
					<a class="ui tiny teal button" id="table_debug_page_{{web?.aab_site_id | cssFriendly}}" [routerLink]="['/website/debug-page', web?.publisher.aab_publisher_id, web?.aab_site_id]" routerLinkActive="active" queryParamsHandling="merge" i18n="main menu|Debug page">
						<i id="debug_button" class="cogs icon"></i>DEBUG</a>
			  </td>
			</tr>
	  </tbody>
	</table>
</div>
