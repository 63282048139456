import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {MessageService, MessageState} from '../../shared/message.service';
import {Observable} from 'rxjs';
import {AdminInterceptorService} from './admin-interceptor.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

	constructor(private authService: AuthService,
				private messageService: MessageService,
				private adminInterceptorService: AdminInterceptorService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		if (this.adminInterceptorService.isUrlWithApiKey(req.url)) {
			return next.handle(req).pipe(
				catchError((err: any, caught: Observable<HttpEvent<any>>) => {
					if (err.status === 401) {
						this.messageService.setMessage({
							state: MessageState.NEGATIVE, header: 'Please login again.',
							description: 'Your session has expired.'
						});
						this.authService.logout();
					}
					return next.handle(req);
				})
			);
		} else {
			return next.handle(req);
		}
	}



}
