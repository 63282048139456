import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {LoggedInUserRights, Website} from 'app/core/models/data-structure';
import {IntervalChooserData, IntervalService} from '../../../../shared/interval.service';
import {CoreService} from '../../../../shared/core.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ChartResource} from '../../../../resources/chart.resource';
import {PublishersService} from '../../../publishers.service';
import {UltraLiteResource} from '../../../../resources/ultra-lite.resource';
import {ImpressionsResource} from '../../../../resources/impressions.resource';
import {AuthService} from '../../../../auth/auth.service';

@Component({
	selector: 'app-impressions-segment',
	templateUrl: './impressions-segment.component.html',
	styleUrls: ['./impressions-segment.component.less']
})

export class ImpressionsSegmentComponent implements OnInit, OnChanges, OnDestroy {
	@Input() websites: Website[];
	aabSiteIds: string[];
	canSeeChart = false;
	intervalData: IntervalChooserData;
	intervalDataSubscription: Subscription;
	fragmentSubscription: Subscription;
	showPlotly = false;
	showDimmer = false;
	adServers: string[] = null;
	oldChartDataOk =  false;
	liteChartDataOk = false;
	ultraLiteChart = {name: 'ultraLite', title: 'Ultra Lite'};
	ultraLiteTable = {name: 'ultraLiteTable', sortBy: 'date'};
	impressionsChart = {name: 'impressions', title: 'Impressions'};
	impressionsTable = {name: 'simpleTable', sortBy: 'date'};
	adSystems: string[] = null;
	selectedAdSystems: string[];
	selectedAdServers: string[];
	metadataUltraLite: { adsystems: string[] } = {adsystems: null};
	metadataImpressions: { adservers: string[] } = {adservers: null};
	rights: LoggedInUserRights;

	constructor(
		private coreService: CoreService,
		private intervalService: IntervalService,
		private route: ActivatedRoute,
		private chartResource: ChartResource,
		private publishersService: PublishersService,
		private ultraLiteResource: UltraLiteResource,
		private impressionsResource: ImpressionsResource,
		private authService: AuthService,
		) {
	}

	ngOnInit(): void {
		this.rights = this.authService.getRights();
		this.intervalDataSubscription = this.intervalService.getSharedIntervalDataObservable().subscribe((intervalData => {
			if (this.intervalService.isDifferentIntervalData(intervalData, this.intervalData)) {
				this.intervalData = intervalData;
				this.setAabSiteIds();
			}
		}));
		this.fragmentSubscription = this.route.fragment.subscribe(fragment => {
			this.showPlotly = 'impressions' === fragment;
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.canSeeChart = this.coreService.isLessThan100(this.websites);
		this.intervalData = this.intervalService.getSharedIntervalData();
		if (this.coreService.isChangeInFilterOfWebsites(changes)) {
			this.setAabSiteIds();
		}
		if (this.aabSiteIds && this.coreService.isLessThan100(this.aabSiteIds)) {
			this.getAvailableAdSystems();
			this.getAvailableAdServers();
		}
	}

	getAvailableAdServers() {
		this.impressionsResource.postMetadata(this.aabSiteIds, this.intervalData).subscribe(res => {
			this.adServers = res['adservers_available'];
			this.changeAdServers(this.adServers);
		});
	}

	getAvailableAdSystems() {
		this.ultraLiteResource.postMetadata(this.aabSiteIds, this.intervalData).subscribe(res => {
			this.adSystems = res['adsystems_available'];
			this.changeAdSystems(this.adSystems);
		});
	}

	setAabSiteIds() {
		if (this.coreService.isLessThan100(this.websites)) {
			this.aabSiteIds = this.websites.map(w => w.aab_site_id);
		}
	}

	changeLiteChartDataOk(liteChartDataOk: boolean) {
		this.liteChartDataOk = liteChartDataOk;
	}

	changeImpChartDataOk(impChartDataOk: boolean) {
		this.oldChartDataOk = impChartDataOk;
	}

	changeAdSystems(adSystems: string[]) {
		this.selectedAdSystems = adSystems;
		this.metadataUltraLite = {'adsystems': this.selectedAdSystems};
	}

	changeAdServers(adServers: string[]) {
		this.selectedAdServers = adServers;
		this.metadataImpressions = {'adservers': this.selectedAdServers};
	}

	ngOnDestroy() {
		if (this.intervalDataSubscription) {
			this.intervalDataSubscription.unsubscribe();
		}
		if (this.fragmentSubscription) {
			this.fragmentSubscription.unsubscribe();
		}
	}

}
