import {Component, HostBinding} from '@angular/core';
import {BaseInputComponent} from './base-input.component';

@Component({
	selector: 'app-number-input',
	template: '<label>{{name}}</label><input (change)="onChange($event)" type="number" value="{{value}}">',
	styles: [''],
})

export class NumberInputComponent extends BaseInputComponent {
	@HostBinding('attr.class') role = 'four wide field';

	constructor( ) {
		super();
	}
}
