import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LoggedInUser} from '../core/models/data-structure';
import {AuthService} from '../auth/auth.service';


export interface ChangePasswordResponse {
	ok: boolean;
	message: string;
}


@Injectable()
export class UserResource {
	url = '/user';

	constructor(private httpClient: HttpClient, private authService: AuthService) {
	}

	login(email: string, password: string): Observable<LoggedInUser> {
		return this.httpClient.post<LoggedInUser>(
			environment.apiEndpoint + '/login',
			{email: email, password: password}
		)
			.pipe(map(response => {
				this.authService.setUser(response);
				return response;
			}));
	}

	change_password(current_password: string, new_password: string, confirm_password: string): Observable<ChangePasswordResponse> {
		return this.httpClient.post<ChangePasswordResponse>(
			environment.apiEndpoint + this.url + '/change_password',
			{current_password: current_password, new_password: new_password, confirm_password: confirm_password}
		);
	}

	reloadUser(): Observable<LoggedInUser> {
		return this.httpClient.get<LoggedInUser>(
			environment.apiEndpoint + this.url + '/reload_user'
		)
			.pipe(map(response => {
				this.authService.setUser(response);
				return response;
			}));
	}



}

