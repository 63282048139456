<div *ngIf="loaderList">
	<div class="ui stackable vertically padded grid container" id="loaders_list">
		<div class="sixteen wide column ui grid">
			<div class="one wide column">
				Version
			</div>
			<div class="two wide column">
				Release date
			</div>
			<div class="three wide column">
				Get the code
			</div>
			<div class="seven wide column">
				Release notes
			</div>
			<div class="two wide column">
				View code
			</div>
		</div>
	</div>
	<app-loader-link *ngFor='let loader of loaderList;' class="ui padded grid container" [loader]="loader"></app-loader-link>
</div>
