import { Component, ComponentFactoryResolver } from '@angular/core';
import { MessageService } from 'app/shared/message.service';
import { FaqResource } from '../../../resources/faq.resource';
import { InputFieldContainer, ItemActions, ItemMessage, RestComponent, SelectOptions } from '../rest/rest.component';
import { forkJoin } from 'rxjs';
import { InputField } from '../rest/rest.component';

@Component({
	selector: 'app-faq-management',
	templateUrl: '../rest/rest.component.html',
})

export class FaqManagementComponent extends RestComponent {
	title = 'Faq Management';
	itemName = 'faq';
	desc = 'To add new FAQs, please save FAQ category above first.';
	fields = this.makeFields();
	constructor(public injected_resource: FaqResource,
				public messageService: MessageService,
				public componentFactoryResolver: ComponentFactoryResolver,
				) {
				super(messageService, componentFactoryResolver);
	}

	makeFields() {
		const fields: InputFieldContainer = {
			container: [
				{ name: 'slug', type: 'text', value: null},
				{ name: 'category', type: 'select', value: null, options: this.categories},
				{ name: 'disabled',	type: 'boolean', value: null},
				{ name: 'order', type: 'number', value: null},
			],
			underline: [
				{ name: 'title', type: 'longText', value: null},
				{ name: 'text',	type: 'longText', value: null},
		]};
		return fields;
	}
	createForm(): void {
		this.getFormData().subscribe(res => {
			this.itemList = res[0]['faqs'];
			for (const item in this.itemList) {
				if (this.itemList.hasOwnProperty(item)) {
					this.selectedIndexes.push(false);
				}
			}
			// copy only the contents, must not replace the original array
			// this.categories.push({'name': '-', 'value': null});
			for (const cat of res[1]['faq_categories']) {
				this.categories.push({'name': cat.title, 'value': cat.id});
			}
			this.showSpinner = false;
		});
	}

	getFormData() {
		return forkJoin(
			[this.resource.getItems(this.fixed),
			this.resource['listFaqCategories']()],
		);
	}
}


