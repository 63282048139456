<fui-modal *ngIf="show" [isClosable]="false" (approved)="onApproved()" (dismissed)="onDismissed()" #modal>
    <div class="header">Delete</div>
    <div class="content">
        <p>Do you really want to delete {{name}}?</p>
    </div>
    <div class="actions">
        <button class="ui button" (click)="modal.deny(); onCancel();">No</button>
        <button class="ui red button" (click)="modal.approve();" autofocus>Yes</button>
    </div>
</fui-modal>
