import {OnInit, Component, OnDestroy} from '@angular/core';
import {BrsSite} from '../models/data-structure';
import {Observable, Subscription} from 'rxjs';
import {PublishersService} from '../publishers.service';
import {AuthService} from '../../auth/auth.service';
import {BlockRateService} from './block-rate.service';
import {IntervalService, IntervalChooserData} from 'app/shared/interval.service';
import {CoreService} from '../../shared/core.service';
import {ChartType} from 'app/shared/chart.service';
import {ChartResource} from 'app/resources/chart.resource';
import {LoggedInUser} from '../../core/models/data-structure';


@Component({
	selector: 'app-block-rate',
	templateUrl: './block-rate.component.html',
	styleUrls: ['./block-rate.component.less'],
})

export class BlockRateComponent implements OnInit, OnDestroy {
	brsSites: BrsSite[];
	verifyDomainSubscription: Subscription;
	deleteBrsSiteSubscription: Subscription;

	selectedSiteSubscription: Subscription;
	intervalDataSubscription: Subscription;
	showAbsoluteValues = true;
	showDeleteDialog = false;

	intervalData: IntervalChooserData;
	openNewWebsite = true;
	selectedSite$: Observable<BrsSite>;
	brs: BrsSite;
	csv: any;
	formatedCsv: any;
	sumCsv = {allowed_pvs: 0, allowed_perc: 0, blocked_pvs: 0, blocked_perc: 0};
	total: any = {'all': 0, 'blocking': 0, 'non_blocking': 0};
	chartTypeBar = ChartType.SIMPLE_BAR;
	chartTypePie = ChartType.SIMPLE_PIE;
	chartSubscriptionBar: Subscription;
	chartSubscriptionPie: Subscription;
	plotlyDataBar: any;
	plotlyDataPie: any;
	dayInterval = 0;
	upgradePending: boolean | null = null;
	upgradeSuccess: boolean;
	user: LoggedInUser;


	constructor(private publishersService: PublishersService,
				private blockRateService: BlockRateService,
				private intervalService: IntervalService,
				private coreService: CoreService,
				private chartResource: ChartResource,
				private authService: AuthService) {
	}

	ngOnInit() {
		this.selectedSite$ = this.blockRateService.getSelectedSite();
		this.intervalDataSubscription = this.intervalService.getSharedIntervalDataObservable().subscribe((intervalData => {
			this.intervalData = intervalData;
			// subscribe to selectedSite - a BehaviorSubject, we can subscribe late without problems
			this.selectedSiteSubscription = this.selectedSite$.subscribe((ret) => {
				this.brs = ret;
				this.getChartData();
			});
		}));
		this.user = this.authService.getUser();
	}

	getChartData() {
		this.getChartDataBar();
	}

	getChartDataBar() {
		if (!(this.brs && this.intervalData)) {
			return;
		}
		if (this.chartSubscriptionBar) {
			this.chartSubscriptionBar.unsubscribe();
		}
		this.chartSubscriptionBar = this.chartResource.post('blockrate_bar', [], [this.brs.aab_site_id], this.intervalData).subscribe(res => {
				this.plotlyDataBar = res.data;
				if (this.plotlyDataBar) {
					this.plotlyDataBar[0].hovertemplate = '%{y} PV<extra></extra>';
					this.plotlyDataBar[1].hovertemplate = '%{y} PV<extra></extra>';

					const dataSlots = this.plotlyDataBar[0].x.length;
					if (this.intervalData.interval === '1d') {
						this.dayInterval = dataSlots;
					} else {
						this.dayInterval = Math.ceil(dataSlots / 24);
					}
				}

				this.csv = res.csv;
				if (this.csv) {
					this.formatCsv(res.csv);
				}

				if (res.total) {
					this.total = res.total;
				} else {
					this.total = {non_blocking: 0, blocking: 0};
				}
				this.total['all'] = this.total['non_blocking'] + this.total['blocking'];

				this.setDataPie(this.total);
		});
	}

	setDataPie(total) {
		const data = [{
			sort: false,
			opacity: 0.95,
			textposition: 'inside',
			direction: 'clockwise',
			labels: ['Blocking\n' + total['blocking'], 'Allowing\n' + total['non_blocking']],
			// textfont: {color: "rgb(243, 243, 243)"},
			values: [ total['blocking'] / total['all'], total['non_blocking'] / total['all'] ],
			textinfo: 'percent',
			type: 'pie',
			hole: .7,
			name: 'Block-Rate',
			marker: {colors: ['rgb(243,105,105)', 'rgb(165,229,158)'] },
			hoverinfo: 'label',
		}];
		this.plotlyDataPie = data;
	}

	formatCsv(data) {
		const dailyData = true;
		if (!data || !data.length) {
			return;
		}
		this.formatedCsv = [];
		const names = ['date', 'allowed_pvs', 'blocked_pvs'];
		let copy = [0, 5, 3];
		if (data[0].length === 7) {
			copy = [0, 1, 6, 4];
			names.splice(1, 0, 'time');
		}

		for (let i = 1; i < data.length; i++) {
			const row = data[i];
			const outRow = {};
			let counter = 0;
			for (const j of copy) {
				outRow[names[counter]] = row[j];
				counter++;
			}
			const total = outRow['allowed_pvs'] + outRow['blocked_pvs'];
			outRow['allowed_perc'] = outRow['allowed_pvs'] / total * 100;
			outRow['blocked_perc'] = outRow['blocked_pvs'] / total * 100;
			this.formatedCsv.push(outRow);
			this.sumCsv.allowed_pvs += outRow['allowed_pvs'];
			this.sumCsv.blocked_pvs += outRow['blocked_pvs'];
		}
		const totalSum = this.sumCsv.allowed_pvs + this.sumCsv.blocked_pvs;
		this.sumCsv.allowed_perc = this.sumCsv.allowed_pvs / totalSum * 100;
		this.sumCsv.blocked_perc = this.sumCsv.blocked_pvs / totalSum * 100;

	}

	getVerifyText(verify: boolean): string {
		if (verify) {
			return 'VERIFIED';
		} else {
			return 'NOT VERIFIED';
		}
	}

	verifyBrsSite() {
		return this.blockRateService.verifyBrsSite(this.brs);
	}

	requestUpgrade() {
		if (!this.upgradePending) {
			this.upgradePending = true;
			this.blockRateService.requestUpgrade(this.brs).subscribe(ret => {
				if (ret.success) {
					this.upgradeSuccess = true;
					// this.user.brs_upgrade = ret.brs_upgrade;
				} else {
					this.upgradeSuccess = false;
				}
				this.upgradePending = false;
			});
		}
	}

	openLastTabWhenNoSites() {
		if (this.brsSites && this.brsSites.length < 0) {
				this.openNewWebsite = false;
		}
	}

	deleteBrsSite(brs) {
		this.blockRateService.deleteBrsSite(brs);

	}

	ngOnDestroy() {
		if (this.verifyDomainSubscription) {
			this.verifyDomainSubscription.unsubscribe();
		}
		if (this.deleteBrsSiteSubscription) {
			this.deleteBrsSiteSubscription.unsubscribe();
		}
		if (this.selectedSiteSubscription) {
			this.selectedSiteSubscription.unsubscribe();
		}
	}
}
