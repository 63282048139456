import {HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AdminInterceptorService} from './admin-interceptor.service';
import {AuthService} from '../../auth/auth.service';
import {environment} from 'environments/environment';
import {MessageService, MessageState} from '../../shared/message.service';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
	private bothVersions: string;
	private adminVersion: string;
	private apiVersion: string;

	constructor(private adminInterceptorService: AdminInterceptorService,
				private authService: AuthService,
				private messageService: MessageService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler) {

		return next.handle(req)
			.pipe(
				tap(event => {
						if (event instanceof HttpResponse) {
							this.bothVersions = event.headers.get('x-api-version');
							if (this.bothVersions) {
								this.setVersions(this.bothVersions);
							}
						}
					})
			);
	}

	private setVersions(bothVersions: string) {
		const versions = bothVersions.split(',');
		this.apiVersion = versions[0];
		this.adminVersion = versions[1];
	}

}
