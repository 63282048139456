import { Injectable } from '@angular/core';
export enum MessageState {
	NEGATIVE,
	POSITIVE,
	INFO,
	WARNING,
}

export interface Message {
	state: MessageState;
	header: string;
	description?: string;
	width?: string;
}

const NO_DATA_MESSAGE = {
	state: MessageState.INFO, header: 'Please login again.',
	description: 'You were logged out because of loading new application data.'
};

const BAD_PUBLISHER_STATUS = {
	state: MessageState.INFO, header: 'Your account with Antiadblock has been disabled.',
	description: 'Please contact our sales team for further information.'
};

const SystemMessages = {
	blockrate: [{
			state: MessageState.NEGATIVE,
			header: 'Block rate out of order',
			description: 'Due to a global update in blocking filters, our block-rate measurements are out of order.\n' +
			'\nWe are still able to gather some data but it is highly unreliable.\n' +
			'\nThe problem should be fixed by\xa021.\xa010. Sorry for the inconvenience.',
			valid: {to: '2019-10-22'}
		},
		{
			state: MessageState.INFO,
			header: 'Block rate fixed',
			description: 'We managed to fix the blockrate measurements. Should you encounter any irregularities, ' +
				'please let us know.',
			valid: {from: '2019-10-22', to: '2019-10-25'}
		}]
};

@Injectable()
export class MessageService {

	static getSystemMessages(location: string): Message[] {
		const available = SystemMessages[location];
		const now = new Date();
		const messages = [];
		let msg;

		if (!available) { return []; }

		for (let i = 0; i < available.length; i++) {
			msg = available[i];
			if (msg.valid) {
				let valid_to, valid_from;
				if (msg.valid.to) {
					valid_to = false;
					const to = new Date(Date.parse(msg.valid.to));
					if (now <= to) {
						valid_to = true;

					}
				}
				if (msg.valid.from) {
					valid_from = false;
					const from = new Date(Date.parse(msg.valid.from));
					if (from <= now) {
						valid_from = true;
					}
				}
				if (valid_to && valid_from) {
					messages.push(msg);
				}
			}
		}
		return messages;
	}

	getNoSectionMessage(website_name: string): Message {
		return { state: MessageState.INFO, header: 'No website sections available.',
		description: 'For make available website sections, please choose ' + website_name + ' website only.'};
	}

	getMessage(): Message {
		return JSON.parse(localStorage.getItem('message'));
	}

	setMessage(message: Message) {
		localStorage.setItem('message', JSON.stringify(message));
	}

	setNoDataMessage() {
		this.setMessage(NO_DATA_MESSAGE);
	}

	setBadPublisherStatus() {
		this.setMessage(BAD_PUBLISHER_STATUS);
	}

	removeMessage() {
		localStorage.removeItem('message');
	}
}
