<h1 class="ui header">Fees Management <span [ngClass]="showSpinner ? 'ui small active inline loader' : 'inactive loader'"></span></h1>
<div class="ui segments">
	<div class="ui segment">
		<app-fee-filter-publisher-website
					  id="fee_filter"
					  *ngIf="!noPublishers"
                      [publishers]="publishers"
					  (changeFilter)="changeFilter($event)">
		</app-fee-filter-publisher-website>

	</div>
	<div class="ui segment">
		<button title="Add Fee" class="ui primary labeled icon button" (click)="showAddFeeModal = true" [disabled]="!isEditable">
			<i class="plus icon"></i> Add Fee
		</button>
	</div>
	<div class="ui segment">
		<app-message *ngIf="message" [message]="message"></app-message>
		<app-fee-table *ngIf="feeTimeLines && lastFee" [feeTimeLines]="feeTimeLines" [isEditable]="isEditable" (messageChange)="changeMessage($event)" (feesChange)="changeFees()"></app-fee-table>
	</div>
	<div *ngIf="pages" class="ui segment">
        <fui-pagination [(collectionSize)]="pages.length * pageSize"
                        [pageSize]="pageSize"
                        [hasNavigationLinks]="true"
                        [(page)]="selectedPage"
                        (pageChange)="pageChange()">
        </fui-pagination>
    </div>
</div>
<app-action-fee-modal *ngIf="showAddFeeModal" [fee]="feeToAdd" [actionType]="addType" [addNewFee]="true" [lastDate]="lastFee.date"
					  (onMessage)="sendMessage($event)" (onChangeFees)="changeFees()" (onClose)="showAddFeeModal=false;"></app-action-fee-modal>
