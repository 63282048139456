import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Publisher, Website} from '../../../models/data-structure';

@Component({
	selector: 'app-fee-filter-publisher-website',
	templateUrl: './fee-filter-publisher-website.component.html',
})

export class FeeFilterPublisherWebsiteComponent  implements OnInit {
	@Input() publishers: Publisher[] = [];
	@Output() changeFilter = new EventEmitter<{publisher: Publisher, website: Website}>();
	selectedPublisher: Publisher;
	selectedWebsite: Website;
	websites: Website[] = [];
	allPublisher: Publisher;
	defaultPublisher: Publisher;
	eyeoPublisher: Publisher;
	fakePublishers: Publisher[];
	showFilter = false;

	createPublisher(p_id: string, name: string, aab_pub_id: string, w_id: string, aab_site_id: string): Publisher {
		const publisherData = {id: p_id, name: name, status: '', aab_publisher_id: aab_pub_id, is_forbidden: false,
			aab_domain: 'antiadblock.cz'};
		const website: Website = {id: w_id, publisher: publisherData, name: name, aab_site_id: aab_site_id,
		adservers: [''], response: true, state: 'Live', site_url: '', url_mask: '', not_url_mask: [], zones: []};
		return {name: name, publisher_data: publisherData, websites: [website]};
	}

	createFakePublishers() {
		this.allPublisher = this.createPublisher('all_pub', 'All', null, 'all_web', null);
		this.defaultPublisher = this.createPublisher('default_pub', '*', '*', 'default_web', '*');
		this.eyeoPublisher = this.createPublisher('eyeo_pub', 'Eyeo', 'eyeo', 'eyeo_web', 'eyeo');
		this.fakePublishers = [this.defaultPublisher, this.allPublisher];
	}

	ngOnInit() {
		this.createFakePublishers();
		this.publishers.unshift(this.eyeoPublisher);
		this.publishers.unshift(this.defaultPublisher);
		this.publishers.unshift(this.allPublisher);
		this.selectedPublisher = this.allPublisher;
		this.websites = [this.allPublisher.websites[0]];
		this.selectedWebsite = this.allPublisher.websites[0];
		this.showFilter = true;
	}

	setFakePublisherWebsite() {
		if (!this.fakePublishers.includes(this.selectedPublisher) && this.eyeoPublisher !== this.selectedPublisher) {
			this.fakePublishers.forEach(pub => {
				if (!this.websites.includes(pub.websites[0])) {
					this.websites.unshift(pub.websites[0]);
				}
			});
		}
	}

	emitSelected() {
		this.changeFilter.emit({publisher: this.selectedPublisher, website: this.selectedWebsite});
	}

	onChangePublisher() {
		this.websites = this.selectedPublisher.websites;
		this.setFakePublisherWebsite();
		this.selectedWebsite = this.websites[0];
		this.emitSelected();
	}

	onChangeWebsite() {
		this.emitSelected();
	}
}
