import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Publisher} from 'app/core/models/data-structure';
import {PublisherResource} from 'app/resources/publisher.resource';
import {Observable, Subscription} from 'rxjs';

@Component({
	selector: 'app-installation-document',
	templateUrl: './installation-document.component.html',
	styleUrls: ['./installation-document.component.less']
})
export class InstallationDocumentComponent implements OnInit, OnDestroy {
	selectedPublisher: Publisher;
	selectedPublisher$: Observable<Publisher>;
	publisherSubscription: Subscription;
	showSpinner = false;
	detectScr: string;
	contentScr = '<script>_content_of_detect.min.js</script>';

	constructor(private route: ActivatedRoute,
				private publishersResource: PublisherResource) {
	}

	ngOnInit() {
		this.showSpinner = true;
		const aab_publisher_id = this.route.snapshot.params['aab_publisher_id'];
		this.selectedPublisher$ = this.publishersResource.get(aab_publisher_id);
		this.publisherSubscription = this.selectedPublisher$.subscribe(
			pub => {
				this.selectedPublisher = pub;
				this.detectScr = `<script src=\"//${pub.publisher_data.aab_publisher_id}.${pub.publisher_data.aab_domain}/js/detect.min.js\"></script>`;
				this.showSpinner = false;
			},
			err => {
				this.showSpinner = false;
			});
	}

	ngOnDestroy() {
		if (this.publisherSubscription) {
			this.publisherSubscription.unsubscribe();
		}
	}
}
