<div class="one column stackable ui grid">
	<div class="ten wide column installation-text">
		<p i18n="live demo section | Live Demo Text 1@@LiveDemoText1">
			Here you can look at the live demo examples of Antiadblock without any need of setup on your side. These
			demos run on our domains with Antiadblock enabled and take live data from your web site. Turn on the adblock
			in your browser, and take a look at what Antiadblock can bring you.
		</p>

		<p i18n="live demo section | Live Demo Text 1@@LiveDemoText1">
			Because these demos run on a slightly different domain than your real site, the preview is not 100% accurate -
			some adblock filters which apply specifically to your site may not apply here. This is the work of our un-blocking
			experts then to customise the solution to your needs.
			All the basic filtering rules though do apply here and you can see how Antiadblock deals with them.
		</p>
		<p>
			If you have any specific requests, mail us to <a href="mailto:support@antiadblock.com">support@antiadblock.com</a>
		</p>

		<div class="ui compact icon yellow message">
			<i class="question sign icon"></i>
			<p i18n="live demo section | Live Demo Text 3@@LiveDemoText3">
				If the live demo pop-up window doesn't open, check the pop-up settings of your browser as seen in the attached picture.
			</p>

			<img src="assets/popup_hint.png" id="pop-up-hint-image"/>
		</div>
	</div>

	<div class="six wide column" id="live_demo_links">
		<app-live-demo-link *ngIf="selectedWebsites && selectedWebsites.length > 0" [websites]="selectedWebsites"></app-live-demo-link>
	</div>
</div>
