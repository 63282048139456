import {Component} from '@angular/core';

@Component({
	selector: 'app-sas-zones-legend',
	templateUrl: './sas-zones-legend.component.html',
	styleUrls: ['../sas-zones.component.less']
})

export class SasZonesLegendComponent {
}
