import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DateFromTo} from '../../models/data-structure';
import {Message, MessageState} from '../../../shared/message.service';


@Component({
	selector: 'app-month-picker',
	templateUrl: './month-picker.component.html',
})

export class MonthPickerComponent implements OnInit {
	@Output()changeDate = new EventEmitter<DateFromTo>();
	@Output()changeMessage = new EventEmitter<Message>();
	today: Date;
	date: Date;
	mode = 'month';

	constructor() {
		const lastMonth = new Date();
		lastMonth.setDate(1);
		lastMonth.setMonth(lastMonth.getMonth() - 1);
		this.date = lastMonth;
		this.onChangeDate();
	}

	ngOnInit(): void {
		this.today = new Date();
	}

	setMessage(message: Message) {
		this.changeMessage.emit(message);
	}

	isDateValid() {
		return this.today > this.date;
	}

	onChangeDate() {
		if (this.date) {
			if (this.isDateValid()) {
				this.setMessage(null);
				const DateTo = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
				let date = this.date.getFullYear() + '-0' + (this.date.getMonth() + 1) + '-01';
				let dateTo = DateTo.getFullYear() + '-0' + (DateTo.getMonth() + 1) + '-' + DateTo.getDate();
				if (this.date.getMonth() > 8) {
					date = this.date.getFullYear() + '-' + (this.date.getMonth() + 1) + '-01';
					dateTo = DateTo.getFullYear() + '-' + (DateTo.getMonth() + 1) + '-' + DateTo.getDate();
				}
				this.changeDate.emit({dateFrom: date, dateTo: dateTo});
			} else {
				this.setMessage({header: 'No data for next month.', description: `We can't predict the future.`,
					state: MessageState.NEGATIVE, width: '80%'});
			}
		}
	}
}
