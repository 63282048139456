import {Component, Input} from '@angular/core';
import {BlockRateOverview} from '../../../models/data-structure';
import {BlockRateResource} from '../../../../resources/block-rate.resource';


@Component({
	selector: 'app-block-rate-overview-table',
	templateUrl: './block-rate-overview-table.component.html',
	styleUrls: ['./block-rate-overview-table.component.less'],

})

export class BlockRateOverviewTableComponent {
	@Input() blockRateOverviews: BlockRateOverview[];

	constructor(private blockRateResource: BlockRateResource) {
	}

	verifySite(blockrate: BlockRateOverview) {
		blockrate.onLoad = true;
		this.blockRateResource.getVerified(blockrate.hash).subscribe(res => {
			blockrate.verified = res.verified;
			blockrate.showModal = true;
			blockrate.onLoad = false;
		}, error => {
			blockrate.onLoad = false;
		});
	}

	formatNumbers(number: number) {
		return number.toLocaleString('en');
	}
}
