<fui-multi-select *ngIf="aabPublisherIds" id="budget-publisher-filter"
				   class="selection"
				   [(ngModel)]="selectedPublishers"
				   [options]="aabPublisherIds"
				   (ngModelChange)="changePublishers()"
				   placeholder="Filter Publishers"
				   #budgetPublishersSelect>
	<fui-select-option *ngFor="let pub of budgetPublishersSelect.filteredOptions"
		[value]="pub">
	</fui-select-option>
</fui-multi-select>
