<div style="width:400px; margin: 0 auto;">
	<div class="ui hidden divider"></div>
	<div class="ui fluid image">
		<a href="">
			<img src="assets/logo.png"/>
		</a>
	</div>
	<div *ngIf="!isLinkValid">
		<app-message [message]="message"></app-message>
		<a href="/login" class="fluid ui button">Go back to login page</a>
	</div>
	<form novalidate *ngIf="isLinkValid" (submit)="resetPassword()" [formGroup]="form" class="ui form">
		<app-new-password-form (formReady)="formInitialized('newPassword', $event)"></app-new-password-form>
		<div class="ui hidden divider"></div>
		<div class="ui hidden divider"></div>

		<button type="submit" class="fluid ui button" id="submit_button"
             [disabled]="form.invalid">Submit</button>
		<div class="ui hidden divider"></div>

		<button type="button" class="fluid ui button" id="reset_button"
             (click)="form.reset({})">Reset this form</button>
	</form>
</div>
