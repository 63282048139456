<div class="ui segment">
	<fui-tabset *ngIf="rights.stats || rights.blockrate">
		<div class="ui teal compact secondary menu">
			<a *ngIf="rights.stats || rights.blockrate" class="item" id="tab_block_rate" [isActive]="isActive(blockRateFragment)" fuiTabHeader="1" (click)="onTabClick(blockRateFragment)">Block Rate</a>
		</div>
		<div class="ui teal compact secondary menu">
			<a *ngIf="rights.stats" class="item" id="tab_impressions" [isActive]="isActive(impressionFragment)" fuiTabHeader="3" (click)="onTabClick(impressionFragment)">Impressions</a>
		</div>
		<div class="ui teal compact secondary menu">
			<a *ngIf="withHostageState && rights.super_admin" class="item" id="tab_hostage_state" [isActive]="isActive(hostageFragment)" fuiTabHeader="4" (click)="onTabClick(hostageFragment)">Hostage State</a>
		</div>
		<div class="ui teal compact secondary menu">
			<a *ngIf="rights.lite" class="item" id="tab_lite_stats" [isActive]="isActive(liteFragment)" fuiTabHeader="5" (click)="onTabClick(liteFragment)">Lite Stats</a>
		</div>
		<div *ngIf="rights.stats || rights.blockrate" class="ui bottom attached" fuiTabContent="1">
			<app-block-rate-segment *ngIf="selectedWebsites && currentFragment === blockRateFragment"
									[intervalData]="intervalData"
									[websites]="selectedWebsites"
									[verified]=true>
			</app-block-rate-segment>
		</div>
		<div *ngIf="rights.stats" class="ui bottom attached" fuiTabContent="3">
			<app-impressions-segment *ngIf="selectedWebsites && currentFragment === impressionFragment" [websites]="selectedWebsites"></app-impressions-segment>
		</div>
		<div *ngIf="withHostageState && rights.super_admin && selectedWebsites.length === 1" class="ui bottom attached" fuiTabContent="4">
			<app-hostage-state [selectedWebsite]="selectedWebsites[0]"></app-hostage-state>
		</div>
		<div *ngIf="rights.lite" class="ui bottom attached" fuiTabContent="5">
			<span *ngIf="currentFragment === liteFragment">
				<app-lite-stats *ngIf="isFewWebsites(selectedWebsites)" [websites]="selectedWebsites"></app-lite-stats>
				<div *ngIf="isFewWebsites(selectedWebsites)">
					<app-info-segment [text]="'Only data for 100 or less selected websites can be shown for performance reasons. Try filtering the websites in the select boxes above.'"></app-info-segment>
				</div>
			</span>
		</div>
	</fui-tabset>
</div>
