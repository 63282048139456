import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {BlockRateComponent} from './block-rate.component';
import {BlockRateInstallComponent} from './block-rate-install/block-rate-install.component';
import {AddNewWebsiteComponent} from './add-new-website/add-new-website.component';
import {BlockRateResource} from '../../resources/block-rate.resource';
import {BlockRateService} from './block-rate.service';
import {SelectWebsiteComponent} from './select-website/select-website.component';
import {BlockRateRoutingModule} from './block-rate-routing.module';
import {BrsRegistrationComponent} from './brs-registration/brs-registration.component';
import {CommonModule} from '@angular/common';
import {BlockRateOverviewComponent} from './block-rate-overview/block-rate-overview.component';
import {BlockRateOverviewTableComponent} from './block-rate-overview/block-rate-overview-table/block-rate-overview-table.component';

@NgModule({
	declarations: [
		BrsRegistrationComponent,
		BlockRateComponent,
		AddNewWebsiteComponent,
		SelectWebsiteComponent,
		BlockRateInstallComponent,
		BlockRateOverviewComponent,
		BlockRateOverviewTableComponent,
	],
	imports: [
		SharedModule,
		CommonModule,
		BlockRateRoutingModule
	],
	exports: [
		BlockRateComponent,
		SelectWebsiteComponent,
		AddNewWebsiteComponent,
		BlockRateInstallComponent,
	],
	providers: [BlockRateResource, BlockRateService]
})
export class BlockRateModule { }
