<div>
	<div class="ui horizontal list">
		<div class="item">
			<label id="publisher_filter_label"><strong>Publisher:</strong></label>
		</div>
		<div class="item">
			<fui-multi-select *ngIf="isMorePublishers"
								id="publishers_selector"
								[(ngModel)]="selectedPublishers"
							  	[options]="publishers"
							   	[optionFormatter]="formatPublishers"
							  	labelField="search"
							  	icon="user"
								(ngModelChange)=onChangePublisher()
								placeholder="All"
							  	#selectPubs>
				<div class="ui icon search input">
					<i id="filter_pubs_search_icon" class="search icon"></i>
					<input fuiSelectSearch id="publishers_search" type="text" placeholder="Search publisher...">
				</div>
				<div class="header">
					<div class="floating ui circular teal label">{{selectedPublishers.length > 0 ? selectedPublishers.length : publishers.length}}</div>
					<i class="list icon"></i>
					Publishers
				</div>
				<div class="scrolling menu" id="filter_publishers_menu">
					<fui-select-option *ngFor="let o of selectPubs.filteredOptions" [value]="o">
					</fui-select-option>
				</div>
			</fui-multi-select>
			<div *ngIf="!isMorePublishers && selectedPublishers.length === 1" id="one_publisher">{{selectedPublishers[0]?.name}}</div>
		</div>
	</div>
	<div *ngIf="!hideWebsites" class="ui horizontal list" style="padding-left: 10px;">
		<div class="item">
			<label id="website_filter_label"><strong>Website:</strong></label>
		</div>
		<div class="item">
			<fui-multi-select 	 id="websites_selector"
								 [(ngModel)]="selectedWebsites"
							 	 [options]="websites"
								 [optionFormatter]="formatWebsites"
								 labelField="search"
								 icon="window maximize outline"
								 (ngModelChange)=onChangeWebsites()
								 placeholder="All"
							  	 #selectWebs>
				<div class="ui icon search input">
					<i id="filter_webs_search_icon" class="search icon"></i>
					<input fuiSelectSearch id="websites_search" type="text" placeholder="Search website...">
				</div>
				<div class="header">
					<div class="floating ui circular teal label">{{selectedWebsites.length > 0 ? selectedWebsites.length : websites.length}}</div>
					<i class="list icon"></i>
					Websites
				</div>
				<div class="scrolling menu" id="filter_websites_menu">
					<fui-select-option *ngFor="let o of selectWebs.filteredOptions" [value]="o"></fui-select-option>
				</div>
			</fui-multi-select>
		</div>
	</div>
</div>
