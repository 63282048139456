<fui-modal [isClosable]="true" (dismissed)="closeTag()" (close)="modal.deny()" *ngIf="addNewFee || (actionType === editAction && fee && fee.showEditFeeModal)" #modal>
	<div class="header">{{actionType}} fee <span *ngIf="actionType === editAction">from {{fee.date}}</span> on publisher {{fee.publisher}} on website {{fee.website}}</div>
	<div class="content">
		<span *ngIf="actionType === addAction"><label>Valid from: </label><input type="date" min="{{lastDate}}" [(ngModel)]="fee.date"></span>
		<label>Set value: </label><input id="fee-input" type="text" appFeeValue [(ngModel)]="fee.value" #feeInput="ngModel">
		<div *ngIf="feeInput.invalid && (feeInput.dirty || feeInput.touched)" class="ui negative message" id="input_email_message">
			<div *ngIf="feeInput.errors.wrongFeeValue">
					This fee value is invalid - it can be number between 0.00 and 99.99 or nothing.
			  	</div>
	</div>
		<button id="submit_fee_button" class="ui tag label" type="submit" (click)="modal.approve('done');actionFee()">
			{{actionType}} Fee
		</button>
	</div>
</fui-modal>
