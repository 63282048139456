import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';


export interface ResetPasswordResponse {
	ok: boolean;
}

@Injectable()
export class ResetPasswordResource {
	url = '/password_recovery';

	constructor(private httpClient: HttpClient) {
	}

	post(email: string): Observable<ResetPasswordResponse> {
		return this.httpClient.post<ResetPasswordResponse>(
			environment.apiEndpoint + this.url + '/send_email',
			{email: email}
		);
	}

	postPassword(reset_key: string,	password: string): Observable<ResetPasswordResponse> {
		return this.httpClient.post<ResetPasswordResponse>(
			environment.apiEndpoint + this.url + '/reset',
			{reset_key: reset_key, password: password}
		);
	}

	get(token: string): Observable<ResetPasswordResponse> {
		return this.httpClient.get<ResetPasswordResponse>(
			environment.apiEndpoint + this.url + '/reset/' + token
		);
	}

}
