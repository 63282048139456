import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';


@Component({
	selector: 'app-website-links',
	templateUrl: './website-links.component.html',
	styleUrls: ['./website-links.component.less'],
})

export class WebsiteLinksComponent implements OnInit {
	aabPublisherId: string;
	aabSiteId: string;
	activeLink: string;

	constructor(private route: ActivatedRoute) {}

	isActive(link: string): string {
		return link === this.activeLink ? 'active section' : 'section';
	}

	ngOnInit() {
		this.aabPublisherId = this.route.snapshot.params['aab_publisher_id'];
		this.aabSiteId = this.route.snapshot.params['aab_site_id'];
		this.activeLink = this.route.snapshot.url[1].path;
	}
}
