import {Component, HostBinding} from '@angular/core';
import {BaseInputComponent} from './base-input.component';

@Component({
	selector: 'app-datetime-input',
	template: '<label>{{getTitle()}}</label>' +
		'<input class="datetime" disabled (change)="onChange($event)" type="text" value="{{formatValue()}}">',
	styles: [''],
})

export class DateTimeInputComponent extends BaseInputComponent {
	@HostBinding('attr.class') role = 'five wide field';

	constructor( ) {
		super();
	}

	formatValue() {
		try {
			const dateobj = new Date(parseInt(this.value, 10) * 1000).toLocaleString('cs-CZ', {timeZone: 'Europe/Prague'});
			// return dateobj.toISOString();
			// return (new Date(dateobj)).toISOString();
			return dateobj;
		} catch (err) {
			return '--';
		}
	}
}
