import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FomanticUIModule} from 'ngx-fomantic-ui';

import {MessageComponent} from './message/message.component';
import {SystemMessageComponent} from './system-message/system-message.component';
import {OrderByPipe} from './directives/order-by.pipe';
import {EscapeHtmlPipe} from './directives/keep-html.pipe';
import {CssFriendlyPipe} from './directives/css-friendly.pipe';
import {EmailValidatorDirective} from './directives/validators/email-validator.directive';
import {NeedCharValidatorDirective,	NeedNumberValidatorDirective, NoSpaceValidatorDirective, SamePasswordValidatorDirective
} from './directives/validators/password-validator.directive';
import {UrlPathValidatorDirective} from './directives/validators/url-validator.directive';
import {OneRequiredValidatorDirective} from './directives/validators/one-required-validator.directive';
import {MessageService} from './message.service';
import {GAService} from './ga.service';
import {NewPasswordFormComponent} from 'app/shared/partial-forms/password/new-password-form.component';
import {BlockRateSegmentComponent} from './block-rate-segment/block-rate-segment.component';
import {WebsiteInputComponent} from './partial-forms/website-input/website-input.component';
import {MoreWebsitesComponent} from './more-websites/more-websites.component';
import {HintIconComponent} from './hint-icon/hint-icon.component';
import {PublisherWebsiteFilterComponent} from './publisher-website-filter/publisher-website-filter.component';
import {IntervalChooserComponent} from './interval-chooser/interval-chooser.component';
import {IntervalService} from './interval.service';
import {PlotlyChartComponent} from './charts/plotly-chart/plotly-chart.component';
import {CoreService} from './core.service';
import {ElementService} from './element.service';
import {SortIconComponent} from './sort-icon/sort-icon.component';
import {OrderByMorePipe} from './directives/order-by-more.pipe';
import {TimezoneComponent} from './partial-forms/timezone/timezone.component';
import {SliderCheckboxComponent} from './partial-forms/slider-checkbox/slider-checkbox.component';
import {InfoSegmentComponent} from './info-segment/info-segment.component';
import {PopUpDescriptionsComponent} from './pop-up-descriptions/pop-up-descriptions.component';
import {CopyClipboardDirective} from './copy-clipboard-directive/copy-clipboard-directive';
import {MultiSelectorComponent} from './partial-forms/multi-selector/multi-selector.component';
import {SectionsService} from './sections.service';
import {WebsiteSectionsSelectorComponent} from './website-sections-selector/website-sections-selector.component';
import {SelectorComponent} from './partial-forms/selector/selector.component';
import {TrueValueValidatorDirective} from './directives/validators/true-value-validator.directive';
import {BlockRateCsvComponent} from './block-rate-csv/block-rate-csv.component';
import {BlockRateCodeComponent} from './block-rate-code/block-rate-code.component';
import {ChartService} from './chart.service';
import {MetaPlotlyChartComponent} from './charts/meta-plotly-chart.component';
import {SimpleTableComponent} from './simple-table/simple-table.component';
import {TableChartComponent} from './charts/table-chart/table-chart.component';
import {ChartResource} from '../resources/chart.resource';
import {MultiCheckboxComponent} from './partial-forms/multi-checkbox/multi-checkbox.component';
import {BlockRateBarComponent} from './block-rate-bar/block-rate-bar.component';
import {BlockRatePieComponent} from './block-rate-pie/block-rate-pie.component';
import {BlockRateProgressComponent} from './block-rate-progress/block-rate-progress.component';
import {BlockrateTrendTableComponent} from './simple-table/blockrate-trend-table/blockrate-trend-table.component';
import {BlockratePieTableComponent} from './simple-table/blockrate-pie-table/blockrate-pie-table.component';
import {UltraLiteTableComponent} from './simple-table/ultra-lite-table/ultra-lite-table.component';
import {FeeValidatorDirective} from './directives/validators/fee-validator.directive';
import {CsvDownloadComponent} from './csv-download/csv-download.component';



@NgModule({
	declarations: [
		OrderByPipe,
		OrderByMorePipe,
		EscapeHtmlPipe,
		CssFriendlyPipe,
		EmailValidatorDirective,
		NoSpaceValidatorDirective,
		NeedNumberValidatorDirective,
		NeedCharValidatorDirective,
		SamePasswordValidatorDirective,
		UrlPathValidatorDirective,
		OneRequiredValidatorDirective,
		MessageComponent,
		SystemMessageComponent,
		NewPasswordFormComponent,
		WebsiteInputComponent,
		BlockRateSegmentComponent,
		BlockRateProgressComponent,
		MoreWebsitesComponent,
		HintIconComponent,
		PublisherWebsiteFilterComponent,
		IntervalChooserComponent,
		PlotlyChartComponent,
		SortIconComponent,
		TimezoneComponent,
		SliderCheckboxComponent,
		InfoSegmentComponent,
		PopUpDescriptionsComponent,
		CopyClipboardDirective,
		MultiSelectorComponent,
		WebsiteSectionsSelectorComponent,
		SelectorComponent,
		TrueValueValidatorDirective,
		BlockRateCsvComponent,
		BlockRateCodeComponent,
		MetaPlotlyChartComponent,
		SimpleTableComponent,
		TableChartComponent,
		MultiCheckboxComponent,
		BlockRateBarComponent,
		BlockRatePieComponent,
		BlockrateTrendTableComponent,
		BlockratePieTableComponent,
		UltraLiteTableComponent,
		FeeValidatorDirective,
		CsvDownloadComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		FomanticUIModule,
		ReactiveFormsModule,
		FormsModule,
	],
	exports: [
		CommonModule,
		FormsModule,
		FomanticUIModule,
		ReactiveFormsModule,
		FormsModule,
		OrderByPipe,
		OrderByMorePipe,
		EscapeHtmlPipe,
		CssFriendlyPipe,
		EmailValidatorDirective,
		NoSpaceValidatorDirective,
		NeedNumberValidatorDirective,
		NeedCharValidatorDirective,
		SamePasswordValidatorDirective,
		UrlPathValidatorDirective,
		OneRequiredValidatorDirective,
		MessageComponent,
		SystemMessageComponent,
		NewPasswordFormComponent,
		WebsiteInputComponent,
		BlockRateSegmentComponent,
		BlockRateProgressComponent,
		MoreWebsitesComponent,
		HintIconComponent,
		PublisherWebsiteFilterComponent,
		IntervalChooserComponent,
		PlotlyChartComponent,
		SortIconComponent,
		TimezoneComponent,
		SliderCheckboxComponent,
		InfoSegmentComponent,
		PopUpDescriptionsComponent,
		CopyClipboardDirective,
		MultiSelectorComponent,
		WebsiteSectionsSelectorComponent,
		SelectorComponent,
		TrueValueValidatorDirective,
		BlockRateCsvComponent,
		BlockRateCodeComponent,
		MetaPlotlyChartComponent,
		SimpleTableComponent,
		TableChartComponent,
		MultiCheckboxComponent,
		BlockRateBarComponent,
		BlockRatePieComponent,
		BlockrateTrendTableComponent,
		BlockratePieTableComponent,
		UltraLiteTableComponent,
		FeeValidatorDirective,
		CsvDownloadComponent,
	],
	entryComponents: [
		SimpleTableComponent,
		BlockRateBarComponent,
		BlockRatePieComponent,
		BlockrateTrendTableComponent,
		BlockratePieTableComponent,
		UltraLiteTableComponent,
	],
	providers: [
		MessageService,
		GAService,
		IntervalService,
		CoreService,
		ElementService,
		SectionsService,
		ChartService,
		ChartResource,
	]
})

export class SharedModule {
}
