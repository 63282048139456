import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {BlockRateResource} from '../../resources/block-rate.resource';
import {MessageService, MessageState} from '../message.service';
import {SectionsService} from '../sections.service';
import {CoreService} from '../core.service';

@Component({
	selector: 'app-block-rate-csv',
	templateUrl: './block-rate-csv.component.html',
})

export class BlockRateCsvComponent implements OnInit, OnChanges {
	@Input() csvData: any;
	@Input() filename: string;
	renderFilename = '';
	showSpinner = true;
	csv: string;
	date = {from: '', to: ''};


	constructor(private blockRateResource: BlockRateResource, private messageService: MessageService,
				private sectionsService: SectionsService, private coreService: CoreService) {
	}

	ngOnInit() {
		if (!this.csv) {
			this.showSpinner = true;
		}
	}

	ngOnChanges() {
		this.prepareCSV(this.csvData);
		this.setFilename(this.filename);
	}

	setFilename(filename) {
		this.renderFilename = filename.replace('%from', this.date.from).replace('%to', this.date.to) + '.csv';
	}

	prepareCSV(csv: any) {
		if (csv) {
			this.csvData = csv;
			this.showSpinner = false;
			this.csv = '\ufeff';
			for (const lineData of this.csvData) {
				const line = lineData.join(', ');
				this.csv += line + '\n';
			}
			this.date.from = this.csvData[1][0].replace(/\//g, '.');
			this.date.to = this.csvData[this.csvData.length - 1][0].replace(/\//g, '.');
		}
	}

	getCSV(event) {
		event.preventDefault();
		const date = new Date();
		const link = document.createElement('a');
		link.setAttribute('href', 'data:text/csv;charset=UTF-8,' + this.csv);
		const formatDate = date.toLocaleDateString('en-GB');
		link.setAttribute('download', `${this.renderFilename}`);
		link.click();
	}

}
