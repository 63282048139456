import {Component, OnDestroy, OnInit, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormBuilder} from '@angular/forms';
import {ResetPasswordResource, ResetPasswordResponse} from '../../resources/reset-password.resource';
import {Message, MessageService, MessageState} from '../../shared/message.service';
import {Subscription, Observable} from 'rxjs';
import {GAService} from '../../shared/ga.service';

const ERROR_MESSAGE = {
	state: MessageState.NEGATIVE,
	header: 'Invalid link',
	description: 'This link is invalid.'
};

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	providers: [ResetPasswordResource]
})

export class ResetPasswordComponent implements OnDestroy, OnInit {
	form: FormGroup;
	resetKey: string;
	isLinkValid: boolean;
	message: Message = ERROR_MESSAGE;
	passwordSubscription: Subscription;
	resetKeySubscription: Subscription;
	resetPasswordResponse: Observable<ResetPasswordResponse>;

	constructor(private activatedRoute: ActivatedRoute,
				private router: Router,
				private resetPasswordResource: ResetPasswordResource,
				private messageService: MessageService,
				private gaService: GAService,
				private fb: FormBuilder) {
	}

	ngOnInit() {
		this.resetKey = this.activatedRoute.snapshot.params['reset_key'];
		this.resetKeySubscription = this.resetPasswordResource.get(this.resetKey).subscribe((res) => {
			this.isLinkValid = res.ok;
		});

		this.form = this.fb.group({
		});
	}

	resetPassword() {
		if (this.form.value.newPassword.password === this.form.value.newPassword.confirm_password) {

			this.resetPasswordResponse = this.resetPasswordResource.postPassword(this.resetKey, this.form.value.newPassword.password);
			this.gaService.sendEvent('reset-password', 'password-reset', null);
			this.passwordSubscription = this.resetPasswordResponse.subscribe(
				(res) => {
					if (res.ok) {
						this.messageService.setMessage({state: MessageState.INFO, header: 'Please login with your new password'});
						this.router.navigate(['/login']);
					} else {
						this.isLinkValid = false;
					}
				},
				(error: Response) => {
					this.isLinkValid = false;
				});
		}
	}

	ngOnDestroy() {
		if (this.passwordSubscription) {
			this.passwordSubscription.unsubscribe();
		}
		if (this.resetKeySubscription) {
			this.resetKeySubscription.unsubscribe();
		}
	}

	/** this injects partial form into the main one and allows full validation and bindings.
		it is "called" from the html template itself */
	formInitialized(name: string, form: FormGroup) {
		this.form.setControl(name, form);
	}

}
