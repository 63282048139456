import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {BlockRateResource} from '../../../resources/block-rate.resource';
import {BlockRateOverview} from '../../models/data-structure';
import {IntervalService} from '../../../shared/interval.service';


@Component({
	selector: 'app-block-rate-overview',
	templateUrl: './block-rate-overview.component.html',
	styleUrls: ['./block-rate-overview.component.less'],
})

export class BlockRateOverviewComponent implements OnInit, OnDestroy {
	blockRateOverviews: BlockRateOverview[];
	blockRateOverviewSubscription: Subscription;
	showSpinner = false;
	timezone: string;

	constructor(private blockRateResource: BlockRateResource, private intervalService: IntervalService) {
	}

	ngOnInit(): void {
		this.timezone = this.intervalService.getDefaultTimeZone();
		this.getOverview();
	}

	getOverview() {
		this.showSpinner = true;
		this.blockRateOverviewSubscription = this.blockRateResource.postBlockRateOverview(this.timezone).subscribe((res => {
			this.blockRateOverviews = res;
			this.showSpinner = false;
		}));
	}

	changeTimeZone(timezone: string) {
		this.timezone = timezone;
		this.getOverview();
	}

	ngOnDestroy() {
		if (this.blockRateOverviewSubscription) {
			this.blockRateOverviewSubscription.unsubscribe();
		}
	}
}
