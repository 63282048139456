import {Component, Input, HostBinding, OnInit} from '@angular/core';
import {BaseInputComponent} from './base-input.component';

@Component({
	selector: 'app-select-input',
	template: '<label>{{name}}</label><select *ngIf="!value" (change)="onChange($event)">' +
	'    <option *ngFor="let opt of options"' +
	'            [attr.selected]="(value==opt.value)?\'selected\':null"' +
	'            value="{{opt.value}}">' +
	'{{opt.name}}</option>' +
	'</select>' +
	'<span *ngIf="value">{{value}}</span>',
	styles: [''],
})

export class SelectInputUneditComponent extends BaseInputComponent {
	@Input() options: Array<any>;
	@HostBinding('attr.class') role = 'four wide field';

	constructor( ) {
		super();
	}
}
