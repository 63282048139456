import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {FomanticUIModule} from 'ngx-fomantic-ui';
import {SharedModule} from '../../shared/shared.module';
import {BudgetTreeTableComponent} from './budget-tree-table/budget-tree-table.component';
import {BudgetComponent} from './budget.component';
import {MonthPickerComponent} from './month-picker/month-picker.component';
import {EndTableComponent} from './budget-tree-table/end-table/end-table.component';
import {TableHeaderComponent} from './budget-tree-table/table-header/table-header.component';
import {BudgetResource} from '../../resources/budget.resource';
import {ActionTagModalComponent} from './budget-tree-table/action-tag-modal/action-tag-modal.component';
import {DeleteTagModalComponent} from './budget-tree-table/delete-tag-modal/delete-tag-modal.component';
import {TagsTableComponent} from './all-tags/tags-table/tags-table.component';
import {AllTagsComponent} from './all-tags/all-tags.component';
import {BudgetFilterComponent} from './budget-filter/budget-filter.component';
import {BudgetPublisherFilterComponent} from './all-tags/budget-publisher-filter/budget-publisher-filter.component';
import {BudgetRoutingModule} from './budget-routing.module';
import {BudgetReportComponent} from './budget-report/budget-report.component';



@NgModule({
	declarations: [
		BudgetTreeTableComponent,
		BudgetComponent,
		MonthPickerComponent,
		EndTableComponent,
		TableHeaderComponent,
		ActionTagModalComponent,
		DeleteTagModalComponent,
		TagsTableComponent,
		AllTagsComponent,
		BudgetFilterComponent,
		BudgetPublisherFilterComponent,
		BudgetReportComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		FomanticUIModule,
		SharedModule,
		BudgetRoutingModule
	],
	exports: [
	],
	providers: [
		BudgetResource
	]
})

export class BudgetModule {

}
