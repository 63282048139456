import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FeeResource, FeeTimeLine} from '../../../../resources/fee.resource';
import {ActionType} from '../../../../resources/budget.resource';
import {Message, MessageState} from '../../../../shared/message.service';

@Component({
	selector: 'app-fee-table',
	templateUrl: './fee-table.component.html',
})

export class FeeTableComponent implements OnInit {
	@Input() feeTimeLines: FeeTimeLine[];
	@Input() isEditable: boolean;
	@Output() messageChange = new EventEmitter<Message>();
	@Output() feesChange = new EventEmitter();
	showEditFeeModal = false;
	feeToEdit: FeeTimeLine;
	editType = ActionType.EDIT;
	isDesc: boolean;
	column: string;
	direction: number;
	showDelete = false;
	feeToDelete: FeeTimeLine;

	constructor(private feeResource: FeeResource) {
	}

	ngOnInit() {
		this.sortTable('date');
	}

	editFee(fee: FeeTimeLine) {
		fee.showEditFeeModal = true;
		this.feeToEdit = fee;
		this.showEditFeeModal = true;
	}

	showDeleteModal(fee: FeeTimeLine) {
		this.feeToDelete = fee;
		this.showDelete = true;
	}

	failedDeleteMessage() {
		this.changeMessage({header: 'Fee ' + this.feeToDelete.id + ' wasn\'t deleted.', description: 'Please try again.',
			state: MessageState.NEGATIVE});
	}

	deleteFee(approved: boolean) {
		this.showDelete = false;
		if (approved) {
			this.feeResource.deleteItem(this.feeToDelete).subscribe(res => {
				if (res.success) {
					// this.feeTimeLines = this.feeTimeLines.filter(i => i !== this.feeToDelete);
					this.changeMessage({header: 'Fee ' + this.feeToDelete.id + ' was successfully deleted.',
							state: MessageState.POSITIVE});
					this.changeFees();
				} else {
					this.failedDeleteMessage();
				}
			}, err => {
					this.failedDeleteMessage();
				});
		}
	}

	changeMessage(message: Message) {
		this.messageChange.emit(message);
	}

	changeFees() {
		this.feesChange.emit();
	}

	sortTable(by: string) {
		this.isDesc = !this.isDesc;
		this.column = by;
		this.direction = this.isDesc ? -1 : 1;
	}
}
