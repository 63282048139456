import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Message, MessageState} from '../../../shared/message.service';
import {BlockRateResource} from '../../../resources/block-rate.resource';
import {emailValidator} from '../../../shared/directives/validators/email-validator.directive';
import {equalsTrueValidator} from '../../../shared/directives/validators/true-value-validator.directive';


@Component({
	selector: 'app-brs-registration',
	templateUrl: './brs-registration.component.html',
	styleUrls: ['./brs-registration.component.less'],
})
export class BrsRegistrationComponent implements OnInit, OnDestroy {
	form: FormGroup;
	registrationBlockRateSubscription: Subscription;
	message: Message;
	websiteData = {};
	hideForm = false;

	constructor(private blockRateResource: BlockRateResource,
				private fb: FormBuilder) {
	}

	ngOnInit() {
		this.form = this.fb.group({
			email: ['', [Validators.required, emailValidator()]],
			acceptTerms: [false, [Validators.required, equalsTrueValidator()]],
		});
	}

	register() {
		// console.log("registering", this.form.value);
		const response = this.blockRateResource.postRegister(this.form.value.email, this.websiteData);
		this.registrationBlockRateSubscription = response.subscribe(
			(res) => {
				if (res.created) {
					this.message = {
						header: 'Account created',
						description: 'Please check your email (inbox or spam) - your login link and further instructions are there.',
						state: MessageState.INFO};
					this.hideForm = true;
				} else {
					if (res.error === 'USER_EXISTS') {
						this.message = {header: 'Registration failed',
							description: 'User already exists. To collect block-rate data for other websites, please log in.', state: MessageState.NEGATIVE};
					} else if (res.error === 'INVALID_URL') {
						this.message = {header: 'Registration failed', description: 'The domain is invalid.', state: MessageState.NEGATIVE};
					} else if (res.error === 'URL_DOESNT_EXIST') {
						this.message = {
								header: 'Registration failed',
								description: 'This domain either does not exist or is not publicly accessible.',
								state: MessageState.NEGATIVE
						};
					} else {
						this.message = {header: 'Registration failed', description: 'Something went wrong', state: MessageState.NEGATIVE};
					}
				}
		}, (err) => {
				this.message = {header: 'Registration failed', description: 'Something went wrong', state: MessageState.NEGATIVE};
		}
		);
	}

	formInitialized(websiteData: any) {
		this.websiteData = websiteData;
	}

	ngOnDestroy() {
		if (this.registrationBlockRateSubscription) {
			this.registrationBlockRateSubscription.unsubscribe();
		}
	}

}
