import {Component, OnInit} from '@angular/core';
import {Website} from 'app/core/models/data-structure';
import * as _ from 'lodash';
import {SelectedDataParamsComponent} from '../meta/selected-data-params/selected-data-params.component';
import {ActiveFragment} from '../../shared/chart.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
})
export class DashboardComponent extends SelectedDataParamsComponent implements OnInit {
	selectedWebsites: Website[] = [];
	isWebLive: boolean;
	isWebLiveResolved = false;
	blockRateTrendFragment = ActiveFragment.BLOCK_RATE_TREND;
	blockRateOsBrowserFragment = ActiveFragment.BLOCK_RATE_OS_BROWSER;
	impressionsFragment = ActiveFragment.IMPRESSIONS;
	hostageStateFragment = ActiveFragment.HOSTAGE_STATE;
	liteStatsFragment = ActiveFragment.LITE_STATS;
	possibleFragments = [this.blockRateTrendFragment, this.blockRateOsBrowserFragment, this.impressionsFragment,
		this.hostageStateFragment, this.liteStatsFragment];

	ngOnInit() {
		super.ngOnInit();
		this.paramsChange();
	}

	hasSomeWebsiteLiveState(websites: Website[]): boolean {
		for (let i = 0; i < websites.length; i++) {
			if (_.get(websites[i], 'state') === 'Live') {
				return true;
			}
		}
		return false;
	}

	paramsChange() {
		if (this.selectedData) {
			this.isWebLiveResolved = false;
			this.isWebLive = this.hasSomeWebsiteLiveState(this.selectedWebsites);
			this.selectedWebsites = this.publishersService.getSelectedWebsitesFromState(this.selectedData);
			this.isWebLiveResolved = true;
		}
	}

}
