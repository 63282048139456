import { Pipe, PipeTransform } from '@angular/core';

@Pipe({  name: 'orderByMore' })
export class OrderByMorePipe implements PipeTransform {


	sortOneColumn(args, a, b): number {
		if (a[args.property] < b[args.property]) {
				return -1 * args.direction;
			} else if ( a[args.property] > b[args.property]) {
				return 1 * args.direction;
			} else {
				return 0;
			}
	}

	transform(records: Array<any>, args?: {property: string, direction: number}[]): any {
		const self = this;
		if (records) {
			return records.sort(function(a, b) {
				let sorted = 0;
				for (let i = 0; i < args.length; i++) {
					sorted = self.sortOneColumn(args[i], a, b);
					if (sorted) {
						break;
					}
				}
				return sorted;
		});
		} else {
			return null;
		}
	}
}
