import {Component, HostListener} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DebugType} from '../../../../resources/debug.resource';
import {SelectedWebsiteParamsComponent} from '../../../meta/selected-website-params/selected-website-params.component';
import {ElementService} from '../../../../shared/element.service';
import {WebsiteService} from '../website.service';

@Component({
	selector: 'app-debug-page',
	templateUrl: './debug-page.component.html',
	styleUrls: ['./debug-page.component.less']
})

export class DebugPageComponent extends SelectedWebsiteParamsComponent {
	realWebsiteDebugType = DebugType.REAL_WEBSITE;
	aabDemoDebugType = DebugType.AAB_DEMO;
	siteHint = 'This tab shows debug information for communication between the end user and your proxy. ' +
				'It will tell you whether your proxy handles the cookie adb=1 properly and whether the fallback mechanism works OK. ' +
				'You may use the the `curl` commands mentioned here to debug the settings of your proxy. ';
	aabHint = 'This tab shows debug information for communication between your proxy and Antiadblock proxy. ' +
				'You may use the the `curl` commands mentioned here to debug the settings of your proxy.';
	tabletOrMobileSize = false;
	@HostListener('window:resize') onResize() {
		this.checkResize();
	}

	constructor(protected route: ActivatedRoute, protected websiteService: WebsiteService, private elementService: ElementService) {
		super(route, websiteService);
		this.checkResize();
	}

	checkResize() {
		this.tabletOrMobileSize =  this.elementService.isTabletOrMobileSize();
	}

}
