import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-table-header',
	templateUrl: './table-header.component.html',
	styleUrls: ['./table-header.component.less']
})

export class TableHeaderComponent {
	@Input() item: any;
	@Input() type: string;
	@Input() groupByDay: boolean;
	@Input() groupByRawData: boolean;
	@Input() collapse: boolean;

	formatCount(count: number) {
		return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	}
}
