import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {TopMenuComponent} from '../menu/top-menu.component';
import {LoggedInGuard} from '../../auth/guard/logged-in.guard';
import {BlockRateComponent} from './block-rate.component';
import {AllowedGuard} from '../../auth/guard/allowed.guard';
import {SuperAdminGuard} from '../../auth/guard/superadmin.guard';
import {BlockRateInstallComponent} from './block-rate-install/block-rate-install.component';
import {FaqComponent} from '../faq/faq.component';
import {ChangePasswordComponent} from '../loggedinuser/changepassword/change-password.component';
import {BrsRegistrationComponent} from './brs-registration/brs-registration.component';
import {BlockRateOverviewComponent} from './block-rate-overview/block-rate-overview.component';


const blockRateRoutes: Routes = [
	{
		path: '', component: TopMenuComponent, canActivate: [LoggedInGuard], children: [
			{path: '', component: BlockRateComponent, canActivate: [LoggedInGuard, AllowedGuard]},
			{path: 'overview', component: BlockRateOverviewComponent, canActivate: [LoggedInGuard, SuperAdminGuard]},
			{path: 'install', component: BlockRateInstallComponent, canActivate: [LoggedInGuard, AllowedGuard]},
			{path: 'faq', component: FaqComponent, canActivate: [LoggedInGuard, AllowedGuard]},
			{path: 'change-password', component: ChangePasswordComponent, canActivate: [LoggedInGuard]},
		]
	},
	{path: 'registration', component: BrsRegistrationComponent},
];

@NgModule({
	imports: [RouterModule.forChild(blockRateRoutes)],
	exports: [RouterModule]
})
export class BlockRateRoutingModule {
}
