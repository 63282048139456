import {NgModule} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';

import {CoreSelectComponent} from './core-select.component';
import {DebugPageComponent} from './debug-page/debug-page.component';
import {PublisherWebsiteSelectComponent} from './publisher-website-select/publisher-website-select.component';
import {WebsitesTableComponent} from './websites-table/websites-table.component';
import {DebugTableComponent} from './debug-page/debug-table/debug-table.component';
import {WebsiteDetailComponent} from './website-detail/website-detail.component';
import {WebsiteZonesComponent} from './website-zones/website-zones.component';
import {ZonesTableComponent} from './website-zones/zones-table/zones-table.component';
import {DashboardChartsTabComponent} from './dashboard-charts-tab/dashboard-charts-tab.component';
import {WebsiteLinksComponent} from './website-links/website-links.component';
import {HostageStateComponent} from './website-detail/hostage-state/hostage-state.component';
import {WebsiteService} from './website.service';
import {LiteStatsComponent} from './lite-stats/lite-stats.component';
import {WebsiteRoutingModule} from './website-routing.module';
import {ImpressionsSegmentComponent} from './impressions-segment/impressions-segment.component';
import {UltraLiteResource} from '../../../resources/ultra-lite.resource';
import {UltraLiteChartComponent} from './impressions-segment/ultra-lite-chart/ultra-lite-chart.component';
import {ImpressionsChartComponent} from './impressions-segment/impressions-chart/impressions-chart.component';
import {ImpressionsResource} from '../../../resources/impressions.resource';


@NgModule({
	declarations: [
		CoreSelectComponent,
		DebugPageComponent,
		PublisherWebsiteSelectComponent,
		WebsitesTableComponent,
		DebugTableComponent,
		WebsiteDetailComponent,
		WebsiteZonesComponent,
		ZonesTableComponent,
		DashboardChartsTabComponent,
		WebsiteLinksComponent,
		HostageStateComponent,
		LiteStatsComponent,
		ImpressionsSegmentComponent,
		UltraLiteChartComponent,
		ImpressionsChartComponent,
	],
	imports: [
		SharedModule,
		WebsiteRoutingModule,
	],
	exports: [
		WebsitesTableComponent,
		DashboardChartsTabComponent,
		ImpressionsSegmentComponent,
		UltraLiteChartComponent,
		ImpressionsChartComponent,
	],
	entryComponents: [ UltraLiteChartComponent, ImpressionsChartComponent ],
	providers: [
		WebsiteService,
		UltraLiteResource,
		ImpressionsResource,
	]
})

export class WebsiteModule {

}
