import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';

const PATH = /(https?:\/\/)?([\w\d-]+\.)+[\w]+/i;


export function pathValidator(): ValidatorFn {
	return (control: AbstractControl): {[key: string]: any} => {
		const path = PATH.test(control.value);
		return path ? null : {'wrongUrlPath': {value: control.value}};
	};
}

@Directive({
	selector: '[appUrlPath][ngModel]',
	providers: [{provide: NG_VALIDATORS, useExisting: UrlPathValidatorDirective, multi: true}]
})
export class UrlPathValidatorDirective implements Validator {

	validate(control: AbstractControl): {[key: string]: any} {
		return pathValidator()(control);
	}
}


