import {Component, OnInit, AfterViewInit} from '@angular/core';
import {Publisher} from 'app/core/models/data-structure';
import {ButtonType} from './button-type';
import {SelectedDataParamsComponent} from '../meta/selected-data-params/selected-data-params.component';
import {ActivatedRoute} from '@angular/router';
import {PublishersService} from '../publishers.service';
import {CoreService} from 'app/shared/core.service';


@Component({
	selector: 'app-installation',
	templateUrl: './installation.component.html',
	styleUrls: ['./installation.component.less'],

})

export class InstallationComponent extends SelectedDataParamsComponent implements OnInit, AfterViewInit {
	public selectedPublishers: Publisher[] = [];
	installationType: ButtonType = ButtonType.INSTALLATION;
	configurationType: ButtonType = ButtonType.CONFIGURATION;

	constructor(protected publishersService: PublishersService,
				protected route: ActivatedRoute, private coreService: CoreService) {
		super(publishersService, route);
	}


	scrollToElement(element: Element): void {
		this.coreService.scrollToElement(element);
	}

	ngOnInit() {
		super.ngOnInit();
		this.paramsChange();
	}

	ngAfterViewInit() {
		this.coreService.scrollToElementByFragment();
	}

	paramsChange() {
		if (this.selectedData) {
			this.selectedPublishers = this.publishersService.getSelectedPublishersFromState(this.selectedData);
		}
	}
}


