import {Component, AfterViewInit, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PublishersService} from '../publishers.service';
import {CoreService} from 'app/shared/core.service';
import {FaqResource} from '../../resources/faq.resource';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styles: ['.ui.accordion .padded {padding-bottom: 30px}']
})

export class FaqComponent implements AfterViewInit, OnInit {
	showSpinner = false;
	faqList: Object[];


	constructor(protected publishersService: PublishersService,
				protected route: ActivatedRoute,
				private coreService: CoreService,
				private faqResource: FaqResource,
				private sanitizer: DomSanitizer) {
	}

	scrollToElement(element: Element): void {
		this.coreService.scrollToElement(element);
	}

	ngAfterViewInit() {
		this.coreService.scrollToElementByFragment();
	}

	ngOnInit(): void {
		this.showSpinner = true;
		this.faqResource.listItems().subscribe(res => {
			this.faqList = res.faqs;
			this.showSpinner = false;
		});
	}

	trustedHtml(text) {
		return this.sanitizer.bypassSecurityTrustHtml(text);
	}

}


