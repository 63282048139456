import { Component, ComponentFactoryResolver } from '@angular/core';
import { MessageService } from 'app/shared/message.service';
import { LiteResource } from '../../../resources/lite.resource';
import { InputFieldContainer, RestComponent } from '../rest/rest.component';
import { forkJoin } from 'rxjs';
// import {RestItemService} from '../rest/rest-item/rest-item.service';

@Component({
	selector: 'app-lite-management',
	templateUrl: './lite-management.component.html',
})

export class LiteManagementComponent extends RestComponent {
	title = 'Lite Loaders Management';
	files = [];
	statuses = [];
	wraps = [];
	fields = this.makeFields();

	constructor(public injected_resource: LiteResource,
				public messageService: MessageService,
				public componentFactoryResolver: ComponentFactoryResolver,
				) {
				super(messageService, componentFactoryResolver);
	}

	makeFields() {
		const fields: InputFieldContainer = {
			container: [
				{ name: 'version', type: 'text', value: null},
				{ name: 'release', type: 'date', value: null},
				{ name: 'publishers', type: 'text', value: null},
				{ name: 'status', type: 'select', value: null, options: this.statuses},
				{ name: 'wrap', type: 'select', value: null, options: this.wraps},
				{ name: 'file', type: 'select', value: null, options: this.files},
			],
			underline: [
				{ name: 'notes', type: 'longText', value: null},
			]};
		return fields;
	}


	createForm(): void {
		this.getFormData().subscribe(res => {
			this.itemList = res[0]['loaders'];
			// copy only the contents, must not replace the original array
			for (const opt of res[1]['options']['statuses']) {
				this.statuses.push(opt);
			}
			for (const opt of res[1]['options']['wraps']) {
				this.wraps.push(opt);
			}
			this.files.push({'name': '-', 'value': null});
			for (const opt of res[2]['files']) {
				this.files.push({'name': opt['name'], 'value': opt['name']});
			}
			this.showSpinner = false;
		});
	}

	getFormData() {
		return forkJoin(
			[this.resource.listItems(),
			this.resource['listLoaderOptions'](),
			this.resource['listLoaderFiles']()]
		);
	}

	getItemName(index): string {
		if ( index && this.itemList[index] ) {
			return 'Loader v.' + this.itemList[index]['version'];
		}
		return '';
	}
}
