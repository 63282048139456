<table *ngIf="selectedWebsite" class="ui very basic celled table" id="debug_table_{{debugType}}">
  <thead>
	<tr>
		<th class="eleven wide"></th>
		<th class="center aligned one wide">
			<button class="ui teal button" id="play_all_button_{{debugType}}" (click)="playAll()"><i id="play_all_button" class="play icon"></i></button>
		</th>
		<th class="center aligned one wide">Expected Status</th>
		<th class="center aligned one wide">Real Status</th>
		<th class="center aligned one wide">Response</th>
	</tr>
  </thead>
  <tbody *ngFor="let res of responses">
	<tr>
		<td id="command_{{debugType}}_{{res.aab_id}}">
			<span class="fake_terminal"
			  [ngStyle]="{'color': 'springgreen', 'font-family': 'Ubuntu Mono'}">
				<div [ngStyle]="{'color': 'white'}" id="command_name_{{debugType}}_{{res.aab_id}}">{{res.name}}
					<span *ngIf="res.response$ && !res.onLoad" (click)="res.collapse = !res.collapse" class="collapse" id="collapse_{{debugType}}_{{res.aab_id}}">
						<i [ngClass]="res.collapse ? 'chevron down icon' : 'chevron up icon'" class="collapse_icon" id="collapse_icon_{{debugType}}_{{res.aab_id}}"></i>
					</span>
					<span [ngClass]="res.onLoad ? 'ui small active inline loader' : 'inactive loader'" class="collapse_icon"></span>
				</div>
				{{res.link}}
				<div *ngIf="res.response$ && !res.collapse" [fuiCollapse]="res.collapse">
					<div *ngFor="let out of res.response?.output" id="command_output_{{debugType}}_{{res.aab_id}}"
						 [ngStyle]="{ 'color': getTextColor(res, out), 'font-family': 'Ubuntu Mono'}">{{out}}<br>
					</div>
					<div style="color:#f5f0f0;" id="command_description_{{debugType}}_{{res.aab_id}}">{{res?.commandDescription}}</div>
				</div>
				<app-hint-icon *ngIf="tabletOrMobileSize" class="hint_icon pull-right" [hint]="res.hint"></app-hint-icon>
			</span>
			<app-hint-icon *ngIf="!tabletOrMobileSize" class="hint_icon pull-right" [hint]="res.hint"></app-hint-icon>
		</td>
		<td class="top aligned" style="text-align:center;">
			<button class="ui teal button" (click)="playCommand(res.aab_id)" id="play_command_{{debugType}}_{{res.aab_id}}"><i id="play_button" class="play icon" ></i></button>
		</td>
		<td style="text-align:center;" id="expected_status_{{debugType}}_{{res.aab_id}}" [ngClass]=getCellClass(res.response?.message)>{{res.expected_status}}</td>
		<td style="text-align:center;" id="real_status_{{debugType}}_{{res.aab_id}}" [ngClass]=getCellClass(res.response?.message)>{{res.response?.status || '-'}}</td>
		<td style="text-align:center;" id="response_{{debugType}}_{{res.aab_id}}" [ngClass]=getCellClass(res.response?.message)>
			<i [ngClass]=getCellIcon(res.response?.message)></i>{{getCellText(res.response?.message)}}
		</td>
	</tr>
  </tbody>
</table>
