<label id="adserver_filter_label"><strong>Ad Server:</strong></label>
<fui-multi-select id="budget_filter_adservers"
				   [(ngModel)]="selectedAdServers"
				   [options]="adServers"
				   (focus)="getAdServers()"
				   (ngModelChange)="changeAdServers()"
				   placeholder="All"
				   icon="window maximize outline"
				   #adServersSelect>
	<div class="ui icon search input">
					<i id="filter_adservers_search_icon" class="search icon"></i>
					<input fuiSelectSearch id="adservers_search" type="text" placeholder="Search ad server...">
				</div>
				<div class="header">
					<div class="floating ui circular teal label">{{selectedAdServers.length > 0 ? selectedAdServers.length : adServers.length}}</div>
					<i class="list icon"></i>
					Ad Servers
				</div>
	<fui-select-option class="scrolling menu" *ngFor="let adServer of adServersSelect.filteredOptions"
		[value]="adServer">
	</fui-select-option>
</fui-multi-select>
