import {Component} from '@angular/core';
import {ChartService} from '../../../shared/chart.service';
import {IntervalService} from '../../../shared/interval.service';
import {ChartResource} from '../../../resources/chart.resource';
import {AdScanResource} from '../../../resources/ad-scan.resource';
import {ActivatedRoute, Router} from '@angular/router';
import {MetaChartSegmentComponent} from '../../meta/meta-chart-segment/meta-chart-segment.component';
import {CoreService} from '../../../shared/core.service';
import {SectionsService} from '../../../shared/sections.service';

@Component({
	selector: 'app-ad-scan-zones-chart-segment',
	templateUrl: './ad-scan-zones-chart-segment.component.html'
})

export class AdScanZonesChartSegmentComponent extends MetaChartSegmentComponent {
	templates: string[];
	template: string;
		legendDescription = [
			{name: 'Invalid Selector', desc: 'the provided css selector is not valid (syntax error)'},
			{name: 'No Advert Found', desc: 'advert was not found in given element'},
			{name: 'DOM Changed', desc: 'the structure of the page has probably changed, ' +
					'the provided selector didn\'t match any elements on the page'},
			{name: 'OK', desc: 'number of found adverts'},
			{name: 'Template Views', desc: 'number of page-views of the given template'},
			{name: 'Page Views', desc: 'number of page-views of the website'},
	];


	constructor(protected chartResource: ChartResource,
				protected chartService: ChartService,
				protected intervalService: IntervalService,
				protected route: ActivatedRoute,
				private adScanResource: AdScanResource,
				protected coreService: CoreService,
				protected sectionsService: SectionsService,
				private router: Router) {
		super(chartResource, chartService, intervalService, route, coreService, sectionsService);
	}

	beforePost() {
		this.getTemplates();
	}

	getSelectedTemplate(templates: string[]) {
		const template = this.route.snapshot.queryParams['t'];
		if (template && templates.includes(template)) {
			this.template = template;
		} else {
			this.template = templates[0];
		}
	}

	getTemplates() {
		if (this.coreService.isLessThan100(this.websites)) {
			this.aabSiteIds = this.websites.map(w => w.aab_site_id);
			this.adScanResource.postAdScanTemplates(this.aabSiteIds, this.intervalData).subscribe(res => {
				this.templates = res.templates;
				this.getSelectedTemplate(this.templates);
				this.postData();
			});
		}
	}

	changeTemplate() {
		this.router.navigate([],  {queryParams: {t: this.template}, fragment: 'adscan_zones_chart',
			relativeTo: this.route, queryParamsHandling: 'merge'});
	}
}
