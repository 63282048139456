import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {SasZone, SasZonesResource, ZoneType} from '../../../resources/sas-zones.resource';
import {Message, MessageState} from '../../../shared/message.service';
import {CoreService} from '../../../shared/core.service';

@Component({
	selector: 'app-sas-zones-accordion',
	templateUrl: './sas-zones-accordion.component.html',
	styleUrls: ['../sas-zones.component.less']
})

export class SasZonesAccordionComponent implements OnChanges {
	@Input() aab_publisher_id: string;
	@Input() isList: boolean;
	@Input() sasZones: SasZone[];
	@Input() zoneTypes: ZoneType[];
	@Output() getZones = new EventEmitter();
	@Output() zonesSaved = new EventEmitter();
	disableSaveAll = true;
	message: Message;

	constructor(private sasZonesResource: SasZonesResource, private coreService: CoreService) {

	}

	ngOnChanges(changes: SimpleChanges): void {
		this.disableSaveAll = true;
		this.checkAllZonesAndSetToggleValues();
	}

	changeAllSameZones(zoneType: ZoneType) {
		this.sasZones.forEach(sasZone => {
				sasZone.postfixes.forEach(postfix => {
					if (postfix.name.includes(zoneType.type)) {
						postfix.value = zoneType.value;
					}
				});
			});
	}

	saveZones() {
		this.sasZonesResource.post(this.aab_publisher_id, this.sasZones, this.zoneTypes).subscribe(res => {
			if (res.ok) {
					this.message = {header: 'Saved',
						description: `SAS zones was successfully saved!`, state: MessageState.POSITIVE};
				} else {
					this.message = {header: 'NOT Saved',
						description: `SAS zones wasn\`t successfully saved!`, state: MessageState.NEGATIVE};
				}
		});
		this.zonesSaved.emit();
	}

	changeZonesToSameValue(sasZones: SasZone[], value: boolean) {
		sasZones.forEach(sasZone => {sasZone.postfixes.forEach(p => p.value = value); });
	}

	upperCaseFirstLetter(word: string) {
		return this.coreService.upperCaseFirstLetter(word);
	}

	checkAllZonesAndSetToggleValues() {
		this.zoneTypes.forEach(zoneType => {
			let atLeastOne = false;
			let allTrue = true;
			this.sasZones.forEach(sasZone => {
				sasZone.postfixes.forEach(postfix => {
					if (postfix.name.includes(zoneType.type)) {
						if (postfix.value) {
							atLeastOne = true;
						} else {
							allTrue = false;
						}
					}
				});
			});
			zoneType.value = allTrue;
		});
	}

	getAllZones() {
		this.getZones.emit();
	}

	getClassFor(sasZone: SasZone) {
		let atLeastOne = false;
		let allTrue = true;
		sasZone.postfixes.forEach(postfix => {
			if (postfix.value) {
				atLeastOne = true;
			} else {
				allTrue = false;
			}
		});
		sasZone.value = allTrue;
		return allTrue ? 'green' : atLeastOne ? 'blue' : 'red';
	}
}
