import {Injectable} from '@angular/core';
import {Publisher} from 'app/core/models/data-structure';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {RestBaseResource} from './rest-base.resource';


@Injectable()
export class PublisherResource {
	url = '/publishers';

	constructor(private httpClient: HttpClient) {
	}

	get(aab_publisher_id: string): Observable<Publisher> {
		return this.httpClient.get<Publisher>(
			`${environment.apiEndpoint}${this.url}/${aab_publisher_id}`
		);
	}

	query(): Observable<Publisher[]> {
		return this.httpClient.get<Publisher[]>(
			environment.apiEndpoint + this.url
		);
	}

}


@Injectable()
export class PublisherRestResource extends RestBaseResource {
	url = '/rest/publisher';
	listUrl = '/rest/list-publisher';

	constructor(protected httpClient: HttpClient) {
		super(httpClient);
	}
}
