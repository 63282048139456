<div class="row unpadded loader-link" [ngClass]="class">
	<div class="sixteen wide column ui grid">
		<div class="one wide column version">
			{{loader['version']}}
		</div>
		<div class="two wide column">
			{{loader['release']}}
		</div>
		<div class="three wide column">
			<div *ngIf="loader['status']!='deleted' && loader['status']!='deprecated'">
				<a class="ui teal icon button" [appCopyClipboard]="getLoaderCode()"><i class="clipboard outline icon"></i> to clipboard</a><br>
				or <a [href]="this.sanitizedUrl()" download="loader.{{loader['version']}}.txt">download as .txt</a>
			</div>
		</div>
		<div class="seven wide column">
			{{loader['notes']}}
		</div>
		<div class="two wide column" (click)="activate()">
			<span class="ui header"><i class="code icon small"></i></span>
		</div>
	</div>

	<div *ngIf="active" class="sixteen wide column">
		<code>
			<pre class="config-text">{{loader['loader']}}</pre>
		</code>
	</div>
</div>

