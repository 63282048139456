import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RestOptions, SelectOptions } from '../rest.component';

@Component({
	selector: 'app-base-input',
	template: '<label>{{getTitle()}}</label><input [disabled]="!isEditable()" (change)="onChange($event)" type="text" value="{{value}}">',
	styles: [''],
})

export class BaseInputComponent {
	@Input() value: string;
	@Input() name: string;
	@Input() type: string;
	@Input() options: RestOptions | SelectOptions;
	@Input() isNew: boolean;
	@Output() change = new EventEmitter();

	constructor( ) {

	}

	getInputValue(event) {
		return event.target.value;
	}

	onChange(event): void {
		this.value = this.getInputValue(event);
		this.change.emit([this.name, this.value]);
	}

	isEditable(): boolean {
		if (this.options && this.options['onlyNew']) {
			return this.isNew;
		}
		return true;
	}

	getTitle(): string {
		if (this.options && this.options['title']) {
			return this.options['title'];
		}
		return this.name;
	}
}
