import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';

function isSomeValue(word: string) {
	const anyLetter = new RegExp('[\\w]+').test(word);
	return word.length !== 0 && anyLetter;
}

export function oneRequiredValidator(): ValidatorFn {
	return (control: AbstractControl): {[key: string]: any} => {
		let ret = false;
		const controls = Object.keys(control.value);
		controls.forEach(key => {
			if (isSomeValue(control.value[key])) {
					ret = true;
				}
		});
		return ret ? null : {'oneRequired': {value: control.value}};
	};
}

@Directive({
	selector: '[appOneRequired][ngModel]',
	providers: [{provide: NG_VALIDATORS, useExisting: OneRequiredValidatorDirective, multi: true}]
})
export class OneRequiredValidatorDirective implements Validator {

	validate(control: AbstractControl): {[key: string]: any} {
		return oneRequiredValidator()(control);
	}
}


