import { Component, ComponentFactoryResolver} from '@angular/core';
import { MessageService } from 'app/shared/message.service';
import { PublisherRestResource } from '../../../resources/publisher.resource';
import { AdserverRestResource } from '../../../resources/adserver.resource';
import { InputFieldContainer, RestComponent } from '../rest/rest.component';
import { WebsiteManagementComponent } from '../website-management/website-management.component';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-adserver-management',
	templateUrl: '../rest/rest.component.html',
})

export class PublisherManagementComponent extends RestComponent {
	title = 'Publishers';
	itemName = 'publisher';
	adservers = [];
	publisherStatuses = [];
	fields = this.makeFields();

	resourceMapping = [
		{   resourceListVar: 'itemList',
			resourceItemsName: 'publishers',
			resourceName: 'publisherResource',
			resourceMethodName: 'getItems',
			passData: true,
			pagination: true,
			optionsVars: {
				status: 'publisherStatuses',
			}
		},
		{   resourceListVar: 'adservers',
			resourceItemsName: 'adservers',
			resourceName: 'adserverResource',
			mapping: {
				name: 'identifier',
				value: 'id'
			},
			zeroItem: {
				name: '-', value: null
			}
		}
	];

	constructor(public publisherResource: PublisherRestResource,
				public messageService: MessageService,
				public componentFactoryResolver: ComponentFactoryResolver,
				public adserverResource: AdserverRestResource,
				) {
				super(messageService, componentFactoryResolver);
	}

	makeFields() {
		const fields: InputFieldContainer = {
			container: [
				{ name: 'id', type: 'text', value: null},
				{ name: 'name', type: 'text', value: null},
				{ name: 'status', type: 'select', value: null, options: this.publisherStatuses},
				{ name: 'is_forbidden', type: 'boolean', value: null, options: {title: 'forbidden'}},
			],
			underline: [
				{ name: 'aab_domain', type: 'text', value: null},
				{ name: 'auth_password', type: 'text', value: null},
				{ name: 'access_full', type: 'boolean', value: null, options: {title: 'access full'}},
				{ name: 'access_lite', type: 'boolean', value: null, options: {title: 'access lite'}},
				{ name: 'access_live_demo', type: 'boolean', value: null, options: {title: 'access live demo'}},
				{ name: 'adservers', type: 'multiSelect', value: null, options: this.adservers},
			],
			wideUnderline: [
				{ name: 'Faqs', type: 'Rest', value: null, options: {
					title: 'Websites',
					component: WebsiteManagementComponent,
					fixed: {publisher: 'id'}}
				}
			],
		};
		return fields;
	}
}
