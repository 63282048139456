import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import {Integrations} from '@sentry/tracing';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
	enableProdMode();

	Sentry.init({
		dsn: 'https://a89a1a63ef1e41a3940f0e464fd9f2de@o467217.ingest.sentry.io/5594177' ,
		autoSessionTracking: true,
		integrations: [
			// Registers and configures the Tracing integration,
			// which automatically instruments your application to monitor its
			// performance, including custom Angular routing instrumentation
			new Integrations.BrowserTracing({
				tracingOrigins: ['localhost', 'https://admin.antiadblock.cz/'],
				routingInstrumentation: Sentry.routingInstrumentation,
			}),
		],
		// // Set tracesSampleRate to 1.0 to capture 100%
		// // of transactions for performance monitoring.
		// // We recommend adjusting this value in production
		// NE! tracesSampleRate: 1.0,
	});

}

platformBrowserDynamic().bootstrapModule(AppModule);
