import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RestBaseResource} from './rest-base.resource';
import {environment} from '../../environments/environment';

@Injectable()
export class AdserverRestResource extends RestBaseResource {
	url = '/rest/adserver';
	listUrl = '/rest/list-adserver';

	constructor(protected httpClient: HttpClient) {
		super(httpClient);
	}
}
