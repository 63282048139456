import {Component} from '@angular/core';
import {SimpleTableComponent} from '../simple-table.component';

@Component({
	selector: 'app-block-rate-pie-table',
	templateUrl: '../simple-table.component.html',
	styles: ['td { text-align: right!important; }']
})

export class BlockratePieTableComponent extends SimpleTableComponent {
}
