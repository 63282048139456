import * as moment from 'moment-timezone';
import {Observable, BehaviorSubject} from 'rxjs';
import { Injectable } from '@angular/core';

export const enum IntervalEnum {
	Date,
	Period
}

export const enum PeriodsEnum {
	Last30Days,
	Today,
	Yesterday,
	ThisWeek,
	Last7Days,
	PreviousWeek,
	ThisMonth,
	PreviousMonth,
	ThisYear,
	PreviousYear,
}

export interface DateInterval {
	dateFrom: Date;
	dateTo: Date;
}

export interface PeriodsInterval {
	type: PeriodsEnum;
	name: string;
	type_id: string;
	default_interval: string;
}

const PERIODS: PeriodsInterval[] = [
		{type: PeriodsEnum.Last30Days, name: 'Last 30 days', type_id: 'last_30_days', default_interval: '1d'},
		{type: PeriodsEnum.Today, name: 'Today', type_id: 'today', default_interval: '1h'},
		{type: PeriodsEnum.Yesterday, name: 'Yesterday', type_id: 'yesterday', default_interval: '1h'},
		{type: PeriodsEnum.Last7Days, name: 'Last 7 days', type_id: 'last_7_days', default_interval: '1h'},
		{type: PeriodsEnum.ThisWeek, name: 'This week', type_id: 'this_week', default_interval: '1h'},
		{type: PeriodsEnum.PreviousWeek, name: 'Previous week', type_id: 'previous_week', default_interval: '1h'},
		{type: PeriodsEnum.ThisMonth, name: 'This month', type_id: 'this_month', default_interval: '1d'},
		{type: PeriodsEnum.PreviousMonth, name: 'Previous month', type_id: 'previous_month', default_interval: '1d'},
		{type: PeriodsEnum.ThisYear, name: 'This year', type_id: 'this_year', default_interval: '1d'},
		{type: PeriodsEnum.PreviousYear, name: 'Previous year', type_id: 'previous_year', default_interval: '1d'},
];


export interface IntervalChooserData {
	intervalEnum: IntervalEnum;
	period: string | DateInterval;
	timeZone: string;
	interval: string;
	absolute: boolean;
}

@Injectable()
export class IntervalService {
	timeZones: string[];
	periods: PeriodsInterval[] = [];
	defaultTimeZone: string;
	private intervalData$ = new BehaviorSubject<IntervalChooserData>(null);
	private intervalData: IntervalChooserData;

	constructor() {
		this.periods = PERIODS;
		this.timeZonesInit();
	}

	private timeZonesInit() {
		const timezoneNames = moment.tz.names();
		const firstTimezones = ['UTC', 'Europe/Prague', 'Europe/Kiev', 'Europe/Warsaw', 'Asia/Kolkata'];
		timezoneNames.sort(function(x, y) {
			if (firstTimezones.indexOf(x) > -1) {
				return -1;
			} else if (firstTimezones.indexOf(y)) {
				return 1;
			} else {
				return 0;
			}
		});
		this.timeZones = timezoneNames;
		const lsData = this.getIntervalDataFromLocalStorage();
		if (lsData) {
			this.defaultTimeZone = lsData.timeZone;
		} else {
			this.defaultTimeZone = this.guessDefaultTimeZone();
		}
	}

	private isDifferentDates(currentValue: IntervalChooserData, previousValue: IntervalChooserData): boolean {
		return (currentValue.intervalEnum === 0
				&& (currentValue.period['dateFrom'] !== previousValue.period['dateFrom']
				|| currentValue.period['dateTo'] !== previousValue.period['dateTo']));
	}

	private isDifferentPeriod(currentValue: IntervalChooserData, previousValue: IntervalChooserData): boolean {
		return (currentValue.intervalEnum === 1 && currentValue.period !== previousValue.period);
	}


	isDifferentIntervalData(currentValue: IntervalChooserData, previousValue: IntervalChooserData): boolean {
		return this.isDifferentIntervalDataExceptAbsolute(currentValue, previousValue)
			|| currentValue.absolute !== previousValue.absolute;
	}

	isDifferentIntervalDataExceptAbsolute(currentValue: IntervalChooserData, previousValue: IntervalChooserData): boolean {
		return (currentValue && previousValue
			&& (currentValue.intervalEnum !== previousValue.intervalEnum
			|| currentValue.interval !== previousValue.interval
			|| currentValue.timeZone !== previousValue.timeZone)
			|| this.isDifferentDates(currentValue, previousValue)
			|| this.isDifferentPeriod(currentValue, previousValue));
	}

	getTimeZones(): string[] {
		if (!this.timeZones) {
			this.timeZonesInit();
		}
		return this.timeZones;
	}

	getPeriodInterfaceByPeriodTypeId(period: string|DateInterval): PeriodsInterval {
		let ret = this.periods[0];
		this.periods.forEach(per => {
			if (per.type_id === period) {
				ret = per;
			}
		});
		return ret;
	}

	getPeriodsInterfaceByEnum(periodsEnum: PeriodsEnum): PeriodsInterval {
		let ret = this.periods[0];
		this.periods.forEach(per => {
			if (per.type === periodsEnum) {
				ret = per;
			}
		});
		return ret;
	}

	private intervalDataInit(defaultPeriod?: PeriodsEnum): void {
		const dataFromLS = this.getIntervalDataFromLocalStorage();
		if (dataFromLS) {
			this.intervalData$ = new BehaviorSubject<IntervalChooserData>(dataFromLS);
			this.intervalData = dataFromLS;
		} else {
			if (!defaultPeriod) {
				defaultPeriod = PeriodsEnum.Last30Days;
			}
			const period = this.getPeriodsInterfaceByEnum(defaultPeriod);
			const timezone = this.getDefaultTimeZone();
			const data = {intervalEnum: IntervalEnum.Period, period: period.type_id,
				interval: period.default_interval, timeZone: timezone, absolute: true};
			this.intervalData$.next(data);
			this.intervalData = data;
		}
	}

	// 2020-08-02T22:00:00.000Z
	convertDateStringToDate(date: string): Date {
		return new Date(date);
	}

	isValidIntervalData(intervalData: IntervalChooserData): boolean {
		if (!intervalData) {
			return false;
		}
		try {
			if (intervalData.intervalEnum === 0) {
				if (typeof intervalData.period['dateFrom'] !== 'object' || typeof intervalData.period['dateFrom'] !== 'object') {
					return false;
				}
			} else if (intervalData.intervalEnum !== 1 && intervalData.period !== 'string') {
					return false;
			}
			if (typeof intervalData.timeZone !== 'string' || typeof intervalData.interval !== 'string'
				|| typeof intervalData.absolute !== 'boolean') {
				return false;
			}
		} catch (e) {
			return false;
		}
		return true;
	}

	getIntervalDataFromLocalStorage(): IntervalChooserData {
		const data = <IntervalChooserData>JSON.parse(localStorage.getItem('intervaldata'));
		if (data && data.intervalEnum === 0) {
			data.period['dateFrom'] = this.convertDateStringToDate(data.period['dateFrom']);
			data.period['dateTo'] = this.convertDateStringToDate(data.period['dateTo']);
		}
		if (this.isValidIntervalData(data)) {
			return data;
		}
	}

	setIntervalDataToLocalStorage(intervalData: IntervalChooserData) {
		if (this.isValidIntervalData(intervalData)) {
			localStorage.setItem('intervaldata', JSON.stringify(intervalData));
		}
	}

	setSharedIntervalData(intervalData: IntervalChooserData): void {
		if (this.isDifferentIntervalData(intervalData, this.intervalData)) {
			this.intervalData$.next(intervalData);
			this.intervalData = intervalData;
			this.setIntervalDataToLocalStorage(intervalData);
		}
	}

	getSharedIntervalDataObservable(): Observable<IntervalChooserData> {
		if (!this.intervalData) {
			this.intervalDataInit();
		}
		return this.intervalData$;
	}

	getSharedIntervalData(): IntervalChooserData {
		if (!this.intervalData) {
			this.intervalDataInit();
		}
		return this.intervalData;
	}

	getDateFromUTC(date: Date): Date {
		if (typeof date === 'string') {
			date = this.convertDateStringToDate(date);
		}
		return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
				date.getUTCHours(), date.getUTCMinutes() - date.getTimezoneOffset(), date.getUTCSeconds()));
	}

	getDefaultTimeZone(): string {
		if (!this.defaultTimeZone) {
			this.timeZonesInit();
		}
		return this.defaultTimeZone;
	}

	private guessDefaultTimeZone(): string {
		return moment.tz.guess();
	}

	getDateIntervalFromUTC(dateInterval: DateInterval): DateInterval {
		return {'dateFrom': this.getDateFromUTC(dateInterval.dateFrom),
			'dateTo': this.getDateFromUTC(dateInterval.dateTo)};
	}

	isDatesMoreThanXDays(dateInterval: DateInterval, x: number): boolean {
		const oneDay = 1000 * 60 * 60 * 24;
		const difference = dateInterval.dateFrom.getTime() - dateInterval.dateTo.getTime();
		const days = - Math.round(difference / oneDay);
		return days >= x;
	}

	isPeriodMoreThan14Days(periodEnum: PeriodsEnum): boolean {
		switch (periodEnum) {
			case PeriodsEnum.Last30Days: {
				return true;
			}
			case PeriodsEnum.Today: {
				return false;
			}
			case PeriodsEnum.Yesterday: {
				return false;
			}
			case PeriodsEnum.ThisWeek: {
				return false;
			}
			case PeriodsEnum.Last7Days: {
				return false;
			}
			case PeriodsEnum.PreviousWeek: {
				return false;
			}
			case PeriodsEnum.ThisMonth: {
				return true;
			}
			case PeriodsEnum.PreviousMonth: {
				return true;
			}
			case PeriodsEnum.ThisYear: {
				return true;
			}
			case PeriodsEnum.PreviousYear: {
				return true;
			}
			default: {
				return true;
			}
		}
	}
}
