import {Component, Input, OnInit} from '@angular/core';
import {Message, MessageService} from '../message.service';


@Component({
	selector: 'app-system-message',
	templateUrl: './system-message.component.html',
})
export class SystemMessageComponent implements OnInit {
	@Input() location: string;
	messages: Message[];

	ngOnInit() {
		this.messages = MessageService.getSystemMessages(this.location);
	}
}
