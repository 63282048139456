<table class="ui very small stackable table">
	<thead>
		<tr>
			<th>Date *</th>
			<th>Login</th>
		</tr>
	</thead>
	<tbody *ngFor="let item of userLogin">
		<td>{{item.date}}</td>
		<td>{{formatFailedLogin(item.failed)}}</td>
	</tbody>
</table>
<p>* Dates are stored in UTC format.</p>
