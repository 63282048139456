import {Component} from '@angular/core';


@Component({
	selector: 'app-lite',
	templateUrl: './lite.component.html',
})

export class LiteComponent {

	constructor() {}

}


