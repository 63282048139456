import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ChartsStructure, Website} from '../core/models/data-structure';
import {IntervalChooserData, IntervalEnum, IntervalService} from '../shared/interval.service';
import {SectionsService} from '../shared/sections.service';


@Injectable()
export class ChartResource {
	url = '/chart';

	constructor(private httpClient: HttpClient, private intervalService: IntervalService, private sectionsService: SectionsService) {
	}

	post(chartType: string, websites: Website[], aab_sites_ids: string[], intervalChooserData: IntervalChooserData, template?: string,
			website_sections?: string[])
		: Observable<ChartsStructure> {
		let period = intervalChooserData.period;
		if (intervalChooserData.intervalEnum === IntervalEnum.Date) {
			period = this.intervalService.getDateIntervalFromUTC({dateFrom: period['dateFrom'], dateTo: period['dateTo']});
		}
		const body = {aab_sites_ids: aab_sites_ids, interval_enum: intervalChooserData.intervalEnum, period: period,
				time_zone: intervalChooserData.timeZone, interval: intervalChooserData.interval};
		if (template) {
			body['template'] = template;
		}
		if (intervalChooserData.absolute) {
			body['absolute'] = true;
		}
		if (this.sectionsService.isSectionsAvailable(websites)) {
			body['website_sections'] = website_sections;
		}
		return this.httpClient.post<ChartsStructure>(
			`${environment.apiEndpoint}${this.url}/${chartType}`, body
		);
	}

}
