import {Component} from '@angular/core';
import {SimpleTableComponent} from '../simple-table.component';

@Component({
	selector: 'app-ultra-lite-table',
	templateUrl: '../simple-table.component.html',
	styles: ['td { text-align: right!important; }']
})

export class UltraLiteTableComponent extends SimpleTableComponent {

	getTotal(name: string) {
		if (name === 'CPT') {
			const totalImp = this.addItemsFromArray(this.tableData, 'impressions');
			const totalRevenue = this.addItemsFromArray(this.tableData, 'total revenue');
			return totalRevenue / totalImp * 1000;
		}
		if (name.includes('fee')) {
			return this.addItemsFromArray(this.tableData, name) / this.tableData.length;
		}
		return super.getTotal(name);
	}

	formatCount(count: any, name: string) {
		count = super.formatCount(count, name);
		if (name.includes('revenue')) {
				const formatted = this.coreService.formatNumberSeparateByThousands(count);
				return formatted + ' CZK';
		}
		return count;
	}
}
