import {Component, HostBinding} from '@angular/core';
import {BaseInputComponent} from './base-input.component';

@Component({
	selector: 'app-file-input',
	template: `<label>{{name}}</label>
				<input *ngIf="!value" (change)="onChange($event)" type="file" accept=".js">
				<span *ngIf="value">{{value}}</span>`,
	styles: [''],
})

export class FileInputComponent extends BaseInputComponent {
	@HostBinding('attr.class') role = 'eight wide field';

	constructor( ) {
		super();
	}

	onChange(event): void {
		const reader = new FileReader();
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
			reader.readAsText(file);
			reader.onload = () => {
				// console.log('file ', file, reader.result);
				const final = {'name': file.name, 'type': file.type, 'content': reader.result};
				this.change.emit([this.name, final]);
			};
		}
	}
}
