import {Component, Input, OnDestroy, OnChanges, HostListener} from '@angular/core';
import {TestResponse, Website} from 'app/core/models/data-structure';
import {DebugResource, DebugResponseMessage, DebugType} from 'app/resources/debug.resource';
import {Observable, Subscription} from 'rxjs';
import {GAService} from 'app/shared/ga.service';
import {ElementService} from '../../../../../shared/element.service';

@Component({
	selector: 'app-debug-table',
	templateUrl: './debug-table.component.html',
	styleUrls: ['./debug-table.component.css'],
	providers: [DebugResource]
})

export class DebugTableComponent implements OnChanges, OnDestroy {
	@Input() selectedWebsite: Website;
	@Input() debugType: DebugType;
	commands: Observable<any>;
	commandsSubscription: Subscription;
	responses: TestResponse[];
	normalXHeaders = ['X-Time', 'X-Nginx-Proxy', 'X-Cache-Status', 'X-Dynamic', 'X-AspNet-Version', 'X-Powered-By'];
	tabletOrMobileSize = false;
	@HostListener('window:resize') onResize() {
		this.checkResize();
	}

	constructor(private debugResource: DebugResource, public gaService: GAService, private elementService: ElementService) {
		this.checkResize();
	}

	ngOnChanges() {
		this.commands = this.debugResource.getCommands(this.debugType, this.selectedWebsite.publisher.aab_publisher_id,
			this.selectedWebsite.aab_site_id);
		this.commandsSubscription = this.commands.subscribe(com => {
			this.responses = com;
		});
	}

	checkResize() {
		this.tabletOrMobileSize =  this.elementService.isTabletOrMobileSize();
	}

	playAll() {
		this.gaService.sendEvent('debug', 'play_all', String(this.debugType));
		if (this.responses) {
			this.responses.forEach(res => {
				this.playCommand(res.aab_id);
			});
		}
	}

	playCommand(request) {
		const response = this.responses.find((res) => res.aab_id === request);
		response.onLoad = true;
		this.gaService.sendEvent('debug', request, String(this.debugType));
		response.response$ = this.debugResource.get(this.debugType, this.selectedWebsite.publisher.aab_publisher_id,
			this.selectedWebsite.aab_site_id, request);
		response.subscription = response.response$.subscribe(res => {
			response.onLoad = false;
			response.response = res;
			response.collapse = true;
		});
	}

	getTextColor(res: TestResponse, out: string) {
		let normalColor = false;
		this.normalXHeaders.forEach(header => {
			if (out.startsWith(`< ${header}`) && !out.startsWith('< X-Time-')) {
				normalColor = true;
			}
		});
		if (out.startsWith('< X-') && !normalColor) {
			res.commandDescription = '* Gray output is generated only in this online administration. You won\'t see it in your command line output.';
			return '#f5f0f0';
		} else {
			return '#6ff';
		}
	}

	getCellClass(message: DebugResponseMessage) {
		switch (message) {
			case DebugResponseMessage.NEED_TO_CHECK: return 'top aligned warning';
			case DebugResponseMessage.OK: return 'top aligned positive';
			case DebugResponseMessage.BAD_PROXY: return 'top aligned negative';
			case DebugResponseMessage.BAD_STATUS: return 'top aligned negative';
			default: return 'top aligned warning';
		}
	}

	getCellIcon(message: DebugResponseMessage) {
		switch (message) {
			case DebugResponseMessage.NEED_TO_CHECK: return '';
			case DebugResponseMessage.OK: return 'icon checkmark';
			case DebugResponseMessage.BAD_PROXY: return 'icon close';
			case DebugResponseMessage.BAD_STATUS: return 'icon close';
			default: return '';
		}
	}

	getCellText(message: DebugResponseMessage) {
		switch (message) {
			case DebugResponseMessage.NEED_TO_CHECK: return 'Need to Check';
			case DebugResponseMessage.OK: return 'OK';
			case DebugResponseMessage.BAD_PROXY: return 'Bad Proxy';
			case DebugResponseMessage.BAD_STATUS: return 'Bad Status';
			default: return 'Need to Check';
		}
	}

	ngOnDestroy() {
		if (this.responses) {
			this.responses.forEach(res => {
				if (res.subscription) {
					res.subscription.unsubscribe();
				}
			});
		}
		if (this.commandsSubscription) {
			this.commandsSubscription.unsubscribe();
		}
	}
}
