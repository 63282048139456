import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Website} from 'app/core/models/data-structure';
import {DemoHashResource, DemoHashResponse} from 'app/resources/demo-hash.resource';
import {Observable, Subscription} from 'rxjs';
import {PublishersService} from '../../publishers.service';

interface WebData {
	onLoad: boolean;
	subscription?: Subscription;
}

@Component({
	selector: 'app-live-demo-link',
	templateUrl: './live-demo-link.component.html',
	styleUrls: ['./live-demo-link.component.less'],
	providers: [DemoHashResource]
})
export class LiveDemoLinkComponent implements OnInit, OnDestroy {
	@Input() websites: Website[] = [];
	webs: WebData[] = [];

	constructor(private demoHashResource: DemoHashResource, private publishersService: PublishersService) {
	}

	ngOnInit() {
		this.setLoadInit();
	}

	setLoadInit() {
		this.websites.forEach(web => {
			this.webs.push({onLoad: false});
		});
	}

	setLoadAndSubsription(website: Website, state: boolean, subscription?: Subscription) {
		const index = this.websites.indexOf(website);
		this.webs[index] = {onLoad: state, subscription: subscription};
	}

	getHashForWeb(web: Website): Observable<DemoHashResponse> {
		const publisher = web.publisher.aab_publisher_id;
		const website = web.aab_site_id;
		return this.demoHashResource.get(publisher, website);
	}

	setCookiesAndNavigate(web) {
		this.setLoadAndSubsription(web, true);
		const hash$ = this.getHashForWeb(web);
		const subscription = hash$.subscribe(
			hash => {
				this.setCookies(hash.hash);
				this.setLoadAndSubsription(web, false, subscription);
				const url = this.publishersService.parseSiteUrl(web.site_url);
				window.open(`${url.protocol}//${url.hostname}.demo.antiadblock.cz?adb.demo.auth=${hash.hash}`);
			});

	}

	setCookies(hash: string) {
		document.cookie = `adb.demo.auth=${hash};path=/;domain=antiadblock.cz;`;
		document.cookie = 'adb=1;path=/;domain=antiadblock.cz;';
	}

	ngOnDestroy() {
		this.webs.forEach(web => {
			if (web.subscription) {
				web.subscription.unsubscribe();
			}
		});
	}

}
