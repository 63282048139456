<h1 id="install_header" class="ui header" i18n="installation section heading| Installation heading">
	Installation for {{ selectedPublisher?.publisher_data?.name }}
	<span [ngClass]="showSpinner ? 'ui small active inline loader' : 'inactive loader'"></span>
</h1>
<ol class="ui">
	<li>
		<ng-container i18n="installation section tutorial | long string of text"> We will create a domain </ng-container>
		<strong>http://{{ selectedPublisher?.publisher_data?.aab_publisher_id }}.{{ selectedPublisher?.publisher_data?.aab_domain }}</strong>
		<ng-container i18n="installation section tutorial | long string of text">for you.</ng-container>
	</li>

	<li i18n="installation section tutorial | long string of text">
		You will once per hour download our detection script from <strong class="break-word">http://{{ selectedPublisher?.publisher_data?.aab_publisher_id }}.{{ selectedPublisher?.publisher_data?.aab_domain }}/js/detect.min.js</strong> and insert it inline in the end of your page (before the closing body tag).
		<ul>
			 <li>Don’t use <code class="break-word">{{detectScr}}</code>, it should be included with
				 <code class="break-word">{{contentScr}}</code>
			 </li>
		<li>For verification of the downloaded file you can use md5 sum available at
		<strong class="break-word">http://{{ selectedPublisher?.publisher_data?.aab_publisher_id }}.{{ selectedPublisher?.publisher_data?.aab_domain }}/js/detect.min.js.md5</strong></li>
	</ul>
	</li>

	<li>
		<ng-container i18n="installation section tutorial | long string of text">You need to setup your proxy to handle requests based on cookies:
		Example of nginx configuration (nginx.conf):
		</ng-container>
		<div></div>
		<h3 i18n="installation section tutorial | what happens when you get a request">When you get a request:</h3>
		<ol>
			<li>
				<ng-container i18n="installation section tutorial | request has a cookie adb=1"><b>Requests containing cookie adb=1</b></ng-container>
				<br />
				<ng-container i18n="installation section tutorial | your proxy will send these request to something.antiadblock.cz">
				Your proxy will send these requests to {{ selectedPublisher?.publisher_data?.aab_publisher_id }}.{{ selectedPublisher?.publisher_data?.aab_domain }}
				</ng-container>
				<br />
				<ng-container i18n="installation section tutorial | request must containt the following headers">
				Request must contain there headers:
				</ng-container>
				<br />
				<code i18n="installation section tutorial | HOST = domain of origin">
					HOST = domain of origin
				</code>
				<br />
				<code>X-Auth: {{ selectedPublisher?.publisher_data?.auth_password }}</code><br />
				<code>X-Client: {{ selectedPublisher?.publisher_data?.aab_publisher_id }}</code><br />
				<ng-container i18n="installation section tutorial | exception for when when your site was visited over https">
					If the user visited your site over https, then also:
				</ng-container>
				<br />
				<code>X-Forwarded-Ssl: on</code>
				<ol class="lower-alpha">
					<li value="-" i18n="installation section tutorial | long string of text">Our server needs to process the request (html, css, js)
					it will download the requested contents from yor website (without the adb cookie), encrypts parts of the contents and returns the altered contents to your proxy, your proxy will deliver this content to the user.
					</li>

					<li value="-" i18n="installation section tutorial | long string of text">Our server doesn’t need to process the request (for example videos, pictures, binary data)
					our proxy will return status 403 Forbidden – your proxy must fall back to your backend and deliver content as usual.
					</li>

					<li value="-" i18n="installation section tutorial | long string of text">In (the rare) case of malfunction on our server
					our proxy won’t answer or returns status 500, your proxy must fall back to your backend and deliver content as usual – performance of your page doesn’t suffer but the ads will be blocked. We will resolve the problem asap.
					</li>
				</ol>
			</li>
			<li>
				<ng-container i18n="installation section tutorial | long string of text">
					<b>Requests without the cookie adb=1 (or with adb=0)</b>
				<br />
				Your proxy will use your backend and deliver content as usual – these requests go completely outside our architecture – users without adblockers won’t be affected at all
				</ng-container>
			</li>
		</ol>
	</li>
</ol>


<h2 class="ui header" i18n="installation section unblocking | How does it work">
How does it work
</h2>
<ng-container i18n="installation section unblocking | a user with adblock visits your page">
A user with an Adblocker visits your page:
</ng-container>
<ul class="ui list">
<li i18n="installation section unblocking | long string of text">
during the first load from your domain – we will mark him with a cookie (ads won’t show yet)
</li>
<li i18n="installation section unblocking | long string of text">
subsequent pageloads – the cookie is set, ads show up
</li>
</ul>
<ng-container i18n="installation section unblocking | long string of text">When the user turns off the Adblocker:</ng-container>
<ul class="ui list">
<li i18n="installation section unblocking | text text text">first page load – ads are still there, we remove the cookie</li>
<li i18n="installation section unblocking | text text text">subsequent page loads – no cookie, adblocker is off, page shows as usual</li>
</ul>

<h2 class="ui header" i18n="installation section unblocking | Unblocking blocked ads (simplified how it works)">
Unblocking (simplified)
</h2>

<ng-container i18n="installation section unblocking | Adblocking programm can block either of two things">Adblockers block two things:</ng-container>

<ul class="ui list">
<li i18n="installation section unblocking | website urls like ">URLs, like bbelements.com/*, adserver.*/ etc.
<li i18n="installation section unblocking | html elements like ">HTML elements in the DOM, i.e everything with<code>class="advert"</code>, or<code>position="absolute"</code>etc..</li>
</ul>
<p i18n="installation section unblocking | Our antiadblock will change all banned urls to the format of">
AntiAdblock will change all banned urls to the following format:
</p>
<p>
	<code class="break-word" i18n="installation section unblocking | website url with sample">your_domain.com/machine_generated_gibberish</code>
</p>
<p i18n="installation section unblocking | long string of text">
Because the network traffic with adb cookie is proxied to antiadblock, these requests will end on our servers, we will decipher the original urls and deliver the content.
In a similar manner we also replace the ids and classes of blocked html elements.
</p>

<p i18n="installation section unblocking | text about updating filters and our maintenance">
The adblockers may create new filters at any time, but it usually takes about 3 days for these changes to propagate to the users. We are closely monitoring the filters and react before the changes appear in the wide public. Nevertheless if you encounter some blocked elements or new filters you are concerned with, feel free to contact our support.
</p>
