import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {LiteComponent} from './lite.component';
import {LiteFilesResource, LiteResource} from '../../resources/lite.resource';
import {LiteLoaderComponent} from './lite-loader/lite-loader.component';
import {LoaderLinkComponent} from './lite-loader/loader-link/loader-link.component';
import {LiteSetupComponent} from './lite-setup/lite-setup.component';
import {LiteRoutingModule} from './lite-routing.module';

@NgModule({
	declarations: [
		LiteComponent,
		LiteLoaderComponent,
		LiteSetupComponent,
		LoaderLinkComponent,
	],
	imports: [
		SharedModule,
		LiteRoutingModule
	],
	providers: [
		LiteResource,
		LiteFilesResource,
	]
})
export class LiteModule { }
