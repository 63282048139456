import { Component, ComponentFactoryResolver, Inject} from '@angular/core';
import { MessageService } from 'app/shared/message.service';
import { WebsiteRestResource } from '../../../resources/website.resource';
import { AdserverRestResource } from '../../../resources/adserver.resource';
import { PublisherRestResource } from '../../../resources/publisher.resource';
import { InputFieldContainer, RestComponent, ResourceMapping } from '../rest/rest.component';

@Component({
	selector: 'app-website-management',
	templateUrl: '../rest/rest.component.html',
})

export class WebsiteManagementComponent extends RestComponent {
	title = 'Websites';
	itemName = 'website';
	adservers = [];
	publishers = [];
	websiteStatuses = [];
	fields = this.makeFields();

	resourceMapping = [
		{   resourceListVar: 'itemList',
			resourceItemsName: 'websites',
			resourceName: 'websiteResource',
			resourceMethodName: 'getItems',
			passData: true,
			pagination: true,
			optionsVars: {
				status: 'websiteStatuses',
			}
		},
		{   resourceListVar: 'adservers',
			resourceItemsName: 'adservers',
			resourceName: 'adserverResource',
			mapping: {
				name: 'identifier',
				value: 'id',
			},
			zeroItem: {
				name: '-', value: null
			}
		},
		{   resourceListVar: 'publishers',
			resourceItemsName: 'publishers',
			resourceName: 'publisherResource',
			mapping: {
				name: 'name',
				value: 'id'
			},
			zeroItem: {
				name: '-', value: null
			}
		}
	];

	constructor(/*public injected_resource: WebsiteRestResource,*/
				public messageService: MessageService,
				public componentFactoryResolver: ComponentFactoryResolver,
				public websiteResource: WebsiteRestResource,
				public adserverResource: AdserverRestResource,
				public publisherResource: PublisherRestResource,
				) {
				super(messageService, componentFactoryResolver);
	}

	makeFields() {
		const fields: InputFieldContainer = {
			container: [
				{ name: 'id', type: 'text', value: null},
				{ name: 'site_name', type: 'text', value: null, options: {title: 'name'}},
				{ name: 'site_status', type: 'select', value: null, options: this.websiteStatuses},
				{ name: 'publisher', type: 'select', value: null, options: this.publishers},
			],
			underline: [
				{ name: 'site_url', type: 'text', value: null},
				{ name: 'cdn_domain', type: 'text', value: null},
				{ name: 'lvl2_domain', type: 'text', value: null},
				{ name: 'url_mask', type: 'text', value: null},
				{ name: 'silent_auth', type: 'boolean', value: null, options: {title: 'silent auth'}},
				{ name: 'is_adscan_enabled', type: 'boolean', value: null, options: {title: 'adscan'}},
				{ name: 'timeout', type: 'text', value: null},
				{ name: 'adservers', type: 'multiSelect', value: null, options: this.adservers},
				{ name: 'is_lite', type: 'boolean', value: null, options: {title: 'is lite'}},
			]
		};
		return fields;
	}
}
