import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {PublishersService} from '../../publishers.service';
import {Publisher, Website} from '../../models/data-structure';
import {AuthService} from '../../../auth/auth.service';
import {Message, MessageService, MessageState} from '../../../shared/message.service';
import {FeeResource, FeeTimeLine} from '../../../resources/fee.resource';
import {ActionType} from '../../../resources/budget.resource';
import {CoreService} from '../../../shared/core.service';


@Component({
	selector: 'app-fee-management',
	templateUrl: './fee-management.component.html',
})

export class FeeManagementComponent implements OnInit {
	publishers: Publisher[];
	dataSubscription: Subscription;
	noPublishers = true;
	selectedPublisherId: string;
	selectedWebsiteId: string;
	feeTimeLines: FeeTimeLine[];
	showSpinner = false;
	showAddFeeModal = false;
	addType = ActionType.ADD;
	feeToAdd: FeeTimeLine;
	defaultFeeValue = '41';
	lastFee: FeeTimeLine;
	message: Message;
	isEditable = false;
	subscription: Subscription;

	pages: string[];
	pageSize = 10;
	selectedPage = 1;

	constructor(private publishersService: PublishersService, private authService: AuthService,
				private messageService: MessageService, private feeResource: FeeResource,
				private coreService: CoreService) {
	}

	ngOnInit() {
		const publishers$ = this.publishersService.getPublishers();
		this.dataSubscription = publishers$.subscribe(pubs => {
			this.publishers = JSON.parse(JSON.stringify(pubs));
			this.noPublishers = this.publishers ? this.publishers[0] === undefined : true;
			if (this.noPublishers) {
				this.authService.logout();
				this.messageService.setNoDataMessage();
			} else {
				this.getListOfFees();
			}
		});
	}

	setAll(publisherOrWeb: any) {
		return !publisherOrWeb ? '*' : publisherOrWeb;
	}

	createFee(aab_pub_id: string, aab_site_id: string, value: string, date: Date | string): FeeTimeLine {
		return {
			publisher: aab_pub_id,
			website: aab_site_id,
			value: value,
			date: date
		};
	}

	createFeeToAdd(aab_pub_id?: string, aab_site_id?: string) {
		this.feeToAdd = this.createFee(aab_pub_id, aab_site_id, this.defaultFeeValue, this.lastFee.date);
	}

	setDefaultLastFee(aab_pub_id: string, aab_site_id: string) {
		this.lastFee = this.createFee(aab_pub_id, aab_site_id, this.defaultFeeValue, null);
	}

	setLastDate(aab_pub_id: string, aab_site_id: string) {
		const pub_id = this.setAll(aab_pub_id);
		const site_id = this.setAll(aab_site_id);
		this.feeResource.getLastFee(pub_id, site_id).subscribe(res => {
			if (res['valid_from']) {
				res['date'] = this.coreService.convertTimestampToStringDate(res['valid_from'] + (24 * 60 * 60));
			}
			this.lastFee = res;
			this.feeTimeLines.forEach(fee => {
				if (this.lastFee.id === fee.id) {
					fee.isLast = true;
				}
			});
			this.createFeeToAdd(aab_pub_id, aab_site_id);
		}, err => {
			this.setDefaultLastFee(pub_id, site_id);
			this.createFeeToAdd(aab_pub_id, aab_site_id);
		});
	}

	convertFeeValueToString(fee: FeeTimeLine): string {
		if (typeof fee.value === 'number') {
			const f = fee.value.toString();
			return [f.slice(0, -2), f.slice(-2)].join('.');
		}
		return fee.value;
	}

	getListOfFees(aab_pub_id?: string, aab_site_id?: string) {
		const p = this.selectedPage - 1;
		this.showSpinner = true;
		const params = {};
		if (aab_pub_id) {
			params['publisher'] = aab_pub_id;
		}
		if (aab_site_id) {
			params['website'] = aab_site_id;
		}
		if (p && this.pages[p]) {
			params['_search_after'] = this.pages[p];
		}
		this.isEditable = !!((aab_pub_id && aab_site_id) || aab_pub_id === '*' || aab_pub_id === 'eyeo');
		this.feeResource.getItems(params).subscribe(res => {
			if (res['success']) {
				if (p === 0 && res['pages']) {
					this.pages = res['pages'];
				}

				res['feetimelines'].forEach(f => {
					f['date'] = this.coreService.convertTimestampToStringDate(f['valid_from']);
					f['value'] = this.convertFeeValueToString(f);
				});
				this.feeTimeLines = res['feetimelines'];
			}
			this.setLastDate(aab_pub_id, aab_site_id);
			this.showSpinner = false;
		});
	}

	changeFilter(data: {publisher: Publisher, website: Website }) {
		this.selectedPublisherId = data.publisher.publisher_data.aab_publisher_id;
		this.selectedWebsiteId = data.website.aab_site_id;
		this.getListOfFees(this.selectedPublisherId, this.selectedWebsiteId);
		this.createFeeToAdd(this.selectedPublisherId, this.selectedWebsiteId);
	}

	sendMessage(message: {actionType: ActionType, fee: FeeTimeLine}) {
		this.actionFee(message.fee, message.actionType);
	}

	convertValueToNumber(value: string | number) {
		let result;
		if (typeof value === 'string') {
			const v = value.split('.');
			if (v.length === 1) {
				result = v[0] + '00';
			} else if (v[1].length === 1) {
				result = v[0] + v[1] + '0';
			} else if (v[1].length === 2) {
				result = v[0] + v[1];
			}
			return parseInt(result, 10);
		}
		return null;
	}

	actionFee(fee: any, actionType: ActionType) {
		fee.value = this.convertValueToNumber(fee.value);
		if (actionType === ActionType.ADD) {
			fee.valid_from = fee.date;
			delete fee.date;
			this.showSpinner = true;
			this.subscription = this.feeResource.createItem(fee)
				.subscribe(res => {
					this.showSpinner = false;
					this.message = {header: 'Fee ' + res['feetimeline']['id'] + ' was succesfully created.', state: MessageState.POSITIVE};
					this.changeFees();
				}, err => {
					this.showSpinner = false;
					if (err.status === 403) {
							this.message = {header: err.error.title, description: err.error.description, state: MessageState.NEGATIVE};
					} else {
						this.message = {
							header: 'Fee wasn\'t added.',
							description: 'Please try again.',
							state: MessageState.NEGATIVE
						};
					}
				});
		} else if (actionType === ActionType.EDIT) {
				this.showSpinner = true;
				this.subscription = this.feeResource.saveItem(fee)
					.subscribe(res => {
						this.showSpinner = false;
						this.message = {header: 'Fee ' + res['feetimeline']['id'] + ' was succesfully edited.', state: MessageState.POSITIVE};
						this.changeFees();
					}, err => {
						this.showSpinner = false;
						if (err.status === 403) {
							this.message = {header: err.error.title, description: err.error.description, state: MessageState.NEGATIVE};
						} else {
							this.message = {header: 'Fee wasn\'t edited.', description: 'Please try again.', state: MessageState.NEGATIVE};
						}
					});
		}
	}

	changeFees() {
		this.getListOfFees(this.selectedPublisherId, this.selectedWebsiteId);
	}

	pageChange() {
		this.getListOfFees(this.selectedPublisherId, this.selectedWebsiteId);
	}

}
