import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RestBaseResource} from './rest-base.resource';
import {environment} from '../../environments/environment';

@Injectable()
export class SettingsResource extends RestBaseResource {
	url = '/settings';
	listUrl = '/settings';

	constructor(protected httpClient: HttpClient) {
		super(httpClient);
	}
}
