import {Component, Input} from '@angular/core';

interface Description {
	name: string;
	desc: string;
}


@Component({
	selector: 'app-pop-up-descriptions',
	templateUrl: './pop-up-descriptions.component.html',
})

export class PopUpDescriptionsComponent {
	@Input() label: string;
	@Input() descriptions: Description[];
}

