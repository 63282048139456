<span id="header_filter_container">
	<h1 class="ui header" i18n="website detail header" style="margin: 0;">
		{{header}}
	</h1>
	<app-publisher-website-select
					  id="core_select"
					  *ngIf="!noPublishers"
                      [publishers]="publishers"
                      [selectedWebsite]="selectedWebsite"
                      [selectedPublisher]="selectedPublisher">
	</app-publisher-website-select>
</span>
<span *ngIf="!noPublishers">
	<router-outlet ></router-outlet>
</span>

