import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
	selector: 'app-budget-publisher-filter',
	templateUrl: './budget-publisher-filter.component.html',

})
export class BudgetPublisherFilterComponent {
	@Input() aabPublisherIds: string[];
	@Output() onChangePublisher = new EventEmitter<string[]>();
	selectedPublishers: string[];

	changePublishers() {
		this.onChangePublisher.emit(this.selectedPublishers);
	}

}
