import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { RestComponent } from './rest.component';
import { RestItemComponent } from './rest-item/rest-item.component';
import { BaseInputComponent } from './inputs/base-input.component';
import { DateInputComponent } from './inputs/date-input.component';
import { DateTimeInputComponent } from './inputs/datetime-input.component';
import { TextInputComponent } from './inputs/text-input.component';
import { LongTextInputComponent } from './inputs/longText-input.component';
import { SelectInputComponent } from './inputs/select-input.component';
import { BooleanInputComponent } from './inputs/boolean-input.component';
import { NumberInputComponent } from './inputs/number-input.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { ControlsComponent } from './controls/controls.component';
import { FileInputComponent } from './inputs/file-input.component';
import { SelectInputUneditComponent } from './inputs/select-input-unedit.component';
import { MultiSelectInputComponent } from './inputs/multi-select-input.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
	],
	declarations: [
		RestComponent,
		RestItemComponent,
		BaseInputComponent,
		TextInputComponent,
		DateInputComponent,
		DateTimeInputComponent,
		LongTextInputComponent,
		FileInputComponent,
		SelectInputComponent,
		SelectInputUneditComponent,
		MultiSelectInputComponent,
		BooleanInputComponent,
		NumberInputComponent,
		DeleteModalComponent,
		ControlsComponent,
	],
	providers: [
	],
	exports: [
		RestComponent,
		SharedModule,
		RestItemComponent,
		DeleteModalComponent,
		ControlsComponent,
	],
	entryComponents: [
		BaseInputComponent,
		TextInputComponent,
		DateInputComponent,
		DateTimeInputComponent,
		LongTextInputComponent,
		SelectInputComponent,
		SelectInputUneditComponent,
		MultiSelectInputComponent,
		BooleanInputComponent,
		NumberInputComponent,
		FileInputComponent,
		DeleteModalComponent,
		ControlsComponent,
	]
})
export class RestModule { }
