import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {pathValidator} from '../../directives/validators/url-validator.directive';
import {BlockRateResource} from '../../../resources/block-rate.resource';
import {BlockRateService} from '../../../core/block-rate/block-rate.service';
import {BrsSite} from '../../../core/models/data-structure';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';


@Component({
	selector: 'app-website-input',
	templateUrl: './website-input.component.html',
	styleUrls: ['./website-input.component.less'],
})

export class WebsiteInputComponent implements OnInit, OnDestroy {
	@Input() useSelectedSite ? = false;
	@Input() noControls ? = false;
	@Output() formClosed = new EventEmitter<Boolean>();
	@Output() formReady = new EventEmitter<any>();
	websiteForm: FormGroup;
	website: BrsSite;
	sending: false;
	showError: any = false;
	showSuccess = false;
	subscription: Subscription;
	formChangeSubscription: Subscription;

	constructor(private fb: FormBuilder,
				private blockRateResource: BlockRateResource,
				private blockRateService: BlockRateService) {}

	ngOnInit() {
		this.websiteForm = this.fb.group({
			name: [''],
			url: ['', [Validators.required,
							pathValidator(),
							]],
			ecpm: ['2.0'],
			impPerPage: ['2.8']
		});

		if (this.useSelectedSite) {
			this.website = this.blockRateService.getSelectedSite().value;
			this.websiteForm.patchValue(this.website);
		}
		const result = this.websiteForm.valueChanges.pipe(debounceTime(500));
		this.formChangeSubscription = result.subscribe((data) => { this.onFormChanges(data); });
	}

	submit() {
		this.showError = false;
		this.showSuccess = false;
		if (this.sending) {
			return;
		}
		if (this.useSelectedSite) {
			this.subscription = this.blockRateResource.postUpdateWebsite(this.website.hash, this.websiteForm.value).subscribe(ret => {
				this.handleResponse('updated', ret);
			});
		} else {
			this.subscription = this.blockRateResource.postNewWebsite(this.websiteForm.value).subscribe(ret => {
				this.handleResponse('created', ret);
			});
		}
	}

	onFormChanges(data) {
		if (this.websiteForm) {
			const websiteData = {
				valid: this.websiteForm.valid,
				name: this.websiteForm.value.name,
				url: this.websiteForm.value.url,
				ecpm: this.websiteForm.value.ecpm
			};
			this.formReady.emit(websiteData);
		}
	}

	handleResponse(okKey: string, ret) {
		this.sending = false;
		this.subscription.unsubscribe();
		this.subscription = null;

		if (!ret[okKey]) {
			this.showError = ret.error || true;
		} else {
			this.showSuccess = true;
			// console.log("requesting sites update with hash", ret.hash);
			this.blockRateService.updateBrsSites(ret.hash).subscribe(
				(sites) => {

					this.formClosed.emit(true);
				});
		}
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		if (this.formChangeSubscription) {
			this.formChangeSubscription.unsubscribe();
		}
	}
}
