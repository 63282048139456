import {NgModule} from '@angular/core';
import {ChangePasswordComponent} from './changepassword/change-password.component';
import {SharedModule} from '../../shared/shared.module';
import {UserResource} from '../../resources/user.resource';

@NgModule({
	declarations: [ChangePasswordComponent],
	imports: [
		SharedModule,
	],
	exports: [
		ChangePasswordComponent
	],
	providers: [UserResource]
})
export class LoggedInUserModule { }
