<div *ngIf="items && items.length > 0" class="ui horizontal list">
	<div class="item">
		<label id="multi_filter_label"><strong>{{upperCaseFirstLetter(name)}}:</strong></label>
	</div>
	<div class="item">
		<fui-multi-select 	 id="multi_selector"
							 [(ngModel)]="selectedItems"
							 [options]="items"
							 icon="folder open outline"
							 (ngModelChange)=onChangeItems()
							 placeholder="All"
							 #selectMulti>
			<div class="ui icon search input">
				<i id="filter_multi_search_icon" class="search icon"></i>
				<input fuiSelectSearch id="multi_search" type="text" placeholder="Search {{name}}...">
			</div>
			<div class="header">
				<div class="floating ui circular teal label">{{selectedItems.length > 0 ? selectedItems.length : items.length}}</div>
				<i class="list icon"></i>
				{{upperCaseFirstLetter(name)}}
			</div>
			<div class="scrolling menu" id="filter_sections_menu">
				<fui-select-option *ngFor="let o of selectMulti.filteredOptions" [value]="o"></fui-select-option>
			</div>
		</fui-multi-select>
	</div>
</div>
