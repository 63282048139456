<div id="change-password">
	<div *ngIf="messages?.length > 0">
		<app-message *ngFor="let message of messages" [message]="message"></app-message>
	</div>

	<form novalidate *ngIf="!isPassChanged" class="ui form" [formGroup]="form" (submit)="change_password()" >
		<div class="field fluid">
	    	<label>Current Password</label>
	    	<input 	type="password"
					placeholder="Current Password"
					formControlName="current_password" />
	  	</div>
		<div *ngIf="form.get('current_password').hasError('required') && form.get('current_password').touched" class="ui negative message">
			Current password is required.
	  	</div>
		<div class="ui hidden divider"></div>
		<app-new-password-form (formReady)="formInitialized('newPassword', $event)"></app-new-password-form>
		<div class="ui hidden divider"></div>
		<button class="fluid ui button" type="submit" [disabled]="!form.valid">Submit</button>
		<div class="ui hidden divider"></div>
		<button type="button" class="fluid ui button" id="reset_button"
			(click)="form.reset({})">Reset form</button>
	</form>
</div>
