<app-interval-chooser *ngIf="canSeeChart" [intervalData]="intervalData" [hideIntervalSwitch]="false"></app-interval-chooser>
<div *ngIf="websites" [class.noCaption]="false" id="chart_segment">
	<div *ngIf="canSeeChart; else TooManySelected">
		<span class="stat-chart-segment">
			<app-multi-checkbox *ngIf="rights.super_admin && (liteChartDataOk || (adSystems && adSystems.length > 0))" class="metadata-checkbox" [items]="adSystems" [name]="'Ad Systems'" [selectedItems]="selectedAdSystems" (changeSelectedItems)="changeAdSystems($event)"></app-multi-checkbox>
			<app-table-chart class="table-chart" *ngIf="adSystems && ultraLiteChart" [aabSiteIds]="aabSiteIds" [intervalData]="intervalData" [chart]="ultraLiteChart" [resource]="ultraLiteResource" [metadata]="metadataUltraLite"
							 [chartDesc]="'The graph above shows unblocked impressions, pageviews, clicks and revenue. This data is only available in daily interval and it is updated every day.'"
							 [table]="ultraLiteTable" (changeDataOk)="changeLiteChartDataOk($event)"></app-table-chart>
			<div [ngClass]="showDimmer ? 'ui active dimmer' : 'inactive dimmer'">
				<div class="ui medium text loader">Loading</div>
			</div>
		</span>
		<div class="ui hidden divider"></div>
		<div class="stat-chart-segment">
			<app-multi-checkbox *ngIf="rights.super_admin && (oldChartDataOk || (adServers && adServers.length > 0))" class="metadata-checkbox" [items]="adServers" [name]="'Ad Servers'" [selectedItems]="selectedAdServers" (changeSelectedItems)="changeAdServers($event)"></app-multi-checkbox>
			<app-table-chart class="table-chart" *ngIf="adServers && impressionsChart" [aabSiteIds]="aabSiteIds" [intervalData]="intervalData" [chart]="impressionsChart" [resource]="impressionsResource" [metadata]="metadataImpressions"
							 [chartDesc]="'The graph above shows unblocked impressions by their type. It is updated every hour.'"
							 [table]="impressionsTable" (changeDataOk)="changeImpChartDataOk($event)"></app-table-chart>
		</div>
	</div>
	<ng-template #TooManySelected>
		<app-info-segment [text]="'Only data for 100 or less selected websites can be shown for performance reasons. Try filtering the websites in the select boxes above.'"></app-info-segment>
	</ng-template>
	<div *ngIf="canSeeChart && !liteChartDataOk && !oldChartDataOk">
		<app-info-segment [text]="'No data to show.'"></app-info-segment>
	</div>
</div>
