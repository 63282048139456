import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LiteComponent} from './lite.component';
import {LoggedInGuard} from '../../auth/guard/logged-in.guard';
import {AllowedGuard} from '../../auth/guard/allowed.guard';

const routes: Routes = [
	{path: '', component: LiteComponent, canActivate: [LoggedInGuard, AllowedGuard]},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class LiteRoutingModule {
}
