import { Component, ComponentFactoryResolver } from '@angular/core';
import { MessageService } from 'app/shared/message.service';
import { InputFieldContainer, RestComponent } from '../rest/rest.component';
import { Observable } from 'rxjs';
import { LiteFilesResource } from '../../../resources/lite.resource';

@Component({
	selector: 'app-lite-management-files',
	templateUrl: './lite-management.component.html',
})

export class LiteManagementFilesComponent extends RestComponent {
	title = 'Lite Loader Files Management';
	itemName = 'file';
	fields = this.makeFields();
	itemMessageInHandler = function(item, data): void {
			item.cmps['file'].instance.value = data['name'];
		};

	constructor(public injected_resource: LiteFilesResource,
				public messageService: MessageService,
				public componentFactoryResolver: ComponentFactoryResolver,
				) {
				super(messageService, componentFactoryResolver);
	}

	makeFields() {
		const fields: InputFieldContainer = {
			container: [
				{ name: 'file', type: 'file', value: null },
			],
			underline: [
				{ name: 'content', type: 'longText', value: null }
			],
		};
		return fields;
	}

	createForm(): void {
		this.getFormData().subscribe(res => {
			const items = [];
			res['files'].forEach(f => {items.push({'file': f.name, 'content': f.content}); });
			this.itemList = items;
			this.showSpinner = false;
		});
	}

	getFormData() {
		const items = this.resource.listItems();
		return items;
	}

	getItemName(index): string {
		if ( index && this.itemList[index] ) {
			return 'Loader name' + this.itemList[index]['name'];
		}
		return '';
	}

}
