import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

export enum SasZonesMainTypes {
	BRANDING = 'branding',
	PREROLL = 'preroll',
	POSTROLL = 'postroll',
}

export interface ZoneType {
	type: SasZonesMainTypes;
	value: boolean;
}

interface Postfix {
	name: string;
	value: boolean;
}

export interface SasZone {
	prefix: string;
	postfixes: Postfix[];
	value?: boolean;
	_web?: string;
	_subdomain?: string;
}

@Injectable()
export class SasZonesResource {
	url = '/sas-zones';

	constructor(private httpClient: HttpClient) {
	}

	get(aab_publisher_id: string): Observable<{'sas-zones-all': SasZone[], 'sas-zones-true': SasZone[]}> {
		return this.httpClient.get<{'sas-zones-all': SasZone[], 'sas-zones-true': SasZone[]}>(
			`${environment.apiEndpoint}${this.url}/${aab_publisher_id}`
		);
	}

	private removeAlonePostfixes(sasZones: SasZone[], starPostfixes: Postfix[]) {
		const allPostfixes = starPostfixes.map(p => p.name);
		sasZones.forEach(sasZone => {
						sasZone.postfixes.forEach(postfix => {
							if (allPostfixes.includes(postfix.name)) {
								postfix.value = false;
							}
						});
					});

	}

	private makeTrueZoneTypesStar(sasZones: SasZone[], zoneTypes: ZoneType[]): SasZone[] {
		const sasZonesCopied = JSON.parse(JSON.stringify(sasZones));
		const starPostfixes = [];
		zoneTypes.forEach(zoneType => {
			if (zoneType.value) {
				if (zoneType.type === SasZonesMainTypes.PREROLL) {
					starPostfixes.push({name: 'preroll_1', value: true});
					starPostfixes.push({name: 'preroll_2', value: true});
				} else if (zoneType.type === SasZonesMainTypes.POSTROLL) {
					starPostfixes.push({name: 'postroll_1', value: true});
				} else {
					starPostfixes.push({name: zoneType.type, value: true});
				}
			}
		});
		this.removeAlonePostfixes(sasZonesCopied, starPostfixes);
		if (starPostfixes.length > 0) {
			sasZonesCopied.push({prefix: '*', postfixes: starPostfixes, _subdomain: '_', _web: 'all', value: true});
		}
		return sasZonesCopied;
	}

	post(aab_publisher_id: string, sasZones: SasZone[], zoneTypes: ZoneType[]): Observable<{'ok': boolean}> {
		const sasZonesCopied = this.makeTrueZoneTypesStar(sasZones, zoneTypes);
		return this.httpClient.post<{'ok': boolean}>(`${environment.apiEndpoint}${this.url}/${aab_publisher_id}`,
			{'sas-zones': sasZonesCopied});
	}
}
