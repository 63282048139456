import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
	selector: 'app-selector',
	templateUrl: './selector.component.html',
})

export class SelectorComponent implements OnInit {
	@Input() options: any[]; // has to have 'name' attribute
	@Input() label: string;
	@Output() valueChanges = new EventEmitter<any>();
	isMore = true;
	selectedOption: any;

	ngOnInit() {
		if (this.options.length === 1) {
			this.selectedOption = this.options[0];
			this.isMore = false;
		}
	}

	changeValue() {
		this.valueChanges.emit(this.selectedOption);
	}
}
