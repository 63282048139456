import {Component} from '@angular/core';
import {CoreFilterMetaComponent} from '../core-filter-meta.component';



@Component({
	selector: 'app-budget',
	templateUrl: './budget.component.html',
})
export class BudgetComponent extends CoreFilterMetaComponent {
}
