import {Component} from '@angular/core';
import {SelectedWebsiteParamsComponent} from '../../../meta/selected-website-params/selected-website-params.component';
import {ActiveFragment} from '../../../../shared/chart.service';

@Component({
	selector: 'app-website-detail',
	templateUrl: './website-detail.component.html',
})

export class WebsiteDetailComponent extends SelectedWebsiteParamsComponent {
	blockRateTrendFragment = ActiveFragment.BLOCK_RATE_TREND;
	blockRateOsBrowserFragment = ActiveFragment.BLOCK_RATE_OS_BROWSER;
	impressionsFragment = ActiveFragment.IMPRESSIONS;
	hostageStateFragment = ActiveFragment.HOSTAGE_STATE;
	possibleFragments = [this.blockRateTrendFragment, this.blockRateOsBrowserFragment, this.impressionsFragment, this.hostageStateFragment];

}
