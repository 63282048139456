import {Component, OnDestroy, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import {BudgetInput, BudgetOutput} from '../../../resources/budget.resource';
import {Subscription} from 'rxjs';
import {Message} from '../../../shared/message.service';

@Component({
	selector: 'app-budget-tree-table',
	templateUrl: './budget-tree-table.component.html',
})

export class BudgetTreeTableComponent implements OnChanges, OnDestroy {
	@Input() budgetInput: BudgetInput;
	@Input() budgetOutput: BudgetOutput;
	@Input() generatedDate: string;
	@Output() tagsChanged = new EventEmitter();
	subscription: Subscription;
	showLoader = false;
	showTable = false;
	unwrappedPanels = [];
	message: Message;

	ngOnChanges() {
		this.showTable = false;
	}

	changeTags() {
		this.tagsChanged.emit();
	}

	changeMessage(message: Message) {
		this.message = message;
	}

	unwrapPanel(name) {
		if (!(this.unwrappedPanels.includes(name))) {
			this.unwrappedPanels.push(name);
		} else {
			this.unwrappedPanels = this.unwrappedPanels.filter(p => p !== name);
		}
	}

	unwrappedPanel(name) {
		return this.unwrappedPanels.includes(name);
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
