import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {BudgetResource} from '../../../resources/budget.resource';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-budget-filter',
	templateUrl: './budget-filter.component.html',

})
export class BudgetFilterComponent implements OnInit, OnDestroy, OnChanges {
	@Input() aab_site_ids: string[];
	@Input() dateFrom: Date;
	@Input() dateTo: Date;
	@Output() onChangeAdServer = new EventEmitter<string[]>();
	adServers: string[];
	selectedAdServers: string[];
	subscription: Subscription;
	getNewAdservers = true;
	showSpinner = false;

	constructor(private budgetResource: BudgetResource) {
	}

	ngOnInit() {
		this.selectedAdServers = [''];
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes) {
			this.getNewAdservers = true;
			this.selectedAdServers = [];
			this.adServers = [];
		}
	}

	changeAdServers() {
		this.onChangeAdServer.emit(this.selectedAdServers);
	}

	getAdServers() {
		if (this.getNewAdservers) {
			this.showSpinner = true;
			this.subscription = this.budgetResource.postAdServers(this.aab_site_ids, this.dateFrom, this.dateTo).subscribe(res => {
			this.adServers = res.adservers;
			this.showSpinner = false;
			this.getNewAdservers = false;
		});
		}
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

}
