import {NgModule} from '@angular/core';
import {WebsiteModule} from './website/website.module';

import {DashboardComponent} from './dashboard.component';
import {SharedModule} from '../../shared/shared.module';


@NgModule({
	declarations: [
		DashboardComponent,
	],
	imports: [
		WebsiteModule,
		SharedModule,
	],
	providers: [

	]
})

export class DashboardModule {

}
