
import {Component, Input, OnInit} from '@angular/core';
import {Website} from '../../models/data-structure';

@Component({
	selector: 'app-ad-scan-table',
	templateUrl: './ad-scan-table.component.html',
})

export class AdScanTableComponent implements OnInit {
	@Input() selectedWebsites: Website[] = [];
	isDesc: boolean;
	column: string;
	direction: number;

	ngOnInit(): void {
		this.sortTable('name');
	}

	sortTable(by: string) {
		this.isDesc = !this.isDesc;
		this.column = by;
		this.direction = this.isDesc ? 1 : -1;
	}
}
