import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';


interface ConfigResponse {
	output: string;
}

@Injectable()
export class ConfigResource {
	url = '/config';

	constructor(private httpClient: HttpClient) {
	}

	get(aab_publisher_id: string): Observable<ConfigResponse> {
		return this.httpClient.get<ConfigResponse>(
			`${environment.apiEndpoint}${this.url}/${aab_publisher_id}`
		);
	}

}
