import {Component} from '@angular/core';
import {ChartResource} from '../../../resources/chart.resource';
import {ChartService} from '../../../shared/chart.service';
import {IntervalService} from '../../../shared/interval.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MetaChartSegmentComponent} from '../../meta/meta-chart-segment/meta-chart-segment.component';
import {SectionsService} from '../../../shared/sections.service';
import {CoreService} from '../../../shared/core.service';


@Component({
	selector: 'app-ad-scan-templates-chart-segment',
	templateUrl: './ad-scan-templates-chart-segment.component.html'
})

export class AdScanTemplatesChartSegmentComponent extends MetaChartSegmentComponent {
	legendDescription = [
		{name: 'Invalid Selector', desc: 'the provided css selector is not valid (syntax error)'},
		{name: 'No Advert Found', desc: 'advert was not found in given element'},
		{name: 'DOM Changed', desc: 'the structure of the page has probably changed, ' +
				'the provided selector didn\'t match any elements on the page'},
		{name: 'Failed Count', desc: 'number of zones that didn\'t load'},
		{name: 'Expected Count', desc: 'expected number of zones on the page'},
		{name: 'Template Views', desc: 'number of page-views of the given template'},
		{name: 'Page Views', desc: 'number of page-views of the website'},

	];

	constructor(protected chartResource: ChartResource,
				protected chartService: ChartService,
				protected intervalService: IntervalService,
				protected route: ActivatedRoute,
				protected coreService: CoreService,
				protected sectionsService: SectionsService,
				private router: Router) {
		super(chartResource, chartService, intervalService, route, coreService, sectionsService);
	}

	beforePost() {
		this.postData();
	}

	navigateToTemplate(template: string) {
		this.router.navigate([],  {queryParams: {t: template}, fragment: 'adscan_zones_chart',
			relativeTo: this.route, queryParamsHandling: 'merge'});
	}
}
