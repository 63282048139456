import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';


@Injectable()
export class HostageStateResource {
	url = '/hostage_state';

	constructor(private httpClient: HttpClient) {
	}

	get(aab_site_id: string): Observable<any[]> {
		return this.httpClient.get<any[]>(
			`${environment.apiEndpoint}${this.url}/${aab_site_id}`
		);
	}
}
