import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {IntervalChooserData} from '../shared/interval.service';


export interface AdScanZones {
	website: string;
	template?: string;
	date: string;
	status: string;
	zone: string;
	count: number;
}

export interface AdScanTemplatesResponse {
	templates: string[];
}


@Injectable()
export class AdScanResource {
	url = '/adscan';

	constructor(private httpClient: HttpClient) {
	}

	postAdScanZones(aab_site_ids: string[], with_template: boolean): Observable<AdScanZones[]> {
		return this.httpClient.post<AdScanZones[]>(
			environment.apiEndpoint + this.url + '/zones',
			{aab_site_ids: aab_site_ids, with_template: with_template}
		);
	}

	postAdScanTemplates(aab_site_ids: string[], intervalChooserData: IntervalChooserData): Observable<AdScanTemplatesResponse> {
		return this.httpClient.post<AdScanTemplatesResponse>(
			environment.apiEndpoint + this.url + '/zones/templates',
			{aab_site_ids: aab_site_ids, interval_enum: intervalChooserData.intervalEnum, period: intervalChooserData.period,
				time_zone: intervalChooserData.timeZone, interval: intervalChooserData.interval}
		);
	}
}
