import {Component, Input} from '@angular/core';


@Component({
	selector: 'app-hint-icon',
	templateUrl: './hint-icon.component.html',
})
export class HintIconComponent {
	@Input() hint: string;
}

