<span *ngIf="label">
	<label id="select_label"><strong>{{label}} :</strong></label>
	<fui-select *ngIf="isMore; else one"
				class="selection"
				id="selector"
				[(ngModel)]="selectedOption"
				(ngModelChange)=changeValue()
				[options]="options"
				labelField="name"
				isSearchable="true"
				placeholder=" "
				#selectPub>
		<fui-select-option *ngFor="let option of selectPub.filteredOptions" id="select_one_{{option.name | cssFriendly}}"
						   [value]="option">
		</fui-select-option>
	</fui-select>
	<ng-template #one id="one_label">{{selectedOption.name}}</ng-template>
</span>
