import {Component, OnDestroy, OnInit} from '@angular/core';
import {PublishersService, SelectedData} from '../../publishers.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-selected-data-params',
	template: ''
})

export class SelectedDataParamsComponent implements OnInit, OnDestroy {
	private routeSubscription: Subscription;
	protected selectedData: SelectedData;

	constructor(protected publishersService: PublishersService,
				protected route: ActivatedRoute) {}

	ngOnInit() {
		this.routeSubscription = this.route.queryParams.subscribe((params: Params) => {
			this.selectedData = this.publishersService.getSelectedDataFromQueryParams(params);
			this.paramsChange();
		});
	}

	ngOnDestroy() {
		if (this.routeSubscription) {
			this.routeSubscription.unsubscribe();
		}
	}

	paramsChange() {
	}

}
