import {Injectable, SimpleChanges} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';


@Injectable()
export class CoreService {

	constructor(private authService: AuthService,
				private router: Router,
				private route: ActivatedRoute) {
	}

	navigateTofragment(fragment: string) {
		this.router.navigate([],  {queryParams: this.route.snapshot.queryParams, fragment: fragment, relativeTo: this.route});
	}

	getFragment(): string {
		return this.route.snapshot.fragment;
	}

	getBaseUrl(): string {
		const user = this.authService.getUser();
		if (user.brs_only && !(user.frontend_rights.stats || user.frontend_rights.config)) { return '/block-rate/'; }
		if (user.frontend_rights.lite && !(user.frontend_rights.stats || user.frontend_rights.config)) { return '/aab-lite'; }
		return '/dashboard';
	}

	prepareHeaderFromUrlPath(path: string): string {
		const words = path.split('-');
		let header = '';
		words.forEach(word => {
			header += this.upperCaseFirstLetter(word) + ' ';
		});
		return header;
	}

	upperCaseFirstLetter(string: string): string {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	hideWebsitesByPath(path: string): boolean {
		return path === 'installation';
	}

	isElementWithContentExist(id: string): boolean {
		const element = document.getElementById(id);
		return element.children.length > 0;
	}

	scrollToElement(element: Element): void {
		element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
	}

	scrollToElementByFragment(): void {
		const fragment = this.getFragment();

		if (fragment == null) {
			return;
		} else {
			const element = document.querySelector('#' + fragment);
			this.scrollToElement(element);
		}
	}

	isLessThan100(array: any[]) {
		return array.length <= 100;
	}

	formatNumberSeparateByThousands(count: number) {
		return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	}

	isChangeInFilterOfWebsites(changes: SimpleChanges) {
		if (changes.websites && !changes.websites.firstChange) {
			const previous = changes.websites.previousValue.map(w => w.aab_site_id);
			const current = changes.websites.currentValue.map(w => w.aab_site_id);
			return !this.isEqualArrays(previous, current);
		} else {
			return true;
		}
	}

	isChangeInFilterOfAabSiteIds(changes: SimpleChanges) {
		if (!changes.aabSiteIds.firstChange) {
			return !this.isEqualArrays(changes.aabSiteIds.previousValue, changes.aabSiteIds.currentValue);
		} else {
			return true;
		}
	}

	isEqualArrays(firstArray: string[], secondArray: string[]): boolean {
		if (!Array.isArray(firstArray) || ! Array.isArray(secondArray) || firstArray.length !== secondArray.length) {
			return false;
		}
		const arr1 = firstArray.concat().sort();
		const arr2 = secondArray.concat().sort();

		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i]) {
				return false;
			}
		}
		return true;
	}

	isNumberFloat(number: number): boolean {
		return Number(number) === number && number % 1 !== 0;
	}

	convertTimestampToStringDate(timestamp: number): string {
		return this.convertTimestampToDate(timestamp).toISOString().split('T')[0];
	}

	convertTimestampToDate(timestamp: number): Date {
		return new Date(timestamp * 1000);
	}
}
