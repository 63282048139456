import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';
import {SelectedDataParamsComponent} from '../../meta/selected-data-params/selected-data-params.component';
import {DateFromTo, Publisher} from '../../models/data-structure';
import {CheckBoxType, ValueTypeCheckbox} from '../../../shared/partial-forms/slider-checkbox/slider-checkbox.component';
import {Message, MessageState} from '../../../shared/message.service';
import {BudgetOutput, BudgetResource} from '../../../resources/budget.resource';
import {PublishersService} from '../../publishers.service';


@Component({
	selector: 'app-budget-report',
	templateUrl: './budget-report.component.html',
	styleUrls: ['./budget-report.component.less']
})
export class BudgetReportComponent extends SelectedDataParamsComponent implements OnInit, OnDestroy {
	publishers: Publisher[];
	date: string;
	dateTo: string;
	budgetInput = {aab_sites_ids: [], group_by_adserver: true, group_by_day: false, group_by_raw_data: false,
		aggregate_by_website: true, adservers: [], from_scratch: false, error_threshold: 10, time_zone: '+01:00'};
	messages = new Set<Message>();
	showCSVSpinner = false;
	subscription: Subscription;
	groupByAdserversType = CheckBoxType.GROUP_BY_ADSERVER;
	splitByDayType = CheckBoxType.SPLIT_BY_DAY;
	aggregateByWebsiteType = CheckBoxType.AGGREGATE_BY_WEBSITE;
	aggregateByTagsType = CheckBoxType.AGGREGATE_BY_TAGS;
	hideTable = false;
	budgetOutput: BudgetOutput;
	generatedDate: string;
	private MAX_SITES_COUNT = 50;
	errorMessage = {header: 'Something went wrong.', description: 'Please try again.', state: MessageState.NEGATIVE, width: '80%'};
	manyWebsMessage = {header: 'Publisher is not selected.',
				description: `Please select some publisher or website (you can see budget report only for less than
				 ${this.MAX_SITES_COUNT} websites).`,
				state: MessageState.WARNING, width: '80%'};
	badDateMessage: Message;

	constructor(protected publishersService: PublishersService,
				protected route: ActivatedRoute, private budgetResource: BudgetResource) {
		super(publishersService, route);
	}

	ngOnInit() {
		super.ngOnInit();
		this.paramsChange();
	}

	changeMessage(message: Message) {
		if (message && !this.badDateMessage) {
			this.badDateMessage = message;
			this.messages.add(this.badDateMessage);
		} else if (message == null && this.badDateMessage) {
			this.messages.delete(this.badDateMessage);
			this.badDateMessage = null;
		}
	}

	sliderChanged(event: ValueTypeCheckbox) {
		this.hideTable = true;
		switch (event.type) {
			case CheckBoxType.AGGREGATE_BY_TAGS:
				this.budgetInput.group_by_raw_data = !event.value;
				break;
			case CheckBoxType.AGGREGATE_BY_WEBSITE:
				this.budgetInput.aggregate_by_website = event.value;
				break;
			case CheckBoxType.SPLIT_BY_DAY:
				this.budgetInput.group_by_day = event.value;
				break;
			case CheckBoxType.GROUP_BY_ADSERVER:
				this.budgetInput.group_by_adserver = event.value;
				break;
		}
	}

	changeDate(date: DateFromTo) {
		this.hideTable = true;
		this.date = date.dateFrom;
		this.dateTo = date.dateTo;
	}

	changeAdServers(adservers: string[]) {
		this.hideTable = true;
		this.budgetInput.adservers = adservers;
	}

	changeTimeZone(timezone: string) {
		this.hideTable = true;
		this.budgetInput.time_zone = timezone;
	}

	checkSelectedWebsitesCount() {
		if (this.budgetInput.aab_sites_ids.length === 0 || this.budgetInput.aab_sites_ids.length > this.MAX_SITES_COUNT - 1) {
			this.messages.add(this.manyWebsMessage);
		} else {
			this.messages.delete(this.manyWebsMessage);
		}
	}

	paramsChange() {
		this.hideTable = true;
		const selectedWebsites = this.publishersService.getSelectedWebsitesFromState(this.selectedData);
		this.budgetInput.aab_sites_ids = _.map(selectedWebsites, 'aab_site_id');
		this.checkSelectedWebsitesCount();
	}

	getCSV() {
		this.showCSVSpinner = true;
		this.subscription = this.budgetResource.postCSV(this.budgetInput, this.date, this.dateTo).subscribe(res => {
			this.messages.delete(this.errorMessage);
			this.setDownload(res.budget_output, res.date);
			this.showCSVSpinner = false;
		}, err => {
			this.showCSVSpinner = false;
			this.messages.add(this.errorMessage);
		});
	}

	setDownload(text: any, date: string) {
		const link = document.createElement('a');
		link.setAttribute('href', 'data:text/csv;charset=utf-8,' + text);
		const formatDate = date.replace(/\s/, '_');
		link.setAttribute('download', `budget_${formatDate}.csv`);
		link.click();
	}

	formatDate(date: string): string {
		const array = date.split(':');
		let result = array[0] + ':' + array[1];
		result = result.replace('T', ' ');
		return result;
	}

	isValidInput(): boolean {
		return this.messages.size === 0;
	}

	postData(fromScratch: boolean) {
		this.budgetInput.from_scratch = fromScratch;
		this.subscription = this.budgetResource.post(this.budgetInput, this.date, this.dateTo).subscribe(res => {
			this.messages.delete(this.errorMessage);
			this.budgetOutput = res.budget_output;
			this.generatedDate = this.formatDate(res.date);
			this.hideTable = false;

		}, err => {
			this.messages.add(this.errorMessage);
		});
	}

	changeTags() {
		setTimeout(() => {
			this.postData(false);
		}, 1000);
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
