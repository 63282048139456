
	<div class="ui raised segment">
		<ng-container *ngIf="brs; else noSite">
			<h1 class="ui teal header">Install block rate measurement</h1>
			<p>To start measuring the blockrate on your website, you have to install our Javascript tag in your HTML.</p>
			<p>Copy the code and paste it in the &lt;head&gt; section of each page of your website. Make sure the script doesn't get
			   altered in any way.
			</p>
			<p>The Javascript tag is unique for each website, after installing it, click verify to check it is properly installed.
			   Once the script is present, it will start collecting data, you can see the results in the Measure tab 2 hours after installation.
			</p>

			<app-block-rate-code [hash]="brs.hash"></app-block-rate-code>
		</ng-container>
		<ng-template #noSite>
			<h1 class="ui teal header">No site selected</h1>
			Start by creating a website by clicking "add website" in the top menu.<br>
			Once the website is created you will find instructions on how to install the measurement code in your page here.
		</ng-template>
	</div>

	<ng-container *ngIf="brs && !brs.verified">
		<div class="ui raised segment stackable grid">
			<div class="ui four wide column">
				<button id="verify_button" class="ui button" (click)="verifyBrsSite(brs)">Verify</button>
				<div *ngIf="brs.verifiedResolved; else loader" id='verified_tag' [ngClass]="brs.verified ? 'ui teal small basic label' : 'ui red small basic label'">
				{{getVerifyText(brs.verified)}}
				</div>
				<ng-template #loader>
					<div id="verify_loader" class="ui active small inline loader"></div>
				</ng-template>
			</div>
			<div class="ui ten wide column">
				If you already installed the above code to your page, please click on verify.
			</div>
		</div>
	</ng-container>

