import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {Router, NavigationEnd} from '@angular/router';
import {GAService} from './shared/ga.service';
import {Subscription} from 'rxjs';
declare function require(moduleName: string): any;
const {version : appVersion} = require('../../package.json');

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
	subscription: Subscription;

	constructor(public router: Router, private authService: AuthService, public gaService: GAService) {
	}

	ngOnInit(): void {
		localStorage.setItem('adminversion', appVersion);
		this.subscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.gaService.setPage(event.urlAfterRedirects);
				this.gaService.sendPageview();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
