import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {BudgetComponent} from './budget.component';
import {LoggedInGuard} from '../../auth/guard/logged-in.guard';
import {SuperAdminGuard} from '../../auth/guard/superadmin.guard';
import {AllTagsComponent} from './all-tags/all-tags.component';

const budgetRoutes: Routes = [
	{path: 'report', component: BudgetComponent, canActivate: [LoggedInGuard, SuperAdminGuard]},
	{path: 'all-tags', component: AllTagsComponent, canActivate: [LoggedInGuard, SuperAdminGuard]},

];

@NgModule({
	imports: [RouterModule.forChild(budgetRoutes)],
	exports: [RouterModule]
})
export class BudgetRoutingModule {
}
