import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {LoggedInUserModule} from './loggedinuser/loggedinuser.module';
import {BlockRateModule} from './block-rate/block-rate.module';
import {AdminModule} from './admin/admin.module';
import {LiveDemoComponent} from './live-demo/live-demo.component';
import {LiveDemoLinkComponent} from './live-demo/live-demo-link/live-demo-link.component';
import {InstallationComponent} from './installation/installation.component';
import {InstallationDocumentComponent} from './installation/installation-document/installation-document.component';
import {InstallationLinkComponent} from './installation/installation-link/installation-link.component';
import {FaqComponent} from './faq/faq.component';
import {ConfigurationDocumentComponent} from './installation/configuration-document/configuration-document.component';
import {PageWithMessageComponent} from './page-with-message/page-with-message.component';
import {AdScanModule} from './adscan/ad-scan.module';
import {BudgetModule} from './budget/budget.module';
import {CoreFilterComponent} from './core-filter.component';
import {SelectedDataParamsComponent} from './meta/selected-data-params/selected-data-params.component';
import {SelectedWebsiteParamsComponent} from './meta/selected-website-params/selected-website-params.component';
import {MenuComponent} from './menu/menu.component';
import {TopMenuComponent} from './menu/top-menu.component';
import {MainMenuLinksComponent} from './menu/main-menu-links/main-menu-links.component';
import {TopMenuLinksComponent} from './menu/main-menu-links/main-menu-links.component';
import {MetaChartSegmentComponent} from './meta/meta-chart-segment/meta-chart-segment.component';
import {MetaTabComponent} from './meta/meta-tab/meta-tab.component';
import {LiteModule} from './lite/lite.module';
import {SasZonesModule} from './sas-zones/sas-zones.module';
import {AppRoutingModule} from '../app-routing.module';
import {CoreFilterMetaComponent} from './core-filter-meta.component';

@NgModule({
	declarations: [
		CoreFilterMetaComponent,
		CoreFilterComponent,
		LiveDemoComponent,
		LiveDemoLinkComponent,
		InstallationComponent,
		InstallationDocumentComponent,
		InstallationLinkComponent,
		ConfigurationDocumentComponent,
		PageWithMessageComponent,
		SelectedDataParamsComponent,
		SelectedWebsiteParamsComponent,
		MenuComponent,
		TopMenuComponent,
		MainMenuLinksComponent,
		TopMenuLinksComponent,
		MetaChartSegmentComponent,
		MetaTabComponent,
		FaqComponent,
	],
	imports: [
		AppRoutingModule,
		AdminModule,
		SharedModule,
		DashboardModule,
		LoggedInUserModule,
		BlockRateModule,
		AdScanModule,
		BudgetModule,
		LiteModule,
		SasZonesModule,
	],
	providers: [
	]
})

export class CoreModule {}
