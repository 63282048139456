import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {AuthInterceptor} from './auth-interceptor';
import {VersionInterceptor} from './version-interceptor';
import {UnauthorizedInterceptor} from './unauthorized-interceptor';

export const HttpInterceptorProvider = [
	{ provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
