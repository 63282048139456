<div class="ui very relaxed horizontal list">
	<div class="item">
		<i class="green caret right icon"></i>
		<div class="content">
			<div class="green bold">Green</div>
			<div class="description">all zones sent to SAS</div>
		</div>
	</div>
	<div class="item">
		<i class="blue caret right icon"></i>
		<div class="content">
			<div class="blue bold">Blue</div>
			<div class="description">some zones sent to SAS</div>
		</div>
	</div>
	<div class="item">
		<i class="red caret right icon"></i>
		<div class="content">
			<div class="red bold">Red</div>
			<div class="description">no zones sent to SAS</div>
		</div>
	</div>
</div>
