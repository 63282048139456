import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User, PublisherList} from 'app/core/admin/user-management/data-structure';
import {environment} from '../../environments/environment';

export interface EditUserParameters {
	action: string;
	user: User;
}

export interface EditUserResponse {
	ok: boolean;
	message: string;
	id?: string;
	pwd?: string;
}

enum apiUrls {
	UserList = '/manage_users',
	UserDetails = '/manage_users/user',
	PubwebList = '/manage_users/user_pubweb_list',
	UserRights = '/manage_users/user_rights',
	UserRightGroups = '/manage_users/user_right_groups',
	UserInvite = '/manage_users/invite'
}

@Injectable()
export class UserManagementResource {
	constructor(private httpClient: HttpClient) {
	}

	get(id: string): Observable<User> {
		return this.httpClient.get<User>(
			`${environment.apiEndpoint}${apiUrls.UserDetails}/${id}`
		);
	}

	post(params: EditUserParameters): Observable<EditUserResponse> {
		return this.httpClient.post<EditUserResponse>(environment.apiEndpoint + apiUrls.UserDetails, params);
	}

	delete(id: string): Observable<EditUserResponse> {
		return this.httpClient.delete<EditUserResponse>(`${environment.apiEndpoint}${apiUrls.UserDetails}/${id}`);
	}

	query(): Observable<User[]> {
		return this.httpClient.get<User[]>(
			environment.apiEndpoint + apiUrls.UserList
		);
	}

	userPubwebList(): Observable<PublisherList[]> {
		return this.httpClient.get<PublisherList[]>(environment.apiEndpoint + apiUrls.PubwebList);
	}

	userRights(): Observable<string[]> {
		return this.httpClient.get<string[]>(environment.apiEndpoint + apiUrls.UserRights);
	}

	userRightGroups(): Observable<string[]> {
		return this.httpClient.get<string[]>(environment.apiEndpoint + apiUrls.UserRightGroups);
	}

	inviteUser(id: string): Observable<{invite: boolean}> {
		return this.httpClient.get<{invite: boolean}>(`${environment.apiEndpoint}${apiUrls.UserInvite}/${id}`);
	}
}
