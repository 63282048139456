import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

export enum ActionType {
	ADD = 'Add',
	EDIT = 'Edit',
}

export interface BudgetReport {
	budget_output: BudgetOutput;
	date: string;
}

export interface BudgetOutput {
	name: string;
	total_count: number;
	zones: Zone[];
	adservers: AdServer[];
}
export interface AdServer {
	name: string;
	total_count: number;
	zones: Zone[];
}

export interface ZoneTag {
	id?: string;
	aab_site_id: string;
	aab_publisher_id: string;
	zone_name: string;
	name: string;
	adserver: string;
	category: string;
	showDeleteTagModal?: boolean;
	showEditTagModal?: boolean;
}

export interface Zone {
	name: string;
	website_name: string;
	aab_site_id: string;
	aab_publisher_id: string;
	count_ok: number;
	count_rtb: number;
	count_substitute: number;
	count_empty: number;
	adserver: string;
	showAddTagModal?: boolean;
	tags?: ZoneTag[];
}

export interface BudgetInput {
	aab_sites_ids: string[];
	group_by_adserver: boolean;
	group_by_day: boolean;
	group_by_raw_data: boolean;
	aggregate_by_website: boolean;
	adservers: string[];
	from_scratch: boolean;
	error_threshold: number;
	time_zone: string;
}

@Injectable()
export class BudgetResource {
	url = '/budget';

	constructor(private httpClient: HttpClient) {
	}

	post(budgetInput: BudgetInput, from: string, to: string): Observable<BudgetReport> {
		return this.httpClient.post<BudgetReport>(
			`${environment.apiEndpoint}${this.url}/${from}/${to}`, budgetInput);
	}
	postTag(zoneTag: ZoneTag)
		: Observable<ZoneTag> {
		return this.httpClient.post<ZoneTag>(`${environment.apiEndpoint}${this.url}/tag`, zoneTag);
	}

	deleteTag(tagId: string): Observable<{ok: boolean}> {
		return this.httpClient.delete<{ok: boolean}>(
			`${environment.apiEndpoint}${this.url}/tag/${tagId}`
		);
	}
	putTag(tagId: string, zoneTag: ZoneTag): Observable<{ok: boolean}> {
		return this.httpClient.put<{ok: boolean}>(
			`${environment.apiEndpoint}${this.url}/tag/${tagId}`,
			{zoneTag: zoneTag}
		);
	}

	postAdServers(aab_sites_ids, from, to): Observable<{adservers: string[]}> {
		return this.httpClient.post<{adservers: string[]}>(
			`${environment.apiEndpoint}${this.url}/adservers/${from}/${to}`, {aab_sites_ids: aab_sites_ids}
		);
	}

	getAllTags(): Observable<ZoneTag[]> {
		return this.httpClient.get<ZoneTag[]>(
			`${environment.apiEndpoint}${this.url}/tags`
		);
	}

	postCSV(budgetInput: BudgetInput, from: string, to: string): Observable<BudgetReport> {
		return this.httpClient.post<BudgetReport>(`${environment.apiEndpoint}${this.url}/csv/${from}/${to}`, budgetInput);
	}
}
